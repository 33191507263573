import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import Icon from '../icons'

const DeleteButton = ({onClick, className}) => {
  const classes = useStyle()
  return <div className={classnames(classes.delete_button, className)} onClick={onClick}>
    <Icon.Delete/>
  </div>
}

const useStyle = createUseStyles({
  delete_button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: '#ED2C39',
    cursor: 'pointer'
  },
})

export default DeleteButton
