import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    background: '#ebeffa',
    minHeight: rem(74),
    marginTop: rem(12),
    padding: [rem(8), rem(77), rem(10), rem(16)],
    borderRadius: rem(20),
    position: 'relative',
    '&:before': {
      content: "''",
      display: 'block',
      backgroundImage: `url(${require(`./banner.svg`).default})`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(64), rem(64)],
      width: rem(64),
      height: rem(64),
      position: 'absolute',
      top: rem(6),
      right: rem(4),
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: rem(-8),
      right: rem(24),
      width: 0,
      height: 0,
      display: 'block',
      zIndex: 2,
      borderLeft: [rem(8), 'solid', 'transparent'],
      borderRight: [rem(8), 'solid', 'transparent'],
      borderBottom: [rem(8), 'solid', '#ebeffa'],
      borderTop: 'none',
    },
  },
  title: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(17),
    lineHeight: rem(22),
    color: '#697896',
  },
  text: {
    marginTop: rem(2),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(13),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
}

const Banner = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.title}>Let's get acquainted</div>
    <div className={classes.text}>Fill in your profile to create more trustworthy connection with a tutor</div>
  </div>

export default injectSheet(styles)(Banner)