import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements, PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'
import classnames from 'classnames'
import {regexps} from '../../utils/regexps'
import {Input, Icon} from 'mtuikit'
import theme, {rem} from './../../theme/base'
import {Submit} from '../../ui'
import {usePaymentRequestPaymentMethodEvent} from "../../hooks";

const cardStyles = ({error}) => ({
  number: {
    base: {
      fontFamily: theme.fontFamilies.Manrope,
      color: theme.colors.charcoalGrey,
      fontSize: '16px',
      lineHeight: '24px',
      '::placeholder': {
        color: error ? theme.colors.brightOrange : theme.colors.blueyGrey,
      },
    },
    invalid: {
      color: theme.colors.brightOrange,
    }
  },
  expiry: {
    base: {},
    invalid: {}
  },
  cvc: {
    base: {},
    invalid: {}
  }
})

const checkName = value => !(value.trim().length > 0)
const checkEmail = value => !regexps.email(value)
const checkPostalCode = value => !(value.trim().length > 0)
const checkElement = value => !(value && value.complete)

const PaymentMethod = ({total, price, paymentRequest, onSuccess, onError, onClose}) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  const [submitted, setSubmitted] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    address: {postal_code: ''},
  })
  const [number, setNumber] = useState()
  const [expiry, setExpiry] = useState()
  const [cvc, setCVC] = useState()

  const isInvalid = (
    checkName(billingDetails.name)
    || checkEmail(billingDetails.email)
    || checkPostalCode(billingDetails.address.postal_code)
    || checkElement(number)
    || checkElement(expiry)
    || checkElement(cvc)
  )

  const addPaymentMethod = async () => {
    setSubmitted(true)
    if (isInvalid) return
    setFetching(true)
    const cardNumberElement = elements.getElement(CardNumberElement)

    const {paymentMethod, error} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: billingDetails
    })

    if (!error) {
      onSuccess(paymentMethod.id)
    } else {
      if (onError) onError(error)
    }

    onClose()
  }

  usePaymentRequestPaymentMethodEvent(paymentRequest, () => onClose())

  return <>
    <div className={classes.content}>
      {
        paymentRequest && <>
          <PaymentRequestButtonElement options={{paymentRequest}}/>
          <div className={classes.divider}/>
        </>
      }
      <div className={classes.block}>
        <div className={classes.label}>Card holder information</div>
        <Input
          wrapperClassname={classes.fieldset}
          className={classnames(classes.input, {error: submitted && checkName(billingDetails.name)})}
          placeholder='First and Last name'
          onChange={value => setBillingDetails({...billingDetails, name: value})}
        />
        <Input
          wrapperClassname={classes.fieldset}
          className={classnames(classes.input, {error: submitted && checkEmail(billingDetails.email)})}
          placeholder='Your email'
          onChange={value => setBillingDetails({...billingDetails, email: value})}
        />
        <Input
          wrapperClassname={classes.fieldset}
          className={classnames(classes.input, {error: submitted && checkPostalCode(billingDetails.address.postal_code)})}
          placeholder='Zip code'
          onChange={value => setBillingDetails({...billingDetails, address: {postal_code: value}})}
        />
      </div>
      <div className={classes.block}>
        <div className={classes.label}>Card information</div>
        <div className={classnames(classes.fieldset)}>
          <CardNumberElement
            className={classnames(classes.input, {error: submitted && checkElement(number)})}
            options={{style: cardStyles({error: submitted && checkElement(number)}).number}}
            onChange={setNumber}
          />
          <div className={classes.icons}>
            {
              (!number || number.brand === 'unknown')
                ? <>
                  <Icon iconName='ic_mastercard'/>
                  <Icon iconName='ic_visa'/>
                  <Icon iconName='ic_amex'/>
                  <Icon iconName='ic_discover'/>
                </>
                : <Icon iconName={`ic_${number?.brand}`}/>
            }
          </div>
        </div>
        <div className={classes.fields}>
          <div className={classnames(classes.fieldset)}>
            <CardExpiryElement
              className={classnames(classes.input, {error: submitted && checkElement(expiry)})}
              options={{style: cardStyles({error: submitted && checkElement(expiry)}).number}}
              onChange={setExpiry}
            />
          </div>
          <div className={classnames(classes.fieldset)}>
            <CardCvcElement
              className={classnames(classes.input, {error: submitted && checkElement(cvc)})}
              options={{style: cardStyles({error: submitted && checkElement(cvc)}).number}}
              onChange={setCVC}
            />
            <Icon className={classes.svvIcon} iconName='ic_cvv'/>
          </div>
        </div>
      </div>
    </div>

    <div className={classes.footer}>
      <Submit
        className={classes.submit}
        text='Pay Now'
        total={total}
        price={price}
        onSubmit={addPaymentMethod}
        disabled={fetching}
      />
    </div>
  </>
}

const useStyles = createUseStyles({
  content: {
    flex: 1,
    padding: [16, 24, 24],
    fallbacks: [
      {overflowY: 'auto'}, // for Mozilla
      {overflowY: 'overlay'},
    ],
  },
  divider: {
    marginTop: rem(16),
    marginBottom: rem(16),
    textAlign: 'center',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: rem(1),
      background: '#E8EEF2',
      height: rem(1),
    },
    '&:after': {
      content: '"Or pay with card"',
      background: theme.colors.white,
      padding: [0, rem(8)],
      color: theme.colors.slateGrey,
      fontSize: rem(14),
      lineHeight: rem(20),
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
    },
  },
  block: {
    '& + &': {
      marginTop: rem(24),
    },
  },
  label: {
    color: theme.colors.charcoalGrey,
    fontSize: rem(14),
    lineHeight: rem(20),
  },
  fieldset: {
    marginTop: rem(8),
    position: 'relative',
  },
  icons: {
    position: 'absolute',
    top: rem(12),
    right: rem(12),
    display: 'block',
    '& > svg': {
      width: rem(36),
      height: rem(24),
      display: 'inline-block',
      verticalAlign: 'top',
      '& + svg': {
        marginLeft: rem(4),
      },
    },
  },
  svvIcon: {
    width: rem(36),
    height: rem(24),
    position: 'absolute',
    display: 'block',
    top: rem(12),
    right: rem(12),
  },
  input: {
    padding: [rem(12), rem(40), rem(12), rem(16), '!important'],
    border: [rem(1), 'solid', '#BAC7E2', '!important'],
    borderRadius: [rem(8), '!important'],
    fontSize: [rem(17), '!important'],
    lineHeight: [rem(24), '!important'],
    transition: 'border 300ms ease',
    '&:focus': {
      borderColor: `${theme.colors.blue} !important`,
    },
    '&:-moz-placeholder': {
      color: theme.colors.blueyGrey,
    },
    '&::-moz-placeholder': {
      color: theme.colors.blueyGrey,
    },
    '&:-ms-input-placeholder': {
      color: theme.colors.blueyGrey,
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.blueyGrey,
    },
    '&.StripeElement--invalid': {
      borderColor: [theme.colors.brightOrange, '!important'],
    },
    '&.error': {
      borderColor: [theme.colors.brightOrange, '!important'],
    }
  },
  fields: {
    '& > $fieldset': {
      width: `calc(50% - ${rem(4)})`,
      display: 'inline-block',
      verticalAlign: 'top',
      '&:first-child': {
        marginRight: rem(4),
      },
      '&:last-child': {
        marginLeft: rem(4),
      },
    },
  },
  footer: {
    padding: rem('24 16'),
  },
})

export default PaymentMethod
