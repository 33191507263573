import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, desktop, adaptive, ui } from '../../../theme/base'
import { Logos } from '../../components'
import Slider from './Slider'

const styles = {
  root: {
    minHeight: rem(857),
    marginTop: rem(-80),
    paddingTop: rem(160),
    paddingBottom: rem(80),
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    [adaptive]: {
      paddingLeft: rem(8),
      paddingRight: rem(8),
      paddingBottom: rem(40),
      overflow: 'hidden',
    },
    '&:before': {
      content: '""',
      display: 'block',
      background: 'linear-gradient(180deg, rgba(60, 120, 255, 0.2) 0%, rgba(60, 120, 255, 0) 100%)',
      height: rem(857),
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
    },
  },
  content,
  trusted: {
    color: theme.colors.slateGrey,
    textAlign: 'center',
  },
  logos: {
    marginTop: rem(16),
  },
  h1: {
    ...ui.title,
    paddingBottom: rem(20),
    [adaptive]: {
      fontSize: rem(24),
      lineHeight: rem(32),
    },
  },
  features: {
    marginTop: rem(80),
    [adaptive]: {
      marginTop: rem(80),
    },
  },
  feature: {
    width: '100%',
    position: 'relative',
    [desktop]: {
      height: rem(496),
      display: 'flex',
      alignItems: 'center',
    },
    '& + &': {
      marginTop: rem(80),
    },
    '&:nth-child(2n+1)': {
      [desktop]: {
        paddingLeft: rem(80),
        justifyContent: 'flex-start',
      },
      '& $image': {
        [desktop]: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
      },
    },
    '&:nth-child(2n)': {
      [desktop]: {
        justifyContent: 'flex-end',
        paddingRight: rem(80),
      },
      '& $block': {
        [desktop]: {
          marginLeft: rem(728),
        },
      },
      '& $image': {
        [desktop]: {
          left: 0,
        },
      },
    },
  },
  block: {
    [desktop]: {
      width: rem(392),
    },
  },
  image: {
    position: 'relative',
    [desktop]: {
      width: rem(704),
      position: 'absolute',
      top: 0,
    },
    [adaptive]: {
      width: '91vw',
      margin: [rem(16), 'auto', 0],
      '& > img': {
        width: 'inherit',
        height: 'auto',
      },
    },
    '& > img': {
      width: '100%',
    },
  },
  opacityImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    display: 'block',
    transition: 'opacity 300ms ease',
  },
  number: {
    background: theme.colors.blue,
    width: rem(48),
    height: rem(48),
    padding: [rem(14), 0, rem(10)],
    textAlign: 'center',
    fontSize: rem(24),
    lineHeight: rem(24),
    letterSpacing: rem(0.192),
    fontWeight: 600,
    color: theme.colors.white,
    fontFamily: theme.fontFamilies.Manrope,
    borderRadius: '50%',
    boxShadow: [
      [0, rem(4), rem(8), 'rgba(86, 93, 107, 0.1)'],
      [0, rem(8), rem(16), 'rgba(186, 207, 255, 0.232903)']
    ],
    display: 'inline-block',
    verticalAlign: 'top',
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(32),
    lineHeight: rem(40),
    letterSpacing: rem(0.192),
    fontWeight: 800,
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    [adaptive]: {
      fontSize: rem(24),
      lineHeight: rem(32),
    },
  },
  subtitle: {
    marginTop: rem(16),
    fontSize: rem(18),
    lineHeight: rem(28),
    letterSpacing: rem(0.108),
    color: theme.colors.slateGrey,
    fontFamily: theme.fontFamilies.Manrope,
  },
}

const HowItWorks = ({
  classes,
  title,
  items,
}) => {
  const SLIDER_ITEMS = [
    [
      require('./img_1_1.png').default,
      require('./img_1_2.png').default,
      require('./img_1_3.png').default,
      require('./img_1_4.png').default
    ],
  ]

  const FEATURES = [
    {
      title: items[0].title,
      subtitle: items[0].subtitle,
      image: <Slider items={SLIDER_ITEMS[0]} />,
    },
    {
      title: items[1].title,
      subtitle: items[1].subtitle,
      image: <img src={require('./img_2_1.png').default} alt='' />,
    },
    {
      title: items[2].title,
      subtitle: items[2].subtitle,
      image: <img src={require('./img_3_1.png').default} alt='' />,
    },
    {
      title: items[3].title,
      subtitle: items[3].subtitle,
      image: <img src={require('./img_4_1.png').default} alt='' />,
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.trusted}>Trusted by students from:</div>
        <Logos className={classes.logos} />
        <div className={classes.features}>
          <div className={classes.h1}>{title}</div>
          <div>
            {FEATURES.map(({ title, subtitle, image }, index) =>
              <div className={classes.feature} key={index}>
                <div className={classes.block}>
                  <div className={classes.number}>{index + 1}</div>
                  <div className={classes.title}>{title}</div>
                  <div className={classes.subtitle}>{subtitle}</div>
                </div>
                <div className={classes.image}>{image}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(styles)(HowItWorks)