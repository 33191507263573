import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, {rem} from './../../theme/base'
import {Icon} from 'mtuikit'

const styles = {
  root: {
    background: 'transparent',
    minHeight: rem(52),
    padding: [rem(14), rem(24)],
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.charcoalGrey,
    textAlign: 'left',
    cursor: 'pointer',
    position: 'relative',
    transition: 'background 300ms ease-in-out',
    '&:hover': {
      background: '#F6F7FB',
    },
    '&:active': {
      background: theme.colors.paleGrey,
    },
    '&.disabled': {
      color: theme.colors.blueyGrey,
      cursor: 'default',
      '& svg': {
        opacity: 0.3,
      },
    },
    '&.alert': {
      color: theme.colors.brightOrangeTwo,
      marginBottom: 0,
      border: 'none',
    },
    '& svg': {
      fill: theme.colors.blue,
      width: rem(16),
      height: rem(16),
      marginRight: rem(12),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  text: {
    fontSize: rem(16),
    lineHeight: rem(22),
    display: 'inline-block',
    verticalAlign: 'middle',
    '&.bold': {
      fontWeight: 800,
    },
  },
  description: {
    marginTop: rem(4),
    paddingLeft: rem(28),
    fontSize: rem(13),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
  divider: {
    background: theme.colors.paleGrey,
    height: rem(1),
    margin: [rem(8), rem(16)],
  },
}

const Action = injectSheet(styles)(
  ({
     classes,
     disabled,
     alert,
     divider,
     bold,
     icon,
     text,
     description,
     onClick,
     onClose,
   }) =>
    <>
      <div
        className={classnames(classes.root, {disabled, alert})}
        onClick={() => {
          if (!disabled) {
            onClose()
            onClick()
          }
        }}
      >
        {icon && <Icon iconName={icon}/>}
        <div className={classnames(classes.text, { bold })}>{text}</div>
        {description && <div className={classes.description}>{description}</div>}
      </div>
      {divider && <div className={classes.divider} />}
    </>
)

const Actions = ({actions, onClose}) => actions.map(action => {
  const actionProps = {...action, onClose}
  return action.copyByClick
    ? (
      <CopyToClipboard key={action.text} text={action.copyByClick} onCopy={onClose}>
        <Action {...actionProps} />
      </CopyToClipboard>
    )
    : <Action key={action.text} {...actionProps} />
})

export default Actions