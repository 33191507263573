import { PROFILE_AVATARS } from './../config'

const getProfileAvatarByEmail = (email) => {
  const index = email.length % PROFILE_AVATARS.length
  const avatarByEmail = PROFILE_AVATARS[index]
  const avatarByEmailUrl = require(`./../assets/images/avatars/${avatarByEmail}`).default

  return avatarByEmailUrl
}

export default getProfileAvatarByEmail