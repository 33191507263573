import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'

const styles = {
  root: {
    padding: [rem(21), rem(64), rem(20)],
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    fontSize: rem(19),
    lineHeight: rem(22),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    right: rem(20),
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
  back: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    left: rem(20),
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: theme.colors.blue,
    },
  }
}

const Header = ({
  onClose,
  onBack,
  children,
  classes,
  className,
}) =>
  <div className={classnames(classes.root, className)}>
    <div className={classes.title}>
      {onBack &&
        <span className={classes.back} onClick={onBack}>
          <Icon iconName='back' />
        </span>
      }
      {children}
    </div>
    <div className={classes.close} onClick={onClose}>
      <Icon iconName='close' />
    </div>
  </div>

export default injectSheet(styles)(Header)
