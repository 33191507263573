import React from 'react'
import {createUseStyles} from 'react-jss'
import {Icon, Button, theme, utils} from 'mtuikit'
import {
  useOrder,
  useTutorQuery,
  useToggleFavTutorMutation
} from '../../hooks'
import {getUserAvatarUrl} from '../../mtuikit/utils/getUserAvatarUrl'
import {rem, adaptive, desktop} from 'mtuikit/theme/base'
import {useActions} from '../../actions'


export const TutorDetails = ({tutorId, orderId}) => {
  const classes = useStyles()
  const actions = useActions()

  const order = useOrder(orderId)

  const tutor_id = tutorId
    || (order.assigned && order.assigned.freelancer_id)
    || (order.connected && order.connected.freelancer_id)

  const tutorsQuery = useTutorQuery(tutor_id)

  const toggleFavTutorMutation = useToggleFavTutorMutation(tutor_id)
  const toggleFavorite = () => toggleFavTutorMutation.mutate()

  const rejectTutor = () => {
    actions.showOrderChat(orderId)
    actions.showRejectTutorPrompt({orderId, tutorId: tutor_id})
  }

  if (!tutorsQuery.isSuccess) {
    return null
  }

  const {
    id,
    name,
    created_at,
    my_last_subject,
    avg_score,
    orders_count,
    is_favorite,
    timezone,
    about,
    subjects,
  } = tutorsQuery.data

  const months_on_nt = utils.getDiffInMonths(created_at)

  return (
    <div className={classes.tutorDetails}>
      <img className={classes.avatar} src={getUserAvatarUrl(id)} alt=''/>
      <div className={classes.name}>{name}</div>
      <div className={classes.mainSubject}>{my_last_subject}</div>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.stats}>
            <div className={classes.item}>
              <div className={classes.value}>
                <span>{avg_score}</span>
                <Icon iconName='star'/>
              </div>
              <div className={classes.statsName}>average score</div>
            </div>
            <div className={classes.item}>
              <div className={classes.value}>
                {orders_count} {orders_count === 1 ? 'task' : 'tasks'}
              </div>
              <div className={classes.statsName}>completed</div>
            </div>
            <div className={classes.item}>
              <div className={classes.value}>
                {months_on_nt} {months_on_nt === 1 ? 'month' : 'months'}
              </div>
              <div className={classes.statsName}>on Nerdy Tutors</div>
            </div>
          </div>
          <div className={classes.buttons}>
            {
              orderId && !order.assigned &&
              <Button type='outline' onClick={rejectTutor}>Reject tutor</Button>
            }
            <Button type='outline' onClick={toggleFavorite}>
              <Icon iconName='favorite_blue'/>
              <span>{is_favorite ? 'Remove from favorites' : 'Add to favorites'}</span>
            </Button>
          </div>
        </div>
        <div className={classes.body}>
          {timezone &&
          <div className={classes.block}>
            <div className={classes.title}>Tutor’s time</div>
            <div className={classes.timezone}>
              <Icon iconName={utils.isUserDaylightHours(timezone) ? 'sun2' : 'moon2'}/>
              <span>{utils.printTimezoneDateTime(timezone)}</span>
            </div>
          </div>
          }
          {about &&
          <div className={classes.block}>
            <div className={classes.title}>About</div>
            <div className={classes.about}>{about}</div>
          </div>
          }
          {subjects?.length > 0 &&
          <div className={classes.block}>
            <div className={classes.title}>Subjects</div>
            <div className={classes.subjects}>
              {subjects.map((subject, index) =>
                <div className={classes.subject} key={index}>{subject}</div>
              )}
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  tutorDetails: {
    padding: rem(24),
    [adaptive]: {
      background: '#F6F7FB',
      padding: [rem(16), 0, 0, 0],
    },
  },
  avatar: {
    width: rem(104),
    height: rem(104),
    margin: [0, 'auto'],
    borderRadius: '50%',
  },
  name: {
    marginTop: rem(16),
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 700,
    color: theme.colors.charcoalGrey,
    textAlign: 'center',
  },
  mainSubject: {
    marginTop: rem(8),
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.slateGrey,
    textAlign: 'center',
  },
  wrapper: {
    background: theme.colors.white,
    width: rem(720),
    margin: [rem(20), 'auto', 0],
    padding: rem(24),
    borderRadius: rem(16),
    [adaptive]: {
      width: '100%',
      padding: rem(16),
    },
  },
  body: {
    borderTop: [1, 'solid', theme.colors.paleGrey],
  },
  header: {
    paddingBottom: rem(24),
  },
  stats: {
    textAlign: 'center',
  },
  item: {
    margin: [0, rem(20)],
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  value: {
    height: rem(24),
    color: theme.colors.slateGrey,
    fontSize: rem(18),
    lineHeight: rem(24),
    fontWeight: 800,
    '& > svg': {
      fill: '#f7b500',
      width: rem(16),
      height: rem(16),
      marginTop: rem(4),
      marginLeft: rem(6),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  statsName: {
    color: theme.colors.blueyGrey,
    fontSize: rem(12),
    lineHeight: rem(20),
  },
  buttons: {
    marginTop: rem(24),
    textAlign: 'center',
    [adaptive]: {
      margin: [rem(24), rem(-8), 0],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& button': {
      fontSize: rem(14),
      lineHeight: 1,
      [desktop]: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: rem(200),
        margin: [0, rem(4)],
        '&:only-child': {
          width: rem(320),
        },
      },
      [adaptive]: {
        width: 'calc(50% - 4px)',
      },
      '& > svg': {
        width: rem(16),
        height: rem(16),
        marginRight: rem(4),
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      '& > span': {
        display: 'inline-block',
        verticalAlign: 'middle',
      },
    },
  },
  block: {
    marginTop: rem(24),
  },
  title: {
    fontSize: rem(16),
    lineHeight: rem(22),
    fontWeight: 800,
    color: theme.colors.charcoalGrey,
  },
  subjects: {
    marginTop: rem(8),
  },
  subject: {
    background: theme.colors.paleGrey,
    marginRight: rem(8),
    marginTop: rem(8),
    padding: [rem(6), rem(12)],
    borderRadius: rem(16),
    fontSize: rem(14),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  about: {
    marginTop: rem(16),
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.charcoalGrey,
  },
  timezone: {
    marginTop: rem(16),
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.charcoalGrey,
    '& > svg': {
      fill: theme.colors.charcoalGrey,
      width: rem(16),
      height: rem(16),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
})
