import React from 'react'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {createUseStyles} from 'react-jss'
import TopUpBalanceBundle from '../../../TopUpBalanceBundle'
import {useBundle} from '../../../../hooks'
import {useActions} from '../../../../actions'
import Layout from './layout'
import theme from '../../../../theme/base'
import Countdown from '../../../Countdown'
import {utils} from 'mtuikit'


const OfferBalanceTopUpBundleEvent = ({bundle_id, subject, data, ...props}) => {
  const classes = useStyles()
  const actions = useActions()
  const bundle = useBundle(bundle_id)

  if (!bundle) return <div style={{height: 250}}/>

  const {is_used: isUsed, is_expired: isExpired} = bundle
  const usedBundleItem = bundle.items.find(({item_id}) => item_id === bundle.used_item_id)

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <Card {...props} data={{...data}}>
      <div className={classes.root}>
        {
          isUsed
            ? <>
              <div className={classes.header2}>
                <img className={classes.header2_icon} src={require('../images/em_party-popper.svg').default} alt=''/>
                <div className={classes.header2_title}>
                  {utils.printAmount(usedBundleItem.price + usedBundleItem.topup_amount, {trunc: true})} added to your
                  balance
                </div>
              </div>
              <div className={classes.description}>
                Check your balance in your <Layout.Action onClick={() => actions.onMenu()}>profile menu</Layout.Action>.
                We'll automatically apply your balance for your next tasks.
              </div>
            </>
            : <>
              {
                isExpired
                  ? <div className={classes.header}>
                    <img className={classes.header_icon} src={require('../images/ic_limited-time.svg').default} alt=''/>
                    <div className={classes.header_msg}>The deal already expired</div>
                  </div>
                  : <div className={`${classes.header} ${classes.header_active}`}>
                    <img className={classes.header_icon} src={require('../images/ic_limited-time_active.svg').default}
                         alt=''/>
                    <div className={classes.header_time}><Countdown dl={bundle.bundle_expire_at}/></div>
                    <div className={`${classes.header_msg} ${classes.header_msg_active}`}>Limited-time offer!</div>
                  </div>
              }
              <div className={classes.title}>
                Save on your next tasks!
                <img className={classes.title_icon} src={require('../images/em_money-bag@2x.png').default} alt=''/>
              </div>
              <div className={classes.description}>Top up your NerdyTutors account balance now to save on future tasks.
              </div>
            </>
        }
        <TopUpBalanceBundle bundle={bundle} className={'chat'} clickSource='chat'/>
      </div>
      <Card.Footer {...props} data={{...data}}/>
    </Card>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  root: {
    fontFamily: theme.fontFamilies.Manrope,
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: [10, 18],
    backgroundColor: 'rgba(61, 119, 255, 0.08)',
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    margin: [-16, -16, 0],
    borderRadius: [2, 20, 0, 0],
  },
  header_active: {
    backgroundColor: '#FFEEB8',
  },
  header_icon: {
    display: 'block',
    width: 20,
    height: 20,
  },
  header_time: {
    color: '#E37900',
    marginLeft: 4,
    minWidth: 65,
  },
  header_msg: {
    color: '#939FB8;',
    marginLeft: 4,
  },
  header_msg_active: {
    color: '#2F333D',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '24px',
    color: '#2F333D',
    marginTop: 16,
  },
  title_icon: {
    display: 'block',
    width: 20,
    height: 20,
    marginLeft: 4,
  },
  description: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
    marginTop: 4,
  },
  header2: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header2_icon: {
    display: 'block',
    width: 24,
    height: 24,
  },
  header2_title: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '24px',
    color: '#2F333D',
    marginLeft: 8,
  },
})
export default OfferBalanceTopUpBundleEvent
