import React, {useRef} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {Link, useHistory} from 'react-router-dom'
import {Button} from 'mtuikit'
import useOutsideClick from '../../utils/useOutsideClick'
import theme, {rem, desktop} from './../../theme/base'
import {
  Avatar,
  Bonus,
  Close,
  Email,
  Items,
  Logout,
  Name,
  Pages,
  Phone,
  Settings,
  Wrapper,
} from './Layout'
import {useAuth, useProfileQuery} from 'hooks'

const styles = {
  root: {
    background: theme.colors.blue,
    width: '83%',
    paddingBottom: rem(24),
    position: 'fixed',
    top: 0,
    bottom: 0,
    overflowY: 'auto',
    [desktop]: {
      width: rem(360),
    }
  },
  logo: {
    width: rem(160),
    height: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'absolute',
    top: rem(15),
    left: '50%',
    transform: 'translateX(-50%)',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  actions: {
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    bottom: rem(16),
    '& button': {
      borderRadius: rem(16),
    },
  },
  login: {
    marginTop: rem(16),
    fontSize: rem(20),
    lineHeight: rem(24),
    textAlign: 'center',
    color: theme.colors.blue,
    fontWeight: 600,
    fontFamily: theme.fontFamilies.Manrope,
    cursor: 'pointer',
  }
}

const Menu = ({
                classes,
                zIndex,
                onFaq,
                onSupport,
                onSettings,
                onClose,
                onLogout,
              }) => {
  const history = useHistory()
  const ref = useRef()
  const profile = useProfileQuery()
  const {isLogged} = useAuth()

  const _onLogout = () => {
    onClose()
    onLogout()
  }
  const _onFaq = () => {
    onClose()
    onFaq()
  }
  const _onSupport = () => {
    onClose()
    onSupport()
  }
  const onSignUp = () => {
    onClose()
    history.push('/signup')
  }
  const onLogin = () => {
    onClose()
    history.push('/login')
  }

  const _onSettings = () => {
    //onSettings()
    onClose()
  }

  const items = [
    {name: 'Frequently Asked Questions', icon: 'menu-faq', onClick: _onFaq},
    {name: 'Contact Support', icon: 'menu-support', onClick: _onSupport},
  ]

  useOutsideClick(ref, onClose)

  return isLogged
    ? (
      <div className={classnames(classes.root)} style={{zIndex}} ref={ref}>
        <Wrapper>
          <Avatar email={profile.data.email}/>
          {
            profile.data.name &&
            <Name>{profile.data.name}</Name>
          }
          {profile.data.phone && <Phone>{profile.data.phone}</Phone>}
          <Email>{profile.data.email}</Email>
          {profile.data.balance > 0 && <Bonus balance={profile.data.balance || 0}/>}
          <Close onClose={onClose}/>
          <Settings onClick={_onSettings}/>
        </Wrapper>
        <Items items={items}/>
        <Pages onClose={onClose}/>
        <Logout onLogout={_onLogout}/>
      </div>
    )
    : (
      <div className={classnames(classes.root)} style={{zIndex}} ref={ref}>
        <Wrapper>
          <Link className={classes.logo} to='/'>
            <img src={require('../../assets/images/logo-blue.svg').default} alt=''/>
          </Link>
          <div className={classes.actions}>
            <Button
              type='primary'
              size='large'
              block
              onClick={onSignUp}
            >
              Sign up
            </Button>
            <div className={classes.login} onClick={onLogin}>Login</div>
          </div>
        </Wrapper>
      </div>
    )
}

export default injectSheet(styles)(Menu)
