import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {useQueryClient, QueryClient, QueryClientProvider} from 'react-query'
import {render} from 'react-dom'
import TagManager from 'react-gtm-module'
import queryString from 'query-string'
import {Provider, useSelector, useDispatch} from 'react-redux'
import {ConnectProvider} from '@connect/connect-xmpp-v2'
import * as Sentry from '@sentry/browser'
import V4LandingPage from './landings/v4'
import {PrivacyPolicy, TermsAndConditions, ProfileSettings, BrowserInstructions} from './landings/pages'
import {AuthEmailPage} from './components/AuthEmailPage'
import {Workarea} from './components/Workarea'
import NewOrderRedirect from './components/NewOrderRedirect'
import SupportChatRedirect from './components/SupportChatRedirect'
import NotFoundPage from './pages/404Page'
import MaintenancePage from './pages/500Page'
import {ConnectingPage2} from './pages/ConnectingPage2'
import {ConnectingPage3} from './pages/ConnectingPage3'
import OperatorBackdoor from './auth/OperatorBackdoor'
import ClientBackdoor from './auth/ClientBackdoor'
import {modals} from './modals'
import {
  useAuth,
  useOrdersQuery,
  useProfileQuery,
  useTutorsQuery,
  useXmppConfig,
  useStripeCustomerQuery,
  useListAllCardsQuery
} from './hooks'
import {Notifications} from './components/Notifications'
import {OrderForm} from './components/OrderFormV5'
import ScrollToTopOnMount from './containers/ScrollToTopOnMount'
import lrtracker, {TrackLanding} from './lrtracker'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import store from './store'
import hotjar from './hotjar'
import {extendOrderChatApi} from './api'
import {getOrderChatExtendDatetime} from './nerdytutors'
import audioNotification from './audio-notification'
import {SupportChatProvider} from './components/SupportChat'
import {ChatOnboardProvider} from './components/Chat/ChatOnboard'
import {
  PushNotificationsProvider,
  showInstantSupportNotification,
  showInstantTutorNotification
} from './push-notifications'

import './assets/reset.css'
import './assets/styles.scss'


const Manager = modals.createManager({useSelector, useDispatch})
const stripeFonts = [{
  cssSrc: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap',
}]


const RedirectToMainPage = props => (
  <Route {...props} render={({location}) => <Redirect to={{pathname: '/', state: {from: location}}}/>}/>
)

const LoginRequiredRouter = ({component, ...rest}) => {
  const {isLogged} = useAuth()
  const profileQuery = useProfileQuery()
  const ordersQuery = useOrdersQuery()
  const tutorsQuery = useTutorsQuery()
  const stripeCustomer = useStripeCustomerQuery()
  const listAllCardsQuery = useListAllCardsQuery()

  const {cid} = profileQuery.data || {}
  useEffect(() => {
    if (!cid) return
    lrtracker.config.setCid(cid)

    if (window.hj) {
      window.hj('identify', cid, {
        ordersCount: profileQuery.data.orders_stat.orders_count
      })
    }

    // eslint-disable-next-line
  }, [cid])

  return (
    <Route
      {...rest}
      render={props => {
        const {tutorId, orderId} = props.match.params

        const redirect = props.location.pathname === '/new-order' ? '/signup' : '/login'
        const isReady = profileQuery.isSuccess && ordersQuery.isSuccess && tutorsQuery.isSuccess && stripeCustomer.isSuccess && listAllCardsQuery.isSuccess

        const orderIdOrTutorIdError = (
          (isReady && orderId && ordersQuery.data.orders.find(o => o.id === orderId) === undefined)
          || (isReady && tutorId && tutorsQuery.data.freelancers.find(t => t.id === tutorId) === undefined)
        )

        if (!isLogged) {
          return <Redirect to={{pathname: redirect, state: {...props.location.state, from: props.location.pathname}}}/>
        }

        if (orderIdOrTutorIdError) {
          return <Redirect to={{pathname: '/orders', state: {...props.location.state, from: props.location.pathname}}}/>
        }

        return isReady
          ? <Elements stripe={loadStripe(stripeCustomer.data.public_key)} options={{fonts: stripeFonts}}>
            <PushNotificationsProvider cid={profileQuery.data.cid}>
              {React.createElement(
                component,
                {
                  profile: profileQuery.data,
                  orders: ordersQuery.data
                }
              )}
              <Manager/>
              <Notifications/>
            </PushNotificationsProvider>
          </Elements>
          : props.location.pathname === '/order-form'
            ? <ConnectingPage3/>
            : <ConnectingPage2/>
      }}
    />
  )
}

const NoAuthRequiredRouter = ({component, ...rest}) => {
  const {isLogged} = useAuth()
  return (
    <Route
      {...rest}
      render={
        ({location}) => {
          const next = (
            queryString.parse(location.search).next
            || location.state?.from
            || '/orders'
          )
          return isLogged
            ? <Redirect to={{pathname: next, state: location.state}}/>
            : React.createElement(component)
        }
      }
    />
  )
}

const skipScrollRoutes = ['/order/:orderId/:tab']

const App = () => {
  const xmppConfig = useXmppConfig()
  const queryClient = useQueryClient()

  const _onMessageHandler = ({message, config, node}) => {
    onMessageHandler({message, config, node}, {queryClient})
  }

  return (
    <ConnectProvider
      xmppConfig={xmppConfig}
      onMessage={_onMessageHandler}
    >
      <Router>
        <TrackLanding/>
        <ScrollToTopOnMount skipScrollRoutes={skipScrollRoutes}>
          <SupportChatProvider>
            <Switch>
              <NoAuthRequiredRouter exact path="/" component={V4LandingPage}/>
              <NoAuthRequiredRouter exact path="/homework-help-3" component={V4LandingPage}/>
              <NoAuthRequiredRouter exact path="/tutoring" component={V4LandingPage}/>
              <NoAuthRequiredRouter exact path="/tutoring-help-1" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/10-OFF-yt1" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/homework-help-1" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/homework-help-2" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/10-OFF-yt2" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/landing-page-2" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/early-access" component={RedirectToMainPage}/>
              <NoAuthRequiredRouter exact path="/login" component={AuthEmailPage.Login}/>
              <NoAuthRequiredRouter exact path="/signup" component={AuthEmailPage.Signup}/>
              <NoAuthRequiredRouter exact path="/restore" component={AuthEmailPage.Restore}/>
              <LoginRequiredRouter exact path="/order-form" component={OrderForm}/>
              <LoginRequiredRouter exact path="/new-order" component={NewOrderRedirect}/>
              <LoginRequiredRouter exact path="/support-chat" component={SupportChatRedirect}/>
              <LoginRequiredRouter exact path="/:folder(tutors)/:tutorId?" component={Workarea}/>
              <LoginRequiredRouter exact path="/:folder(orders)/:orderId?/:target(chat|task|tutor)?"
                                   component={Workarea}/>
              <LoginRequiredRouter exact path="/settings" component={ProfileSettings}/>
              <Route exact path="/operator-login/:token" component={OperatorBackdoor}/>
              <Route exact path="/:target(o|s)_:token" component={ClientBackdoor}/>
              <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
              <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
              <Route exact path="/browser-instructions" component={BrowserInstructions}/>
              <Route exact path="/500" component={MaintenancePage}/>
              <Route component={NotFoundPage}/>
            </Switch>
          </SupportChatProvider>
        </ScrollToTopOnMount>
      </Router>
    </ConnectProvider>
  )
}

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({gtmId: process.env.REACT_APP_GTM_ID})
}

if (process.env.REACT_APP_GTM2_ID) {
  TagManager.initialize({gtmId: process.env.REACT_APP_GTM2_ID})
}


if (process.env.REACT_APP_HOTJAR) {
  hotjar()
}

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY})
}

lrtracker.metrics.trackLocation()
lrtracker.metrics.trackGoogleClientId()


const onMessageHandler = ({message, config, node}, {queryClient}) => {
  const {order_id, freelancer_id, bundle_id} = message.event?.event_params || {}

  if (order_id) {
    queryClient.invalidateQueries('orders')
    queryClient.invalidateQueries(['orders', order_id])
  }

  if (freelancer_id) {
    queryClient.invalidateQueries('tutors')
    queryClient.invalidateQueries(['tutors', freelancer_id])
  }

  if (order_id || freelancer_id) {
    queryClient.invalidateQueries('profile')
  }

  if (bundle_id) {
    queryClient.invalidateQueries('bundles')
  }

  if (message.from !== config.userJid) audioNotification()

  if (node.startsWith('crm:orders:order') && message.from === config.userJid) {
    const orderId = node.split(':')[3]
    const {orders} = queryClient.getQueryData('orders')
    const order = orders.find(o => o.id === orderId)
    const extendDatetime = getOrderChatExtendDatetime(order)
    if (extendDatetime) {
      extendOrderChatApi(order.id, extendDatetime)
    }
  }

  if (node.startsWith('crm:orders:order') && message.from !== config.userJid) {
    const orderId = node.split(':')[3]
    if (window.location.pathname.includes(orderId) && document.hasFocus()) return
    showInstantTutorNotification(orderId, message)
  }

  if (node.startsWith('smstool:support:user') && message.from !== config.userJid) {
    if (document.hasFocus()) return
    showInstantSupportNotification(message)
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 1
    },
  },
})

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ChatOnboardProvider>
        <App/>
      </ChatOnboardProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
)


