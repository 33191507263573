import React from 'react'
import moment from 'moment-timezone'
import {useActions} from '../../actions'
import {useOrderQuery, useOrder} from '../../hooks'
import {createUseStyles} from 'react-jss'
import {Icon, Button, theme, utils} from 'mtuikit'
import {rem} from 'mtuikit/theme/base'
import classnames from 'classnames'
import OrderNumber from '../Orders/OrderNumber'
import Countdown from '../Countdown'


const Subheader = ({back, stat, button, action, onMore, mobile = false}) => {
  const classes = useStyle()
  return (
    <div className={classes.subheader}>
      <div>
        {back && (
          <div className={classes.item}>
            <div className={classes.back} onClick={back.onClick}>
              <Icon iconName='back_input'/>
              <span>{back.text}</span>
            </div>
          </div>
        )}
        {stat && stat.map(({iconName, title, value}) => (
          <div className={classnames(classes.item, 'withIcon')} key={title}>
            <Icon iconName={iconName}/>
            <div className={classes.title}>{title}</div>
            <div className={classes.value}>{value}</div>
          </div>
        ))}
        {!mobile && button && (
          <div className={classes.item}>
            <Button className={classes.button} onClick={button.onClick}>{button.text}</Button>
          </div>
        )}
      </div>
      <div>
        <div className={classes.item}>
          {action && (
            <div onClick={action.onClick} className={classes.link}>
              {action.text}
            </div>
          )}
          {mobile && button && (
            <div className={classes.item}>
              <Button className={classes.mobileButton} onClick={button.onClick}>{button.text}</Button>
            </div>
          )}
          <div className={classes.details} onClick={onMore}>
            <Icon iconName='more_rounded'/>
          </div>
        </div>
      </div>
    </div>
  )
}

const searchLiveSession = (order, status) => {
  return order.live_sessions ? order.live_sessions.find(liveSession => liveSession.status === status) : null
}

const getOrderSubtitle = (order) => {
  if (order.refunded) {
    return {
      iconName: 'calendar_decorative',
      title: 'Task was refunded on',
      value: utils.printDateTime(order.refunded.refunded_at)
    }
  }
  if (order.closed) {
    return {
      iconName: 'calendar_decorative',
      title: 'Task was done on',
      value: utils.printDateTime(order.closed.closed_at)
    }
  }
  if (order.revision && order.revision.status === 'revision') {
    return {
      iconName: 'calendar_decorative',
      title: 'Task will be revised within',
      value: <Countdown dl={order.revision.deadline}/>
    }
  }
  if (order.revision && order.revision.status === 'requested') {
    return {
      iconName: 'calendar_decorative',
      title: 'Task will be revised within',
      value: <Countdown dl={order.revision.deadline}/>
    }
  }
  if (order.revision && order.revision.status === 'completed') {
    return {
      iconName: 'calendar_decorative',
      title: 'Сhat will be closed in',
      value: <Countdown dl={moment(order.revision.changed_at).add(24, 'hours')}/>
    }
  }
  if (order.completed) {
    return {
      iconName: 'calendar_decorative',
      title: 'Сhat will be closed in',
      value: <Countdown dl={moment(order.completed.completed_at).add(24, 'hours')}/>
    }
  }
  const upcomingLiveSession = searchLiveSession(order, 'upcoming')
  if (upcomingLiveSession) {
    return {
      iconName: 'calendar_decorative',
      title: 'Live session is scheduled for',
      value: utils.printDateTime(upcomingLiveSession.scheduled_at)
    }
  }
  const scheduledLiveSession = searchLiveSession(order, 'scheduled')
  if (scheduledLiveSession) {
    return {
      iconName: 'calendar_decorative',
      title: 'Live session is scheduled for',
      value: <>{utils.printDateTime(scheduledLiveSession.scheduled_at)}</>
    }
  }
  return {
    iconName: 'calendar_decorative',
    title: 'Task will be done by',
    value: utils.printDateTime(order.deadline)
  }
}

export const DesktopOrderChatSubheader = ({orderId}) => {
  const actions = useActions()
  const orderQuery = useOrderQuery(orderId)
  const joinableLiveSession = (
    searchLiveSession(orderQuery.data, 'upcoming')
    || searchLiveSession(orderQuery.data, 'started')
  )

  const button = joinableLiveSession ? {
    text: 'Join now',
    onClick: () => actions.joinVideoCall(joinableLiveSession.link, joinableLiveSession.c_token)
  } : null

  return <Subheader
    stat={[
      {iconName: 'time_decorative', title: 'Tutoring time', value: orderQuery.data.size},
      getOrderSubtitle(orderQuery.data),
    ]}
    button={button}
    action={{text: 'Task info', onClick: () => actions.showOrderDetails(orderId)}}
    onMore={() => actions.showOrderContextMenu({orderId})}
  />
}

export const MobileOrderChatSubheader = ({orderId}) => {
  const actions = useActions()
  const orderQuery = useOrderQuery(orderId)
  const joinableLiveSession = (
    searchLiveSession(orderQuery.data, 'upcoming')
    || searchLiveSession(orderQuery.data, 'started')
  )
  const button = joinableLiveSession ? {
    text: 'Join now',
    onClick: () => actions.joinVideoCall(joinableLiveSession.link, joinableLiveSession.c_token)
  } : null
  return <Subheader
    back={{text: 'Task list', onClick: actions.showOrders}}
    button={button}
    onMore={() => actions.showOrderContextMenu({orderId})}
    mobile
  />
}

export const MobileOrderChatSubheader2 = ({orderId}) => {
  const actions = useActions()
  //const orderQuery = useOrderQuery(orderId)
  //const order = useOrder(orderId)
  const classes = useStyle()
  return <div className={classes.subheader}>
    <div className={classes.item}>
      <div onClick={actions.showOrders} className={classes.back}>
        <Icon iconName='back_input'/>
        Task list
      </div>
    </div>
    <div className={classes.item}>
      <div onClick={() => actions.showOrderDetails(orderId)} className={classes.back}>
        <Icon iconName='ic_info'/>
        Task details
      </div>
    </div>
  </div>
}

/*
export const OrderDetailSubheader = ({orderId}) => {
  const actions = useActions()
  return <Subheader
    back={{text: 'Back to the chat', onClick: () => actions.showOrderChat(orderId)}}
    onMore={() => actions.showOrderContextMenu({orderId})}
  />
}
 */
export const OrderDetailSubheader = ({orderId}) => {
  const actions = useActions()
  const order = useOrder(orderId)
  const classes = useStyle()
  return <div className={classes.subheader}>
    <div className={classes.item}>
      <div onClick={() => actions.showOrderChat(orderId)} className={classes.back}>
        <Icon iconName='back_input'/>
        Chat
      </div>
    </div>

    <div className={classes.orderNumberItem}>
      <OrderNumber orderNumber={order.number}/>
    </div>
  </div>
}


export const TutorDetailSubheader = ({tutorId, orderId}) => {
  const actions = useActions()
  return <Subheader
    back={orderId
      ? {text: 'Back to the chat', onClick: () => actions.showOrderChat(orderId)}
      : {text: 'Tutor list', onClick: () => actions.showTutors()}
    }
    onMore={() => actions.showTutorContextMenu({tutorId, orderId})}
  />
}

export const Subheaders = {
  DesktopOrderChatSubheader,
  MobileOrderChatSubheader,
  OrderDetailSubheader,
  TutorDetailSubheader
}

const useStyle = createUseStyles({
  subheader: {
    height: '100%',
    padding: [0, rem(16)],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  button: {
    paddingLeft: rem(20),
    paddingRight: rem(20),
    fontSize: rem(14),
  },
  mobileButton: {
    width: 'auto',
    height: rem(28),
    paddingLeft: rem(20),
    paddingRight: rem(20),
    fontSize: rem(14),
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    '& + &': {
      marginLeft: rem(24),
    },
    '&.withIcon': {
      paddingLeft: rem(40),
      '& svg': {
        width: rem(32),
        height: rem(32),
        display: 'block',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  },
  orderNumberItem: {
    marginRight: 'auto',
    marginLeft: rem(10),
  },
  title: {
    fontSize: rem(10),
    lineHeight: rem(16),
    color: theme.colors.slateGrey,
  },
  value: {
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    color: theme.colors.slateGrey,
  },
  link: {
    color: theme.colors.blue,
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    display: 'inline-block',
    verticalAlign: 'middle',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.colors.blue,
    },
    '& > svg': {
      width: rem(16),
      height: rem(16),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  back: {
    background: 'rgba(60, 120, 255, 0.1)',
    height: rem(28),
    padding: [rem(6), rem(10), rem(6), rem(7)],
    color: theme.colors.blue,
    fontSize: rem(14),
    lineHeight: rem(16),
    fontWeight: 600,
    borderRadius: rem(64),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.colors.blue,
    },
    '& > svg': {
      width: rem(16),
      height: rem(16),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  details: {
    width: rem(28),
    height: rem(28),
    marginLeft: rem(16),
    display: 'inline-block',
    verticalAlign: 'middle',
    cursor: 'pointer',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  copy: {
    color: theme.colors.slateGrey,
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    '& > svg': {
      width: rem(16),
      height: rem(16),
      marginLeft: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
})
