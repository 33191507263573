export const colors = {
  white: '#FFFFFF',
  black1: '#565D6B',
  black2: '#2F333D',
  blue: '#3D77FF'
}

export const typography = {
  s: {
    fontSize: '10px',
    fontWeight: 500
  },
  m: {
    fontSize: '12px',
    fontWeight: 700,
  },
  x: {
    fontSize: '14px',
    fontWeight: 700
  },
  xx: {
    fontSize: '16px',
    fontWeight: 800
  }
}