import React, {useState, useRef} from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, {rem} from './../../theme/base'
import {Icon} from './../Icon'
import useOutsideClick from '../../../utils/useOutsideClick'

const styles = {
  root: {
    borderRadius: 'inherit',
    position: 'relative',
    // '& + &': {
    //   borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    //   borderRadius: 0,
    // },
  },
  input: {
    background: '#fff',
    width: '100%',
    padding: rem(24),
    fontSize: rem(19),
    lineHeight: rem(22),
    fontFamily: theme.fontFamilies.Manrope,
    color: theme.colors.charcoalGrey,
    border: 'none',
    outline: 'none',
    display: 'block',
    borderRadius: 'inherit',
    wordBreak: 'normal',
    '&.icon': {
      paddingRight: rem(68),
    },
    '&.error': {
      color: theme.colors.brightOrange,
      borderColor: [theme.colors.brightOrange, '!important'],
      '&:-moz-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&::-moz-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&::-webkit-text-fill-color': {
        color: theme.colors.brightOrange,
      },
    },
    '&:-moz-placeholder': {
      fontSize: rem(16),
      lineHeight: rem(24),
    },
    '&::-moz-placeholder': {
      fontSize: rem(16),
      lineHeight: rem(24),
    },
    '&:-ms-input-placeholder': {
      fontSize: rem(16),
      lineHeight: rem(24),
    },
    '&::-webkit-input-placeholder': {
      fontSize: rem(16),
      lineHeight: rem(24),
    },
  },
  icon: {
    '& svg': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: rem(24),
      right: rem(24),
      fill: theme.colors.blueyGrey,
      transition: 'fill 300ms ease',
      pointerEvents: 'none',
      '&.focus': {
        fill: theme.colors.blue,
      },
    },
  },
  text: {
    height: rem(24),
    position: 'absolute',
    top: rem(24),
    right: rem(24),
    fontSize: rem(13),
    lineHeight: rem(24),
    letterSpacing: rem(0.08),
    color: theme.colors.blueyGrey,
    pointerEvents: 'none',
  },
}

const Input = ({
                 children,
                 classes,
                 wrapperClassname,
                 className,
                 icon,
                 status,
                 error,
                 onChange,
                 onKeyUp,
                 onFocus,
                 onBlur,
                 onClickOutside,
                 value,
                 ...otherProps
               }) => {
  const [focus, setFocus] = useState(false)

  const hasIconClassName = icon || status

  const inputProps = {
    ...otherProps,
    value,
    className: classnames(
      classes.input,
      className,
      {
        icon: hasIconClassName,
        error,
      }
    ),
    error: error ? error.toString() : 'false',
    onChange: e => onChange && onChange(e.target.value),
    onKeyUp: e => onKeyUp && onKeyUp(e),
    onFocus: e => {
      onFocus && onFocus(e)
      setFocus(true)
    },
    onBlur: e => {
      onBlur && onBlur(e)
      setFocus(false)
    },
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'off',
  }

  const ref = useRef()

  useOutsideClick(ref, () => {
    if (onClickOutside) {
      onClickOutside()
    }
  })

  return (
    <fieldset className={classnames(
      classes.root,
      wrapperClassname,
      {
        [classes.icon]: hasIconClassName,
      }
    )}>
      <input {...inputProps} ref={ref}/>
      {icon &&
      <Icon className={classnames({focus})} iconName={icon}/>
      }
      {status &&
      <div className={classes.text}>{status}</div>
      }
      {children}
    </fieldset>
  )
}

export default injectSheet(styles)(Input)
