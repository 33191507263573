import React from 'react'
import {createUseStyles} from 'react-jss'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import Layout from './layout'
import {useOrder, useTutor} from '../../../../hooks'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'

const OrderClosedEvent = ({order_id, ...props}) => {
  const classes = useStyle()
  const actions = useActions()
  const order = useOrder(order_id)
  const tutor = useTutor(order.assigned.freelancer_id)
  const requestNewTask = () => actions.showOrderForm({order: {recommended: {freelancer_id: tutor.id}}})
  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <img
          className={classes.avatar}
          src={getUserAvatarUrl(order.assigned.freelancer_id)}
          alt=''
        />
        <div className={classes.message}>Liked working with {tutor.name}? Ask him/her to work on your next task!</div>
        <Layout.Buttons>
          <Layout.Button onClick={requestNewTask}>Assign a new task</Layout.Button>
        </Layout.Buttons>
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

const useStyle = createUseStyles({
  avatar: {
    display: 'block',
    width: 44,
    height: 44,
    margin: [0, 'auto']
  },
  message: {
    margin: [8, 0, 24],
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#565D6B',
  }
})

export default OrderClosedEvent
