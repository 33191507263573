import React from "react"
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import Layout from './layout'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'
import {useOrderLiveSession} from '../../../../hooks'
import LiveSessionCanceledEvent from './LiveSessionCanceledEvent'

const LiveSessionRescheduledEvent = ({order_id, live_session_id, ...props}) => {
  const {created_by, scheduled_at, duration} = props.data.event.event_params
  const liveSession = useOrderLiveSession(order_id, live_session_id)

  if (liveSession?.deleted) {
    return (
      <LiveSessionCanceledEvent
        order_id={order_id}
        live_session_id={live_session_id}
        {...props}
      />
    )
  }

  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <Layout.Title1 iconName='live_session'>Live session rescheduled</Layout.Title1>
        <Layout.LiveSession
          datetime={scheduled_at}
          duration={duration}
          avatar={getUserAvatarUrl(created_by)}
        />
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

export default LiveSessionRescheduledEvent