import React, {useState} from 'react'
import injectSheet from 'react-jss'
import theme, {adaptive, desktop, MODAL_WIDTH_DESKTOP, rem} from './../../theme/base'
import Button from './../Button'
import Layout from './../Layout'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    [adaptive]: {
      height: '100%',
    },
    [desktop]: {
      width: rem(MODAL_WIDTH_DESKTOP),
    },
  },
  title: {
    padding: [rem(17), rem(56)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.11),
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(15),
    right: rem(20),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  item: {
    marginTop: rem(24),
    paddingLeft: rem(16),
    paddingRight: rem(16),
    position: 'relative',
  },
  label: {
    paddingLeft: rem(40),
    color: theme.colors.charcoalGrey,
    fontSize: rem(15),
    lineHeight: rem(28),
    fontWeight: 600,
    letterSpacing: rem(0.09),
    position: 'relative',
    cursor: 'pointer',
  },
  radio: {
    display: 'none',
    '&:checked': {
      '& + label': {
        '&:before': {
          borderColor: theme.colors.blue,
        },
        '&:after': {
          opacity: 1,
        },
      },
    },
    '& + label': {
      '&:before': {
        content: "''",
        width: rem(28),
        height: rem(28),
        borderRadius: '50%',
        border: [rem(2), 'solid', theme.colors.lightBlueGrey],
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        transition: 'border 300ms ease-in-out',
      },
      '&:after': {
        content: "''",
        background: theme.colors.blue,
        width: rem(20),
        height: rem(20),
        borderRadius: '50%',
        position: 'absolute',
        left: rem(4),
        top: rem(4),
        display: 'block',
        opacity: 0,
        transition: 'opacity 300ms ease-in-out',
      },
    },
  },
  text: {
    marginTop: rem(11),
    paddingLeft: rem(40),
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(22),
    letterSpacing: rem(0.09),
  },
  fieldset: {
    width: '100%',
    display: 'block',
    position: 'relative',
  },
}

const Report = ({
                  report,
                  onClose,
                  onSubmit,
                  classes,
                }) => {
  const [active, setActive] = useState(null)
  return (
    <div className={classes.root}>
      <Layout.Header onClose={onClose}>
        <div className={classes.title}>Choose report reason</div>
      </Layout.Header>
      <Layout.Content>
        {report.map(({value, title, text}) => {
          const reportId = `report-${value}`
          const checked = active === value
          return (
            <div className={classes.item} key={value}>
              <fieldset className={classes.fieldset}>
                <input
                  id={reportId}
                  className={classes.radio}
                  type='radio'
                  value={value}
                  checked={checked}
                  onChange={() => setActive(value)}
                />
                <label className={classes.label} htmlFor={reportId}>{title}</label>
              </fieldset>
              <div className={classes.text}>{text}</div>
            </div>
          )
        })}
      </Layout.Content>
      <Layout.Footer>
        <Button size='large' disabled={!active} block onClick={() => {
          onSubmit(active)
        }}>
          Send report
        </Button>
      </Layout.Footer>
    </div>
  )
}

export default injectSheet(styles)(Report)
