import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import {rem} from './../../../theme/base'
import Attach from './../Attach'
import Attachment from './../Attachment'
import useUploder from './../useUploader'

const styles = {
  root: {
    //width: `calc(100% + ${rem(8)})`,
    //marginLeft: rem(-4),
    paddingTop: rem(12),
    position: 'relative',
  },
}

const getFileUUID = file => file.uuid || file.url

const Uploader = ({
  files,
  config,
  onChange,
  onStatusChange,
  classes,
}) => {
  const [uploading, uploaded, uploadFile, removeFile] = useUploder(config)

  useEffect(() => {
    uploaded.forEach(file => {
      onChange(files.concat(file))
      removeFile(file.uuid)
    })
  }, [files, uploaded, uploaded.length, onChange, removeFile])

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(uploading.length > 0)
    }
  }, [files, uploaded, uploading.length, onChange, onStatusChange, removeFile])

  return (
    <div className={classes.root}>
      <Attach block={files.length || uploading.length} onAttach={uploadFile} />
        {files.map((file, index) =>
          <Attachment
            key={getFileUUID(file) + index}
            {...file}
            onRemove={() => onChange(files.filter(f => getFileUUID(f) !== getFileUUID(file)))}
          />
        )
      }
      {uploading.map(file =>
        <Attachment
          key={file.uuid}
          {...file}
          onRemove={() => removeFile(file.uuid)}
          onReload={() => console.log('reload')}
        />
      )}
    </div>
  )
}

export default injectSheet(styles)(Uploader)
