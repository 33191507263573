export const Suggest = ({
  className,
  onClick,
}) => <svg
  className={className}
  onClick={onClick}
  width='24'
  height='24'
  viewBox='0 0 24 24'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path d='M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z' fill='#FBBC04' fillOpacity='0.32'/>
  <path d='M14.7766 6.99337C14.2749 6.58081 13.6875 6.28543 13.0572 6.12879C12.4269 5.97216 11.7696 5.95821 11.1332 6.08797C10.2858 6.25914 9.50833 6.67816 8.89947 7.29187C8.29061 7.90558 7.87778 8.68633 7.71335 9.53504C7.59327 10.1714 7.61509 10.8264 7.77725 11.4534C7.93941 12.0803 8.23792 12.6638 8.65148 13.1621C9.03528 13.5952 9.25528 14.1491 9.27326 14.7275V16.3637C9.27326 16.7977 9.44565 17.2139 9.75251 17.5207C10.0594 17.8276 10.4756 18 10.9095 18H13.0912C13.5252 18 13.9414 17.8276 14.2482 17.5207C14.5551 17.2139 14.7275 16.7977 14.7275 16.3637V14.8311C14.7458 14.1925 14.9804 13.579 15.3929 13.0912C16.1157 12.1971 16.4563 11.0539 16.3409 9.91009C16.2255 8.76623 15.6634 7.71414 14.7766 6.98246V6.99337ZM13.6366 16.3637C13.6366 16.5084 13.5792 16.6471 13.4769 16.7494C13.3746 16.8517 13.2359 16.9092 13.0912 16.9092H10.9095C10.7649 16.9092 10.6261 16.8517 10.5239 16.7494C10.4216 16.6471 10.3641 16.5084 10.3641 16.3637V15.8183H13.6366V16.3637ZM14.5475 12.4149C13.9991 13.0653 13.6791 13.8777 13.6366 14.7275H12.5458V13.0912C12.5458 12.9465 12.4883 12.8078 12.386 12.7055C12.2838 12.6032 12.145 12.5458 12.0004 12.5458C11.8557 12.5458 11.717 12.6032 11.6147 12.7055C11.5124 12.8078 11.455 12.9465 11.455 13.0912V14.7275H10.3641C10.3497 13.8919 10.0406 13.0883 9.49143 12.4585C9.12907 12.0243 8.88541 11.5037 8.78419 10.9473C8.68297 10.391 8.72765 9.81785 8.91387 9.28389C9.1001 8.74993 9.42152 8.27334 9.84678 7.90059C10.2721 7.52784 10.7867 7.27165 11.3404 7.157C11.8163 7.05901 12.3081 7.06823 12.78 7.18398C13.2519 7.29973 13.6921 7.51912 14.0686 7.82621C14.4452 8.13329 14.7486 8.52038 14.9569 8.95936C15.1652 9.39834 15.2732 9.87819 15.2729 10.3641C15.2769 11.1112 15.0204 11.8364 14.5475 12.4149V12.4149Z' fill='#F59700'/>
</svg>