import React from 'react'
import injectSheet from 'react-jss'
import Prompt from './../Prompt'

const styles = {
  delete: {
    color: '#ed2c39 !important',
  },
}

const CloseAndCreateDraft = ({
                               classes,
                               onCancel,
                               onClose,
                               onAccept,
                               ...otherProps
                             }) => {
  const _onAccept = () => {
    onAccept();
    onClose()
  }
  const _onClose = () => {
    onCancel && onCancel();
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='attention'/>
      <Prompt.Title>Close task?</Prompt.Title>
      <Prompt.Details details={[
        'Do you want to save this task as draft before closing?'
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose} className={classes.delete}>Delete task</Prompt.Button>
        <Prompt.Button type='primary' onClick={_onAccept}>Save draft</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default injectSheet(styles)(CloseAndCreateDraft)