import {useState} from 'react'
import {useListAllCardsQuery} from '../../hooks'


export function useCardList() {
  const listAllCards = useListAllCardsQuery().data
  const [paymentMethod, setPaymentMethod] = useState(listAllCards.length > 0 ? listAllCards[0].id : null)

  const card = listAllCards.find(({id}) => id === paymentMethod)?.card || null

  return {
    card,
    listAllCards,
    paymentMethod,
    changePaymentMethod: setPaymentMethod
  }
}
