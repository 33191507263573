import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem } from './../../theme/base'
import {printDateTime} from './../../utils/printDateTime'

const iconsPath = require.context('./')

const styles = {
  status: {
    height: rem(36),
    padding: [rem(8), rem(100), rem(8), rem(44)],
    fontSize: rem(16),
    fontWeight: 600,
    lineHeight: rem(20),
    color: theme.colors.charcoalGrey,
    position: 'relative',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:before': {
      content: '""',
      backgroundSize: `${rem(36)} ${rem(36)}`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      width: rem(36),
      height: rem(36),
      position: 'absolute',
      left: 0,
      top: 0,
    },
    '&:after': {
      content: '""',
      backgroundSize: `${rem(20)} ${rem(20)}`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      width: rem(20),
      height: rem(20),
      position: 'absolute',
      left: rem(8),
      top: rem(8),
    },
    ...['complete-blue', 'complete-green', 'process', 'rate', 'refund', 'draft'].reduce(
      (result, iconName) => {
        let after = {}
        if (iconName === 'process') {
          after = {
            '&:after': {
              backgroundImage: `url(${iconsPath(`./loader.svg`).default})`
            }
          }
        }
        return {
          ...result,
          [`&.${iconName}`]: {
            '&:before': {
              backgroundImage: `url(${iconsPath(`./icon-${iconName}.svg`).default})`
            },
            ...after,
          }
        }
      }, {})
  },
  date: {
    position: 'absolute',
    top: rem(12),
    right: 0,
    fontSize: rem(12),
    lineHeight: rem(14),
    textAlign: 'right',
    color: theme.colors.blueyGrey,
  },
}

const Status = injectSheet(styles)(({ date, icon, classes, children }) =>
  <div className={classnames(classes.status, icon)}>
    {children}
    {date && <div className={classes.date}>{printDateTime(date)}</div>}
  </div>
)

Status.propTypes = {
  icon: PropTypes.oneOf(['complete-blue', 'complete-green', 'process', 'rate', 'refund', 'draft']),
  date: PropTypes.number,
}

Status.defaultProps = {
  icon: 'complete-blue',
  date: '',
}

export default Status