import React from 'react'
import Prompt from './../Prompt'

const CloseAndUpdateDraft = ({
                               onCancel,
                               onClose,
                               onAccept,
                               ...otherProps
                             }) => {
  const _onAccept = () => {
    onAccept();
    onClose()
  }
  const _onClose = () => {
    onCancel && onCancel();
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='attention'/>
      <Prompt.Title>Close task</Prompt.Title>
      <Prompt.Details details={[
        'Do you want to save сhanges you have just made as a draft?'
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>No, thanks</Prompt.Button>
        <Prompt.Button type='primary' onClick={_onAccept}>Save</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default CloseAndUpdateDraft