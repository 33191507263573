import React from 'react'
import {createUseStyles} from 'react-jss'
import Header from './../Header'
import Footer from '../Footer'
import theme, {rem} from '../../theme/base'

const MobileView = ({subheader, workarea, list, header, footer}) => {
  const classes = useStyle()
  return <>
    {header && <div className={classes.header}><Header/></div>}
    {subheader && <div className={classes.subheader}>{subheader}</div>}
    <div className={classes.body}>
      {list && <div className={classes.list}>{list}</div>}
      {workarea && <>{workarea}</>}
    </div>
    {footer && <div className={classes.footer}><Footer/></div>}
  </>
}

//const HEADER_HEIGHT_MOBILE = 66
const SUBHEADER_HEIGHT_DESKTOP = 56
const FOOTER_HEIGHT_MOBILE = 101

export const useStyle = createUseStyles({
  body: {
    flex: 1,
    overflowY: 'auto',
  },
  header: {
  },
  subheader: {
    height: rem(SUBHEADER_HEIGHT_DESKTOP),
    borderBottom: [1, 'solid', theme.colors.paleGrey],
  },
  list: {
    padding: [rem(24), rem(16), rem(FOOTER_HEIGHT_MOBILE + 24), rem(16)],
  },
  footer: {
    height: rem(FOOTER_HEIGHT_MOBILE),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
})

export default MobileView
