import React, { useState } from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem, desktop } from '../../mtuikit/theme/base'
import Swipe from 'react-easy-swipe'
import Remove from './Remove'

const styles = {
  root: {
    marginTop: rem(16),
    borderRadius: rem(16),
    position: 'relative',
    '& + &': {
      marginTop: rem(8),
    },
  },
  card: {
    background: theme.colors.white,
    height: rem(112),
    boxShadow: [
      [0, rem('16 40 0'), 'rgba(0, 53, 136, 0.15)'],
      [rem('0 8 12 0'), 'rgba(0, 38, 96, 0.06)']
    ],
    borderRadius: 'inherit',
    display: 'block',
    position: 'relative',
    transition: 'transform 300ms ease-out',
    '&.swiped': {
      transform: `translateX(${rem(-80)})`,
    },
    '&.active': {
      [desktop]: {
        outline: [rem(2), 'solid', theme.colors.blue],
      },
    },
  },
  clickArea: {
    height: 'inherit',
    padding: rem(16),
    position: 'relative',
    '&.image.big': {
      paddingLeft: rem(96)
    },
    '&.image.small': {
      paddingLeft: rem(60)
    },
    '&.image.rate': {
      paddingLeft: rem(76)
    },
    '&.label': {
      paddingRight: rem(66),
    },
    '&.counter': {
      paddingRight: rem(48),
    },
  },
  removeMobile: {
    display: 'block',
    [desktop]: {
      display: 'none',
    },
  },
  removeDesktop: {
    display: 'none',
    [desktop]: {
      'div:hover > div > &': {
        display: 'block',
      },
    },
  },
}

const Card = ({
  label,
  counter,
  image,
  className,
  children,
  onClick,
  onDelete,
  classes,
  swipe,
  active,
}) => {
  const [swiped, setSwiped] = useState(false)

  const onSwipeLeft = () => setSwiped(true)
  const onSwipeRight = () => setSwiped(false)
  const onEmptyFunction = () => {}

  const removeProps = {
    swipe,
    onDelete,
  }

  return (
    <div className={classnames(classes.root, className)}>
      <Remove className={classes.removeMobile} {...removeProps} />
      <Swipe
        onSwipeLeft={swipe ? onSwipeLeft : onEmptyFunction}
        onSwipeRight={swipe ? onSwipeRight : onEmptyFunction}
        allowMouseEvents
        className={classnames(classes.card, className, { swiped, active })}
      >
        <div className={classnames(classes.clickArea, {
            image,
            label,
            counter,
            big: image === 'big',
            small: image === 'small',
            rate: image === 'rate',
          })}
          onClick={onClick}
        >
          {children}
        </div>
        <Remove className={classes.removeDesktop} {...removeProps} />
      </Swipe>
    </div>
  )
}

export default injectSheet(styles)(Card)
