import Actions from './Actions'
import {Button} from './Button'
import Details from './Details'
import Icon from './Icon'
import Title from './Title'
import Wrapper from './Wrapper'

const Layout = {
  Actions,
  Button,
  Details,
  Icon,
  Title,
  Wrapper,
}

export default Layout