import React, {useState} from "react"
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import Layout from './layout'
import {utils} from 'mtuikit'
import {
  useAcceptAndPayOrderEstimateMutation,
  useDeclineOrderEstimateMutation,
  useOrderQuery, usePaymentRequest, usePaymentRequestPaymentMethodEvent,
  useProfileQuery, useInvalidateOrder
} from '../../../../hooks'
import {useActions} from '../../../../actions'
import CardList from '../../../CardList'
import {useCardList} from '../../../CardList/hooks'
import useCallbackInTime from '../../../../utils/useCallbackInTime'

const MORE_BUTTON = {true: 'Hide details', false: 'See details'}

const ActivePaymentRequest = ({order, profile, estimate}) => {
  const actions = useActions()
  const [more, setMore] = useState(false)
  const {listAllCards, paymentMethod, changePaymentMethod} = useCardList()
  const acceptAndPayOrderEstimateMutation = useAcceptAndPayOrderEstimateMutation()
  const declineOrderEstimateMutation = useDeclineOrderEstimateMutation()

  const availableBalance = profile.balance
  const balance = Math.min(availableBalance, estimate.additional.price)
  const toPaid = Math.min(estimate.additional.price, estimate.additional.price - balance)

  const paymentRequest = usePaymentRequest('US', 'usd', toPaid)

  const acceptAndPayOrderEstimate = () => {
    if (toPaid === 0) {
      acceptAndPayOrderEstimateMutation.mutate({
        orderId: order.id,
        estimateId: estimate.id,
      })
    } else if (paymentMethod) {
      acceptAndPayOrderEstimateMutation.mutate({
        orderId: order.id,
        estimateId: estimate.id,
        paymentMethod
      })
    } else {
      actions.showAddPaymentMethod({
        price: estimate.additional.price,
        total: toPaid,
        paymentRequest,
        onSuccess: paymentMethod => {
          acceptAndPayOrderEstimateMutation.mutate({
            orderId: order.id,
            estimateId: estimate.id,
            paymentMethod
          })
        }
      })
    }
  }

  usePaymentRequestPaymentMethodEvent(paymentRequest, ev => {
    acceptAndPayOrderEstimateMutation.mutate({
      orderId: order.id,
      estimateId: estimate.id,
      paymentRequestEvent: ev
    })
  })

  const tryAgain = () => acceptAndPayOrderEstimateMutation.reset()

  const declineOrderEstimate = () => {
    declineOrderEstimateMutation.mutate({orderId: order.id, estimateId: estimate.id})
  }

  const isSubmitDisabled = acceptAndPayOrderEstimateMutation.isLoading || declineOrderEstimateMutation.isLoading

  return !acceptAndPayOrderEstimateMutation.isError
    ? <>
      <Layout.Title1>Additional time requested:</Layout.Title1>
      <Layout.Extratime description={utils.sizeToHuman(estimate.additional.size)}/>
      {
        more &&
        <>
          <Layout.Details>
            <Layout.Subtitle>Time you have paid for:</Layout.Subtitle>
            <Layout.Text>{utils.sizeToHuman(order.size)}</Layout.Text>
          </Layout.Details>
          <Layout.Details>
            <Layout.Subtitle>Time needed to complete your task:</Layout.Subtitle>
            <Layout.Text>{utils.sizeToHuman(estimate.accepted.size)}</Layout.Text>
          </Layout.Details>
          {
            balance > 0 &&
            <>
              <Layout.Details>
                <Layout.Subtitle>Subtotal:</Layout.Subtitle>
                <Layout.Text>{utils.printAmount(estimate.additional.price)}</Layout.Text>
              </Layout.Details>
              <Layout.Details>
                <Layout.Subtitle>Paid from account balance:</Layout.Subtitle>
                <Layout.Text>{utils.printAmount(balance)}</Layout.Text>
              </Layout.Details>
            </>
          }
          <Layout.Details>
            <Layout.Subtitle>Total to be paid:</Layout.Subtitle>
            <Layout.Text bold>{utils.printAmount(toPaid)}</Layout.Text>
          </Layout.Details>
        </>
      }
      <Layout.More onClick={() => setMore(!more)}>
        {MORE_BUTTON[more]}
      </Layout.More>
      {
        toPaid > 0 &&
        <CardList
          listAllCards={listAllCards}
          paymentMethod={paymentMethod}
          onChange={changePaymentMethod}
          labelForCard='****'
        />
      }
      <Layout.Buttons>
        <Layout.Button
          type='outline'
          onClick={declineOrderEstimate}
          disabled={isSubmitDisabled}
          isFetching={isSubmitDisabled}
        >
          Decline
        </Layout.Button>
        <Layout.Button
          onClick={acceptAndPayOrderEstimate}
          disabled={isSubmitDisabled}
          isFetching={isSubmitDisabled}
        >
          {toPaid > 0 ? `Pay ${utils.printAmount(toPaid)}` : `Accept`}
        </Layout.Button>
      </Layout.Buttons>
    </>
    : <>
      <Layout.Title1 iconName='payment_request_declined'>Payment failed</Layout.Title1>
      <Layout.Details>
        <Layout.Text>
          Please double-check your balance and credit card info or try using a different card to make the payment.
        </Layout.Text>
      </Layout.Details>
      <Layout.Buttons>
        <Layout.Button onClick={tryAgain} disabled={isSubmitDisabled}>Try again</Layout.Button>
      </Layout.Buttons>
    </>
}

const AcceptedPaymentRequest = ({order, estimate}) => {
  const [more, setMore] = useState(false)
  return <>
    <Layout.Title1 iconName='paid'>Payment successful</Layout.Title1>
    <Layout.Extratime description={`+${utils.sizeToHuman(estimate.additional.size)}`}/>
    {
      more &&
      <>
        <Layout.Details>
          <Layout.Subtitle>New order size time:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(order.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Total paid:</Layout.Subtitle>
          <Layout.Text bold>{utils.printAmount(order.paid.amount)}</Layout.Text>
        </Layout.Details>
      </>
    }
    <Layout.More onClick={() => setMore(!more)}>
      {MORE_BUTTON[more]}
    </Layout.More>
  </>
}

const DeclinedPaymentRequest = ({order, estimate}) => {
  const actions = useActions()
  const [more, setMore] = useState(false)

  const rejectTutor = () => actions.showRejectTutorPrompt({tutorId: order.assigned.freelancer_id, orderId: order.id})

  return <>
    <Layout.Title1 iconName='payment_request_declined'>Request declined</Layout.Title1>
    <Layout.Extratime description={`${utils.sizeToHuman(estimate.additional.size)}`}/>
    {
      more &&
      <>
        <Layout.Details>
          <Layout.Subtitle>Time you have paid for:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(order.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Time needed to complete your task:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(estimate.accepted.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Total to be paid:</Layout.Subtitle>
          <Layout.Text bold>{utils.printAmount(estimate.additional.price)}</Layout.Text>
        </Layout.Details>
      </>
    }
    <Layout.More onClick={() => setMore(!more)}>
      {MORE_BUTTON[more]}
    </Layout.More>
    {
      order.assigned !== null &&
      <Layout.Buttons>
        <Layout.Button type='outline' onClick={rejectTutor}>Reject tutor</Layout.Button>
      </Layout.Buttons>
    }
  </>
}

const CanceledPaymentRequest = ({order, estimate}) => {
  const [more, setMore] = useState(false)
  return <>
    <Layout.Title1 iconName='payment_request_declined'>Request canceled</Layout.Title1>
    <Layout.Extratime description={`${utils.sizeToHuman(estimate.additional.size)}`}/>
    {
      more &&
      <>
        <Layout.Details>
          <Layout.Subtitle>Time you have paid for:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(order.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Time needed to complete your task:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(estimate.accepted.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Total to be paid:</Layout.Subtitle>
          <Layout.Text bold>{utils.printAmount(estimate.additional.price)}</Layout.Text>
        </Layout.Details>
      </>
    }
    <Layout.More onClick={() => setMore(!more)}>
      {MORE_BUTTON[more]}
    </Layout.More>
  </>
}

const ExpiredPaymentRequest = ({order, estimate}) => {
  const [more, setMore] = useState(false)
  return <>
    <Layout.Title1 iconName='payment_request_declined'>Request expired</Layout.Title1>
    <Layout.Extratime description={`${utils.sizeToHuman(estimate.additional.size)}`}/>
    {
      more &&
      <>
        <Layout.Details>
          <Layout.Subtitle>Time you have paid for:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(order.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Time needed to complete your task:</Layout.Subtitle>
          <Layout.Text>{utils.sizeToHuman(estimate.accepted.size)}</Layout.Text>
        </Layout.Details>
        <Layout.Details>
          <Layout.Subtitle>Total to be paid:</Layout.Subtitle>
          <Layout.Text bold>{utils.printAmount(estimate.additional.price)}</Layout.Text>
        </Layout.Details>
      </>
    }
    <Layout.More onClick={() => setMore(!more)}>
      {MORE_BUTTON[more]}
    </Layout.More>
  </>
}

const REQUESTED_MAP = {
  'accepted': AcceptedPaymentRequest,
  'active': ActivePaymentRequest,
  'declined': DeclinedPaymentRequest,
  'canceled': CanceledPaymentRequest,
  'expired': ExpiredPaymentRequest,
}


const OrderEstimatedEvent = ({order_id, estimate_id, ...props}) => {
  const orderQuery = useOrderQuery(order_id)
  const profileQuery = useProfileQuery()

  const estimates = orderQuery.data?.estimates || []
  const estimate = estimates.find(estimate => estimate.id === estimate_id)

  const invalidateOrder = useInvalidateOrder(order_id)
  useCallbackInTime(estimate?.expired_at, invalidateOrder)

  if (!(orderQuery.isFetched && profileQuery.isFetched && estimate)) return <div style={{height: 500}}/>

  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        {estimate && React.createElement(REQUESTED_MAP[estimate.status], {
          order: orderQuery.data,
          profile: profileQuery.data,
          estimate
        })}
      </Layout.Body>
      {
        estimate.status === 'active'
          ? <Card.Footer {...props}>
            <Layout.TimeCountdown text='Expires in' end={estimate.expired_at}/>
            <Layout.MessageStatus {...props}/>
          </Card.Footer>
          : <Card.Footer  {...props}/>
      }
    </Card>
  </MessageWrapper>
}

export default OrderEstimatedEvent

