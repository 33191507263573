import {useRegisterAndAuthorizeByEmailMutation, useAuthorizeByLoginMutation} from '../../hooks'

export const formData = {
  login: {
    greeting: 'Or use your email to log in',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    submitButtonText: 'Log in',
    restorePasswordLink: true,
    secondActionButton: {
      text: 'Do not have an account?',
      link: 'Sign up',
      to: '/signup'
    },
    authMutation: useAuthorizeByLoginMutation
  },
  signup: {
    greeting: 'Or use your email to get started',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Create your password',
    submitButtonText: 'Sign up',
    restorePasswordLink: false,
    secondActionButton: {
      text: 'Already have an account?',
      link: 'Log in',
      to: '/login'
    },
    authMutation: useRegisterAndAuthorizeByEmailMutation
  },
}