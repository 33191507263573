import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {theme} from 'mtuikit'

const useStyle = createUseStyles({
  buttonWrapper: {
    textAlign: 'right',
  },
  button: {
    background: theme.colors.blue,
    width: 140,
    height: 40,
    padding: 10,
    color: theme.colors.white,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 600,
    textAlign: 'center',
    borderRadius: 28,
    boxShadow: [
      [0, 8, 22, -6, 'rgba(24, 39, 75, 0.12)'],
      [0, 14, 64, -4, 'rgba(24, 39, 75, 0.12)']
    ],
    display: 'inline-block',
    verticalAlign: 'top',
    '&:disabled': {
      background: theme.colors.paleGrey,
      color: theme.colors.blueyGrey,
      boxShadow: 'none',
    },
  },
})

const Button = ({
                  children,
                  className,
                  ...rest
                }) => {
  const classes = useStyle()
  return (
    <div className={classnames(classes.buttonWrapper, className)}>
      <button className={classes.button} {...rest}>{children}</button>
    </div>
  )
}

export default Button