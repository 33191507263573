import React from 'react'
import injectSheet from 'react-jss'
import { ui } from '../../theme/base'

const styles = {
  root: ui.list,
}

const List = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(List)