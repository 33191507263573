import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'

const useStyle = createUseStyles({
  logo: {
    width: 24,
    height: 24,
    marginRight: 12,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'default',
  },
})

export const Logo = ({className}) => {
  const classes = useStyle()
  return (
    <svg
      className={classnames(classes.logo, className)}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H1C0.447715 24 0 23.5523 0 23V8C0 3.58172 3.58172 0 8 0Z' fill='url(#paint0_linear_9663_21105)'/>
      <path d='M9.44765 17.5047V12.1213H9.63249L13.8838 18.2325C14.8195 19.5841 15.4549 20 16.4715 20C17.8924 20 18.6433 19.0989 18.6433 17.4123V6.49531C18.6433 4.92419 17.8 4 16.3906 4C15.0043 4 14.1957 4.91264 14.1957 6.49531V11.7401H14.0108L9.79422 5.7213C8.85848 4.39278 8.29242 4 7.31047 4C5.78556 4 5 4.93574 5 6.76101V17.5047C5 19.0643 5.83177 20 7.24116 20C8.63899 20 9.44765 19.0874 9.44765 17.5047Z' fill='white'/>
      <defs>
      <linearGradient id='paint0_linear_9663_21105' x1='0' y1='0' x2='0' y2='24' gradientUnits='userSpaceOnUse'>
      <stop stopColor='#55AEFF'/>
      <stop offset='1' stopColor='#2B75FF'/>
      </linearGradient>
      </defs>
    </svg>
  )
}