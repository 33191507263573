import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import CookiePopUp from '../../components/CookiePopUp'
import theme, { rem, content2, adaptive } from '../../../theme/base'
import { PAGES } from '../../../../config'

const styles = {
  root: {
    background: theme.colors.blue20,
    padding: [rem(65), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
  },
  content: content2,
  column: {
    maxWidth: rem(256),
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: rem(160),
      [adaptive]: {
        marginLeft: 0,
      },
    },
    [adaptive]: {
      width: '100%',
      maxWidth: 'none',
      marginTop: rem(32),
      // display: 'block',
      // '&.resources': {
      //   display: 'none',
      // },
    },
  },
  logo: {
    paddingBottom: rem(40),
    [adaptive]: {
      paddingBottom: 0,
    },
    '& a': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& img': {
      width: rem(160),
      height: rem(24),
      display: 'block',
    },
  },
  block: {
    [adaptive]: {
      marginTop: rem(8),
    },
    '& + &': {
      marginTop: rem(16),
    },
  },
  title: {
    paddingBottom: rem(16),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: rem(24),
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
      paddingBottom: 0,
    },
  },
  subtitle: {
    // paddingTop: rem(16),
    paddingBottom: rem(4),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    display: 'flex',
    gap: rem(6),
    justifyContent: 'flex-start',
    alignItems: 'center',
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
      paddingTop: 0,
    },
    '& img': {
      width: rem(24),
      height: rem(24),
      marginTop: rem(2),
      display: 'block',
    },
    '& span': {
      marginTop: rem(4),
      fontSize: 'inherit',
      lineHeight: rem(18),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  item: {
    [adaptive]: {
      marginTop: rem(4),
    },
    '& + &': {
      marginTop: rem(16),
      [adaptive]: {
        marginTop: rem(4),
      },
    },
  },
  link: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
    },
    'a.&': {
      cursor: 'pointer',
    },
  },
}

const Footer = ({
  classes,
  addresses,
}) =>
  <>
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <Link to='/'>
            <img src={require('./logo-nerdy-blue.svg').default} alt='' />
          </Link>
        </div>
        <div className={classes.column}>
          <div className={classes.title}>Our Address</div>
          {addresses.map(({ key, country, address }, index) => address
            ? <div className={classes.block} key={index}>
                <div className={classes.subtitle}>
                  <img src={`/img/flags/${key}.png`} width="24" height="24" alt='' />
                  <span>{country}</span>
                </div>
                <div className={classes.link}>{address}</div>
              </div>
            : null
          )}
        </div>
        <div className={classes.column}>
          <div className={classes.title}>Contact us</div>
          <div className={classes.block}>
            <div className={classes.subtitle}>Email:</div>
            <a
              className={classes.link}
              href='mailto:support@nerdy-tutors.com'
              target='_blank'
              rel='noreferrer'
            >
              support@nerdy-tutors.com
            </a>
          </div>
          {/* <div className={classes.block}>
            <div className={classes.subtitle}>Phone number:</div>
            <a className={classes.link} href='tel:+18775748050'>+1-877-574-80-50</a>
          </div> */}
        </div>
        <div className={classnames(classes.column, 'resources')}>
          <div className={classes.title}>Resources</div>
          <ul className={classes.list}>
            {PAGES.map(({ name, link }, index) =>
              <div className={classes.item} key={index}>
                <Link className={classes.link} to={link}>{name}</Link>
              </div>
            )}
            {/*
            <div className={classes.item}>
              <a className={classes.link} href="https://math-on.com/" target="_blank">MathOn</a>
            </div>
            */}
          </ul>
        </div>
      </div>
    </div>
    <CookiePopUp />
  </>

export default injectSheet(styles)(Footer)
