import React, {useEffect, useState} from 'react'
import {getContextAccessTokenApi, setToken} from '../api'
import {useAuth, useXmppConfig} from '../hooks'
import {Redirect, useParams} from 'react-router-dom'
import {useSupportChatContext} from "../components/SupportChat";

const TARGET = {
  o: context => `/orders/${context.order_id}/chat`,
  s: () => `/orders`
}

const ClientBackdoor = () => {
  const {target, token} = useParams()
  const {setAuth, isLogged} = useAuth()
  const [context, setContext] = useState()
  const [pathname, setPathname] = useState()
  const xmpp = useXmppConfig()
  const supportChat = useSupportChatContext()

  useEffect(() => {
      getContextAccessTokenApi(token)
        .then(context => {
          setContext(context)
          setToken(context.auth_token)
          setAuth()
        })
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
      if (!(context && isLogged && xmpp)) return

      if (target === 's') {
        supportChat.open()
      }

      setPathname((TARGET[target] && TARGET[target](context)) || '/orders')
    },
    // eslint-disable-next-line
    [isLogged, xmpp, context]
  )


  return pathname
    ? <Redirect to={{pathname}}/>
    : null
}

export default ClientBackdoor
