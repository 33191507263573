export const Checkmark = () =>
  <svg
    width='12'
    height='11'
    viewBox='0 0 12 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5251 0.573054C12.0762 1.02083 12.16 1.83058 11.7122 2.38168L5.29321 10.2819L0.431546 5.96044C-0.0991747 5.48869 -0.146978 4.67602 0.324773 4.1453C0.796525 3.61458 1.60919 3.56678 2.13991 4.03853L4.99253 6.57419L9.71649 0.760146C10.1643 0.209044 10.974 0.125281 11.5251 0.573054Z'
      fill='white'
    />
  </svg>