import React from 'react'
import {useChat} from '@connect/connect-xmpp-v2'
import {Chat as BaseChat} from '@connect/connect-ui-js-v2'
import ThemeUIProvider from './theme'
import {MessageBox} from './components/MessageBox'
import {Message} from './components/Message'
import {useChatOnboard} from './ChatOnboard'

export const Chat = ({
                       chatConfig,
                       placeholder,
                       defaultMessage,
                       onboardParams,
                       messagebox = true,
                       audio,
                       pic
                     }) => {
  const {
    list,
    refs,
    extendListRefs,
    fetchNextPage,
    hasNextPage,
    notification,
    typing,
    setReadMessages,
    isFetched
  } = useChat(chatConfig)

  const onboard = useChatOnboard(onboardParams)

  const renderMessageBox = messagebox
    ? () => <MessageBox chatConfig={chatConfig} useAudio={audio} defaultMessage={defaultMessage}/>
    : () => null

  const _refs = {...refs, ...onboard.refs}
  const _list = [...list, ...onboard.list].sort((a, b) => _refs[a].time - _refs[b].time)

  return (
    <ThemeUIProvider>
      <BaseChat
        key={chatConfig.node}
        unreadCount={notification.count}
        messageIdList={[..._list].reverse()}
        messageRefs={extendListRefs(_list, _refs)}
        renderMessage={(messageId, messageData) => <Message messageId={messageId} messageData={messageData}
                                                            chatConfig={chatConfig} pic={pic}/>}
        renderMessageBox={renderMessageBox}
        onEndReached={fetchNextPage}
        hasMore={hasNextPage}
        setReadMessages={setReadMessages}
        isTyping={typing || onboard.typing}
        placeholder={placeholder(isFetched)}
        styles={{height: '100%'}}
        isFetched={isFetched}
      />
    </ThemeUIProvider>
  )
}
