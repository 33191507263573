import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {Icon, Button} from 'mtuikit'
import theme, {rem, content, adaptive, desktop} from './../../theme/base'
import {useActions} from '../../actions'
import {useAuth} from '../../hooks'

const styles = {
  root: {
    ...content,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.colors.white,
    padding: [rem(16), 0],
    [adaptive]: {
      borderRadius: [0, 0, rem(24), rem(24)],
    },
    [desktop]: {
      paddingTop: rem(8),
      paddingBottom: rem(8),
      boxShadow: `inset 0 ${rem(-1)} 0 0 ${theme.colors.paleGrey}`,
    },
    '&.flat': {
      background: theme.colors.paleGrey,
      borderRadius: 0,
    },
    '&.onlyLogo': {
      justifyContent: 'center',
    },
    '&.onlyDesktop': {
      [adaptive]: {
        display: 'none',
      },
    },
  },
  content,
  hamburger: {
    width: rem(28),
    height: rem(28),
    marginRight: rem(16),
    cursor: 'pointer',
    [desktop]: {
      marginRight: rem(8),
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&.hamburger-mobile': {
        [desktop]: {
          display: 'none',
        },
      },
      '&.hamburger-desktop': {
        [adaptive]: {
          display: 'none',
        },
      },
    },
  },
  logo: {
    backgroundImage: `url(${require('./../../assets/images/logo-blue.svg').default})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    width: rem(160),
    height: rem(24),
    cursor: 'pointer',
    [desktop]: {
      backgroundImage: `url(${require('./../../assets/images/logo-min.svg').default})`,
      width: rem(24),
      height: rem(24),
    },
  },
  add: {
    [adaptive]: {
      display: 'none',
    },
    [desktop]: {
      marginLeft: 'auto',
    },
    '& a': {
      lineHeight: rem(38),
    },
  },
  button: {
    width: rem(144),
    '&:hover': {
      color: theme.colors.white,
    },
  },
  items: {
    [adaptive]: {
      display: 'none',
    },
    [desktop]: {
      marginLeft: rem(80),
    },
  },
  item: {
    fontSize: rem(12),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    fontWeight: 300,
    textTransform: 'uppercase',
    '& + &': {
      marginLeft: rem(40),
    },
    '&.active': {
      color: theme.colors.charcoalGrey,
      fontWeight: 600,
      borderBottom: [rem(2), 'solid', theme.colors.blue],
    },
  },
  support: {
    [desktop]: {
      right: rem(176),
    },
  }
}

const useRouterParamValue = (paramName) => {
  const [to, setTo] = useState()
  const params = useParams()
  const paramValue = params[paramName]

  useEffect(() => {
    if (paramValue) {
      setTo(paramValue)
    }
  }, [paramValue])

  return to
}

const useHeaderNavigation = () => {
  const orderIdParam = useRouterParamValue('orderId')

  const myTasksTo = orderIdParam ? `/orders/${orderIdParam}/chat` : '/orders'
  const myTutorsTo = '/tutors'

  return {myTasksTo, myTutorsTo}
}

const Header = ({
                  flat,
                  onlyLogo,
                  onlyDesktop,
                  className,
                  classes
                }) => {
  const actions = useActions()
  const {isLogged} = useAuth()
  const {folder = 'orders'} = useParams()
  const {myTasksTo, myTutorsTo} = useHeaderNavigation()

  return (
    <header className={classnames(classes.root, className, {flat, onlyLogo, onlyDesktop})}>
      {!onlyLogo &&
      <div className={classes.hamburger} onClick={actions.onMenu}>
        <Icon className='hamburger-mobile' iconName='hamburger'/>
        <Icon className='hamburger-desktop' iconName='hamburger-desktop'/>
      </div>
      }
      <Link className={classes.logo} to='/'/>
      {isLogged &&
      <div className={classes.items}>
        <Link className={classnames(classes.item, {'active': folder === 'orders'})} to={myTasksTo}>My tasks</Link>
        <Link className={classnames(classes.item, {'active': folder === 'tutors'})} to={myTutorsTo}>My tutors</Link>
      </div>
      }
      {!onlyLogo && isLogged &&
      <>
        <div className={classes.add}>
          <Button
            className={classes.button}
            type='primary'
            size='medium'
            onClick={() => actions.showOrderForm()}
          >
            New task
          </Button>
        </div>
      </>
      }
    </header>
  )
}

export default injectSheet(styles)(Header)
