import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    flexGrow: 1,
  },
}

const Helper = ({ classes }) => <div className={classes.root} />

export default injectSheet(styles)(Helper)