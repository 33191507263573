import moment from 'moment-timezone'
import {useEffect, useState} from 'react'

const getDiff = toTime => {
  const start = moment()
  const end = moment(toTime)
  return start > end ? 0 : moment.duration(end.diff(start))
}

function useTimeCountdown(toTime, timeout = 600) {
  const [diff, setDiff] = useState(getDiff(toTime))

  useEffect(() => {
    let t
    if (diff !== 0) {
      t = setTimeout(() => {
        setDiff(getDiff(toTime))
      }, timeout)
    }
    return () => {
      clearTimeout(t)
    }
  }, [diff, setDiff, timeout, toTime])

  return diff && diff.days() > 0
    ? moment().to(toTime, true)
    : moment().startOf('day').add(diff).format(diff && diff.hours() > 0 ? 'HH:mm:ss' : 'mm:ss')
}

export default useTimeCountdown
