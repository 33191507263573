import React from 'react'
import {createUseStyles} from 'react-jss'
import {utils} from 'mtuikit'
import theme, {desktop, adaptive, media} from '../../theme/base'
import {useSupportChatConfig} from '../../hooks'
import {SupportChat as BaseSupportChat} from '../Chat/SupportChat'
import {ICON_SIZE_DESKTOP, POSITION, MARGIN} from './consts'

const SupportChat = ({onClose, defaultMessage}) => {
  const classes = useStyle()
  const chatConfig = useSupportChatConfig()
  const isMobile = utils.useMobile(media.desktop)
  return chatConfig
    ? <div className={classes.root}>
      <div className={classes.header}>
        Support chat
        <img
          className={classes.close}
          src={isMobile
            ? require('./images/ic_close_mobile.svg').default
            : require('./images/ic_close_desktop.svg').default
          }
          alt=''
          onClick={onClose}
        />
      </div>
      <BaseSupportChat chatConfig={chatConfig} defaultMessage={defaultMessage}/>
    </div>
    : null
}

const useStyle = createUseStyles({
  root: {
    background: '#F6F7FB',
    position: 'fixed',
    zIndex: 1005,
    boxShadow: [
      [0, 8, 12, 'rgba(0, 38, 96, 0.06)'],
      [0, 8, 40, 'rgba(0, 53, 136, 0.15)']
    ],
    [desktop]: {
      width: 420,
      height: `calc(100vh - ${POSITION + ICON_SIZE_DESKTOP + MARGIN * 2}px)`,
      borderRadius: 16,
      right: POSITION,
      bottom: POSITION + ICON_SIZE_DESKTOP + MARGIN,
      top: 16,
      '& > div': {
        '&:last-child': {
          '& > div': {
            '&:last-child': {
              borderRadius: [0, 0, 16, 16],
            },
          },
        },
      },
    },
    [adaptive]: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  header: {
    background: theme.colors.white,
    padding: [18, 54],
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 800,
    color: theme.colors.charcoalGrey,
    textAlign: 'center',
    borderBottom: [1, 'solid', '#BAC7E2'],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    '& + div': {
      transform: 'translateY(56px)',
      height: `calc(100% - 56px) !important`,
    },
    [desktop]: {
      borderRadius: [16, 16, 0, 0],
    }
  },
  close: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    [desktop]: {
      width: 24,
      height: 24,
      right: 12,
    },
    [adaptive]: {
      width: 28,
      height: 28,
      right: 16,
    },
  }
})

export default SupportChat
