import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'

const styles = {
  root: {
    width: rem(36),
    height: rem(36),
    borderRadius: '50%',
    display: 'block',
    position: 'absolute',
    top: rem(18),
    left: rem(16),
    '& + &': {
      '& img': {
        width: rem(20),
        height: rem(20),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      display: 'inherit',
    },
    '.big &': {
      width: rem(64),
      height: rem(64),
    },
    '.rate &': {
      width: rem(44),
      height: rem(44),
    },
  },
  online: {
    content: "''",
    width: rem(10),
    height: rem(10),
    background: theme.colors.online,
    borderRadius: '50%',
    position: 'absolute',
    right: rem(0),
    top: rem(-2),
    zIndex: 1,
    display: 'block',
    '.big &': {
      width: rem(12),
      height: rem(12),
      right: rem(2),
      top: rem(2),
    }
  },
  favorite: {
    width: rem(14),
    height: rem(14),
    display: 'block',
    position: 'absolute',
    bottom: rem(-4),
    right: rem(-2),
    fill: theme.colors.blue,
    '.big &': {
      width: rem(15),
      height: rem(15),
      bottom: rem(0),
      right: rem(0),
    }
  },
  block: {
    width: rem(14),
    height: rem(14),
    display: 'block',
    position: 'absolute',
    bottom: rem(-4),
    right: rem(-2),
    fill: theme.colors.blue,
    '.big &': {
      width: rem(15),
      height: rem(15),
      bottom: rem(0),
      right: rem(0),
    }
  },
}

const Image = ({
  src,
  online,
  big,
  favorite,
  block,
  classes,
}) =>
  <div className={classnames(classes.root, { big })}>
    {online && <div className={classes.online} />}
    {favorite && <Icon className={classes.favorite} iconName='favorite-tutor' />}
    {block && <Icon className={classes.block} iconName='block-tutor' />}
    <img src={src} alt='' />
  </div>

export default injectSheet(styles)(Image)
