import React from "react"
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import Layout from './layout'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'
import {BadRateAlert, GoodRateAlert} from '../../../Alerts'
import {useOrder, useRateOrderMutation, useTutorQuery} from '../../../../hooks'

const RateRequestedEvent = ({order_id, ...props}) => {
  const actions = useActions()
  const order = useOrder(order_id)
  const rateOrderMutation = useRateOrderMutation()
  const tutorQuery = useTutorQuery(order.assigned.freelancer_id)

  const onRate = rate => {
    rateOrderMutation.mutate({orderId: order_id, rate})
    actions.showOverlay(rate > 3 ? GoodRateAlert : BadRateAlert)
  }

  if (!tutorQuery.isFetched) return <div style={{height: 264}}/>

  const {data: tutor} = tutorQuery

  return order.rated === null
    ? (
      <MessageWrapper {...props}>
        <Card {...props}>
          <Layout.Body>
            <Layout.Title1>Rate your experience</Layout.Title1>
            <Layout.RateExperience>
              <Layout.Avatar url={getUserAvatarUrl(tutor.id)}/>
              <Layout.Title>{tutor.name}</Layout.Title>
              <Layout.Subject>{tutor.my_last_subject}</Layout.Subject>
              <Layout.TutorStat score={tutor.avg_score} helped={tutor.orders_count}/>
            </Layout.RateExperience>
            <Layout.Stars onChange={e => onRate(parseInt(e.target.value))}/>
          </Layout.Body>
          <Card.Footer {...props} />
        </Card>
      </MessageWrapper>
    )
    : (
      <MessageWrapper {...props}>
        <Card {...props}>
          <Layout.Body>
            <Layout.Title1>
              <center>You’ve rated your experience</center>
            </Layout.Title1>
            <Layout.RatedExperience>
              <Layout.RateKey value={order.rated.rate}/>
              <Layout.RateValue>{["It’s horrible", "It’s bad", "It’s disappointing", "It’s good", "It’s great!"][order.rated.rate - 1]}</Layout.RateValue>
            </Layout.RatedExperience>
          </Layout.Body>
          <Card.Footer {...props} />
        </Card>
      </MessageWrapper>
    )
}

export default RateRequestedEvent
