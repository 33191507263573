import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {desktop, adaptive, media} from 'mtuikit/theme/base'
import closedChatIcon from './images/closed.svg'
import openedChatIcon from './images/opened.svg'
import {theme, utils} from '../../mtuikit'
import {useActiveBundle, useSupportChatConfig} from '../../hooks'
import {useNotification} from '@connect/connect-xmpp-v2'
import {ICON_SIZE_MOBILE, ICON_SIZE_DESKTOP, POSITION} from './consts'
import {useSupportChatContext} from './context'
import classnames from 'classnames'

const SupportChatButton = ({className}) => {
  const classes = useStyle()
  const chatConfig = useSupportChatConfig()
  const {count, first} = useNotification(chatConfig)
  const [hover, setHover] = useState(false)
  const [popup, setPopup] = useState()
  const {isOpen, toggle} = useSupportChatContext()
  const isMobile = utils.useMobile(media.desktop)
  const activeBundle = useActiveBundle()

  if (isMobile && isOpen) return null

  return (
    <div className={classnames(classes.chat, {[classes.bundles]: activeBundle}, classes[className])}>
      <div className={classes.icon} onClick={toggle}>
        <img src={openedChatIcon} alt='' style={{opacity: isOpen ? 1 : 0}}/>
        <img src={closedChatIcon} alt='' style={{opacity: isOpen ? 0 : 1}}/>
        {
          count > 0 && !isOpen &&
          <div className={classes.count}>
            {count}
          </div>
        }
      </div>
      {
        !(isMobile || count === 0 || popup === first?.message) &&
        <div
          className={classes.message}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {
            hover &&
            <img
              className={classes.close_message}
              src={require('./images/ic_close_desktop.svg').default}
              alt=''
              onClick={() => setPopup(first?.message)}
            />
          }
          {first?.message}
        </div>
      }
    </div>
  )
}

const useStyle = createUseStyles({
  chat: {
    position: 'fixed',
    zIndex: 5,
    [desktop]: {
      bottom: POSITION,
      right: POSITION,
    },
    [adaptive]: {
      bottom: 16,
      right: 16,
    },
  },
  top: {
    [adaptive]: {
      top: 14,
      bottom: 'auto',
      '& $icon': {
        width: ICON_SIZE_MOBILE,
        height: ICON_SIZE_MOBILE,
      },
    },
  },
  bundles: {
    [adaptive]: {
      top: 13 + 40,
    }
  },
  header: {
    [adaptive]: {
      position: 'absolute',
      top: 14,
      '& $icon': {
        width: ICON_SIZE_MOBILE,
        height: ICON_SIZE_MOBILE,
      },
    },
  },

  chatTop: {
    backgroundColor: 'red',
  },
  icon: {
    cursor: 'pointer',
    width: ICON_SIZE_DESKTOP,
    height: ICON_SIZE_DESKTOP,
    '& > img': {
      width: 'inherit',
      height: 'inherit',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'block',
      transition: 'opacity 300ms ease',
    },
  },
  count: {
    background: 'linear-gradient(180deg, #FF54A6 0%, #ED2C39 66.67%)',
    width: 20,
    height: 20,
    border: [2, 'solid', 'rgba(255, 255, 255, 0.24)'],
    fontSize: 12,
    lineHeight: 16 / 12,
    fontWeight: 600,
    color: theme.colors.white,
    textAlign: 'center',
    position: 'absolute',
    top: -4,
    right: -4,
    borderRadius: '50%',
  },
  message: {
    background: '#F6F7FB',
    width: 326,
    padding: [16, 32, 16, 16],
    borderRadius: [16, 16, 2, 16],
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    color: theme.colors.charcoalGrey,
    position: 'absolute',
    bottom: '100%',
    right: 0,
    transform: 'translateY(-8px)',
    boxShadow: [
      [0, 4, 12, 'rgba(24, 39, 75, 0.12)'],
      [0, 14, 64, -4, 'rgba(24, 39, 75, 0.12)'],
    ],
  },
  close_message: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: 12,
    right: 8,
    cursor: 'pointer',
  },
})

export default SupportChatButton
