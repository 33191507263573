import React from 'react'
import {ThemeProvider} from 'styled-components'
import {themeDefault} from '@connect/connect-ui-js-v2'
import imageSet from './img'

import './index.scss'

const CircularStdBold = 'CircularStd-Bold'
const CircularStdBook = 'CircularStd-Book'

const colorBrand1 = '#3D77FF'
const colorBrand2 = '#366CE5'
const colorBrand3 = '#3060CC'

const colorGrey1 = '#1E222B'
const colorGrey2 = '#2F333D'
const colorGrey3 = '#565D6B'
const colorGrey4 = '#939FB8'
const colorGrey5 = '#BAC7E2'
const colorGrey6 = '#EBEEF5'
const colorGrey7 = '#F6F7FB'
const colorBlack = '#000000'
const colorWhite = '#FFFFFF'
const colorYellow = '#F7B500'

const colorSuccess1 = '#34C759'
// const colorSuccess2 = '#49B000'
const colorSuccess3 = '#489900'

const colorError1 = '#ED2C39'
const colorError2 = '#CC2631'
const colorError3 = '#B20005'

const colorTypographyPrimary = '#F6F7FB'

const typography = {
  headline1: {
    fontFamily: CircularStdBold,
    fontSize: 18,
    lineHeight: '28px',
    letterSpacing: -0.45,
  },
  body1: {
    fontFamily: CircularStdBook,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: -0.32,
  },
  body2: {
    fontFamily: CircularStdBook,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.15,
  },
  capture1: {
    fontFamily: CircularStdBook,
    fontSize: 12,
    lineHeight: '20px',
  },
}

const boxShadow = '0px 4px 8px rgba(86, 93, 107, 0.1)'

export const theme = themeDefault

theme.connectUi.messageInput.font = typography.body1
theme.connectUi.messageInput.color = colorGrey1
theme.connectUi.messageInput.borderColor = 'transparent'
theme.connectUi.messageInput.placeholderColor = colorGrey4
theme.connectUi.messageInput.backgroundColor = colorGrey6
theme.connectUi.messageInput.borderTopColor = colorGrey6

theme.connectUi.message.shadowOffset = {
  width: 0,
  height: 4,
}

theme.connectUi.message.shadowRadius = 8
theme.connectUi.message.shadowColor = 'rgba(0, 53, 136, 0.1)'
theme.connectUi.message.shadowOpacity = 1
theme.connectUi.message.boxShadow = boxShadow
theme.connectUi.message.maxWidth = '75%'
theme.connectUi.message.font = typography.body1
theme.connectUi.message.timeBackground = colorBlack
theme.connectUi.message.timeOpacity = 0.7
theme.connectUi.message.inBackground = colorWhite
theme.connectUi.message.inColor = colorGrey1
theme.connectUi.message.outBackground = colorBrand1
theme.connectUi.message.outTimeColor = colorWhite
theme.connectUi.message.inTimeColor = colorGrey1
theme.connectUi.message.progressColor = colorSuccess1
theme.connectUi.message.textIndent = 70
// theme.connectUi.message.fileSizeLarge = 208
// theme.connectUi.message.fileSize = 154
// theme.connectUi.message.fileSizeSmall = 100
theme.connectUi.message.showError = false

theme.connectUi.dropdown.color = colorGrey1
theme.connectUi.dropdown.deleteColor = colorError2

theme.connectUi.header.font = typography.headline1
theme.connectUi.header.titleColor = colorTypographyPrimary

theme.connectUi.messageList.backgroundColor = colorGrey7
theme.connectUi.messageList.typingDotColor = colorBrand1
// audio record
theme.connectUi.audioRecord.header.font = typography.headline1;
theme.connectUi.audioRecord.header.color = colorGrey2;

theme.connectUi.audioRecord.subHeader.font = typography.body2;
theme.connectUi.audioRecord.subHeader.color = colorGrey3;
theme.connectUi.audioRecord.recordTime.font = typography.headline1;
theme.connectUi.audioRecord.recordTime.color = colorGrey2;

theme.connectUi.audioRecord.buttonMic.backgroundColor = colorBrand1;
theme.connectUi.audioRecord.buttonMic.backgroundHoverColor = colorBrand1;

theme.connectUi.audioRecord.buttonRePlay.backgroundColorPlay = colorBrand1;
theme.connectUi.audioRecord.buttonRePlay.backgroundHoverColorPlay = colorBrand1;
theme.connectUi.audioRecord.buttonRePlay.backgroundColorPause = colorGrey6;
theme.connectUi.audioRecord.buttonRePlay.backgroundHoverColorPause = colorGrey6;

theme.connectUi.audioRecord.buttonTrash.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonTrash.label.color = colorGrey2;
theme.connectUi.audioRecord.buttonTrash.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonTrash.label.color = colorGrey2;

theme.connectUi.audioRecord.buttonSend.label.font = typography.capture1;
theme.connectUi.audioRecord.buttonSend.label.color = colorGrey2;

theme.connectUi.imageSet = imageSet
theme.connectUi.typography = typography
theme.connectUi.boxShadow = boxShadow
theme.connectUi.message.boxShadow = boxShadow

theme.connectUi.btnSend = {
  default: colorBrand1,
  hover: {
    background: colorGrey6,
    color: colorBrand2,
  },
  active: {
    color: colorBrand3,
    background: colorGrey5,
  },
}

theme.connectUi.btnAttach = {
  default: colorBrand1,
  hover: {
    background: colorGrey6,
    color: colorBrand2,
  },
  active: {
    color: colorBrand3,
    background: colorGrey5,
  },
}

theme.connectUi.btnClose = {
  default: colorBrand1,
  hover: {
    background: colorGrey6,
    color: colorBrand2,
  },
  active: {
    color: colorBrand3,
    background: colorGrey5,
  },
}

theme.connectUi.btnWarning = {
  default: colorError1,
  hover: {
    color: colorError2,
  },
  active: {
    color: colorError3,
  },
}

theme.connectUi.btnTrash = {
  default: colorBrand1,
  hover: {
    background: 'transparent',
    color: colorBrand1,
  },
  active: {
    color: colorBrand1,
    background: 'transparent',
  },
};

theme.connectUi.btnMic = {
  default: colorBrand1,
  hover: {
    background: 'transparent',
    color: colorBrand1,
  },
  active: {
    color: colorBrand1,
    background: 'transparent',
  },
};

theme.connectUi.message.outBackgroundHover = colorBrand2
theme.connectUi.message.inBackgroundHover = colorGrey5

theme.connectUi.badgeBackground = colorBrand1
theme.connectUi.badgeColor = colorWhite
theme.connectUi.badgeBorderRadius = 12
theme.connectUi.badgeMinWidth = 20
theme.connectUi.badgeHeight = 20
theme.connectUi.badgeTop = -10

theme.connectUi.colors.backgroundColor = colorGrey7
theme.connectUi.colors.dateSeparatorColor = colorGrey4
theme.connectUi.colors.title = colorGrey2
theme.connectUi.colors.text = colorGrey3
theme.connectUi.colors.link = colorBrand1
theme.connectUi.colors.linkHover = colorBrand2
theme.connectUi.colors.dividerBackground = colorSuccess3
theme.connectUi.colors.radioDefault = colorGrey5
theme.connectUi.colors.radioActive = colorBrand1
theme.connectUi.colors.avatarBorderColor = colorWhite
theme.connectUi.colors.starDefault = colorBrand1
theme.connectUi.colors.starHover = colorYellow

theme.connectUi.message.outColor = colorWhite

theme.connectUi.audioRecord.waveView.waveColor = '#D5E1EB'
theme.connectUi.audioRecord.waveView.progressColor = '#007AFF'

theme.connectUi.audio.waveView.inWaveColor = '#AFBFCD'
theme.connectUi.audio.waveView.inProgressColor = '#007AFF'
theme.connectUi.audio.waveView.outWaveColor = '#FFFFFF7A'
theme.connectUi.audio.waveView.outProgressColor = '#FFFFFF'

theme.connectUi.audio.playBtn.outBackground = '#FFFFFF'
theme.connectUi.audio.playBtn.inBackground = '#007AFF'
theme.connectUi.audio.playBtn.outIconColor = '#007AFF'
theme.connectUi.audio.playBtn.inIconColor = '#FFFFFF'

theme.connectUi.audio.pauseBtn.outBackground = '#FFFFFF'
theme.connectUi.audio.pauseBtn.inBackground = '#007AFF'
theme.connectUi.audio.pauseBtn.outIconColor = '#007AFF'
theme.connectUi.audio.pauseBtn.inIconColor = '#FFFFFF'

const ThemeUIProvider = (props) => <ThemeProvider theme={theme} {...props} />

export default ThemeUIProvider
