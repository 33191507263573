import React from 'react'
import {Report} from 'mtuikit'
import {REPORT} from '../../config'
import {useActions} from '../../actions'
import {ReportSentAlert} from '../Alerts'

const ReportTutor = ({
                       tutorId,
                       onClose,
                       ...otherProps
                     }) => {
  const actions = useActions()
  const sendTutorReport = () => {
    onClose()
    actions.showOverlay(ReportSentAlert)
  }
  return (
    <Report
      report={REPORT}
      onSubmit={() => sendTutorReport()}
      onClose={onClose}
      {...otherProps}
    />
  )
}

export default ReportTutor