import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
//import { rem, adaptive, desktop } from './../../theme/base'

const styles = {
  root: {
    flex: 1,
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
  },
}

const Content = ({
  children,
  classes,
  className,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Content)
