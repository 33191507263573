import React, {useState, forwardRef} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {rem, media} from './../../../theme/base'
import {utils} from 'mtuikit'
import Icon from './../layout/Icon'
import Title from './../layout/Title'
import styles from './styles'
import {useProfileQuery, useSupportChatConfig} from '../../../hooks'
import {SupportChat} from '../../Chat/SupportChat'

const _styles = {
  ...styles,
  chat: {
    background: '#F6F7FB',
    width: rem(352),
    borderRadius: [0, rem(16), rem(16), 0],
    overflow: 'hidden',
  },
}

const ChatWrapper = forwardRef(({
                                  className,
                                  title,
                                  banner,
                                  children,
                                  classes,
                                  onBack,
                                  onClose,
                                  onSupport
                                }, ref) => {
  const [showChat, setShowChat] = useState(false)
  const isDesktop = !utils.useMobile(media.desktop)
  const {returned} = useProfileQuery().data
  const configChat = useSupportChatConfig()

  const _onSupport = isDesktop
    ? () => setShowChat(!showChat)
    : onSupport

  return (
    <div className={classes.flex}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Title>{title}</Title>
          {onBack
            ? <Icon.Back onClick={onBack}/>
            : returned
              ? <Icon.Close onClick={onClose}/>
              : null
          }
          {
            showChat && isDesktop
              ? <Icon.CloseChat onClick={_onSupport}/>
              : <Icon.Support onClick={_onSupport} banner={banner} returned={returned}/>
          }
        </div>
        <div className={classes.wrapper} ref={ref}>
          <div className={classnames(classes.content, className)}>
            {children}
          </div>
        </div>
      </div>
      {
        showChat && isDesktop &&
        <div className={classes.chat}>
          <SupportChat chatConfig={configChat}/>
        </div>
      }
    </div>
  )
})

export default injectSheet(_styles)(ChatWrapper)