import React from 'react'
import {createUseStyles} from 'react-jss'
import {Modal} from './../../modals'
import TopUpBalanceBundle from '../TopUpBalanceBundle'
import theme, {adaptive} from '../../theme/base'
import Countdown from '../Countdown'
import {useBundle} from '../../hooks'


const PopupBalanceTopUpBundle = ({bundleId, onClose, zIndex}) => {
  const classes = useStyle()
  const bundle = useBundle(bundleId)
  return <Modal className={classes.root} zIndex={zIndex} mobileStickToBottom>
    <div className={classes.header}>
      <img className={classes.header_icon} src={require('./img/ic_limited-time_active.svg').default} alt=''/>
      <div className={classes.header_time}><Countdown dl={bundle.bundle_expire_at}/></div>
      <div className={`${classes.header_msg} ${classes.header_msg_active}`}>Limited-time offer!</div>
      <button className={classes.close_btn} onClick={onClose}>
        <img className={classes.close_icon} src={require('./img/ic_close.svg').default} alt=''/>
      </button>
    </div>
    <div className={classes.modal_body}>
      <div className={classes.title}>
        Save on your next tasks!
        <img className={classes.title_icon} src={require('./img/em_money-bag@2x.png').default} alt=''/>
      </div>
      <div className={classes.description}>Top up your NerdyTutors account balance now to save on future tasks.</div>
      <TopUpBalanceBundle bundle={bundle} className={'modal'} onPaid={onClose} clickSource='popup'/>
    </div>
  </Modal>
}

const useStyle = createUseStyles({
  root: {
    fontFamily: theme.fontFamilies.Manrope,
    padding: 0,
    height: 'auto',
    [adaptive]: {
      maxWidth: 'none',
    },
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: [18, 16, 18, 26],
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    borderRadius: [24, 24, 0, 0],
    width: '100%',
    backgroundColor: '#FFEEB8',
  },
  header_icon: {
    display: 'block',
    width: 20,
    height: 20,
  },
  header_time: {
    color: '#E37900',
    minWidth: 65,
    marginLeft: 4,
  },
  header_msg: {
    color: '#939FB8;',
    marginLeft: 4,
  },
  header_msg_active: {
    color: '#2F333D',
  },
  close_btn: {
    padding: 0,
    backgroundColor: 'transparent',
    marginLeft: 'auto',
  },
  close_icon: {
    display: 'block',
    width: 20,
    height: 20,
  },
  modal_body: {
    padding: 24,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#2F333D',
  },
  title_icon: {
    display: 'block',
    width: 24,
    height: 24,
    marginLeft: 8,
  },
  description: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: 16,
    lineHeight: '24px',
    color: '#565D6B',
    marginTop: 4,
    textAlign: 'center',
  },
})

export default PopupBalanceTopUpBundle
