import React, {forwardRef} from 'react'
//import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from './../layout/Icon'
import Title from './../layout/Title'
import styles from './styles'

const BaseWrapper = forwardRef(({
  className,
  title,
  children,
  classes,
  onBack,
  onClose
}, ref) =>
    <div className={classes.root}>
      <div className={classes.header}>
        <Title>{title}</Title>
        {onBack
            ? <Icon.Back onClick={onBack} />
            : <Icon.Close onClick={onClose} />
        }
      </div>
      {children}
    </div>
)

export default injectSheet(styles)(BaseWrapper)
