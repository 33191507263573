import {useEffect} from 'react'
import moment from 'moment-timezone'

function useCallbackInTime(at, callback, force = false) {
  useEffect(() => {
    let t

    if (at) {
      const diff = moment(at).diff(moment())
      if (diff > 0) {
        t = setTimeout(callback, diff)
      } else if (force) {
        callback()
      }
    }

    return () => clearTimeout(t)
  }, [at, callback, force])
}

export default useCallbackInTime