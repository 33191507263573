import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {Button, InputPhone} from 'mtuikit'
import theme, {adaptive, desktop, rem} from '../../mtuikit/theme/base'
import Icon from '../Chat/components/icons'
import {useActions} from '../../actions'
import {useProfileQuery, useUpdateProfileMutation} from '../../hooks'
import {trackValues} from '../../lrtracker'
import Overlay from '../Overlay'

const PopupEditPhone = ({orderId, editedThrough, onClose, zIndex}) => {
  const classes = useStyles()
  const actions = useActions()
  const profileQuery = useProfileQuery()
  const updateProfileMutation = useUpdateProfileMutation()
  const [phone, setPhone] = useState(profileQuery.data.phone)

  const onSavePhone = () => {
    const cleanedPhone = phone[0] !== "+" ? "+" + phone : phone
    updateProfileMutation.mutate({phone: cleanedPhone}, {
      onSuccess: () => onClose(),
    })
    trackValues(editedThrough, 'clientPhoneNumberChanged', {
      context: {
        isNew: !profileQuery.data.phone,
        orderId
      }
    })
  }

  const error = updateProfileMutation.error && 'Please edit your phone number above or'

  return <Overlay zIndex={zIndex - 1}>
    <div className={classes.wrapper} style={{zIndex}}>
      <div className={classes.modal}>
        <div className={classes.header}>
          Phone number
          <button className={classes.closeBtn} onClick={() => onClose()}>
            <Icon.Close/>
          </button>
        </div>
        <div className={classes.modalBody}>
          <div className={classes.notice}>
            <Icon.Alert/>
            <div className={classes.noticeMessage}>We need <span
              className={classes.strong}>your phone number</span> to send you <span className={classes.strong}>SMS for only important</span> notifications
            </div>
          </div>

          <div className={classes.form}>
            <div className={classes.field}>
              <InputPhone
                country='us'
                inputProps={{
                  autoFocus: true,
                  className: classnames({'error': error}),
                }}
                value={phone}
                onChange={value => setPhone(value)}
              />
            </div>
          </div>
          {
            error &&
            <div className={classes.errorMessage}>
              {error}{' '}
              <span onClick={() => actions.showSupportChat()}>contact our support team to get help.</span>
            </div>
          }
          <Button
            type='primary'
            size='large'
            className={classes.button}
            onClick={onSavePhone}
            disabled={updateProfileMutation.isLoading}
            block
          >
            Save my phone number
          </Button>
        </div>
      </div>
    </div>
  </Overlay>
}

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '& input': {
      height: rem(56),
      paddingRight: rem(48),
      paddingTop: rem(15),
      paddingBottom: rem(15),
      border: [rem(1), 'solid', '#BAC7E2'],
      borderRadius: rem(28),
      textAlign: 'center',
      fontSize: rem(20),
      lineHeight: rem(24),
      letterSpacing: rem(0.12),
      fontFamily: theme.fontFamilies.Manrope,
      fontWeight: 'normal',
      color: theme.colors.charcoalGrey,
      boxShadow: 'none',
      '&.empty': {
        paddingRight: rem(24),
      },
    },
  },
  modal: {
    width: '100%',
    margin: 'auto',
    background: '#fff',
    boxShadow: '0px 8px 12px rgba(0, 38, 96, 0.06), 0px 8px 40px rgba(0, 53, 136, 0.15)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    [adaptive]: {
      marginBottom: 0,
      minHeight: 'auto',
      borderRadius: [16, 16, 0, 0],
    },
    [desktop]: {
      maxWidth: rem(464),
      borderRadius: rem(24),
    },
  },
  header: {
    width: '100%',
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 800,
    fontSize: rem(16),
    lineHeight: rem(22),
    textAlign: 'center',
    color: '#2F333D',
    position: 'relative',
    padding: [20, 0, 20],
    borderBottom: '1px solid #EBEEF5',
  },
  closeBtn: {
    display: 'block',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    position: 'absolute',
    top: 18,
    right: 16,
  },
  modalBody: {
    padding: [22, 16, 40],
  },
  notice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& svg': {
      flexShrink: 0,
    },
  },
  noticeMessage: {
    marginLeft: rem(8),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(14),
    lineHeight: rem(20),
    color: '#565D6B;',
    '& svg': {
      flexShrink: 0,
    }
  },
  strong: {
    fontWeight: 700,
  },
  link: {
    color: theme.colors.blue,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: rem(16),
    fontWeight: 700,
    cursor: 'pointer',
  },
  button: {
    marginTop: 36,
    '&:hover': {
      color: theme.colors.white,
    },
  },
  form: {
    marginTop: rem(16),
    width: '100%',
  },
  field: {
    position: 'relative',
    '& input': {
      width: '100%',
      paddingLeft: rem(73),
      textAlign: 'left',
      borderRadius: rem(8),
      '&.error': {
        borderColor: `${theme.colors.brightOrangeTwo} !important`,
        backgroundColor: 'rgba(255, 87, 0, 0.08)',
        '& + .flag-dropdown:before': {
          borderColor: `${theme.colors.brightOrangeTwo}`,
        }
      },
    },
    '& .react-tel-input': {
      '& .flag-dropdown.open:before': {
        backgroundColor: 'transparent',
      }
    },
    '& $flag-dropdown': {
      backgroundColor: 'red',
    }
  },
  errorMessage: {
    fontSize: rem(12),
    lineHeight: rem(16),
    color: theme.colors.brightOrangeTwo,
    textAlign: 'center',
    marginTop: 4,
    '& span': {
      color: theme.colors.blue,
      fontFamily: theme.fontFamilies.Manrope,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer'
    },
  },

})

export default PopupEditPhone
