import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames'
import {utils} from 'mtuikit'
import {useNotification} from '@connect/connect-xmpp-v2'
import {media} from './../../theme/base'
import Popup from './../Popup'
import Bubble from './layout/Bubble'
import Banner from './layout/Banner'
import SupportIcon from './layout/SupportIcon'
import {useSupportChatConfig} from '../../hooks'

let CLOSED_POPUP = []

const SupportIconChat_ = ({
                            bubbleLayout,
                            bannerLayout,
                            banner,
                            returned,
                            chatConfig,
                            ...otherProps
                          }) => {
  const {count, first} = useNotification(chatConfig)
  const modals = useSelector(state => state.modals)
  const [showPopup, setShowPopup] = useState(CLOSED_POPUP)
  const isDesktop = !utils.useMobile(media.desktop)

  const hidePopup = popupId => {
    CLOSED_POPUP = [...CLOSED_POPUP, popupId]
    setShowPopup(CLOSED_POPUP)
  }

  const unreadMessage = count ? first : null

  const popupAvailable = (
    isDesktop
    && showPopup.indexOf(banner && banner.id) === -1
    && showPopup.indexOf(unreadMessage && unreadMessage.id) === -1
    && (bubbleLayout.layout !== 'bottom' || modals.length === 0)
  )

  const unreadMessageAvailable = unreadMessage && popupAvailable
  const bannerAvailable = banner && popupAvailable

  let content = null
  let layout = null
  switch (true) {
    case unreadMessageAvailable:
      layout = bubbleLayout
      content = <Bubble
        className={classnames(bubbleLayout.layout, {'notReturned': !returned})}
        onClick={otherProps.onClick}
        onClose={() => hidePopup(unreadMessage.id)}>
        {unreadMessage.message}
      </Bubble>
      break;
    case bannerAvailable:
      layout = bannerLayout
      content = <Banner
        {...banner}
        className={bannerLayout.layout}
        onClick={otherProps.onClick}
        onClose={() => hidePopup(banner.id)}
      />
      break;
    default:
  }

  return <Popup
    trigger={
      <SupportIcon nmessages={count}{...otherProps}/>
    }
    content={content}
    {...layout}
  />
}

const HOC = (Component, Hook) => props => {
  const chatConfig = Hook()
  return chatConfig
    ? <Component {...{chatConfig, ...props}}/>
    : null
}

export const SupportIconChat = HOC(SupportIconChat_, useSupportChatConfig)