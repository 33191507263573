import React from 'react'
import injectSheet from 'react-jss'
import theme, {rem, adaptive, desktop} from '../../../../theme/base'
import ScrollbarWrap from '../ScrollbarWrap'
import Item from './Item'
import Overlay from '../Overlay'

const styles = {
  list: {
    zIndex: 3,
    [desktop]: {
      width: 360,
      position: 'absolute',
      top: '100%',
      left: -16,
      transform: 'translateY(8px)',
    },
    [adaptive]: {
      background: theme.colors.white,
      height: '75vh',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: [16, 16, 0, 0],
      '& .scrollbar': {
        height: '100% !important',
        maxHeight: '100% !important',
        top: '0 !important',
        '& > div': {
          maxHeight: '100% !important',
        },
      },
    },
  },
  randomTutors: {
    paddingLeft: rem(96),
    paddingRight: rem(16),
    '& > div': {
      '&:first-child': {
        left: rem(16),
      },
    },
  },
  divider: {
    marginTop: rem(16),
    padding: [0, rem(16)],
    fontSize: rem(14),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
    color: theme.colors.slateGrey,
    '&:first-child': {
      paddingBottom: rem(8,)
    },
  },
  close: {
    [desktop]: {
      display: 'none',
    },
    [adaptive]: {
      width: rem(24),
      height: rem(24),
      position: 'fixed',
      bottom: '75vh',
      right: rem(16),
      transform: `translateY(${rem(-8)})`,
      zIndex: 3,
      cursor: 'pointer',
      '&:before': {
        content: '""',
        width: rem(15),
        height: rem(2),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotateZ(-45deg)',
        background: theme.colors.white,
        borderRadius: rem(4),
      },
      '&:after': {
        content: '""',
        width: rem(15),
        height: rem(2),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotateZ(45deg)',
        background: theme.colors.white,
        borderRadius: rem(4),
      },
    },
  },
}

const List = ({
                classes,
                tutors,
                onSelect,
                onHide,
              }) => {
  const {favorite} = []
  return (
    <>
      <Overlay adaptive/>
      <div className={classes.close} onClick={onHide}/>
      <div className={classes.list}>
        <ScrollbarWrap>
          <div className={classes.divider}>Default option</div>
          <Item
            id='default'
            name='Auto-match tutor'
            subtitle='We’ll pick the best tutor for your subject'
            onClick={() => onSelect(null)}
          />
          <div className={classes.divider}>Your previous tutors</div>
          {tutors.map(({id, name, orders_count}) =>
            <Item
              key={id}
              id={id}
              name={name}
              subtitle={orders_count === 1 ? 'Helped once' : `Helped ${orders_count} times`}
              favorite={favorite?.indexOf(id) > -1}
              onClick={() => onSelect(id)}
            />
          )}
        </ScrollbarWrap>
      </div>
    </>
  )
}

export default injectSheet(styles)(List)