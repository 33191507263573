import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'
import classnames from 'classnames'

const styles = {
  root: {
    marginTop: rem(4),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&.bold': {
      fontWeight: 600,
      color: theme.colors.charcoalGrey,
    },
    '&.sliced': {
      paddingRight: rem(50),
    },
    '.timeline &': {
      paddingRight: 0,
    },
    '& + &': {
      marginTop: rem(8),
    },
  },
}

const Text = ({
  children,
  bold,
  sliced,
  classes,
  className,
}) =>
  <div className={classnames(classes.root, className, { bold, sliced })}>
    {children}
  </div>

export default injectSheet(styles)(Text)