import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {theme} from 'mtuikit'
import {Button} from '../../layout/Button'
import Uploader from '../../layout/File/Uploader'
import {S3, SUBJECT_AND_CATEGORIES} from './../../../../config'
import Textarea from '../../layout/Textarea'

const useStyle = createUseStyles({
  question: {
    marginTop: 16,
  },
  title: {
    color: theme.colors.slateGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 800,
  },
  textarea: {
    marginTop: 16
  },
  radiobuttons: {
    marginTop: 8
  },
  radiobutton: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 12,
    },
    '& > input[type=radio]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            borderWidth: 6,
            borderColor: theme.colors.blue,
          },
        },
      },
    },
    '& > label': {
      background: '#F6F7FB',
      padding: [6, 12, 6, 32],
      borderRadius: 44,
      color: theme.colors.blue,
      fontSize: 14,
      lineHeight: 20 / 14,
      fontWeight: 800,
      display: 'block',
      position: 'relative',
      cursor: 'pointer',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 4,
        top: 4,
        width: 24,
        height: 24,
        border: [2, 'solid', '#BAC7E2'],
        borderRadius: '50%',
        transition: 'border 300ms ease',
      },
    },
  },
  footer: {
    marginTop: 16,
  },
})

const RADIOS = [
  {text: 'Yes', value: ['Live session', 'Video call']},
  {text: 'No', value: ['No live session']},
  {text: 'Decide later', value: ['Decide later']},
]

const P0 = 'E.g. I’m stuck on a few Pre-Calculus midterm prep questions (screenshot attached) and want to find a tutor who can help me out with them.'
const P1 = 'E.g. I’m stuck on a several midterm prep problems and need a tutor to help me out with them. Screenshot and instructions are attached.'
const P2 = 'E.g. I’m stuck on a several midterm prep questions and need a tutor to help me out with them. Screenshot and instructions are attached.'
const P3 = 'E.g. I’m stuck with a paper and even have no idea how to start. I need a tutor to help me out. Screenshot of the instructions is attached.'

const PLACEHOLDER = {
  'Business Studies': P1,
  'Chemistry': P1,
  'Environmental science': P1,
  'Finance & Economics': P1,
  'Math & Statistics': P1,
  'Physics & Engineering': P1,
  'Computer science & IT': P2,
  'Social sciences': P2,
  'English & Literature': P3,
  'Humanities': P3,
}

const getPlaceholder = s => {
  try {
    return PLACEHOLDER[SUBJECT_AND_CATEGORIES.find(sc => sc[0] === s)[1]]
  } catch {
    return P0
  }
}

export const TaskDescriptionStep = ({
                                      details,
                                      outputs,
                                      files,
                                      subject,
                                      onChangeDetails,
                                      onChangeFiles,
                                      onContinue,
                                      onChangeOutputs,
                                      isSubmitted,
                                      error,
                                      fileUploading,
                                      setFileUploading
                                    }) => {
  const classes = useStyle()
  const submit = () => {
    if (outputs.length === 0) onChangeOutputs(['Decide later'])
    onContinue()
  }
  return <>
    <Textarea
      className={classnames(classes.textarea, {error})}
      placeholder={
        error
          ? "Please let our tutors know what kind of task you need help with"
          : getPlaceholder(subject)
      }
      minRows={4}
      value={details}
      onChange={e => onChangeDetails(e.target.value)}
      autoFocus
    />
    <Uploader
      config={S3}
      files={files}
      onChange={onChangeFiles}
      onStatusChange={status => setFileUploading(status)}
    />
    <div className={classes.question}>
      <div className={classes.title}>Will you need a live video call with the tutor?</div>
      <div className={classes.radiobuttons}>
        {RADIOS.map(({text, value}, index) =>
          <div className={classes.radiobutton} key={index}>
            <input
              id={value[0]}
              type='radio'
              name='outputs'
              value={value[0]}
              checked={outputs.length > 0 ? outputs[0] === value[0] : value[0] === 'Decide later'}
              onChange={() => onChangeOutputs(value)}
            />
            <label htmlFor={value[0]}>{text}</label>
          </div>
        )}
      </div>
    </div>
    <div className={classes.footer}>
      <Button onClick={submit} disabled={error || fileUploading}>{isSubmitted ? 'Save' : 'Continue'}</Button>
    </div>
  </>
}