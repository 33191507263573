import React from 'react'
import {MessageWrapper, Card} from '@connect/connect-ui-js-v2'
import {createUseStyles} from 'react-jss'
import {useActions} from '../../../../actions'
import Layout from './layout'
import {useCompleteOnboarding, useSmstoolApi} from '../../../../hooks'

const OnboardRequestInstructions = ({data, order_id, end_time, ...props}) => {
  const classes = useStyles()
  const actions = useActions()
  const smstool = useSmstoolApi()

  useCompleteOnboarding(order_id, order => {
    actions.showSupportChat()
    smstool.sendSupportChatOnboarding(order)
  })

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <Card {...props} data={{...data}}>
      <Layout.Body>
        <img className={classes.title_icon} src={require('../images/ic_flash.png').default} alt=''/>
        <Layout.Title>Speed up the process</Layout.Title>
        <Layout.Text>
          Add all additional information and files that the tutor might need to help you with this task:
        </Layout.Text>
        <Layout.Buttons>
          <Layout.Button
            onClick={() => actions.showAddTaskInstructions(order_id)}
          >
            Add files & instructions
          </Layout.Button>
        </Layout.Buttons>
      </Layout.Body>
      <Card.Footer {...props} data={{...data}}/>
    </Card>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  title_icon: {
    display: 'block',
    width: 48,
    height: 48,
    marginBottom: 16,
  },
})

export default OnboardRequestInstructions
