import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from 'theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    textAlign: 'center',
    position: 'relative',
  },
  label: {
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.blue,
    fontFamily: theme.fontFamilies.Manrope,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  checkbox: {
    display: 'none',
  },
}

const Custom = ({
  classes,
  type,
  customText,
  onClick,
}) =>
  <div className={classes.root}>
    <input id={type} type='checkbox' className={classes.checkbox} onChange={onClick} />
    <label className={classes.label} htmlFor={type}>{customText}</label>
  </div>

export default injectSheet(styles)(Custom)