import * as moment from 'moment'
import { calendar as options } from './config'

export const printDateTime = (value) => {
  switch (true) {
    case (typeof (value) === 'number'):
      return moment.unix(value).calendar(null, options)
    default:
      return moment(value).calendar(null, options)
  }
}
