import React from 'react'
import {createUseStyles} from 'react-jss'
import moment from 'moment'
import {useNotifications} from '../../../hooks'
import {colors, typography} from './consts'

const options = {
  sameDay: 'h:mm A',
  lastDay: 'ddd',
  lastWeek: 'ddd',
  sameElse: 'MMM D',
}

const Notifications = ({orderId}) => {
  const classes = useStyle()
  const {newMessageCount, lastMessageTime, lastMessageText} = useNotifications(orderId)
  return <>
    {
      newMessageCount > 0 &&
      <div className={classes.counter}>{newMessageCount}</div>
    }
    <div className={classes.root}>
      {
        lastMessageTime && lastMessageText
          ? <>
            <div className={classes.text}>{lastMessageText}</div>
            <div className={classes.time}>
              {moment(lastMessageTime).calendar(null, options)}
            </div>
          </>
          : <div className={classes.text}>{' '}</div>
      }
    </div>
  </>
}

const useStyle = createUseStyles({
  root: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  counter: {
    ...typography.s,
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: '#3C78FF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '28px',
    right: '28px'
  },
  text: {
    ...typography.x,
    color: colors.black2,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 52,
    lineHeight: 'normal',
    height: 20,
    '& br': {
      display: 'inline',
      content: '" "',
      clear: "none"
    }
  },
  time: {
    ...typography.s,
    color: colors.black1,
    flexShrink: 0,
    padding: [0, 0, 2, 4],
  }
})

export default Notifications
