import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'

const styles = {
  root: {
    background: theme.colors.vermillion,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    transition: 'background 300ms ease-out',
    cursor: 'pointer',
    '&:hover, &:active': {
      background: theme.colors.vermillionTwo,
    },
    '& svg': {
      fill: theme.colors.white,
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    [desktop]: {
      width: rem(32),
      height: rem(32),
      top: rem(16),
      right: rem(16),
      transform: 'none',
      zIndex: 2,
      '& svg': {
        width: rem(12),
        height: rem(12),
      },
    },
  },
}

const Remove = ({
  classes,
  className,
  swipe,
  onDelete,
}) => {
  if (!swipe) {
    return null
  }

  return (
    <div className={classnames(classes.root, className)} onClick={onDelete}>
      <Icon iconName='remove' />
    </div>
  )
}

export default injectSheet(styles)(Remove)