import React from 'react'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import {useOrderQuery} from '../../../../hooks'
import Layout from './layout'

const OnboardRequestInstructions = ({data, order_id, ...props}) => {
  const actions = useActions()

  const orderQuery = useOrderQuery(order_id)
  const {data: order} = orderQuery

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{
      ...data,
      message: <>⚡To speed up tutor search, please check you have provided full instructions and all files your tutor
        might need.
        {
          !order.assigned && !order.refunded &&
          <>
            {' '}
            <Layout.Action onClick={() => actions.showAddTaskInstructions(order_id)}>Add instructions</Layout.Action>
          </>
        }
      </>
    }}/>
  </MessageWrapper>
}

export default OnboardRequestInstructions