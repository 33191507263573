import React from "react"
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import Layout from './layout'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'
import {useOrderLiveSession} from "../../../../hooks";

const LiveSessionCanceledEvent = ({order_id, live_session_id, ...props}) => {
  const {created_by, scheduled_at} = props.data.event.event_params
  const liveSession = useOrderLiveSession(order_id, live_session_id)
  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <Layout.Title1
          iconName='live_session_ended'
          disabled
        >
          Live session is canceled
        </Layout.Title1>
        <Layout.LiveSession
          datetime={scheduled_at}
          duration={liveSession?.planed_duration_minutes}
          avatar={getUserAvatarUrl(created_by)}
        />
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

export default LiveSessionCanceledEvent