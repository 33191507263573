import React from 'react'
import injectSheet from 'react-jss'
import Item from './Item'
import Line from './Line'

const styles = {
  root: {
    position: 'relative',
  },
}

const mapItem = onClick => ({ value, label, active, disabled }) =>
  <Item
    key={label}
    value={label}
    active={active}
    disabled={disabled}
    onClick={() => onClick(value)}
  />

const COUNT = 6

const List = ({
  classes,
  items,
  onClick,
}) => {
  const subArray1 = items.slice(0, COUNT)
  const subArray2 = items.slice(COUNT, items.length)

  return (
    <div className={classes.root}>
      <Line>
        {subArray1.map(mapItem(onClick))}
      </Line>
      <Line>
        {subArray2.map(mapItem(onClick))}
      </Line>
    </div>
  )
}

export default injectSheet(styles)(List)