import React from 'react'
import {createUseStyles} from 'react-jss'
import {Button} from '../../layout/Button'
import {SelectDeadline} from '../../layout/SelectDeadline'
import {checkRestriction} from '../../../../nerdytutors'

const useStyle = createUseStyles({
  footer: {
    marginTop: 16,
  },
})

export const DueDateStep = ({
                              helpType,
                              deadline,
                              minDeadline,
                              size,
                              deadlineset,
                              onChangeDeadline,
                              onContinue,
                              isSubmitted
                            }) => {
  const classes = useStyle()

  const _size = size || 1
  const _deadline = deadline || 24

  const restriction = checkRestriction(_size, _deadline, helpType)
  const dateError = restriction && (restriction - _deadline > 24)
  const timeError = restriction
  const disabled = dateError || timeError

  const submit = () => {
    if (deadline === null) onChangeDeadline(_deadline)
    onContinue()
  }

  return (
    <>
      <SelectDeadline
        helpType={helpType}
        deadline={_deadline}
        minDeadline={minDeadline}
        size={_size}
        deadlineset={deadlineset}
        onChange={onChangeDeadline}
        dateError={dateError}
        timeError={timeError}
      />
      <div className={classes.footer}>
        <Button onClick={submit} disabled={disabled}>{isSubmitted ? 'Save' : 'Continue'}</Button>
      </div>
    </>
  )
}