import React from 'react'
import injectSheet from 'react-jss'
import { rem } from './../../theme/base'

const styles = {
  root: {
    backgroundImage: `url(${require(`./icon-process.svg`).default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: rem(56),
    height: rem(56),
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'fixed',
    '&:after': {
      content: '""',
      backgroundImage: `url(${require(`./loader.svg`).default})`,
      backgroundSize: `${rem(26)} ${rem(26)}`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      width: rem(26),
      height: rem(26),
      position: 'absolute',
      left: rem(15),
      top: rem(15),
    },
  },
}

const Loader = ({ classes, zIndex }) => <div className={classes.root} style={{ zIndex }} />

export default injectSheet(styles)(Loader)