import React, {useEffect} from 'react'
import injectSheet from 'react-jss'
import {useLocation} from 'react-router-dom'
import DesktopWrapperHOC2 from './../DesktopWrapperHOC2'
import theme, {rem} from './../../theme/base'
import {Wrapper, Logo, Greeting, Error, Form, Field, Submit, StateLink, Action, LoginWithGoogleButton} from './Layout'
import {
  useAuthorizeByLoginMutation,
  useAuthorizationByProviderTokenMutation,
  useBodyBackgroundColor,
} from './../../hooks'
import useEmailPasswordForm from './hooks'
import {useTrackValues} from '../../lrtracker'

const styles = {
  forgot: {
    marginTop: rem(16),
    color: theme.colors.slateGrey,
    textAlign: 'center',
    '& a': {
      color: theme.colors.blue,
    },
  },
}

const BaseLoginForm = ({classes, ...props}) => {
  useBodyBackgroundColor(theme.colors.blue)
  const location = useLocation()
  const authMutation = props.authMutation()
  const tokenAuthMutation = useAuthorizationByProviderTokenMutation('google')
  const {
    email,
    password,
    setEmail,
    setPassword,
    isEmailValid,
    isPasswordValid,
    emailError,
    passwordError,
    isSubmitDisabled,
    error,
    setSubmitted
  } = useEmailPasswordForm(authMutation, tokenAuthMutation, location.state?.email || '', useAuthorizeByLoginMutation)

  useTrackValues('AuthForm', 'AuthFormOpened', {
    context: {
      location: location.pathname,
      ref: location.state?.ref || 'student'
    }
  })

  const onSubmitLogin = () => {
    setSubmitted(true)
    if (isEmailValid && isPasswordValid) {
      authMutation.mutate({email, password})
    }
  }

  const onSubmitToken = token => {
    tokenAuthMutation.mutate({token: token.tokenId})
  }

  useEffect(() => {
  }, [location, props.trackerContextName])

  return (
    <DesktopWrapperHOC2>
      <Wrapper>
        <Logo/>
        <LoginWithGoogleButton onSuccess={onSubmitToken}/>
        <Greeting>{props.greeting}</Greeting>
        {
          error &&
          <Error>{error}</Error>
        }
        <Form>
          <Field
            type='email'
            placeholder={props.emailPlaceholder}
            value={email}
            onChange={setEmail}
            error={emailError}
            onKeyUp={e => e.keyCode === 13 && !isSubmitDisabled && onSubmitLogin()}
          />
          <Field
            type='password'
            placeholder={props.passwordPlaceholder}
            value={password}
            onChange={setPassword}
            error={passwordError}
            onKeyUp={e => e.keyCode === 13 && !isSubmitDisabled && onSubmitLogin()}
          />
          <Submit onClick={onSubmitLogin} disabled={isSubmitDisabled}>{props.submitButtonText}</Submit>
          {
            props.restorePasswordLink &&
            <div className={classes.forgot}>
              Forgot password? {' '}
              <StateLink to='/restore' state={{email}}>
                Restore
              </StateLink>
            </div>
          }
        </Form>
      </Wrapper>
      <Action
        state={{email}}
        {...props.secondActionButton}
      />
    </DesktopWrapperHOC2>
  )
}

export default injectSheet(styles)(BaseLoginForm)