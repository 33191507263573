import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import {utils} from 'mtuikit'
import theme, {rem, content, ui, adaptive, desktop, media} from '../../../theme/base'
import Header from '../Header'
import {MiniOrderForm} from '../../components'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [0, 0, rem(158), 0],
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'],
      [0, rem(8), rem(40), 'rgba(0, 53, 136, 0.15)']
    ],
    borderRadius: [0, 0, rem(24), rem(24)],
    position: 'relative',
    zIndex: 2,
    [adaptive]: {
      padding: [0, rem(8), rem(16), rem(8)],
    },
  },
  content,
  wrapper: {
    paddingTop: rem(158),
    position: 'relative',
    zIndex: 2,
  },
  header: {
    display: 'block',
  },
  title: {
    ...ui.title,
    fontSize: rem(48),
    lineHeight: rem(56),
    textAlign: 'left',
    '& > span': {
      color: theme.colors.blue,
    },
  },
  p: {
    ...ui.p,
    marginTop: rem(16),
    [adaptive]: {
      ...ui.p[adaptive],
      textAlign: 'left',
    },
  },
  form: {
    [desktop]: {
      width: rem(704),
      marginTop: rem(48),
    },
    [adaptive]: {
      marginTop: rem(24),
    },
  },
  image: {
    display: 'block',
    position: 'absolute',
    zIndex: 1,
    [desktop]: {
      width: rem(599),
      height: 'auto',
      top: rem(106),
      left: '50%',
      transform: `translateX(${rem(150)})`,
      '&.mobile': {
        display: 'none',
      },
    },
    [adaptive]: {
      width: '65vw',
      minWidth: rem(414),
      top: rem(56),
      right: rem(-16),
      '&.desktop': {
        display: 'none',
      },
    },
  },
  subjects: {
    maxWidth: '100%',
    margin: [rem(12), 0, 0, 0],
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      '& > div': {
        marginTop: rem(12),
      },
    },
    '& > div': {
      background: 'rgba(60, 120, 255, 0.24)',
      '&:hover': {
        background: 'rgba(60, 120, 255, 0.36)',
      },
      '& > span': {
        color: theme.colors.blue,
      },
    },
  },
}


// const removeLastChar = (string, character) => string.slice(-1) === character
//   ? string.slice(0, -1)
//   : string

const Hero = ({
  classes,
  showLogin,
  h1,
  title,
  subtitle,
  buttonText,
}) => {
  const isMobile = utils.useMobile(media.desktop)
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Header
          showLogin={showLogin}
          customRelativeClassName={classes.header}
        />
        <div className={classes.wrapper}>
          <div className={classes.title}>
            {isMobile
              ? <>{h1[0]}{' '}<br/><span>{h1[1]}</span></>
              : <>{h1[0]}{' '}<span>{h1[1]}</span></>
            }
          </div>
          <div className={classes.p}>
            <div dangerouslySetInnerHTML={{__html: title}}/>
            <div dangerouslySetInnerHTML={{__html: subtitle}}/>
          </div>
          <div className={classes.form}>
            <MiniOrderForm
              subjectsClassName={classes.subjects}
              buttonText={buttonText}
              placeholder='What subject do you need help with?'
              top
            />
          </div>
        </div>
      </div>
      {isMobile
        ? <img
          className={classnames(classes.image, 'mobile')}
          src={require('./promo-mobile@1x.jpg').default}
          srcSet={`
            ${require('./promo-mobile@3x.jpg').default} 3x, 
            ${require('./promo-mobile@2x.jpg').default} 2x, 
            ${require('./promo-mobile@1x.jpg').default} 1x
          `}
          alt=''
        />
        : <img
          className={classnames(classes.image, 'desktop')}
          src={require('./promo-desktop@1x.jpg').default}
          srcSet={`
            ${require('./promo-desktop@3x.jpg').default} 3x, 
            ${require('./promo-desktop@2x.jpg').default} 2x, 
            ${require('./promo-desktop.svg').default} 1x
          `}
          alt=''
        />
      }
    </div>
  )
}

export default injectSheet(styles)(Hero)