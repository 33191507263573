import React, {useEffect, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'

const defaultState = []
const MODAL_ADD = 'MODAL_ADD'
const MODAL_REMOVE = 'MODAL_REMOVE'
const MODAL_BACK = 'MODAL_BACK'
const MODAL_REMOVE_ALL = 'MODAL_REMOVE_ALL'

const actions = {
  add: payload => ({type: MODAL_ADD, payload: {...payload, uuid: uuidv4()}}),
  remove: uuid => ({type: MODAL_REMOVE, payload: uuid}),
  back: n => ({type: MODAL_BACK, payload: n}),
  removeAll: () => ({type: MODAL_REMOVE_ALL})
}

function reducer(state = defaultState, action) {
  const {type, payload} = action
  switch (type) {
    case MODAL_ADD:
      return [...state, payload]
    case MODAL_REMOVE:
      return state.filter(item => item.uuid !== payload)
    case MODAL_BACK:
      return state.slice(0, state.length - payload)
    case MODAL_REMOVE_ALL:
      return [...defaultState]
    default:
      return state
  }
}

const createManager = ({
                         useSelector,
                         useDispatch,
                         className: classNameOver,
                         style: styleOver,
                       }) => () => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const zIndex = 1000
  const mul = modals.length

  const defaultOverflowY = useRef(document.body.style.overflowY)
  const defaultHeight = useRef(document.body.style.height)
  useEffect(() => {
    document.body.style.overflowY = modals.length > 0
      ? 'hidden'
      : defaultOverflowY.current
    document.body.style.height = modals.length > 0
      ? '100vh'
      : defaultHeight.current
    return () => {
      // eslint-disable-next-line
      document.body.style.overflowY = defaultOverflowY.current
      document.body.style.height = 'auto'
    }
  }, [modals])

  return modals.map(({uuid, container, component, className, style, ...otherProps}, index) => {
    return React.createElement(container, {
      key: uuid,
      zIndex: zIndex + index * mul,
      className: className || classNameOver,
      style: style || styleOver,
      children: React.createElement(component, {
        ...otherProps,
        onClose: () => dispatch(actions.remove(uuid)),
        onBack: n => dispatch(actions.back(n)),
        zIndex: zIndex + index * mul + 1,
      })
    })
  })
}

const store = {
  reducer,
  actions,
  createManager,
}

export default store
