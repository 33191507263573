import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'

const Icon = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#EBEEF5"/>
  <path fillRule="evenodd" clipRule="evenodd"
        d="M8.34435 8.34315C7.75857 8.92893 7.75857 9.87868 8.34435 10.4645L11.8799 14L8.34435 17.5355C7.75857 18.1213 7.75857 19.0711 8.34435 19.6569C8.93014 20.2426 9.87989 20.2426 10.4657 19.6569L14.0012 16.1213L17.5367 19.6569C18.1225 20.2426 19.0723 20.2426 19.6581 19.6569C20.2438 19.0711 20.2438 18.1213 19.6581 17.5355L16.1225 14L19.6581 10.4645C20.2438 9.87868 20.2438 8.92893 19.6581 8.34315C19.0723 7.75736 18.1225 7.75736 17.5367 8.34315L14.0012 11.8787L10.4657 8.34315C9.87989 7.75736 8.93014 7.75736 8.34435 8.34315Z"
        fill="#3D77FF"/>
</svg>

const Close = ({onClick, className}) => {
  const classes = useStyles()
  return <button className={classnames(classes.root, className)} onClick={onClick}>
    <Icon/>
  </button>
}

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 18,
    right: 16,
    display: 'block',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  }
})

export default Close
