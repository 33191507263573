import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import Icon from '../icons'

const Pic = ({paid, unpaid, refunded, className}) => {
  const classes = useStyle()
  const fill = unpaid ? '#3D77FF' : '#ffffff'
  return <div className={classnames(classes.icon, className, {paid, unpaid, refunded})}>
    <Icon.Task fill={fill}/>
  </div>
}

const useStyle = createUseStyles({
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '44px',
    height: '44px',
    background: '#3D77FF',
    borderRadius: '8px',

    '&.refunded': {
      background: '#BAC7E2',
    },

    '&.unpaid': {
      background: '#3D77FF4D',
    }
  }
})

export default Pic