import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, {rem} from './../../../theme/base'
import {Icon} from 'mtuikit'


const styles = {
  root: {
    borderRadius: 'inherit',
    position: 'relative',
    '& + &': {
      marginTop: rem(4),
      borderTop: [rem(1), 'solid', theme.colors.paleGrey],
      borderRadius: 0,
    },
  },
  input: {
    background: '#fff',
    width: '100%',
    padding: [rem(20), rem(24)],
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 500,
    letterSpacing: rem(0.11),
    fontFamily: 'arial',
    color: theme.colors.charcoalGrey,
    border: 'none',
    outline: 'none',
    display: 'block',
    borderRadius: 'inherit',
  },
  error: {
    color: theme.colors.brightOrange,
    '&:-moz-placeholder': {
      color: theme.colors.brightOrange,
    },
    '&::-moz-placeholder': {
      color: theme.colors.brightOrange,
    },
    '&:-ms-input-placeholder': {
      color: theme.colors.brightOrange,
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.brightOrange,
    },
    '&::-webkit-text-fill-color': {
      color: theme.colors.brightOrange,
    },
  },
  icon: {
    paddingRight: rem(48),
    '& svg': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: rem(24),
      right: rem(24),
    }
  },
  text: {
    height: rem(24),
    position: 'absolute',
    top: rem(24),
    right: rem(24),
    fontSize: rem(13),
    lineHeight: rem(24),
    letterSpacing: rem(0.08),
    color: theme.colors.blueyGrey,
  },
};

const Input = ({
           children,
           classes,
           className,
           icon,
           status,
           error,
           onChange,
           onClickOutside,
           ...otherProps
         }) => {
  const inputProps = {
    ...otherProps,
    className: classnames(classes.input, className, {[classes.error]: error}),
    error: error ? error.toString() : 'false',
    onChange: e => onChange && onChange(e.target.value),
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'off',
  }

  return (
    <fieldset className={classnames(classes.root, {[classes.icon]: icon || status})}>
      <input {...inputProps} />
      {icon &&
        <Icon iconName={icon}/>
      }
      {status &&
        <div className={classes.text}>{status}</div>
      }
      {children}
    </fieldset>
  )
}

export default injectSheet(styles)(Input)