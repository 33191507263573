import React, {createContext, useContext, useState} from 'react'
import SupportChat from './SupportChat'

const Context = createContext({})

export const useSupportChatContext = () => useContext(Context)

const SupportChatProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState('')

  const toggle = () => {
    setIsOpen(!isOpen)
    setDefaultMessage('')
  }

  const close = () => {
    setIsOpen(false)
    setDefaultMessage('')
  }

  const open = (defaultMessage) => {
    setDefaultMessage(defaultMessage)
    setIsOpen(true)
  }

  return <Context.Provider value={{isOpen, close, open, toggle}}>
    {children}
    {isOpen && <SupportChat onClose={close} defaultMessage={defaultMessage}/>}
  </Context.Provider>
}

export default SupportChatProvider