import React from 'react'
import injectSheet from 'react-jss'
import Slider from 'react-slick'
import { utils } from 'mtuikit'
import theme, { rem, content, adaptive, media } from '../../../theme/base'
import Logos from '../../components/Logos'
import Item from './Item'

const REVIEW_ITEMS = [
  { 
    name: `Kate`,
    avatar: 'kate',
    rate: 5,
    title: `It’s save my time!`,
    text: `Unlike other tutoring services I saved time on picking the best fit tutor to help. The platform assigned him automatically based on what I needed. Happy with the result, gonna use more.`, 
    date: 'Today',
  },
  { 
    name: `Rosa George`,
    avatar: 'rosa',
    rate: 5,
    title: `Very helpful`,
    text: `My friend has recently started working with Nerdy Tutors and as she was very satisfied, i decided to give it a try. What especially like about this service is how quickly I shifted from A to Z where Z is me totally self confident on how to move on with my lab. Would recommend!`,
    date: '3 days ago',
  },
  { 
    name: `Helen S`,
    avatar: 'helen',
    rate: 5,
    title: `They helped me to understand a sociology project`,
    text: `They helped me to understand what was needed for my sociology project. I really liked how easy-going my tutor was, we were discussing the chapters I failed to get properly and now even after 1 session, I think I've managed to understand more stuff. That's really cool, thanks!`,
    date: '3 days ago',
  },
]

const OPTIONS = {
  className: 'center',
  infinite: false,
  arrows: false,
  variableWidth: true,
  centerPadding: '0px',
  slidesToShow: 3,
  speed: 500,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: media.desktop,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        autoplay: true,
      },
    },
  ],
}

const styles = {
  root: {
    padding: [rem(100), 0],
    textAlign: 'center',
    overflow: 'hidden',
    [adaptive]: {
      padding: [rem(64), 0],
    },
    '& .slick-slider': {
      marginTop: rem(40),
    },
    '& .slick-slide': {
      display: 'inline-block',
      verticalAlign: 'top',
      '& + .slick-slide': {
        marginLeft: rem(24),
      },
    },
    '& .slick-dots': {
      marginTop: rem(24),
      textAlign: 'center',
      '& li': {
        background: '#BAC7E2',
        width: rem(8),
        height: rem(8),
        margin: [0, rem(6)],
        borderRadius: '50%',
        display: 'inline-block',
        verticalAlign: 'top',
        transition: 'transform 300ms ease',
        '&.slick-active': {
          background: theme.colors.blue,
          transform: 'scale(1.5)',
        },
      },
      '& button': {
        background: 'transparent',
        width: 'inherit',
        height: 'inherit',
        padding: 0,
        borderRadius: 'inherit',
        textIndent: rem(-9999),
      },
    },
  },
  content: {
    ...content,
    width: rem(1224),
    paddingLeft: 0,
    paddingRight: 0,
  },
  h1: {
    fontSize: rem(24),
    lineHeight: rem(32),
    letterSpacing: rem(0.12),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 600,
    textAlign: 'center',
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > img': {
      width: rem(20),
      height: rem(20),
      margin: [rem(6), rem(14), rem(6), rem(4)],
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  h2: {
    marginTop: rem(8),
    fontSize: rem(18),
    lineHeight: rem(28),
    letterSpacing: rem(0.12),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    textAlign: 'center',
  },
  list: {
    marginTop: rem(40),
  },
  logos: {
    marginTop: rem(80),
  },
}

const Review = ({ classes }) => {
  const isMobile = utils.useMobile(media.desktop)
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.h1}>
          <span>4.8</span>
          <img src={require('./star-yellow.svg').default} alt='' />
          <span>Average tutor score</span>
        </div>
        <div className={classes.h2}>based on 760+ client reviews</div>
        <div className={classes.list}>
          <Slider {...OPTIONS}>
            {REVIEW_ITEMS.map((props, index) => <Item {...props} key={index} />)}
          </Slider>
        </div>
        {!isMobile &&
          <Logos className={classes.logos} />
        }
      </div>
    </div>
  )
}

export default injectSheet(styles)(Review)