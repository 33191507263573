import React, { Fragment, useEffect } from 'react'
import { createPortal } from 'react-dom'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../theme/base'
import {Icon} from './../../Icon'

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999999,
  },
  overlay: {
    background: theme.colors.black80,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(24),
    right: rem(16),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  delete: {
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    bottom: rem(24),
    textAlign: 'center',
    color: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontFamily: theme.fontFamilies.Manrope,
    '& div': {
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& svg': {
      width: rem(15),
      height: rem(16),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'top',
      fill: theme.colors.white,
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

const ImageView = ({
  classes,
  url,
  onClose,
  onRemove,
}) => {
  const rootNode = document.getElementById('root')
  const imageViewNode = document.createElement('div')
  imageViewNode.classList.add(classes.root)

  const onEscape = (e) => {
    if (e.keyCode === 27) {
      onClose()
    }
  }

  useEffect(() => {
    document.body.insertBefore(imageViewNode, rootNode)
    return () => document.body.removeChild(imageViewNode)
  })

  useEffect(() => {
    document.addEventListener('keyup', onEscape)
    return () => {
      document.removeEventListener('keyup', onEscape)
    }
  })

  return createPortal(
    <Fragment>
      <div className={classes.overlay} onClick={onClose} />
      <img className={classes.image} src={url} alt='' />
      <div className={classes.close} onClick={onClose}>
        <Icon iconName='close-transparent' />
      </div>
      {onRemove &&
        <div className={classes.delete}>
          <div onClick={() => {
            onRemove()
            onClose()
          }}
          >
            <Icon iconName='remove' />
            <span>Delete photo</span>
          </div>
        </div>
      }
    </Fragment>, 
    imageViewNode
  )
}

export default injectSheet(styles)(ImageView)