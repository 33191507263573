import * as moment from 'moment'

const options = {
  sameDay: 'h:mm A',
  nextDay: 'h:mm A',
  nextWeek: 'h:mm A',
  lastDay: 'h:mm A',
  lastWeek: 'h:mm A',
  sameElse: 'h:mm A',
}

export const printDateTimeCustom = (value) => {
  switch (true) {
    case (typeof (value) === 'number'):
      return moment.unix(value).calendar(null, options)
    default:
      return moment(value).calendar(null, options)
  }
}