import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, {rem, adaptive} from '../theme/base'

const styles = {
  root: {
    marginTop: rem(24),
    position: 'sticky',
    bottom: 0,
    zIndex: 3,
    [adaptive]: {
      background: theme.colors.white,
      marginTop: 0,
      padding: [rem(24), rem(16)],
      position: 'fixed',
      left: 0,
      right: 0,
    },
  },
}

const Sticky = ({
                  classes,
                  className,
                  children,
                }) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Sticky)
