import React, {useState, memo} from 'react'
import {createUseStyles} from 'react-jss'
import theme, {rem} from './../../../../../theme/base'
import classnames from 'classnames'
import {Icon} from 'mtuikit'
import ImageView from './../ImageView'
import {STATUS} from './../s3uploader'
import {getUserAvatarUrl} from "../../../../../mtuikit/utils/getUserAvatarUrl";

const DOCUMENTS = ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx', 'zip', 'rar', 'mp3', 'wav', 'mp4', 'avi', 'txt']
const IMAGES = ['jpg', 'jpeg', 'png', 'gif']


const Attachment = memo(({
                           name,
                           preview,
                           location,
                           progress,
                           status,
                           label,
                           onRemove,
                           onReload,
                           userId,
                           className,
                           big
                         }) => {
  const classes = useStyle()
  const [imageview, setImageview] = useState(false)
  const type = name.toLowerCase().split('.').pop()
  const src = IMAGES.indexOf(type) > -1 && (preview || location)
  const filetype = DOCUMENTS.indexOf(type) > -1 ? type : 'unknown-format'
  const loading = status === STATUS.UPLOADING
  const error = status === STATUS.ERROR

  const size_ = big ? 'big' : 'small'
  return (
    <div className={classnames(classes.root, size_, className, {loading})}>
      {src
        ? <div
          className={classes.preview}
          style={{backgroundImage: `url(${src})`}}
          onClick={() => setImageview(true)}
        />
        : <a className={classes.filetype} href={location} target='_blank' rel='noreferrer'>
          <Icon iconName={filetype}/>
          <div className='name'>{name}</div>
        </a>
      }
      {
        src && imageview &&
        <ImageView
          url={src}
          onClose={() => setImageview(false)}
          onRemove={onRemove}
        />
      }
      {
        loading &&
        <div className={classes.progress}>
          <div style={{width: `${progress}%`}}/>
        </div>
      }
      {
        label &&
        <div className={classes.label}>
          <Icon iconName='revision'/>
        </div>
      }
      {
        onRemove &&
        <div className={classes.remove} onClick={onRemove}>
          <Icon iconName='upload-remove'/>
        </div>
      }
      {
        error &&
        <div className={classes.reload} onClick={onReload}>
          <Icon iconName='reload'/>
        </div>
      }
      {
        userId && big &&
        <img src={getUserAvatarUrl(userId)} alt='' className={classnames(classes.avatar, {image: src, file: !src})}/>
      }
    </div>
  )
})

const useStyle = createUseStyles({
  root: {
    width: rem(88),
    height: rem(88),

    '&.small': {
      width: rem(88),
      height: rem(88),
    },
    '&.big': {
      width: rem(104),
      height: rem(104),
    },

    margin: rem(4),
    borderRadius: rem(8),
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&.loading': {
      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(60, 120, 255, 0.64)',
        borderRadius: rem(8),
        zIndex: 2,
      },
    },
  },
  preview: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    cursor: 'pointer',
  },
  filetype: {
    padding: rem(8),
    background: theme.colors.white,
    width: 'inherit',
    height: 'inherit',
    borderRadius: rem(16),
    boxShadow: `0 ${rem(4)} ${rem(8)} 0 rgba(86, 92, 107, 0.1)`,
    display: 'block',
    '& .name': {
      fontFamily: theme.fontFamilies.Manrope,
      fontSize: rem(13),
      fontWeight: 600,
      lineHeight: rem(16),
      color: theme.colors.slateGrey,
      position: 'absolute',
      textAlign: 'left',
      left: rem(8),
      top: rem(60),
      right: rem(8),
      '$root.small &': {
        whiteSpace: 'nowrap'
      },
      '$root.big &': {
        whiteSpace: 'normal',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
      },

      overflow: 'hidden',
    },
    '& svg': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: [rem(40), rem(40)],
      width: rem(40),
      height: rem(40),
      position: 'absolute',
      // left: rem(12),
      // top: rem(12),
      top: rem(8),
      left: rem(8),
    },
  },
  remove: {
    width: rem(24),
    height: rem(24),
    display: 'block',
    position: 'absolute',
    top: rem(4),
    right: rem(4),
    cursor: 'pointer',
    zIndex: 3,
    '& svg': {
      width: 'inherit',
      height: 'inherit',
    },
  },
  reload: {
    width: rem(40),
    height: rem(40),
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  label: {
    width: rem(20),
    height: rem(32),
    position: 'absolute',
    top: 0,
    right: rem(12),
    display: 'block',
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
    },
  },
  progress: {
    background: theme.colors.black,
    height: rem(4),
    borderRadius: rem(4),
    position: 'absolute',
    left: rem(8),
    right: rem(8),
    bottom: rem(8),
    zIndex: 3,
    '& > div': {
      zIndex: 3,
      background: theme.colors.white,
      height: 'inherit',
      borderRadius: 'inherit',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
    },
  },
  avatar: {
    width: 20,
    height: 20,
    position: 'absolute',
    '&.image': {
      right: 8,
      bottom: 8
    },
    '&.file': {
      top: 36,
      left: 38
    }
  }
})

export default Attachment