import BlockTutor from '../Actions/BlockTutor'
import CloseAndCreateDraft from './CloseAndCreateDraft'
import CloseAndUpdateDraft from './CloseAndUpdateDraft'
import DeleteOrder from '../Actions/DeleteOrder'
import DraftSaved from './DraftSaved'
import OtherPhoneNumber from './OtherPhoneNumber'
import RejectTutor from '../Actions/RejectTutor'
import ReportTutor from '../Actions/ReportTutor'
import TaskDeleted from './TaskDeleted'
import ChatAreNotAvailable from './ChatAreNotAvailable'

export const Prompts = {
  BlockTutor,
  CloseAndCreateDraft,
  CloseAndUpdateDraft,
  DeleteOrder,
  DraftSaved,
  OtherPhoneNumber,
  RejectTutor,
  ReportTutor,
  TaskDeleted,
  ChatAreNotAvailable,
}