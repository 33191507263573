import css from 'dom-css'

export const isClient = typeof window !== 'undefined'

export const isMobile = isClient ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) : false
export const isIos = /iPad|iPhone|iPod/.test(typeof navigator !== 'undefined' ? navigator.userAgent : false)
export const isAndroid = /Android/.test(typeof navigator !== 'undefined' ? navigator.appVersion : false)

export const getScrollbarWidth = () => {
  let scrollbarWidth = false

  if (scrollbarWidth !== false) {
    return scrollbarWidth
  }
  
  if (typeof document !== 'undefined') {
    const div = document.createElement('div')
    css(div, {
      width: 100,
      height: 100,
      position: 'absolute',
      top: -9999,
      overflow: 'scroll',
      MsOverflowStyle: 'scrollbar'
    })
    document.body.appendChild(div)
    scrollbarWidth = (div.offsetWidth - div.clientWidth)
    document.body.removeChild(div)
  } else {
    scrollbarWidth = 0
  }

  return scrollbarWidth || 0
}

export const regEscape = (s) => s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export const sortBy = (a, b, key, sort) => {
  const keyA = key ? a[key].toLowerCase() : a.toLowerCase()
  const keyB = key ? b[key].toLowerCase() : b.toLowerCase()

  if (keyA > keyB) {
    return sort === 'asc' ? 1 : -1
  } else if (keyA < keyB) {
    return sort === 'asc' ? -1 : 1
  } else {
    return 0
  }
}

export const formattedNumber = (number) => {
  if (!number) {
    return 0
  }
  
  return (number.toString()).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const isPressEnter = (e) => e.keyCode === 13
export const isPressBackspace = (e) => e.keyCode === 8
