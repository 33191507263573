import React, {Fragment, useEffect, useState, useRef} from 'react'
import {useOrderFormParams, getDefaultOrderParams} from './hooks'
import {useSmstoolRemider} from '../../hooks'
import {createUseStyles} from 'react-jss'
import {SupportChatButton} from '../SupportChat'
import classnames from 'classnames'
import theme from './../../theme/base'
import {STEPS} from './steps'
import {Logo} from './layout/Logo'
import {Back} from './layout/Back'
import Popup from './layout/Popup'
import {Checkmark} from './layout/Checkmark'
import {Edit} from './layout/Edit'
import {Suggest} from './layout/Suggest'
import {utils} from 'mtuikit'
import {desktop, adaptive, media} from 'mtuikit/theme/base'
import Overlay from './layout/Overlay'
import {useSaveAndPayOrderMutation, useSaveOrderMutation} from '../../hooks'
import FailedPaymentResult from './layout/PaymentResult/Failed'
import {useActions} from '../../actions'
import {useLocation, useHistory} from 'react-router-dom'

import {useTrackValues, trackValues} from '../../lrtracker'
import analytics from '../../analytics'
import {getStageNameAndContext} from './analytics'

const ORDER_FORM_VERSION = 'OrderFormV4'

const composeSmstoolMessage = (step, orderFormParams) => {
  return `STEP: ${step}\n\n`
    + `title: ${orderFormParams.title}\n`
    + `subject: ${orderFormParams.subject}\n`
    + `details: ${orderFormParams.details}\n`
    + `size: ${utils.sizeToHumans(orderFormParams.size)}\n`
    + `deadline: ${orderFormParams.deadline}\n`
    + `files: ${orderFormParams.files.length}\n`
    + `requested tutor id: ${orderFormParams.requestedTutorId}\n`
    + `let tutor decide: ${orderFormParams.estimateRequired}\n`
    + `outputs: ${orderFormParams.outputs}\n`
}

const getStepFormHash = hash => {
  if (hash.length < 2) return 0

  try {
    return parseInt(hash[1]) || 0
  } catch {
  }
  return 0
}

const OrderForm = ({profile}) => {
  const classes = useStyle()
  const actions = useActions()
  const location = useLocation()
  const history = useHistory()

  const defaultOrderParams = getDefaultOrderParams(location.state?.order)
  const orderFormParams = useOrderFormParams(defaultOrderParams)
  const [submittedSteps, setSubmittedSteps] = useState([])
  const step = getStepFormHash(location.hash)

  const [fileUploading, setFileUploading] = useState(false)
  const prevFileUploading = useRef(fileUploading)
  const prevFileLength = useRef(orderFormParams.cleanedFiles.length)

  useEffect(() => {
      const availableStep = location.hash && submittedSteps.indexOf(getStepFormHash(location.hash)) > -1
      if (!availableStep) {
        history.push({pathname: '/order-form', state: location.state})
      }
    },
    // eslint-disable-next-line
    []
  )

  const setStep = (step) => {
    history.push(`/order-form#${step}`)
  }

  const isMobile = utils.useMobile(media.desktop)

  const [suggest, setSuggest] = useState(false)
  const onSuggest = () => setSuggest(!suggest)

  const [paymentResult, setPaymentResult] = useState()
  const [showCloseTaskPopup, setShowCloseTaskPopup] = useState(false)


  const saveOrderMutation_ = useSaveOrderMutation({
    onSuccess: order => {
      if (!orderFormParams.cleanedOrder.id) orderFormParams.setId(order.id)
      if (order.files && order.files.length) orderFormParams.onChangeFiles(getDefaultOrderParams(order).files)
    }
  })

  const saveOrderMutation = useSaveOrderMutation({
    onSuccess: (order) => {
      trackValues(ORDER_FORM_VERSION, 'OrderDraftSaved', {
        context: {
          id: order.id,
          toid: orderFormParams.cleanedOrder.toid
        }
      })
    }
  })

  const onBack = orderFormParams.isChanged
    ? () => setShowCloseTaskPopup(true)
    : () => {
      actions.showOrders()
    }

  const onDontSaveOrder = () => {
    actions.showOrders()
  }

  const saveAndPayOrderMutation = useSaveAndPayOrderMutation({
    onResult: ({order, error}) => {
      const orderPaidContext = {...orderContext, id: order.id}

      if (!error) {
        actions.showOrderChat(order.id, 'order_paid')

        if (profile.orders_stat.paid_orders_count === 0) {
          analytics.firstOrderPaid(profile.id)
        }

        trackValues(ORDER_FORM_VERSION, 'CheckoutStageCompleted', {
          context: {
            ...orderPaidContext,
            price: orderFormParams.price,
            total: orderFormParams.total,
            balance: orderFormParams.balance
          }
        })
      } else {
        setPaymentResult(
          <FailedPaymentResult
            onClose={() => {
              setPaymentResult(null)
            }}
          />
        )
        trackValues(ORDER_FORM_VERSION, 'CheckoutStageFailed', {
          context: {
            ...orderPaidContext,
            price: orderFormParams.price,
            total: orderFormParams.total,
            balance: orderFormParams.balance
          }
        })
      }
    }
  })

  const _onContinue = (index, isValid, isSubmitted) => {
    if (!isSubmitted) setSubmittedSteps([...submittedSteps, step])
    if (isValid) {
      if (step + 1 === STEPS.length) {
        if (orderFormParams.total === 0) {
          saveAndPayOrderMutation.mutate({
            order: orderFormParams.cleanedOrder,
            files: orderFormParams.cleanedFiles,
            requestedTutorId: orderFormParams.cleanedRequestedTutorId
          })
        } else if (orderFormParams.paymentMethod) {
          saveAndPayOrderMutation.mutate({
            order: orderFormParams.cleanedOrder,
            files: orderFormParams.cleanedFiles,
            requestedTutorId: orderFormParams.cleanedRequestedTutorId,
            paymentMethod: orderFormParams.paymentMethod
          })
        } else {
          actions.showAddPaymentMethod({
            price: orderFormParams.price,
            total: orderFormParams.total,
            paymentRequest: orderFormParams.paymentRequest,
            onSuccess: paymentMethod => {
              saveAndPayOrderMutation.mutate({
                order: orderFormParams.cleanedOrder,
                files: orderFormParams.cleanedFiles,
                requestedTutorId: orderFormParams.cleanedRequestedTutorId,
                paymentMethod: paymentMethod
              })
            },
            onError: () => {
              setPaymentResult(
                <FailedPaymentResult
                  onClose={() => {
                    setPaymentResult(null)
                  }}
                />
              )
            }
          })
        }
      } else {
        setStep(step + 1)
      }
    }
  }

  useEffect(() => {
      if (!orderFormParams.cleanedOrder.id) return

      const stopFileUploading = prevFileUploading.current && !fileUploading
      prevFileUploading.current = fileUploading

      const removedFile = prevFileLength.current > orderFormParams.cleanedFiles.length
      prevFileLength.current = orderFormParams.cleanedFiles.length

      if (!stopFileUploading && !removedFile) return

      const t = setTimeout(() =>
          saveOrderMutation_.mutate({
            order: {id: orderFormParams.cleanedOrder.id},
            files: orderFormParams.cleanedFiles,
          }),
        1000
      )

      return () => clearTimeout(t)
    },
    // eslint-disable-next-line
    [fileUploading, orderFormParams.cleanedFiles.length]
  )

  useEffect(() => {
      if (!orderFormParams.cleanedOrder.subject) return

      const {subject, deadline, size, ...other} = orderFormParams.cleanedOrder
      let order = {...other}

      if (subject) order.subject = subject
      if (size) order.size = size
      if (deadline) order.deadline = deadline

      const t = setTimeout(() =>
          saveOrderMutation_.mutate({
            order,
            requestedTutorId: orderFormParams.cleanedRequestedTutorId
          }),
        1000
      )
      return () => clearTimeout(t)
    },
    // eslint-disable-next-line
    [
      orderFormParams.cleanedOrder.subject,
      orderFormParams.cleanedOrder.details,
      orderFormParams.cleanedOrder.size,
      orderFormParams.cleanedOrder.deadline,
      orderFormParams.cleanedOrder.output_formats,
      orderFormParams.cleanedOrder.let_tutor_decide,
      orderFormParams.cleanedRequestedTutorId
    ]
  )

  const orderContext = {id: orderFormParams.cleanedOrder.id, toid: orderFormParams.cleanedOrder.toid}
  const [stageName, stageContext] = getStageNameAndContext(step, STEPS, orderFormParams)

  useTrackValues(ORDER_FORM_VERSION, 'OrderFormOpened', {context: orderContext, predicat: () => step === 0})
  useTrackValues(ORDER_FORM_VERSION, stageName, {
    context: {...orderContext, ...stageContext},
    predicat: () => stageName && stageContext
  })

  useSmstoolRemider(profile.cid, composeSmstoolMessage(step, orderFormParams))

  const {returned} = profile || {}

  const isEditable = STEPS.every(({valid}, index) =>
    valid
      ? submittedSteps.indexOf(index) > -1
        ? Boolean(valid(orderFormParams))
        : true
      : true
  )

  const prevSize = useRef(orderFormParams.cleanedOrder.size)
  useEffect(() => {
    const newSize = orderFormParams.cleanedOrder.size
    const paidOrdersCount = profile.orders_stat.paid_orders_count
    if (newSize && !prevSize.current && paidOrdersCount === 0) {
      analytics.priceCalculated(profile.id)
      prevSize.current = newSize
    }
  }, [step])

  return paymentResult
    ? paymentResult
    : (
      <div className={classes.orderFormWrapper}>
        <div className={classes.orderForm}>
          <div className={classes.wrapper}>
            <div className={classes.header}>
              <div className={classes.backIcon}>
                {
                  returned
                    ? <Back onClick={onBack}/>
                    : <Logo/>
                }
              </div>
              <div className={classes.title}>Create new task for a tutor</div>
              <SupportChatButton className={'header'}/>
            </div>
            {STEPS.map(({component, extendedTitle, collapsedTitle, valid, generateSavedTextFunc, helper}, index) => {
              const isActive = index === step
              const isValid = valid ? Boolean(valid(orderFormParams)) : true
              const isSubmitted = submittedSteps.indexOf(index) > -1
              const error = isSubmitted && !isValid
              const onContinue = () => _onContinue(index, isValid, isSubmitted)
              const onEdit = isEditable ? () => setStep(index) : null
              const loading = saveOrderMutation.isLoading || saveAndPayOrderMutation.isLoading
              return (
                <Fragment key={index}>
                  {
                    isActive &&
                    <div
                      className={classnames(classes.step, 'active', {loading})}>
                      <div className={classes.stepTitle}>
                        <div className={classes.counter}>{index + 1}</div>
                        <div className={classes.extendedTitle}>{extendedTitle}</div>
                        <Suggest className={classes.suggest} onClick={onSuggest}/>
                      </div>
                      <div className={classes.stepForm}>
                        {React.createElement(component, {
                          ...orderFormParams,
                          isValid,
                          isSubmitted,
                          error,
                          onContinue,
                          fileUploading,
                          setFileUploading
                        })}
                      </div>
                      {((isMobile && suggest) || !isMobile) &&
                      <>
                        <Overlay onClick={onSuggest} adaptive/>
                        <div className={classes.stepHelper}>
                          {helper({
                            onSuggest,
                            returned,
                            subject: isValid ? orderFormParams.subject : null,
                          })}
                        </div>
                      </>
                      }
                      {
                        loading &&
                        <img
                          className={classes.loadingImg}
                          src={require('../../assets/images/loader-blue.svg').default}
                          alt=''
                        />
                      }
                    </div>
                  }
                  {
                    !isActive && isSubmitted && isValid &&
                    <div className={classnames(classes.step, 'saved', {isEditable})} onClick={onEdit}>
                      <div className={classes.stepTitle}>
                        <div className={classes.counter}>
                          <Checkmark/>
                          <Edit/>
                        </div>
                        <div className={classes.extendedTitle}>{extendedTitle}</div>
                        <Suggest className={classes.suggest} onClick={onSuggest}/>
                      </div>
                      {
                        generateSavedTextFunc &&
                        <div className={classes.subTitle}>{generateSavedTextFunc(orderFormParams)}</div>
                      }
                    </div>
                  }
                  {
                    !isActive && isSubmitted && !isValid &&
                    <div className={classnames(classes.step, 'disabled')}>
                      <div className={classes.stepTitle}>
                        <div className={classes.counter}>{index + 1}</div>
                        <div className={classes.collapsedTitle}>{collapsedTitle}</div>
                        <div className={classes.completeThisStep}>
                          <span onClick={() => setStep(index)}>Complete this step</span>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    !isActive && !isSubmitted &&
                    <div className={classnames(classes.step, 'disabled')}>
                      <div className={classes.stepTitle}>
                        <div className={classes.counter}>{index + 1}</div>
                        <div className={classes.collapsedTitle}>{collapsedTitle}</div>
                      </div>
                    </div>
                  }
                </Fragment>
              )
            })}
          </div>
          {
            showCloseTaskPopup &&
            <Popup
              title='Close task?'
              text='Do you really want to close this task?'
              onCancel={() => setShowCloseTaskPopup(false)}
              primaryText='Close'
              onPrimary={onDontSaveOrder}
              secondaryText='Don’t close'
              onSecondary={() => setShowCloseTaskPopup(false)}
              className={classes.popup}
            />
          }
        </div>
      </div>
    )
}

const useStyle = createUseStyles({
  orderFormWrapper: {
    overflow: 'hidden',
  },
  orderForm: {
    background: theme.colors.white,
    minHeight: '100vh',
    position: 'relative',
    [adaptive]: {
      width: '100%',
    },
    [desktop]: {
      width: '50%',
      minWidth: 504 + 24 + 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '&:before': {
      background: '#F5F8FA',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
  header: {
    marginBottom: 24,
    padding: [16, 0, 15, 0],
    borderBottom: [1, 'solid', theme.colors.paleGrey],
    position: 'relative',
    [adaptive]: {
      background: theme.colors.white,
      marginBottom: 0,
      padding: [20, 16, 19],
      textAlign: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    },
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: 14,
    lineHeight: 24 / 14,
    fontWeight: 800,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  wrapper: {
    paddingBottom: 13,
    [desktop]: {
      width: 504,
      marginRight: 24,
      paddingBottom: 25,
    },
    [adaptive]: {
      paddingTop: 88,
    },
  },
  step: {
    padding: 16,
    border: [1, 'solid', theme.colors.paleGrey],
    borderRadius: 16,
    position: 'relative',
    [adaptive]: {
      marginLeft: 16,
      marginRight: 16,
    },
    '& + &': {
      marginTop: 8,
    },
    '&.active': {
      boxShadow: [
        [0, 8, 12, 'rgba(0, 38, 96, 0.06)'],
        [0, 8, 40, 'rgba(0, 53, 136, 0.15)']
      ],
      '& $stepTitle': {
        color: theme.colors.charcoalGrey,
      },
      '& $counter': {
        background: theme.colors.blue,
      },
    },
    '&.loading': {
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 16,
        zIndex: 2,
      },
    },
    '&.saved': {
      cursor: 'pointer',
      '& $counter': {
        background: '#49B000',
      },
    },
    '&.saved.isEditable': {
      '&:hover': {
        background: '#F6F7FB',
        '& $counter': {
          background: theme.colors.blue,
          '& > svg': {
            width: 12,
            height: 12,
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              display: 'block',
            },
          },
        },
      },
    },
    '&.disabled': {
      '& $stepTitle': {
        color: theme.colors.blueyGrey,
        fontWeight: 400,
      },
    },
  },
  stepTitle: {
    minHeight: 24,
    paddingLeft: 32,
    position: 'relative',
    [adaptive]: {
      paddingRight: 28,
    },
  },
  extendedTitle: {
    color: theme.colors.slateGrey,
    fontWeight: 800,
    display: 'inline-block',
    verticalAlign: 'top',
    [desktop]: {
      fontSize: 16,
      lineHeight: 24 / 16,
    },
    [adaptive]: {
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
  collapsedTitle: {
    color: theme.colors.slateGrey,
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    [desktop]: {
      fontSize: 16,
      lineHeight: 24 / 16,
    },
    [adaptive]: {
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 14,
      lineHeight: 20 / 14,
    },
  },
  completeThisStep: {
    marginTop: 4,
    '& > span': {
      color: theme.colors.blue,
      fontSize: 14,
      lineHeight: 20 / 14,
      fontWeight: 800,
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  stepForm: {},
  stepHelper: {
    background: theme.colors.white,
    padding: 16,
    boxShadow: [0, 4, 8, 'rgba(86, 93, 107, 0.1)'],
    borderRadius: 16,
    [desktop]: {
      width: 328,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 504 + 24 + 24,
    },
    [adaptive]: {
      maxHeight: '95vh',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: [0, 4, 8, 'rgba(86, 93, 107, 0.1)'],
      borderRadius: [16, 16, 0, 0],
      border: [1, 'solid', theme.colors.paleGrey],
      zIndex: 3,
      overflowY: 'auto',
    },
  },
  counter: {
    background: '#BAC7E2',
    width: 24,
    height: 24,
    textAlign: 'center',
    color: theme.colors.white,
    fontSize: 14,
    lineHeight: 24 / 14,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    top: 0,
    '& > svg': {
      width: 12,
      height: 10,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&:first-child': {
        display: 'block',
      },
      '&:last-child': {
        display: 'none',
      },
    },
  },
  subTitle: {
    marginTop: 6,
    paddingLeft: 32,
    color: theme.colors.slateGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suggest: {
    [desktop]: {
      display: 'none',
    },
    [adaptive]: {
      width: 24,
      height: 24,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  backIcon: {
    width: 24,
    height: 24,
    zIndex: 2,
    cursor: 'pointer',
    [desktop]: {
      marginRight: 12,
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
    },
    [adaptive]: {
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: `translateY(-50%)`,
    },
  },
  loadingImg: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
  },
  popup: {
    zIndex: 3,
    [desktop]: {
      position: 'absolute',
      right: 210,
      top: 48,
    }
  }
})


export {
  OrderForm
}
