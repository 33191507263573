import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import moment from 'moment'
import classnames from 'classnames'
import {useParams} from 'react-router-dom'
import {Button, InputPhone} from 'mtuikit'
import theme, {rem, adaptive} from '../../theme/base'
import {
  useOrderQuery,
  useProfileQuery,
  useUpdateOrderMetaDataMutation,
  useUpdateProfileMutation
} from '../../hooks'
import {useActions} from '../../actions'
import {trackValues} from '../../lrtracker'
import {Header} from './../../ui'
import {Modal} from './../../modals'
import {isOnboardCanStart} from '../../nerdytutors'
import {usePushNotifications} from './../../push-notifications'

const EditPhoneForm = ({onClose, zIndex}) => {
  const classes = useStyles()
  const {orderId} = useParams()
  const profileQuery = useProfileQuery()
  const {data: order} = useOrderQuery(orderId)
  const actions = useActions()
  const updateProfileMutation = useUpdateProfileMutation()
  const updateMetaDataMutation = useUpdateOrderMetaDataMutation()
  const [editPhone, setEditPhone] = useState(!profileQuery.data.phone)
  const [phone, setPhone] = useState(profileQuery.data.phone)

  const {
    isEnabled: isPushNotificationEnabled,
    isGranted: isPushNotificationGranted,
  } = usePushNotifications()

  const onSubmit = () => {
    const onboardVersion = isPushNotificationEnabled && !isPushNotificationGranted ? 3 : 2
    if (isOnboardCanStart(order)) {
      updateMetaDataMutation.mutate({
        orderId,
        onboard_version: onboardVersion,
        onboard_started_at: moment().valueOf(),
        first_time_client: profileQuery.data.orders_stat.paid_orders_count === 1
      })
    }

    onClose()
  }

  const onChange = (value) => {
    setPhone(value)
  }

  const onSavePhone = () => {
    updateProfileMutation.mutate({phone: phone[0] !== "+" ? "+" + phone : phone})
    setEditPhone(false)
    trackValues('paymentCompletedPopup', 'clientPhoneNumberChanged', {
      context: {
        id: orderId,
        isNew: !profileQuery.data.phone
      }
    })
  }

  const error = updateProfileMutation.error && 'Please edit your phone number above or'
  const editMode = !profileQuery.data.phone || editPhone || updateProfileMutation.isError || updateProfileMutation.isLoading

  const checkList = [
    '100% secure',
    'No spam',
    'Only SMS',
    'Free',
  ]

  return <Modal className={classes.root} zIndex={zIndex}>
    <Header>Task successfully placed! 🎉</Header>
    {!profileQuery.data.phone ?
      <>
        <div className={`${classes.notice} ${classes.notice1}`}>
          We need <span className={classes.strong}>your phone number</span> to send you <span
          className={classes.strong}>SMS</span> notification in case of:
        </div>

        <div className={classes.itemList}>
          <div className={classes.item}>
            <img
              className={classes.itemImg}
              src={require(`components/EditPhoneForm/ill1.png`).default}
              srcSet={`
                            ${require(`components/EditPhoneForm/ill1@3x.png`).default} 3x,
                            ${require(`components/EditPhoneForm/ill1@2x.png`).default} 2x,
                            ${require(`components/EditPhoneForm/ill1.png`).default} 1x
                          `}
              alt=''
            />
            <div className={classes.itemTitle}>Tutor is connected to the chat</div>
          </div>
          <div className={classes.item}>
            <img
              className={classes.itemImg}
              src={require(`components/EditPhoneForm/ill2.svg`).default}
              alt=''
            />
            <div className={classes.itemTitle}>Task status updated</div>
          </div>
          <div className={classes.item}>
            <img
              className={classes.itemImg}
              src={require(`components/EditPhoneForm/ill3.png`).default}
              srcSet={`
                            ${require(`components/EditPhoneForm/ill3@3x.png`).default} 3x,
                            ${require(`components/EditPhoneForm/ill3@2x.png`).default} 2x,
                            ${require(`components/EditPhoneForm/ill3.png`).default} 1x
                          `}
              alt=''
            />
            <div className={classes.itemTitle}>New message<br/> from your tutor</div>
          </div>
        </div>

        <div className={classes.form}>
          <div className={classes.field}>
            <InputPhone
              country='us'
              inputProps={{
                autoFocus: true,
                className: classnames(classes.input, {'error': error}),
              }}
              value={phone}
              onChange={onChange}
            />
          </div>
        </div>
        {
          error &&
          <div className={classes.errorMessage}>
            {error}{' '}
            <span onClick={() => actions.showSupportChat()}>contact our support team to get help.</span>
          </div>
        }

        <ul className={classes.checkList}>
          {checkList.map((item, key) => {
            return (
              <li className={classes.checkItem} key={`checkItem-${key}`}>
                <img
                  className={classes.checkImg}
                  src={require(`components/EditPhoneForm/i-stepper.svg`).default}
                  alt=''
                />
                <span className={classes.checkMessage}>{item}</span>
              </li>
            )
          })}
        </ul>

        <Button
          type='primary'
          size='large'
          className={classes.button}
          onClick={onSavePhone}
          disabled={updateProfileMutation.isLoading}
          block
        >
          Save my phone number
        </Button>
      </>

      :

      <>
        <img
          className={`${classes.itemImg} ${classes.itemImg1}`}
          src={require(`components/EditPhoneForm/ill3.png`).default}
          srcSet={`
                        ${require(`components/EditPhoneForm/ill3@3x.png`).default} 3x, 
                        ${require(`components/EditPhoneForm/ill3@2x.png`).default} 2x, 
                        ${require(`components/EditPhoneForm/ill3.png`).default} 1x
                      `}
          alt=''
        />

        {
          !editMode
            ? <>
              <div className={`${classes.notice} ${classes.notice3}`}>
                We’ll notify you as soon as your tutor is found<br/>
                by texting you at{' '}
                <span className={classes.strong}>{profileQuery.data.phone}</span><br/>
                <div className={classes.link} onClick={() => setEditPhone(true)}>
                  Edit my phone number
                </div>
              </div>
              <Button
                block
                type='primary'
                size='large'
                className={classes.button}
                onClick={onSubmit}
              >
                Got it!
              </Button>
            </>
            : <>
              <div className={`${classes.notice} ${classes.notice2}`}>
                We’ll notify you as soon as your tutor is found<br/>
                by texting you at:
              </div>
              <div className={classnames(classes.form, classes.input)}>
                <div className={classes.field}>
                  <InputPhone
                    country='us'
                    inputProps={{
                      autoFocus: true,
                      className: classnames(classes.input, {'error': error}),
                    }}
                    value={phone}
                    onChange={onChange}
                  />
                  {!error &&
                  <button className={classes.cancel} onClick={() => setEditPhone(false)}>
                    Cancel
                  </button>
                  }
                </div>
              </div>
              {
                error &&
                <div className={classes.errorMessage}>
                  {error}{' '}
                  <span onClick={() => actions.showSupportChat()}>contact our support team to get help.</span>
                </div>
              }
              <Button
                type='primary'
                size='large'
                className={classes.button}
                onClick={onSavePhone}
                disabled={updateProfileMutation.isLoading}
                block
              >
                Update my phone number
              </Button>
            </>
        }
      </>
    }
  </Modal>
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    '& input': {
      height: rem(56),
      paddingRight: rem(48),
      paddingTop: rem(15),
      paddingBottom: rem(15),
      border: [rem(1), 'solid', '#BAC7E2'],
      borderRadius: rem(28),
      textAlign: 'center',
      fontSize: rem(20),
      lineHeight: rem(24),
      letterSpacing: rem(0.12),
      fontFamily: theme.fontFamilies.Manrope,
      fontWeight: 'normal',
      color: theme.colors.charcoalGrey,
      boxShadow: 'none',
      '&.empty': {
        paddingRight: rem(24),
      },
    },
  },
  notice: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(16),
    lineHeight: rem(24),
    textAlign: 'center',
    color: '#2F333D',
  },
  notice1: {
    marginTop: rem(12),
  },
  notice2: {
    marginTop: rem(20),
    marginBottom: rem(16),
  },
  notice3: {
    marginTop: rem(20),
  },
  strong: {
    fontWeight: 700,
  },
  link: {
    color: theme.colors.blue,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: rem(16),
    fontWeight: 700,
    cursor: 'pointer',
  },
  button: {
    marginTop: 'auto',
    '&:hover': {
      color: theme.colors.white,
    },
  },

  form: {
    marginTop: rem(8),
    width: '100%',
  },
  field: {
    position: 'relative',
    '& input': {
      width: '100%',
      paddingLeft: rem(73),
      textAlign: 'left',
      borderRadius: rem(8),
      '&.error': {
        borderColor: `${theme.colors.brightOrangeTwo} !important`,
        backgroundColor: 'rgba(255, 87, 0, 0.08)',
        '& + .flag-dropdown:before': {
          borderColor: `${theme.colors.brightOrangeTwo}`,
        }
      },
    },
    '& .react-tel-input': {
      '& .flag-dropdown.open:before': {
        backgroundColor: 'transparent',
      }
    },
    '& $flag-dropdown': {
      backgroundColor: 'red',
    }
  },
  cancel: {
    position: 'absolute',
    right: rem(16),
    top: 0,
    fontWeight: 700,
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(16),
    lineHeight: rem(54),
    color: theme.colors.blue,
    backgroundColor: 'transparent',
  },

  errorMessage: {
    marginTop: rem(4),
    fontSize: rem(12),
    lineHeight: rem(16),
    color: theme.colors.brightOrangeTwo,
    textAlign: 'center',
    '& span': {
      color: theme.colors.blue,
      fontFamily: theme.fontFamilies.Manrope,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer'
    },
  },

  itemList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: rem(16),
    marginBottom: rem(32),
  },
  item: {
    width: rem(128),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [adaptive]: {
      width: rem(104),
    }
  },
  itemImg: {
    display: 'block',
    width: rem(88),
    height: rem(48),
  },
  itemImg1: {
    marginTop: rem(58),
    marginLeft: rem(20),
  },
  itemTitle: {
    marginTop: rem(8),
    //fontFamily: 'SF Pro Text',
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(20),
    textAlign: 'center',
    [adaptive]: {
      fontWeight: 400,
      fontSize: rem(12),
      lineHeight: rem(16),
    },
  },
  checkList: {
    margin: [rem(10), 0],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  checkItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginLeft: rem(12),
    }
  },
  checkImg: {
    display: 'block',
    width: rem(12),
    height: rem(12),
  },
  checkMessage: {
    display: 'block',
    marginLeft: rem(3),
    fontWeight: 700,
    fontSize: rem(12),
    lineHeight: rem(16),
    letterSpacing: rem(0.15),
    color: '#2F333D',
  }
})

export default EditPhoneForm
