import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Button} from 'mtuikit'
import CardList from '../CardList'
import {useCardList} from '../CardList/hooks'
import {usePaymentRequest} from '../../hooks'
import {useActions} from '../../actions'
import {utils} from 'mtuikit'

const SelectPaymentMethodAndPay = ({isActive, price, total, balance, onPaid, usePayment}) => {
  const classes = useStyle()
  const actions = useActions()
  const [result, setResult] = useState(null)
  const {listAllCards, card, paymentMethod, changePaymentMethod} = useCardList()
  const paymentRequest = usePaymentRequest('US', 'usd', total)
  const mutation = usePayment({
    onResult: result => {
      if (!result.error && onPaid) onPaid(result)
      setResult(result)
    }
  })

  const isError = result && result.error && isActive
  const isSuccess = (result && !result.error) || !isActive

  const submit = total === 0
    ? () => mutation.mutate()
    : paymentMethod
      ? () => mutation.mutate({paymentMethod, paymentRequest})
      : () => actions.showAddPaymentMethod({
        price,
        total,
        paymentRequest,
        onSuccess: paymentMethod => mutation.mutate({paymentMethod, paymentRequest}),
        onError: error => setResult({error})
      })

  const reset = () => setResult(null)

  if (isSuccess) {
    return <div className={classes.resultBlock}>
      <img className={classes.resultImg} src={require(`./img/ic_check.svg`).default} alt={''}/>
      <div className={classes.resultTitle}>Payment successful</div>
    </div>
  }

  if (isError) {
    return <div className={classes.resultBlock}>
      <img className={classes.resultImg} src={require(`./img/ic_close.svg`).default} alt={''}/>
      <div className={classes.resultTitle}>Payment failed</div>
      <div className={classes.resultMessage}>
        Please double-check your balance and credit card info or try using a different card to make the payment.
      </div>
      <Button
        className={classes.tryButton}
        size={'large'}
        onClick={reset}
      >Try again</Button>
    </div>
  }

  return <>
    <div className={classes.paymentBlock}>
      <div className={classes.paymentTitle}>Total to be paid:</div>
      <div className={classes.paymentSubBlock}>
        <div className={classes.priceBlock}>
          {
            total === price
              ? <span>{utils.printAmount(price)}</span>
              : <><span>{utils.printAmount(price)}</span>{' '} <span>{utils.printAmount(total)}</span></>
          }
        </div>
        <div className={classes.balanceBlock}>
          {
            total === price
              ? null
              : total === 0
                ? `${utils.printAmount(price)} paid from account balance:`
                : `${utils.printAmount(balance)} paid from account balance`
          }
        </div>
        {
          total > 0 &&
          <CardList
            listAllCards={listAllCards}
            paymentMethod={paymentMethod}
            paymentRequest={listAllCards.length === 0 && paymentRequest}
            onChange={changePaymentMethod}
            className={classes.cards}
          />
        }
      </div>
    </div>
    <Button
      className={classes.button}
      size={'large'}
      onClick={submit}
      isFetching={mutation.isLoading}
      block
    >
      {
        total === 0
          ? `Confirm`
          : card
            ? <>
              <span>Pay with · {card.last4}</span>
              {' '}
              <span className={classes.amount}>{utils.printAmount(total)}</span>
            </>
            : `Pay ${utils.printAmount(total)}`
      }
    </Button>
  </>
}

const useStyle = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  promoImg: {
    display: 'block',
    width: 112,
    height: 68,
    marginTop: [24]
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    padding: [0, 16, 24],
    borderBottom: '1px solid #EBEEF5',
  },
  paymentBlock: {
    width: '100%',
    padding: [24, 18]
  },
  paymentTitle: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '22px',
  },
  paymentSubBlock: {
    paddingLeft: 16
  },
  priceBlock: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#939FB8',
    marginTop: 16,
    '& span:last-child': {
      color: '#2F333D',
    },
  },
  balanceBlock: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
  },
  cards: {},
  button: {
    marginTop: 'auto',
    justifyContent: 'center',
    padding: '0 24px!important',
    fontWeight: 700,
    fontSize: 18,
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '&:before': {
      content: '""',
      display: 'block',
      height: 40,
      width: 0,
      borderLeft: '1px solid rgba(255, 255, 255, .2)',
      paddingRight: 16,
    },
  },
  resultBlock: {
    flex: 1,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultImg: {
    display: 'block',
    width: 36,
    height: 36,
  },
  resultTitle: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#2F333D',
    marginTop: 12,
  },
  resultMessage: {
    margin: [12, 0, 24],
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '.15px',
    color: '#2F333D',
  },
  tryButton: {
    width: 240,
  }
})

export default SelectPaymentMethodAndPay
