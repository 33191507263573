import React from 'react'
import Layout from './layout'
import {Card, MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {utils} from 'mtuikit'

const EstimatePaidEvent = ({auto_confirm, size, data, ...props}) => {
  return !auto_confirm
    ? <MessageWrapper {...props} data={{...data, type: 'message'}}>
      <MessageText {...props} data={{...data, message: 'Your payment was successful.'}}/>
    </MessageWrapper>
    : <MessageWrapper {...props} data={data}>
      <Card {...props} data={data}>
        <Layout.Body>
          <Layout.Title1 iconName='task_completed'>Your task is confirmed!</Layout.Title1>
          <Layout.Text>
            Your tutor checked the task and confirmed that it will
            take <span>{utils.sizeToHuman(size)} of work</span> to complete.
          </Layout.Text>
        </Layout.Body>
        <Card.Footer {...props} data={data}/>
      </Card>
    </MessageWrapper>
}

export default EstimatePaidEvent
