function getGoogleClientId() {
  let p = ('; ' + document.cookie).split('; _ga=')
  if (p.length === 2) {
    const _ga = p.pop().split(';').shift()
    return _ga.split('.').slice(2, 4).join('.')
  }
}

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
  )

  return matches ? decodeURIComponent(matches[1]) : undefined
}

function getGtagClientId() {
  const _ga = getCookie('_ga')
  if (!_ga) return false
  if (/GA1.2./.test(_ga)) return _ga.replace(/^GA1.2./, '')
}

const GA4_ENABLED = process.env.REACT_APP_GA4_API_SECRET && process.env.REACT_APP_GA4_MEASUREMENT

const ga4 = (event_name, cid) => {
  if (!GA4_ENABLED) {
    console.log(`GA4> ${event_name}`, {cid})
    return
  }

  const clientId = getGtagClientId() || 'no client id'
  const gaSessionId = clientId.split('.')?.[1] || 'no session id'

  const client_id = getGoogleClientId()
  const payload = JSON.stringify({
    client_id,
    user_id: cid,
    non_personalized_ads: false,
    events: [{
      name: event_name,
      params: {
        items: [],
        ga_session_id: gaSessionId
      }
    }],
  })

  fetch(
    `https://google-analytics.com/mp/collect?api_secret=${process.env.REACT_APP_GA4_API_SECRET}&measurement_id=${process.env.REACT_APP_GA4_MEASUREMENT}`,
    {
      method: 'POST',
      body: payload
    })
}

// eslint-disable-next-line
export default {
  leadCreated: cid => ga4('lead_created', cid),
  firstOrderPaid: cid => ga4('paid', cid),
  priceCalculated: cid => ga4('price_calculated', cid)
}