import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    lineHeight: rem(20),
    display: 'block',
    fontSize: rem(13),
    fontWeight: 'normal',
    color: theme.colors.brightOrangeTwo,
    textTransform: 'uppercase',
  }
}

const Status = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Status)