import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import theme, {rem} from '../theme/base'

const Header = ({children, className}) => {
  const classes = useStyle()
  return <div className={classnames(classes.root, className)}>{children}</div>
}

const useStyle = createUseStyles({
  root: {
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 700,
    fontSize: rem(22),
    lineHeight: rem(32),
    textAlign: 'center',
    color: '#2F333D',
  },
})

export default Header