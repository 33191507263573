import React from 'react'
import moment from 'moment'
import {TOP_TUTOR_ADDON_AVAILABLE_FROM} from './../../nerdytutors'
import {useMessages} from '@connect/connect-xmpp-v2'

const add = (start, delay) => start ? start + delay : null

const lte = (delay, time) => delay && time
  ? delay >= time ? null : delay
  : delay

const gte = (delay, time) => delay && time
  ? delay >= time ? delay : null
  : delay

const filter = (delay, b) => b ? delay : null


export const useOrderOnboardParams = (order, config) => {
  const {list, refs, hasNextPage, isFetched} = useMessages(config, {})
  const fistLoadedMessageId = list.find(id => !(refs[id].from === 'onboard' || refs[id].from === config.username))
  return order
    ? {
      node: config.node,
      list: getOnboardScenario(order.meta_data.first_time_client, order.meta_data.onboard_version),
      orderId: order.id,
      subject: order.subject,
      firstConnectedAt: order.first_connected_at ? moment(order.first_connected_at).valueOf() : null,
      onboardStartedAt: order.meta_data.onboard_started_at,
      outputsSubmittedAt: order.meta_data.outputs_submitted_at,
      firstTimeClient: order.meta_data.first_time_client,
      isRecommended: order?.recommended?.freelancer_id,
      hasNextPage: hasNextPage,
      isFetched: isFetched,
      isEmpty: isFetched && list.length === 0,
      fistLoadedMessageAt: fistLoadedMessageId ? refs[fistLoadedMessageId].time : undefined,
    } : {
      list: []
    }
}


export const getOrderOnboard = (order, config) => {
  return order
    ? {
      node: config.node,
      list: getOnboardScenario(order.meta_data.first_time_client, order.meta_data.onboard_version),
      orderId: order.id,
      subject: order.subject,
      firstConnectedAt: order.first_connected_at ? moment(order.first_connected_at).valueOf() : null,
      onboardStartedAt: order.meta_data.onboard_started_at,
      outputsSubmittedAt: order.meta_data.outputs_submitted_at,
      firstTimeClient: order.meta_data.first_time_client,
      isRecommended: order?.recommended?.freelancer_id
    }
    : {
      list: []
    }
}

// sync message id for order detail highlighted
export const orderChatFirstOnboardV1 = [
  [
    'message',
    <>Welcome to Nerdy Tutors!<br/>
      Your task is in good hands! Our Tutors have already helped with <span className={'strong'}>300,000+</span> task,
      and average client’s rate is <span className={'strong'}>4.65</span>⭐</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ], [
    'message',
    'You Tutor will connect to this chat to discuss all details. Meanwhile please add more info about your task:',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_outputs',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000), ctx.firstConnectedAt),
  ], [
    'message',
    <>Now we are searching for the best-matching Tutor for your task.<br/>
      In <span className={'strong'}>95% of cases</span> it takes from 15 min up to several hours to find the best tutor.
      It depends on task deadline, complexity and clarity of your instructions.</>,
    ctx => lte(add(ctx.outputsSubmittedAt, 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_phone',
    ctx => lte(add(ctx.outputsSubmittedAt, 5000 + 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_instructions',
    ctx => lte(add(ctx.outputsSubmittedAt, 5000 + 5000 + 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_support',
    ctx => lte(add(ctx.outputsSubmittedAt, 5000 + 5000 + 5000 + 7000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_top_tutor_addon',
    ctx => filter(gte(lte(add(ctx.outputsSubmittedAt, 5000 + 5000 + 5000 + 7000 + 7000), ctx.firstConnectedAt), TOP_TUTOR_ADDON_AVAILABLE_FROM), !ctx.isRecommended),
  ]
]

export const orderChatReturnedOnboardV1 = [
  [
    'message',
    'Thank you for placing a new task!\n' +
    'Your tutor will connect to this chat to discuss all details. Meanwhile, please add more info about your task:',
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_outputs',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_phone',
    ctx => lte(add(ctx.outputsSubmittedAt, 5000), ctx.firstConnectedAt),
  ], [
    'event',
    'onboard:request_top_tutor_addon',
    ctx => filter(gte(lte(add(ctx.outputsSubmittedAt, 5000 + 7000), ctx.firstConnectedAt), TOP_TUTOR_ADDON_AVAILABLE_FROM), !ctx.isRecommended),
  ]
]

export const orderChatFirstOnboardV2 = [
  [
    'message',
    <>Welcome to Nerdy Tutors!<br/>
      Your task is in good hands! Our Tutors have already helped with <span className={'strong'}>300,000+</span> task,
      and average client’s rate is <span className={'strong'}>4.65</span>⭐</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>Your tutor will join this chat soon!<br/>
      Providing clear and detailed instructions helps us match you with the best tutor faster.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>We're checking your instructions. Our manager will contact you <span className={'strong'}>within the next 10 minutes</span> in
      the support chat for clarifications or to confirm everything is clear.<br/>
      💬 Please <span className={'strong'}>stay online while we review your task</span>. Your prompt response is
      crucial
      for finding the best tutor quickly.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:request_instructions2',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000 + 5000 + 5000), ctx.firstConnectedAt),
  ]
]

export const orderChatFirstOnboardV3 = [
  [
    'message',
    <>Welcome to Nerdy Tutors!<br/>
      Your task is in good hands! Our Tutors have already helped with <span className={'strong'}>300,000+</span> task,
      and average client’s rate is <span className={'strong'}>4.65</span>⭐</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>Your tutor will join this chat soon!<br/>
      Providing clear and detailed instructions helps us match you with the best tutor faster.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>We're checking your instructions. Our manager will contact you <span className={'strong'}>within the next 10 minutes</span> in
      the support chat for clarifications or to confirm everything is clear.<br/>
      💬 Please <span className={'strong'}>stay online while we review your task</span>. Your prompt response is
      crucial
      for finding the best tutor quickly.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:enable_notifications',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:request_instructions2',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000 + 5000 + 5000), ctx.firstConnectedAt),
  ]
]


export const orderChatReturnedOnboardV2 = [
  [
    'event',
    'onboard:greetings',
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>We're checking your instructions. Our manager will contact you <span className={'strong'}>within the next 10 minutes</span> in
      the support chat for clarifications or to confirm everything is clear.<br/>
      💬 Please <span className={'strong'}>stay online while we review your task</span>. Your prompt response is crucial
      for finding the best tutor quickly.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:request_instructions2',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000), ctx.firstConnectedAt),
  ]
]

export const orderChatReturnedOnboardV3 = [
  [
    'event',
    'onboard:greetings',
    ctx => lte(add(ctx.onboardStartedAt, 2000), ctx.firstConnectedAt),
  ],
  [
    'message',
    <>We're checking your instructions. Our manager will contact you <span className={'strong'}>within the next 10 minutes</span> in
      the support chat for clarifications or to confirm everything is clear.<br/>
      💬 Please <span className={'strong'}>stay online while we review your task</span>. Your prompt response is crucial
      for finding the best tutor quickly.</>,
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:enable_notifications',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000), ctx.firstConnectedAt),
  ],
  [
    'event',
    'onboard:request_instructions2',
    ctx => lte(add(ctx.onboardStartedAt, 2000 + 5000 + 5000 + 5000), ctx.firstConnectedAt),
  ]
]

const ONBOARD_SCENARIO_MAP = {
  3: [orderChatFirstOnboardV3, orderChatReturnedOnboardV3],
  2: [orderChatFirstOnboardV2, orderChatReturnedOnboardV2],
  1: [orderChatFirstOnboardV1, orderChatReturnedOnboardV1],
}

const getOnboardScenario = (isFirstTimeClient, version) =>
  version in ONBOARD_SCENARIO_MAP
    ? ONBOARD_SCENARIO_MAP[version][isFirstTimeClient ? 0 : 1]
    : ONBOARD_SCENARIO_MAP[2][isFirstTimeClient ? 0 : 1]