import React from 'react'
import {Button, utils} from 'mtuikit'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {useActions} from '../../actions'
import {adaptive, desktop} from '../../theme/base'

const TopUpBalanceBundle = ({bundle, className, onPaid, clickSource}) => {
  const classes = useStyles()
  const actions = useActions()

  const {items, used_item_id, is_expired: isExpired, is_used: isUsed} = bundle
  const isActive = !isExpired && !isUsed

  return <div className={classnames(classes.root, {[classes.active]: isActive}, classes[className])}>
    <div className={classes.bundle_list}>
      {items.map(({item_id, price, topup_amount, description}) =>
        !used_item_id || item_id === used_item_id
          ? <div className={classes.bundle_item} key={item_id}>
            <div className={classes.item_block}>
              <div className={classnames(classes.title, {[classes.active]: isActive})}>
                Top up {utils.printAmount(price + topup_amount, {trunc: true})}
                <div className={classnames(classes.label, {[classes.active]: isActive})}>
                  {isActive
                    ? <img className={classes.label_icon} src={require('./ic_wallet_active.svg').default} alt=''/>
                    : <img className={classes.label_icon} src={require('./ic_wallet.svg').default} alt=''/>
                  }
                  Save {utils.printAmount(topup_amount, {trunc: true})}
                </div>
              </div>
              <div className={classes.description}>{description}</div>
            </div>
            <Button
              className={classes.button}
              disabled={!isActive}
              onClick={() => actions.showBuyBalanceTopUpBundle(bundle.id, item_id, onPaid, clickSource)}
            >
              {used_item_id
                ? <>Added</>
                : <>Get for {utils.printAmount(price, {trunc: true})}</>
              }
            </Button>
          </div>
          : null
      )}
    </div>
  </div>
}

const useStyles = createUseStyles({
  root: {},
  active: {},
  bundle_list: {
    marginTop: 16,
  },
  bundle_item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #EBEEF5',
    borderRadius: 8,
    '&:not(:first-child)': {
      marginTop: 8,
    },
    [adaptive]: {
      flexDirection: 'column',
      padding: [16],
      gap: 16,
    },
    [desktop]: {
      flexWrap: 'wrap',
      padding: [12, 12, 12, 16],
      gap: 8,
    },
  },
  item_block: {
    [adaptive]: {
      width: '100%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    color: '#939FB8',
    '&$active': {
      color: '#2F333D',
    },
    [adaptive]: {
      justifyContent: 'space-between',
    },
    [desktop]: {
      justifyContent: 'flex-start',
    },
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 20,
    background: 'rgba(61, 119, 255, 0.08)',
    borderRadius: 48,
    padding: [0, 8],
    fontWeight: 800,
    fontSize: 10,
    lineHeight: '16px',
    color: '#939FB8',
    marginLeft: 4,
    '&$active': {
      background: 'rgba(73, 176, 0, 0.2)',
      color: '#489900',
    }
  },
  label_icon: {
    display: 'block',
    width: 12,
    height: 12,
    marginRight: 2,
  },
  description: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
    marginTop: 4,
  },
  button: {
    [adaptive]: {
      boxShadow: '0 8px 22px -6px rgba(24, 39, 75, 0.12), 0 14px 64px -4px rgba(24, 39, 75, 0.12)',
      width: '100%',
      height: 40,
    },
    [desktop]: {
      width: '114px',
    },
    '&:disabled': {
      background: '#EBEEF5',
      color: '#939FB8',
    }
  },
  modal: {
    [adaptive]: {
      '& $bundle_item': {
        flexDirection: 'row',
        padding: [12, 12, 12, 16],
      },
      '& $title': {
        justifyContent: 'flex-start',
      },
      '& $button': {
        marginTop: 0,
        width: 114,
      }
    },
  },
  chat: {
    [desktop]: {
      '& $bundle_item': {
        flexDirection: 'column',
        padding: [16],
        gap: 16,
      },
      '& $item_block': {
        width: '100%',
      },
      '& $title': {
        justifyContent: 'space-between',
      },
      '& $button': {
        boxShadow: '0 8px 22px -6px rgba(24, 39, 75, 0.12), 0 14px 64px -4px rgba(24, 39, 75, 0.12)',
        width: '100%',
        height: 40,
      }
    },
  }
})

export default TopUpBalanceBundle
