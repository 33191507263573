import React, {Fragment} from 'react'
import classnames from 'classnames'
import {Link} from 'react-router-dom'
import injectSheet from 'react-jss'
import CookiePopUp from './../../v4/components/CookiePopUp'
import theme, {rem, content, adaptive} from '../../theme/base'
import {PAGES} from './../../../config'

const styles = {
  root: {
    background: theme.colors.paleGrey,
    padding: [rem(80), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
  },
  column: {
    maxWidth: rem(330),
    paddingRight: rem(80),
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      width: '100%',
      maxWidth: 'none',
      marginTop: rem(32),
      paddingRight: 0,
      // display: 'block',
      // '&.resources': {
      //   display: 'none',
      // },
    },
  },
  logo: {
    paddingBottom: rem(40),
    [adaptive]: {
      paddingBottom: 0,
    },
    '& a': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& img': {
      width: rem(160),
      height: rem(24),
      display: 'block',
    },
  },
  block: {
    [adaptive]: {
      marginTop: rem(8),
    },
    '& + &': {
      marginTop: rem(16),
    },
  },
  title: {
    paddingBottom: rem(16),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
      paddingBottom: 0,
    },
  },
  subtitle: {
    paddingTop: rem(16),
    paddingBottom: rem(4),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
      paddingTop: 0,
    },
    '& img': {
      width: rem(24),
      height: rem(24),
      marginRight: rem(2),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      marginTop: rem(4),
      fontSize: 'inherit',
      lineHeight: rem(18),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  item: {
    [adaptive]: {
      marginTop: rem(4),
    },
    '& + &': {
      marginTop: rem(16),
      [adaptive]: {
        marginTop: rem(4),
      },
    },
  },
  link: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.colors.slateGrey,
    [adaptive]: {
      fontSize: rem(14),
      lineHeight: rem(20),
    },
    'a.&': {
      cursor: 'pointer',
    },
  },
  content,
}

const Footer = ({classes}) =>
  <Fragment>
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <Link to='/'>
            <img src={require('./logo-nerdy-blue.svg').default} alt=''/>
          </Link>
        </div>
        <div className={classes.column}>
          <div className={classes.title}>Our Address</div>
          <div className={classes.block}>
            <div className={classes.subtitle}>
              <img src="/img/flags/bg.png" width="24" height="24" alt=''/>
              <span>HK</span>
            </div>
            <div className={classes.link}>
              Office 9<br/>
              2 Iliensko shose street<br/>
              1220 Sofia<br/>
              Bulgaria
            </div>
          </div>

          {/*<div className={classes.block}>*/}
          {/*  <div className={classes.subtitle}>*/}
          {/*    <img src={require('./uk.png').default} alt='' />*/}
          {/*    <span>U.K.</span>*/}
          {/*  </div>*/}
          {/*  <div className={classes.link}>22 Upper Ground, SE1 9PD, London, UK</div>*/}
          {/*</div>*/}
{/*
          <div className={classes.block}>
            <div className={classes.subtitle}>
              <img src={require('./us.png').default} alt=''/>
              <span>US</span>
            </div>
            <div className={classes.link}>75 E Santa Clara St, San Jose, <br/>CA 95113, United States</div>
          </div>
          <div className={classes.block}>
            <div className={classes.subtitle}>
              <img src={require('./eu.png').default} alt=''/>{' '}
              <span>EU</span>
            </div>
            <div className={classes.link}>str. Iliensko shose No 2, office 9, <br/>1220 Sofia, Bulgaria</div>
          </div>
*/}
        </div>
        <div className={classes.column}>
          <div className={classes.title}>Contact us</div>
          <div className={classes.block}>
            <div className={classes.subtitle}>Email:</div>
            <a
              className={classes.link}
              href='mailto:support@nerdy-tutors.com'
              target='_blank'
              rel='noreferrer'
            >
              support@nerdy-tutors.com
            </a>
          </div>
          {/* <div className={classes.block}>
            <div className={classes.subtitle}>Phone number:</div>
            <a className={classes.link} href='tel:+18775748050'>+1-877-574-80-50</a>
          </div> */}
        </div>
        <div className={classnames(classes.column, 'resources')}>
          <div className={classes.title}>Resources</div>
          <ul className={classes.list}>
            {PAGES.map(({name, link}, index) =>
              <div className={classes.item} key={index}>
                <Link className={classes.link} to={link}>{name}</Link>
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
    <CookiePopUp/>
  </Fragment>

export default injectSheet(styles)(Footer)
