import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import { Icon, Button, Editable } from '../../index'
import { wrapPlaceholder, unwrapPlaceholder, validTemplate } from './utils'

const styles = {
  wrapper: {
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    top: rem(16),
    bottom: rem(16),
  },
  editable: {
    marginTop: rem(16),
    position: 'absolute',
    top: rem(20),
    bottom: rem(72),
    left: 0,
    right: 0,
  },
  tooltip: {
    paddingLeft: rem(24),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    position: 'relative',
    '& span': {
      color: theme.colors.blue,
    },
    '& svg': {
      width: rem(16),
      height: rem(16),
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  actions: {
    height: rem(56),
    marginTop: rem(16),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  reset: {
    color: '#ED2C39',
  },
  submit: {
    paddingLeft: rem(16),
    paddingRight: rem(16),
    position: 'absolute',
    top: 0,
    right: 0,
  },
}

const Editor = ({
  classes,
  template,
  onSubmit,
}) => {
  const defaultValue = wrapPlaceholder(template)
  const [value, setValue] = useState(defaultValue)
  const formattedText = unwrapPlaceholder(value)
  const validText = validTemplate(value)
  const onChange = (e) => setValue(e.target.value)
  const onReset = () => setValue(defaultValue)
  const onCopy = () => onSubmit(formattedText)
  return (
    <div className={classes.wrapper}>
      <div className={classes.tooltip}>
        <Icon iconName='question' />
        Please, replace all <span>[blue text]</span> with actual info.
      </div>
      <Editable
        className={classes.editable}
        html={value}
        onChange={onChange}
      />
      <div className={classes.actions}>
        <Button
          className={classes.reset}
          size='large'
          type='outline'
          onClick={onReset}
          disabled={value === defaultValue}
        >
          Reset
        </Button>
        <CopyToClipboard text={formattedText} onCopy={onCopy}>
          <Button className={classes.submit} size='large' type='primary' disabled={!validText} >
            Copy text and close
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default injectSheet(styles)(Editor)