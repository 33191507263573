import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'
import {Icon} from './../Icon'

const styles = {
  root: {
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    borderRadius: 'inherit',
    position: 'relative',
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: '50%',
    right: rem(20),
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
}

const Header = ({
  classes,
  className,
  children,
  onClose,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
    {onClose &&
      <div className={classes.close} onClick={onClose}>
        <Icon iconName='close' />
      </div>
    }
  </div>

export default injectSheet(styles)(Header)
