import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from 'mtuikit'
import theme, { rem } from './../../../theme/base'

const styles = {
  root: {
    position: 'absolute',
    top: rem(8),
    right: rem(8),
    width: rem(16),
    height: rem(16),
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
}

const CloseIcon = ({
  classes,
  onClick
}) =>
  <div className={classes.root} onClick={onClick}>
    <Icon iconName='close' />
  </div>

export default injectSheet(styles)(CloseIcon)