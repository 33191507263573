import React from 'react'
import injectSheet from 'react-jss'
import {rem, desktop, adaptive} from '../../../../../../theme/base'

const styles = {
  root: {
    [desktop]: {
      width: rem(464),
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: 24,
    },
    [adaptive]: {
      width: '100%',
    },
  },
  logo: {
    [desktop]: {
      width: 160,
      height: 24,
      position: 'absolute',
      top: -48,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [adaptive]: {
      display: 'none',
    },
  }
}

const Wrapper = ({
  classes,
  children,
}) =>
  <div className={classes.root}>
    <img
      className={classes.logo}
      src={require('../../../../../../assets/images/logo-blue.svg').default}
      alt=''
    />
    {children}
  </div>

export default injectSheet(styles)(Wrapper)