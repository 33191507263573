import {createUseStyles} from 'react-jss'

export default createUseStyles({
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [10, 0],
    backgroundColor: '#FFEEB8',
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  icon: {
    display: 'block',
    width: 20,
    height: 20,
  },
  time: {
    color: '#E37900',
    marginLeft: '.4em',
    minWidth: 65,
  },
  msg1: {
    color: '#2F333D',
    marginLeft: '.4em',
  },
  msg2: {
    color: '#3D77FF',
    marginLeft: '.4em',
  },
})
