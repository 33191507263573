import React, {useState} from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {Input} from 'mtuikit'
import {SlideUpDropDownAnimation} from '../../../Animation'
import SubjectsList from '../../../SubjectsList'
import {Error} from '../../layout/Error'
import {Button} from '../../layout/Button'
import TutorsList from '../../layout/TutorsList'

const useStyle = createUseStyles({
  inputWrapper: {
    marginTop: 16,
    '& > svg': {
      top: '50%',
      transform: 'translateY(-50%)',
      right: 12,
    },
  },
  input: {
    background: '#F6F7FB',
    height: 44,
    padding: [12, 40, 12, 16],
    borderRadius: 8,
    fontSize: 14,
    lineHeight: 20 / 14,
    '&.error': {
      background: 'rgba(255, 87, 0, 0.08)',
    },
  },
  footer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  requestTutorWrapper: {
    position: 'relative',
  },
})

export const SubjectNameStep = ({
                                  subject,
                                  tutors,
                                  requestedTutor,
                                  onChangeSubject,
                                  onChangeRequestedTutorId,
                                  onContinue,
                                  isSubmitted,
                                  error
                                }) => {
  const classes = useStyle()
  const [showSubjects, setShowSubjects] = useState(false)
  return <>
    <Input
      wrapperClassname={classes.inputWrapper}
      className={classnames(classes.input, {error})}
      placeholder={'E.g. Math'}
      value={subject}
      onChange={(value) => onChangeSubject(value)}
      onFocus={() => setShowSubjects(true)}
      onClickOutside={() => setShowSubjects(false)}
      icon='search'
      error={error}
    >
      {
        showSubjects &&
        <SlideUpDropDownAnimation>
          <SubjectsList
            search={subject}
            onClick={value => {
              onChangeSubject(value)
              setShowSubjects(false)
            }}
          />
        </SlideUpDropDownAnimation>
      }
    </Input>
    {
      error &&
      <Error>Please select a subject</Error>
    }
    <div className={classes.footer}>
      <div className={classes.requestTutorWrapper}>
        {
          tutors.length > 0 &&
          <TutorsList
            subject={subject}
            tutors={tutors}
            requestedTutor={requestedTutor}
            onChangeRequestedTutorId={onChangeRequestedTutorId}
          />
        }
      </div>
      <Button onClick={onContinue} disabled={error}>{isSubmitted ? 'Save' : 'Continue'}</Button>
    </div>
  </>
}