import React, {useState} from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {Button} from '../../layout/Button'
import Overlay from '../../layout/Overlay'
import DesktopDropdown from '../../layout/SelectSize/DesktopDropdown'
import {utils} from 'mtuikit'
import {getPrice} from 'nerdytutors'
import theme, {rem, desktop, adaptive} from 'theme/base'

const useStyles = createUseStyles({
  input: {
    background: '#F6F7FB',
    marginTop: rem(16),
    padding: [rem(12), rem(36), rem(12), rem(16)],
    fontSize: rem(14),
    lineHeight: rem(20),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    borderRadius: rem(8),
    textAlign: 'left',
    position: 'relative',
    cursor: 'pointer',
    '&.active': {
      borderColor: theme.colors.blue,
      color: theme.colors.blue,
      fontWeight: 600,
    },
    '&.error': {
      background: 'rgba(255, 87, 0, 0.08)',
    },
    '& > img': {
      width: rem(16),
      height: rem(16),
      position: 'absolute',
      top: '50%',
      right: rem(12),
      transform: 'translateY(-50%)',
      display: 'block',
    },
  },
  placeholder: {
    color: theme.colors.blueyGrey,
  },
  footer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tutorDecide: {
    position: 'relative',
    '& > input[type=checkbox]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            backgroundImage: `url(${require('./check.svg').default})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: theme.colors.blue,
            borderColor: theme.colors.blue,
          },
        },
      },
      '& + label': {
        padding: [2, 0, 2, 32],
        fontSize: 14,
        lineHeight: 20 / 14,
        fontWeight: 800,
        color: theme.colors.blue,
        display: 'block',
        position: 'relative',
        cursor: 'pointer',
        '&:before': {
          content: '""',
          width: 24,
          height: 24,
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          border: [2, 'solid', '#BAC7E2'],
          borderRadius: 4,
        }
      }
    },
  },
  popup: {
    background: theme.colors.white,
    borderRadius: 8,
    boxShadow: [
      [0, 8, 12, 'rgba(0, 38, 96, 0.06)'],
      [0, 8, 40, 'rgba(0, 53, 136, 0.15)'],
    ],
    zIndex: 3,
    [desktop]: {
      width: 328,
      padding: 16,
      position: 'absolute',
      left: -16,
      top: '100%',
      transform: 'translateY(8px)',
    },
    [adaptive]: {
      width: '100%',
      padding: [16, 16, 40, 16],
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  popupTitle: {
    color: theme.colors.charcoalGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 700,
  },
  popupText: {
    marginTop: 8,
    color: theme.colors.slateGrey,
    fontSize: 12,
    lineHeight: 20 / 12,
    fontWeight: 400,
  },
  popupButton: {
    marginTop: 16,
    padding: [10, 24],
    color: theme.colors.blue,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 600,
    border: [1, 'solid', '#BAC7E2'],
    borderRadius: 28,
    textAlign: 'center',
    cursor: 'pointer',
    [desktop]: {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

export const AmountHoursStep = ({
                                  size,
                                  deadline,
                                  sizeset,
                                  estimateRequired,
                                  onChangeEstimateRequired,
                                  onChangeSize,
                                  onContinue,
                                  isSubmitted,
                                  error
                                }) => {
  const classes = useStyles()
  const [showSelect, setShowSelect] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const _size = size || 1

  const submit = () => {
    if (size === null) onChangeSize(_size)
    onContinue()
  }

  return <>
    <div
      className={classnames(classes.input, {active: showSelect, error})}
      onClick={() => setShowSelect(true)}
    >
      <b>{utils.sizeToHumans(_size)}</b> · {utils.printAmount(getPrice(_size, deadline, 0).price)}
      <img src={require('./arrow.svg').default} alt=''/>
    </div>
    {
      showSelect &&
      <DesktopDropdown
        items={sizeset}
        defaultvalue={size}
        onChange={value => {
          onChangeSize(value)
          setShowSelect(false)
          onChangeEstimateRequired(false)
          setShowPopup(false)
        }}
        onBlur={() => setShowSelect(false)}
      />
    }
    <div className={classes.footer}>
      <div className={classes.tutorDecide}>
        <input
          type='checkbox'
          id='tutorDecide'
          name='tutorDecide'
          value={estimateRequired}
          onChange={e => {
            onChangeEstimateRequired(e.target.checked)
            setShowPopup(e.target.checked)
            if (e.target.checked) {
              onChangeSize(1)
            }
          }}
          checked={estimateRequired}
        />
        <label htmlFor='tutorDecide'>Let tutor decide</label>
        {
          showPopup &&
          <>
            <Overlay adaptive/>
            <div className={classes.popup}>
              <div className={classes.popupTitle}>Great!</div>
              <div className={classes.popupText}>In this case, <b>pre-pay for an hour</b> of tutor's time and one of our
                tutors will review your task details and send you an accurate time estimate.
              </div>
              <div className={classes.popupButton} onClick={() => setShowPopup(false)}>Got it</div>
            </div>
          </>
        }
      </div>
      <Button onClick={submit} disabled={error}>{isSubmitted ? 'Save' : 'Continue'}</Button>
    </div>
  </>
}