import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from 'theme/base'

const styles = {
  select: {
    left: '-100%',
    top: '-100%',
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    '& option': {
      padding: [0, rem(16)],
      cursor: 'pointer',
      '&.active': {
        background: theme.colors.blue,
        color: theme.colors.white,
        '&:hover': {
          background: theme.colors.blue,
        },
      },
      '&:hover': {
        background: '#D6E3FF',
      },
    },
  },
}

const MobileDropdown = ({
  classes,
  items,
  defaultvalue,
  onBlur,
  onChange,
}) =>
  <select
    defaultValue={defaultvalue}
    className={classes.select}
    onChange={e => onChange(Number(e.target.value))}
    onBlur={onBlur}
    autoFocus
  >
    {items.map(({ key, value, text }) =>
      <option key={key} value={value}>{text}</option>
    )}
  </select>

export default injectSheet(styles)(MobileDropdown)