import React, {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import Icon from '../icons'
import {useOrderQuery, useSaveOrderMutation, useUpdateOrderMetaDataMutation} from '../../../../hooks'
import moment from 'moment'

const checkboxList = [
  'Video call',
  'Written explanation',
  'Step by step solution',
  'Exam preparation',
  'File',
  'Other'
]

const getDefaultOutputs = outputs =>
  outputs
    .filter(v => !['No live session', 'Decide later', 'Live session'].includes(v))
    .reduce((r, v) => r.includes(v) ? r : [...r, v], [])

const OnboardRequestOutputs = ({order_id, ...props}) => {
  const classes = useStyle()
  const [outputs, setOutputs] = useState([])
  const [other, setOther] = useState('')

  const orderQuery = useOrderQuery(order_id)

  const updateMetaDataMutation = useUpdateOrderMetaDataMutation()
  const updateOrderMutation = useSaveOrderMutation({
    onSuccess: () => {
      updateMetaDataMutation.mutate({orderId: order_id, outputs_submitted_at: moment().valueOf()})
    }
  })

  const handleCheck = (event) => {
    let updatedList = [...outputs]
    if (event.target.checked) {
      updatedList = [...outputs, event.target.value]
    } else {
      updatedList.splice(outputs.indexOf(event.target.value), 1)
    }
    setOutputs(updatedList)
  }

  const submit = () => {
    updateOrderMutation.mutate({
      order: {
        id: order.id,
        output_formats: [...order.output_formats, ...outputs],
        other_output_format: other
      }
    })
  }

  useEffect(() => {
      setOutputs(getDefaultOutputs(orderQuery.data?.output_formats || []))
      setOther(orderQuery.data?.other_output_format || '')
    },
    // eslint-disable-next-line
    [orderQuery.isFetched]
  )

  if (!orderQuery.isFetched) return <div style={{height: 366}}/>

  const order = orderQuery.data
  const submitted = order.meta_data.outputs_submitted_at

  return <MessageWrapper {...props}>
    <Card {...props}>
      <div className={classes.root}>
        <div className={classes.cardTitle}>What type of help do you prefer?</div>

        {!submitted ?
          <form className={classes.form} onSubmit={e => {
            e.preventDefault()
            submit()
          }}>

            <div className={classes.checkboxList}>
              {checkboxList.map((item, key) => {
                return (
                  <label className={classes.checkboxItem} key={`checkboxItem-${key}`}>
                    <input className={classes.checkboxInput} value={item} type="checkbox"
                           checked={outputs.includes(item)} onChange={handleCheck}/>
                    <Icon.Checkbox/>
                    <div className={classes.checkboxMessage}>
                      {
                        item === 'Video call'
                          ? <>Video call{' '}
                            <div className={classes.popular}>
                              <img
                                className={classes.popularIcon}
                                src={require('../images/popular.svg').default}
                                alt=''
                              />
                              Popular choice
                            </div>
                          </>
                          : item
                      }
                    </div>
                  </label>
                )
              })}
            </div>

            {outputs.includes('Other') &&
            <input id="other" type="text"
                   className={classes.input}
                   placeholder={'Type your option'}
                   onChange={e => setOther(e.target.value)}
            />
            }
            <button className={classes.button} type={'submit'}
                    disabled={updateMetaDataMutation.isFetching || updateMetaDataMutation.isFetching}>
              Submit
            </button>
          </form>
          :
          <ul className={classes.outputList}>
            {outputs.map((item, key) => {
              return (
                <li className={classes.outputItem} key={`outputItem-${key}`}>
                  {item === 'Other' && other ? `${item}: ${other}` : item}
                </li>
              )
            })}
          </ul>
        }
      </div>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

const useStyle = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: '#2F333D',
  },
  checkboxItem: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkboxInput: {
    display: 'none',
    '&:not(:checked) + svg': {
      fill: '#fff',
      stroke: '#bac7e2',
    },
    '&:checked + svg': {
      color: '#3D77FF',
      stroke: "#3D77FF",
    }
  },
  checkboxMessage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
    marginLeft: 8,
  },
  input: {
    marginTop: 8,
    padding: [10, 12, 10, 16],
    width: '100%',
    height: 40,
    background: '#F6F7FB',
    borderRadius: 8,
    fontSize: 14,
    letterSpacing: 0.15,
    color: '#2F333D',
    border: 'none',
  },
  button: {
    background: '#3D77FF',
    boxShadow: '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
    borderRadius: 28,
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    //height: 40,
    padding: [10, 24],
    color: '#FFF',
    marginLeft: 'auto',
    marginTop: 16,
  },
  outputItem: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
    marginTop: 16,
  },
  popular: {
    display: 'flex',
    alignItems: 'center',
    padding: [0, 8],
    height: 20,
    background: '#FFEEB8',
    borderRadius: 48,
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '16px',
    color: '#E37900',
    marginLeft: 8,
    whiteSpace: 'nowrap',
  },
  popularIcon: {
    display: 'block',
    width: 12,
    height: 12,
    margin: [0, 2, 1, 0],
  },
})

export default OnboardRequestOutputs
