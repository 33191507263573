import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'

const useStyle = createUseStyles({
  checkbox: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 3,
    transition: 'all .2s ease-in-out',
    border: '0.71px solid #939FB8',
    '&$disabled': {
      backgroundColor: '#BAC7E2',
    },
    '&$checked:not($disabled)': {
      backgroundColor: '#3D77FF',
      '& $icon': {
        visibility: 'visible',
      }
    },
  },
  checked: {
    border: 'none',
  },
  disabled: {
    border: 'none',
    cursor: 'default',
  },
  checkboxInput: {
    display: 'none',
  },
  icon: {
    visibility: 'hidden',
    display: 'block',
    width: 10,
    height: 8.33,
  },
});

const Checkbox = ({ checked, disabled, onChange }) => {
  const classes = useStyle();
  return (
      <label className={classnames(classes.checkbox, {[classes.checked]:checked}, {[classes.disabled]:disabled})}>
        <input className={classes.checkboxInput} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <svg className={classes.icon} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.60426 0.311448C10.0635 0.684593 10.1333 1.35938 9.76017 1.81863L4.41101 8.40217L0.359622 4.80093C-0.0826456 4.40781 -0.122482 3.73059 0.270644 3.28832C0.663771 2.84605 1.34099 2.80622 1.78326 3.19934L4.16045 5.3124L8.09707 0.467358C8.47022 0.00810658 9.14501 -0.0616965 9.60426 0.311448Z" fill="white"/>
        </svg>
      </label>
  )
};

export default Checkbox
