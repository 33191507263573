import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { rem, justified } from './../../theme/base'

const styles = {
  root: {
    ...justified,
    width: '100%',
    marginTop: rem(24),
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .mt16': {
      marginTop: rem(16),
    },
  //   &.actions__float-left {
  //     width: 100%,
  //     float: left,
  //   }
  //   &.actions__bottom {
  //     width: auto,
  //     position: absolute,
  //     left: rem(16),
  //     right: rem(16),
  //     bottom: rem(16),
  //     .actions-item {
  //       &:only-child {
  //         width: 100%,
  //       }
  //     }
  //   }
  },
  item: {
    width: `calc(50% - ${rem(4)})`,
    display: 'inline-block',
    verticalAlign: 'top',
    '&:only-child': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
		},
		'& svg': {
			width: rem(12),
			height: rem(12),
			marginTop: rem(3),
			marginRight: rem(4),
			display: 'inline-block',
			verticalAlign: 'top',
		},
    '& > button': {
      width: '100%',
		},
		'.profile &': {
			'&:first-child': {
				width: `calc(40% - ${rem(4)})`,
			},
			'&:last-child': {
				width: `calc(60% - ${rem(4)})`,
			},
			'> button': {
				minWidth: 0,
			},
		},
	},
}

const Actions = ({ items, className, classes }) => {
  const isItemsIsset = items && items.length;

  if (!isItemsIsset) {
    return null;
  }

  return (
    <div className={classnames(classes.root, className)}>
      {items.map((item, index) => 
        <div className={classes.item} key={`action-${index}`}>{item}</div>
      )}
    </div>
  )
}

export default injectSheet(styles)(Actions)