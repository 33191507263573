import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
}

const Line = ({ 
  classes, 
  children, 
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Line)