import React from 'react'
import injectSheet from 'react-jss'
import theme, {rem} from '../../../../../../theme/base'

const styles = {
  root: {
    paddingLeft: rem(34),
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    fontFamily: theme.fontFamilies.Manrope,
    position: 'relative',
    '& + &': {
      marginTop: rem(20),
    },
    '& > img': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: rem(2),
      left: 0,
      display: 'block',
    },
    '& span': {
      fontFamily: theme.fontFamilies.Manrope,
    },
  },
}

const Item = ({
                icon,
                classes,
                children,
              }) =>
  <div className={classes.root}>
    {icon === 'success' &&
    <img src={require('./icon-success.svg').default} alt=''/>
    }
    {icon === 'failed' &&
    <img src={require('./icon-failed.svg').default} alt=''/>
    }
    {icon === 'alert' &&
    <img src={require('./icon-alert.svg').default} alt=''/>
    }
    {children}
  </div>

export default injectSheet(styles)(Item)