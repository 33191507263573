import React from 'react'
import {ChatMessageBox} from '@connect/connect-ui-js-v2'
import {useAddDraft, useBroadcastTyping} from '@connect/connect-xmpp-v2'

export const MessageBox = ({chatConfig, defaultMessage, useAudio = false}) => {
  const addDraft = useAddDraft(chatConfig)
  const broadcastTyping = useBroadcastTyping(chatConfig)
  return (
    <ChatMessageBox
      defaultMessage={defaultMessage}
      addDraft={addDraft}
      broadcastTyping={broadcastTyping}
      useAudio={useAudio}
    />
  )
}