import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {useOrderQuery} from '../../hooks'
import {utils} from 'mtuikit'
import getDeadlineStatus from './utils/getDeadlineStatus'


const Information = ({orderId, className}) => {
  const classes = useStyle()

  const orderQuery = useOrderQuery(orderId)
  if (!orderQuery.isSuccess) return null

  const {data: order} = orderQuery
  const {subject, number, size, price} = order

  return <div className={classnames(classes.root, className)}>
    <div className={classes.row}>
      <div className={classes.key}>Course name:</div>
      <div className={classes.value}>{subject}</div>
    </div>
    <div className={classes.row}>
      <div className={classes.key}>Task number:</div>
      <div className={classes.value}>{number}</div>
    </div>
    <div className={classes.row}>
      <div className={classes.key}>{getDeadlineStatus(order)[0] || "Help will be provided by"}:</div>
      <div className={classes.value}>{utils.printDateTime(getDeadlineStatus(order)[1])}</div>
    </div>
    <div className={classes.row}>
      <div className={classes.key}>Paid for:</div>
      <div className={classes.value}>{size}</div>
    </div>
    <div className={classnames(classes.row, classes.total)}>
      <div className={classes.key}>Total paid:</div>
      <div className={classes.value}>{utils.printAmount(price)}</div>
    </div>
  </div>
}

const useStyle = createUseStyles({
  root: {
    margin: [14, 0]
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& + &': {
      marginTop: 12,
    }
  },
  key: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#565D6B'
  },
  value: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#565D6B'
  },
  total: {
    marginTop: '44px !important',
    '& $key, & $value': {
      fontWeight: '800',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#2F333D',
    }
  }
})

export default Information
