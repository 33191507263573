import React from 'react'
import useStyle from './styles'
import Banner from './index'
import {useActiveBundle} from '../../hooks'
import {useActions} from '../../actions'
import Countdown from '../Countdown'

const BalanceTopUpBundleBanner = () => {
  const classes = useStyle()
  const actions = useActions()
  const activeBundle = useActiveBundle()
  return activeBundle?.is_active
    ? <Banner onClick={() => actions.showPopupBalanceTopUpBundle(activeBundle.id)}>
      <img className={classes.icon} src={require('./img/ic_limited-time.svg').default} alt=''/>
      <div className={classes.time}><Countdown dl={activeBundle.bundle_expire_at}/></div>
      <div className={classes.msg1}>Limited-time offer!</div>
      <div className={classes.msg2}>Show details</div>
    </Banner>
    : null
}

export default BalanceTopUpBundleBanner