import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../theme/base'
import {Icon} from '../Icon'
import Message from './Message'

const styles = {
  root: {
    background: '#6dd400',
    padding: [0, rem(19), 0, rem(11)],
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 7,
    '& svg': {
      width: rem(14),
      height: rem(12),
      //marginRight: rem(4),
      display: 'block',
      pointerEvents: 'none',
    },
  },
}

const Success = ({
  classes,
  children,
}) => {
  return (
    <Message className={classes.root}>
      <Icon iconName='check' />
      <div>{children}</div>
    </Message>
  )
}

export default injectSheet(styles)(Success)
