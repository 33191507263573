import React from 'react'
import {utils} from 'mtuikit'
import {media} from '../../theme/base'
import {useParams} from 'react-router-dom'
import MobileView from './MobileView'
import DesktopTwoColumnsView from './DesktopTwoColumnsView'
import DesktopThreeColumnsView from './DesktopThreeColumnsView'
import {Orders} from '../Orders'
import {Tutors} from '../Tutors'
import SelectOrderMessage from './SelectOrderMessage'
import SelectTutorMessage from './SelectTutorMessage'
import {MobileOrderChatSubheader2, OrderDetailSubheader, TutorDetailSubheader} from '../Subheader'
import {TutorDetails} from '../TutorDetails'
import {OrderChat} from '../Chat/OrderChat'
import OrderDetails from '../Orders/OrderDetails'
import {SupportChatButton} from '../SupportChat'
import PopupBalanceTopUpBundle from '../Banner/BalanceTopUpBundleBanner'
import {createUseStyles} from "react-jss";

const DesktopOrders = () => {
  const {orderId} = useParams()
  return <>
    <DesktopTwoColumnsView sidebar={<Orders orderId={orderId}/>} workarea={<SelectOrderMessage/>}/>
    <SupportChatButton/>
  </>
}

const DesktopTutors = () => {
  const {tutorId} = useParams()
  return <>
    <DesktopTwoColumnsView sidebar={<Tutors tutorId={tutorId}/>} workarea={<SelectTutorMessage/>}/>
    <SupportChatButton/>
  </>
}

const DesktopOrderChatView = () => {
  const {orderId} = useParams()
  return <>
    <DesktopThreeColumnsView
      lsidebar={<Orders orderId={orderId}/>}
      workarea={<OrderChat orderId={orderId}/>}
      rsidebar={<OrderDetails orderId={orderId}/>}
    />
    <SupportChatButton/>
  </>
}

const DesktopOrderTutorView = () => {
  const {orderId, tutorId} = useParams()
  const sidebar = orderId ? <Orders orderId={orderId}/> : <Tutors tutorId={tutorId}/>
  return <>
    <DesktopTwoColumnsView
      sidebar={sidebar}
      subheader={<TutorDetailSubheader orderId={orderId} tutorId={tutorId}/>}
      workarea={<TutorDetails orderId={orderId} tutorId={tutorId}/>}
    />
    <SupportChatButton/>
  </>
}

const MobileOrders = () => <>
  <MobileView list={<Orders/>} header footer/>
  <SupportChatButton className={'top'}/>
</>

const MobileTutors = () => <>
  <MobileView list={<Tutors/>} header footer/>
  <SupportChatButton className={'top'}/>
</>

const MobileOrderChatView = () => {
  const {orderId} = useParams()
  return <>
    <MobileView
      subheader={<MobileOrderChatSubheader2 orderId={orderId}/>}
      workarea={<OrderChat orderId={orderId}/>}
    />
  </>
}

const MobileOrderDetailsView = () => {
  const {orderId} = useParams()
  return <>
    <MobileView
      subheader={<OrderDetailSubheader orderId={orderId}/>}
      workarea={<OrderDetails className={`orderMobile`} orderId={orderId}/>}
    />
    <SupportChatButton/>
  </>
}

const MobileOrderTutorView = () => {
  const {orderId, tutorId} = useParams()
  return <MobileView
    subheader={<TutorDetailSubheader tutorId={tutorId} orderId={orderId}/>}
    workarea={<TutorDetails tutorId={tutorId} orderId={orderId}/>}
  />
}

const FOLDER = {
  desktop: {tutors: DesktopTutors, orders: DesktopOrders},
  mobile: {tutors: MobileTutors, orders: MobileOrders}
}

const TARGET = {
  desktop: {chat: DesktopOrderChatView, task: DesktopOrderChatView, tutor: DesktopOrderTutorView},
  mobile: {chat: MobileOrderChatView, task: MobileOrderDetailsView, tutor: MobileOrderTutorView}
}

const useStyle = createUseStyles({
  wrapper: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
})

export const Workarea = (props) => {
  const {folder, target, orderId, tutorId} = useParams()
  const screen = utils.useMobile(media.desktop) ? 'mobile' : 'desktop'
  const classes = useStyle()

  let t
  t = !target && orderId && 'order'
  t = t || (!target && tutorId && 'tutor')
  t = t || target

  return <div className={classes.wrapper}>
    <PopupBalanceTopUpBundle/>
    {
      t
        ? React.createElement(TARGET[screen][t], props)
        : React.createElement(FOLDER[screen][folder], props)
    }
  </div>
}
