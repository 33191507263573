import React from 'react'
import moment from 'moment'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'

const SupportUnavailable = ({data, end_time, ...props}) => {

  const message = 'Support team can’t answer right now... ' +
    'We think they were abducted by aliens 😱. ' +
    `But don’t worry,  they'll be back at ${moment.unix(end_time).format('h A')}. ` +
    'Anyways, feel free to ask any questions and we will answer them as soon as possible.'

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{...data, message}}/>
  </MessageWrapper>
}

export default SupportUnavailable