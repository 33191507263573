import React from 'react';
import Layout from '../Chat/components/events/layout'
import {PAYMENT_REQUEST, PAYMENT_REQUEST_MAP} from './../../config'

const CARD_ICON_MAP = {
  visa: 'visa',
  mastercard: 'mastercard',
  americanexpress: 'americanexpress',
  dinersclub: 'dinersclub',
  discover: 'discover',
  jcb: 'jcb',
  unionpay: 'unionpay'
}

const CardList = ({
                    listAllCards,
                    paymentMethod,
                    paymentRequest,
                    onChange,
                    className,
                    labelForCard = 'Pay with card ****'
                  }) => {

  const paymentRequestType = paymentRequest?._buttonTypeName

  return <Layout.Cards className={className}>
    {
      listAllCards.length > 0 &&
      <Layout.Card
        cardType={CARD_ICON_MAP[listAllCards[0].card.brand] || 'unknown'}
        checked={listAllCards[0].id === paymentMethod}
        onClick={() => onChange(listAllCards[0].id)}
      >
        {labelForCard} {listAllCards[0].card.last4}
      </Layout.Card>
    }
    {
      paymentRequestType && PAYMENT_REQUEST_MAP[paymentRequestType] &&
      <Layout.Card
        cardType={PAYMENT_REQUEST_MAP[paymentRequestType].cardType}
        checked={paymentMethod === PAYMENT_REQUEST}
        onClick={() => onChange(PAYMENT_REQUEST)}
      >
        {PAYMENT_REQUEST_MAP[paymentRequestType].label}
      </Layout.Card>
    }
    <Layout.Card
      cardType='newcard'
      checked={paymentMethod === null}
      onClick={() => onChange(null)}
    >
      {listAllCards.length > 0 ? 'New payment method' : 'Pay with Card'}
    </Layout.Card>
  </Layout.Cards>
}

export default CardList