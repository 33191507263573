import {useActions} from '../../actions'
import {useOrder, useToggleFavTutorMutation, useTutor} from '../../hooks'
import {ContextMenu} from '../ContextMenu'
import React from 'react'
import ReportTutor from '../Actions/ReportTutor'
import BlockTutor from '../Actions/BlockTutor'

export const TutorContextMenu = ({tutorId, orderId, ...rest}) => {
  const actions = useActions()

  const order = useOrder(orderId)

  const tutor_id = tutorId
    || (order.assigned && order.assigned.freelancer_id)
    || (order.connected && order.connected.freelancer_id)

  const tutor = useTutor(tutor_id)

  const toggleFavTutorMutation = useToggleFavTutorMutation(tutor_id)

  const menu = []

  if (!tutor.is_blocked) {
    menu.push({
      icon: 'contextMenu-add',
      text: 'Request new task',
      disabled: false,
      onClick: () => {
        actions.removeAll()
        actions.showOrderForm({order: {recommended: {freelancer_id: tutor_id}}})
      },
      // divider: true,
    })
  }

  if (order) {
    menu.push({
      icon: 'contextMenu-reject',
      text: 'Reject Tutor',
      description: !!order.assigned
        ? 'Unavailable, because tutor already in process'
        : null,
      disabled: !!order.assigned,
      onClick: () => actions.showRejectTutorPrompt({orderId, tutorId: tutor_id}),
    })
  }

  if (!tutor.is_blocked) {
    menu.push({
      icon: tutor.is_favorite ? 'contextMenu-heart_unfavorite' : 'contextMenu-heart',
      text: tutor.is_favorite ? 'Remove from favorites' : 'Add to favorites',
      disabled: false,
      onClick: () => toggleFavTutorMutation.mutate(),
      divider: false,
    })
  }

  menu.push({
    icon: 'contextMenu-report',
    text: 'Report Tutor',
    disabled: false,
    onClick: () => actions.showModal(ReportTutor, {tutorId: tutor.id}),
  })

  menu.push({
    icon: 'contextMenu-block',
    text: 'Block Tutor',
    description: tutor.is_blocked ? 'Unavailable, because tutor already blocked' : null,
    disabled: tutor.is_blocked,
    onClick: () => actions.showOverlay(BlockTutor, {tutorId: tutor.id}),
  })

  return <ContextMenu actions={menu}{...rest}/>
}