import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {theme, utils} from 'mtuikit'
import {Button} from '../../layout/Button'
import CardList from './../../../CardList'
import {trackClicks} from '../../../../lrtracker'
import {PAYMENT_REQUEST, PAYMENT_REQUEST_MAP} from '../../../../config'

const useStyles = createUseStyles({
  item: {
    marginTop: 16,
    '& + &': {
      marginTop: 24,
    },
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 800,
  },
  value: {
    marginTop: 4,
    color: theme.colors.slateGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 400,
    letterSpacing: 0.15,
  },
  oldPrice: {
    marginRight: 8,
    color: theme.colors.blueyGrey,
    fontSize: 21,
    lineHeight: 28 / 21,
    fontWeight: 600,
    textDecoration: 'line-through',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  currentPrice: {
    color: theme.colors.charcoalGrey,
    fontSize: 21,
    lineHeight: 28 / 21,
    fontWeight: 600,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  showHideDetails: {
    marginTop: 8,
    color: theme.colors.blue,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 800,
    cursor: 'pointer',
  },
  cardList: {},
  button: {
    marginTop: 24,
    '& > button': {
      width: '100%',
    },
  },
  icons: {
    marginTop: 16,
    textAlign: 'center',
  },
  icon: {
    width: 42,
    height: 28,
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 8,
    },
  },
})

const ICONS = ['masterCard', 'visa', 'amex', 'discover', 'f', 'jcb']

export const PaymentsStep = ({
                               id,
                               toid,
                               size,
                               price,
                               total,
                               isReadyForPayment,
                               listAllCards,
                               paymentMethod,
                               paymentRequest,
                               onChangePaymentMethod,
                               onContinue
                             }) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const paymentFromBalance = price - total

  const pay = trackClicks(onContinue, 'orderPayBtn', {
    context: {
      id, toid, price,
      stage: 'orderFormPaymentStage',
      type: paymentMethod === PAYMENT_REQUEST
        ? PAYMENT_REQUEST_MAP[paymentRequest._buttonTypeName]?.cardType || 'other payment request button'
        : 'Card',
    }
  })

  return <>
    {show &&
    <>
      <div className={classes.item}>
        <div className={classes.title}>Time needed to complete your task:</div>
        <div className={classes.value}>{utils.sizeToHumans(size)}</div>
      </div>
      <div className={classes.item}>
        <div className={classes.title}>Subtotal:</div>
        <div className={classes.value}>{utils.printAmount(price)}</div>
      </div>
      {
        paymentFromBalance > 0 &&
        <div className={classes.item}>
          <div className={classes.title}>Paid from account balance:</div>
          <div className={classes.value}>{utils.printAmount(paymentFromBalance)}</div>
        </div>
      }
    </>
    }
    <div className={classes.item}>
      {show &&
      <div className={classes.title}>Total to be paid:</div>
      }
      <div className={classes.value}>
        {
          paymentFromBalance > 0 &&
          <span className={classes.oldPrice}>{utils.printAmount(price)}</span>
        }
        <span className={classes.currentPrice}>{utils.printAmount(total)}</span>
      </div>
    </div>
    <div className={classes.showHideDetails} onClick={() => setShow(!show)}>
      {show ? 'Hide' : 'See'} details
    </div>
    {
      total > 0 &&
      <div className={classes.cardList}>
        <CardList
          listAllCards={listAllCards}
          paymentMethod={paymentMethod}
          paymentRequest={listAllCards.length === 0 && paymentRequest}
          onChange={onChangePaymentMethod}
          className={classes.cards}
        />
      </div>
    }
    <Button
      className={classes.button}
      onClick={pay}
      disabled={!isReadyForPayment}
    >Confirm and pay {utils.printAmount(total)}</Button>
    <div className={classes.icons}>
      {ICONS.map((item, index) =>
        <div className={classes.icon} key={index}>
          <img src={require(`./${item}.svg`).default} alt=''/>
        </div>
      )}
    </div>
  </>
}