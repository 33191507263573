import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {useOrderQuery, useTutorQuery} from '../../hooks'
import Icon from './icons'
import Avatar from './ui/Avatar'
import {colors} from './ui/consts'
import {Button} from 'mtuikit'
import {useActions} from '../../actions'
import {trackValues} from '../../lrtracker'
import {getIsTopTutorAddonActive} from '../../nerdytutors'

const TutorInfo = ({orderId, className}) => {
  const classes = useStyle()
  const actions = useActions()

  const orderQuery = useOrderQuery(orderId)

  const tutorId = orderQuery.isSuccess && (
    (orderQuery.data.connected && orderQuery.data.connected.connected_at && orderQuery.data.connected.freelancer_id)
    || (orderQuery.data.assigned && orderQuery.data.assigned.freelancer_id)
  )

  const tutorQuery = useTutorQuery(tutorId)

  if (!orderQuery.isSuccess || (tutorId && !tutorQuery.isSuccess)) return null

  const {data: order} = orderQuery
  const {data: tutor} = tutorQuery

  const rejectTutor = () => actions.showRejectTutorPrompt({tutorId: order.connected.freelancer_id, orderId})
  const viewProfile = () => {
    actions.showOrderTutorProfile(orderId)
    trackValues('chatClientTutor', 'clientTutorViewProfileBtnClick', {context: {id: orderId, type: 'Sidebar'}})
  }

  const isTopTutorAddonActive = getIsTopTutorAddonActive(order)

  return <div className={classnames(classes.root, className)}>
    {
      tutor
        ? <>
          <Avatar userId={tutor.id} className={classes.avatar} big top={isTopTutorAddonActive}/>
          <div className={classes.rate}>
            {tutor.name}{' '}
            {tutor.avg_score}{' '}
            <Icon.Star/>{' '}
            {
              isTopTutorAddonActive &&
              <span className={classes.topLabel}>
                  <img className={classes.topLabelIcon} src={require(`./images/ic_awersome.svg`).default} alt=''/>
                  <span className={classes.topLabelMessage}>TOP</span>
               </span>
            }
          </div>
          <div className={classes.subject}>{order.subject}</div>
          {
            order.connected &&
            <div className={classes.buttons}>
              <Button type='outline' block onClick={rejectTutor}>Reject tutor</Button>
              <Button block onClick={viewProfile}>View profile</Button>
            </div>
          }
          {
            order.assigned && <div className={classes.view_profile} onClick={viewProfile}>View profile</div>
          }
        </>
        : <>
          <img className={classes.avatarDefault}
               src={require('./images/tutors.png').default}
               srcSet={require('./images/tutors.png').default + ' 1x,' + require('./images/tutors@2x.png').default + ' 2x,' + require('./images/tutors@3x.png').default + ' 3x'}
               alt=''/>
          {
            isTopTutorAddonActive
              ? <>
                <div className={classes.rate}>Looking for a TOP tutor...</div>
                <div className={classes.text}>You will get matched with one of our TOP experts
                  in <strong>{order.subject}</strong></div>
              </>
              : <>
                <div className={classes.rate}>4.65&nbsp;<Icon.Star/>&nbsp;Average tutor score</div>
                <div className={classes.text}>based on 300K+ client ratings</div>
              </>
          }
        </>
    }
  </div>
}

const useStyle = createUseStyles({
  root: {
    margin: [24, 0]
  },
  rate: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 800,
    color: colors.black2,
    whiteSpace: 'nowrap',
    "& svg": {
      marginRight: 4,
    }
  },
  text: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.15,
    textAlign: 'center',
    color: '#565D6B',
    marginTop: 4,
  },
  subject: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.15,
    textAlign: 'center',
    color: '#565D6B',
    marginTop: 2
  },
  avatar: {
    margin: [16, 'auto'],
    width: 64
  },
  avatarDefault: {
    display: 'block',
    width: 112,
    height: 68,
    margin: [0, 'auto', 2],
  },
  buttons: {
    marginTop: 12,
    display: 'flex',
    '& button:not(:first-child)': {
      marginLeft: 8,
    },
  },
  view_profile: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#3D77FF',
    marginTop: 16,
    cursor: 'pointer'
  },
  topLabel: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFEEB8',
    borderRadius: 48,
    padding: [0, 8],
    height: 20,
  },
  topLabelIcon: {
    display: 'block',
    width: 12,
    height: 12,
  },
  topLabelMessage: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '16px',
    color: '#E37900',
    padding: [1, 0, 0, 2],
  },
  buttonPromo: {
    margin: [16, 'auto', 0],
  },
  buttonIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  }
})

export default TutorInfo
