import React from 'react'
import {createUseStyles} from 'react-jss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import theme, {desktop, adaptive} from 'theme/base'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { useBodyBackgroundColor } from 'hooks'

const HEADER_HEIGHT_DESKTOP = 56
const HEADER_HEIGHT_MOBILE = 66
const ASIDE_WIDTH = 360

const useStyle = createUseStyles({
  aside: {
    position: 'absolute',
    padding: [60, 16],
    [desktop]: {
      width: ASIDE_WIDTH,
      top: HEADER_HEIGHT_DESKTOP,
      borderRight: [1, 'solid', theme.colors.lightBlueGrey50],
      left: 0,
      bottom: 0,
    },
    [adaptive]: {
      width: '100%',
      top: HEADER_HEIGHT_MOBILE,
    },
  },
  workarea: {
    [desktop]: {
      position: 'absolute',
      left: ASIDE_WIDTH,
      right: 0,
      top: HEADER_HEIGHT_DESKTOP,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [adaptive]: {
      display: 'none',
    },
  },
  card: {
    background: theme.colors.white,
    height: 112,
    borderRadius: 16,
    boxShadow: [0, 4, 8, 'rgba(86, 93, 107, 0.1)'],
    position: 'relative',
    [desktop]: {
      width: 294,
    },
    [adaptive]: {
      width: '100%',
    },
    '& + &': {
      marginTop: 8,
    },
  },
  cardCircle: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    position: 'absolute',
    left: 16,
    top: 18,
  },
  cardLine1: {
    width: '50%',
    height: 16,
    borderRadius: 2,
    position: 'absolute',
    left: 60,
    top: 20,
  },
  cardLine2: {
    width: '70%',
    height: 16,
    borderRadius: 2,
    position: 'absolute',
    left: 60,
    top: 44,
  },
  cardLine3: {
    width: '70%',
    height: 16,
    borderRadius: 2,
    position: 'absolute',
    left: 60,
    top: 78,
  },
})

export const ConnectingPage2 = () => {
  const classes = useStyle()
  useBodyBackgroundColor(theme.colors.white)
  return (
    <>
      <Header />
      <div className={classes.aside}>
        {[...Array(3).keys()].map((_, index) =>
          <div className={classes.card} key={index}>
            <SkeletonTheme baseColor="#F3F5F9" highlightColor="#DDE2F0">
              <Skeleton className={classes.cardCircle} />
              <Skeleton className={classes.cardLine1} />
              <Skeleton className={classes.cardLine2} />
              <Skeleton className={classes.cardLine3} />
            </SkeletonTheme>
          </div>
        )}
      </div>
      <div className={classes.workarea}>
        <img
          src={require('../../assets/images/loader-blue.svg').default}
          alt=''
          width={24}
          height={24}
        />
      </div>
      <Footer/>
    </>
  )
}
