import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../../../mtuikit/theme/base'

const ICONS = {
  block: require('./block-icon.svg').default,
  delete: require('./delete-icon.svg').default,
  reject: require('./reject-icon.svg').default,
  attention: require('./attention-icon.svg').default,
  add: require('./add-icon.svg').default,
  phone: require('./phone-icon.svg').default,
  record: require('./record-icon.svg').default,
  success: require('./success-icon.svg').default,
}

const styles = {
  root: {
    width: rem(48),
    height: rem(48),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

const Icon = ({
  classes,
  name,
}) =>
  <img
    className={classes.root}
    src={ICONS[name]}
    alt=''
  />

export default injectSheet(styles)(Icon)