import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../mtuikit/theme/base'
import Button from '../../mtuikit/components/Button'

const styles = {
	root: {
		width: `calc(50% - ${rem(4)})`,
		display: 'inline-block',
		verticalAlign: 'top',
	},
}

export default injectSheet(styles)((
	{
		classes,
		children,
		size,
		...otherProps
	}) => 
		<Button 
			className={classes.root} 
			size='medium'
			{...otherProps}
		>
			{children}
		</Button>
)
