import React from 'react'
import injectSheet from 'react-jss'
import theme, {rem, desktop, adaptive} from '../../../../../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: rem(24),
    border: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [desktop]: {
      height: rem(542),
      marginTop: rem(96),
      borderRadius: [rem(24), rem(24), rem(16), rem(16)],
    },
    [adaptive]: {
      height: 'auto',
      minHeight: '100vh',
      paddingTop: rem(120),
      paddingBottom: rem(104),
      border: 'none',
    },
  },
}

const Content = ({
                   classes,
                   children,
                 }) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Content)