import React, {Fragment, useRef, Children, useState, useEffect} from 'react'
import * as moment from 'moment'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import {theme as chatTheme} from '../../theme'
import {rem, adaptive} from '../../../../mtuikit/theme/base'
import {Button as ButtonTheme, Icon, theme} from 'mtuikit'
import useTimeCountdown from './../../../../utils/useTimeCountdown'
import {MessageStatus} from '@connect/connect-ui-js-v2'

const TutorHeader = ({classes, avatar, color}) => (
  <div className={classnames(classes.tutor_header, color)}>
    <img className={classes.avatar1} src={avatar} alt=''/>
  </div>
)

const Body = ({classes, children}) => (
  <div className={classes.body}>{children}</div>
)

const Title = ({classes, children, className}) => (
  <div className={classnames(classes.title, className)}>{children}</div>
)

const Title1 = ({classes, children, iconName, iconStyles}) => (
  <div className={classes.title1}>
    {iconName && <Icon iconName={iconName} styles={iconStyles}/>}
    {iconName ? <span>{children}</span> : children}
  </div>
)

const Title2 = ({classes, children, img}) => (
  <div className={classes.title2}>
    <img src={img} alt=''/>
    <span>{children}</span>
  </div>
)

const Subject = ({classes, children}) => (
  <div className={classes.subject}>{children}</div>
)

const TutorStat = ({classes, score, helped}) => (
  <div className={classes.tutor_stat}>
    <span>{score}</span>
    <Icon iconName='star'/>
    <span>·&nbsp;&nbsp;Helped {helped} times</span>
  </div>
)

const Buttons = ({classes, children}) => (
  <div className={classes.buttons}>{children}</div>
)

const Button = ({classes, children, ...props}) => (
  <ButtonTheme className={classes.button} {...props}>{children}</ButtonTheme>
)

const Details = ({classes, children}) => (
  <div className={classes.details}>{children}</div>
)

const Subtitle = ({classes, children}) => (
  <div className={classes.subtitle}>{children}</div>
)

const Text = ({classes, children, bold}) => (
  <div className={classnames(classes.text, {bold})}>{children}</div>
)

const Divider = ({classes}) => (<div className={classes.divider}></div>
)

const A = ({classes, href, children}) => (
  <a className={classes.link} href={href}>{children}</a>
)

const B = ({classes, children}) => (
  <span className={classes.bold}>{children}</span>
)


const Action = ({classes, className, children, ...props}) => (
  <span className={classnames(classes.link, className)} {...props}>{children}</span>
)

const SearchHeader = ({classes, title, description, avatars}) => (<>
  <div className={classes.search_title}>{title}</div>
  <div className={classes.search_stat}>
    {avatars.map((a, index) => (
      <img src={a} key={index} alt=''/>
    ))}
    <span>{description}</span>
  </div>
</>)

const LiveSession = ({classes, datetime, duration, avatar}) => {
  const dt = moment(datetime)
  const dtEnd = moment(datetime).add(duration, 'minutes')
  return (
    <div className={classes.live_session}>
      <div className={classes.scheduled_time}>{dt.format('h:mm A')} - {dtEnd.format('h:mm A')}</div>
      <div className={classes.scheduled_date}>{dt.format('dddd, D MMMM')}</div>
      <img
        className={classes.scheduled_by}
        src={avatar}
        alt=''
      />
    </div>
  )
}

const Extratime = ({classes, description}) => (
  <div className={classes.extratime}>
    <div className={classes.extratime_description}>{description}</div>
  </div>
)

const Cards = ({classes, children, className, ...props}) => (
  <div className={classnames(classes.cards, className)} {...props}>{children}</div>
)

let cardInputId = 0
const getcardInputId = () => `cardInputId${++cardInputId}`

const Card = ({classes, children, cardType, checked, onClick}) => {
  const refId = useRef(getcardInputId())
  return (
    <div className={classes.card} onClick={onClick}>
      <input
        id={refId.current}
        type='radio'
        name='payment'
        defaultChecked={checked}
      />
      <label htmlFor={refId.current}>
        {
          cardType
            ? (<>
              <Icon iconName={`card-${cardType}`}/>
              <span>{children}</span>
            </>)
            : children
        }
      </label>
    </div>
  )
}

const More = ({classes, children, onClick}) => (
  <div className={classes.more} onClick={onClick}>
    {children}
  </div>
)

const RateExperience = ({classes, children}) => (
  <div className={classes.rate_experience}>
    {children}
  </div>
)

const RatedExperience = ({classes, children}) => (
  <div className={classes.rated_experience}>
    {children}
  </div>
)

const Avatar = ({classes, url, online = true, favorite = true}) => (
  <div className={classes.avatar2}>
    <img src={url} alt=''/>
    {
      online &&
      <div className={classes.online}/>
    }
    {
      favorite &&
      <Icon className={classes.favorite} iconName='ic_fav_blue'/>
    }
  </div>
)

const Stars = ({classes, onChange}) => {
  const rates = ['1', '2', '3', '4', '5']
  return (
    <div className={classes.stars}>
      {rates.reverse().map((value, index) =>
        <Fragment key={index}>
          <Star
            className={classes.star}
            id={`star-${value}`}
            value={value}
            onChange={onChange}
          />
        </Fragment>
      )}
    </div>
  )
}

const Star = ({className, id, value, onChange}) => (
  <>
    <input type='radio' id={id} name='rating' value={value} onChange={onChange}/>
    <label className={className} htmlFor={id} title={value}>
      <Icon iconName='star2'/>
    </label>
  </>
)

const RateKey = ({classes, value}) => (
  <div className={classes.rate_key}>
    <span>{value}</span>
    <Star
      className={classes.star}
      id={`star-${value}`}
      value={value}
    />
  </div>
)

const RateValue = ({classes, children}) => (
  <div className={classes.rate_value}>
    {children}
  </div>
)

const MessageStatus_ = ({classes, ...props}) => <MessageStatus {...props} isRelative/>

const TimeCountdown = ({classes, text, end}) => {
  const countdown = useTimeCountdown(end)
  return <div className={classes.countdown}>{text} {countdown}</div>
}

const Collapsable = ({classes, trigger, children, visibleLines = 0}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [containerCollapsedHeight, setContainerCollapsedHeight] = useState(null)
  const [containerExpandedHeight, setContainerExpandedHeight] = useState(null)
  const [containerStyles, setContainerStyles] = useState(null)
  const containerRef = useRef(null);

  const [childrenCount, setChildrenCount] = useState(children);

  function getHeightOfFirstElementOrLetter(el) {
    const firstChild = el.firstChild;

    if (firstChild.nodeType === Node.TEXT_NODE) {
      const lineHeight = getComputedStyle(el).lineHeight;
      const lineHeightValue = parseFloat(lineHeight);
      return lineHeightValue;
    } else {
      return firstChild.offsetHeight + 8; //margin:4px for each inline-block element
    }
  }

  const collapsedStyles = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: visibleLines,
  };

  useEffect(() => {
    const container = containerRef.current;
    setContainerCollapsedHeight(getHeightOfFirstElementOrLetter(container) * visibleLines);
    setContainerExpandedHeight(container.clientHeight);
    setContainerStyles(collapsedStyles);
    setChildrenCount(Children.count(children));
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (childrenCount !== Children.count(children)) {
      setChildrenCount(Children.count(children));
      if (!isExpanded) {
        setContainerStyles(null);
        setTimeout(() => {
          setContainerStyles(collapsedStyles)
        }, 500);
      } else {
        setContainerExpandedHeight(container.clientHeight);
      }
    }
  }, [children]);


  useEffect(() => {
    const container = containerRef.current;
    if (!containerStyles) {
      setContainerExpandedHeight(container.clientHeight);
    }
  }, [containerStyles]);


  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setContainerStyles(collapsedStyles)
      }, 500);
    } else {
      setContainerStyles(null)
    }
  }, [isExpanded]);

  return <div className={classes.collapsable}>
    <div className={classnames(classes.text, classes.collapsable_wrapper)}
         style={{maxHeight: isExpanded ? containerExpandedHeight : containerCollapsedHeight}}
    >
      <div className={classes.collapsable_inner} ref={containerRef}
           style={containerStyles}
      >
        {children}
      </div>
    </div>

    {containerExpandedHeight > containerCollapsedHeight &&
    <div
      className={classnames(classes.link, classes.collapsable_trigger)}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded ? trigger[1] : trigger[0]}
    </div>
    }
  </div>
}


const styled = injectSheet({
  tutor_header: {
    height: rem(64),
    borderRadius: [rem(2), rem(20), 0, 0],
    margin: [rem(-16), rem(-16), rem(40), rem(-16)],
    position: 'relative',
    '&.blue': {
      background: '#8bcbff',
      backgroundImage: `url(${require('../images/confetti.svg').default})`,
      backgroundSize: [rem(288), rem(64)],
      backgroundPosition: 'bottom center',
    },
    '&.green': {
      background: '#9BD060',
      backgroundImage: `url(${require('../images/bg_tutor_card_process.svg').default})`,
      backgroundSize: [rem(288), rem(64)],
      backgroundPosition: 'bottom center',
    },
    '&.orange': {
      background: '#FFB26B',
    },
    '&.yellow': {
      backgroundImage: `url(${require('../images/top_tutor.svg').default})`,
      backgroundSize: 'cover',
    },
  },
  avatar1: {
    width: rem(64),
    height: rem(64),
    position: 'absolute',
    top: rem(32),
    left: rem(16),
    borderRadius: '50%',
    border: [3, 'solid', chatTheme.connectUi.colors.avatarBorderColor],
  },
  body: {
    padding: [0, 0, rem(16), 0],
    position: 'relative',
  },
  title: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(18),
    lineHeight: rem(24),
    fontWeight: 800,
  },
  title1: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(16),
    lineHeight: rem(22),
    fontWeight: 800,
    paddingTop: rem(16),
    '& > svg': {
      width: rem(24),
      height: rem(24),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  title2: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(16),
    lineHeight: rem(22),
    fontWeight: 800,
    paddingTop: rem(16),
    '& > img': {
      width: rem(24),
      height: rem(24),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > span': {
      display: 'inline-block',
      width: `calc(100% - ${rem(40)})`,
      verticalAlign: 'middle',
    },
  },
  subject: {
    marginTop: rem(8),
    color: chatTheme.connectUi.colors.text,
    fontSize: rem(14),
    lineHeight: rem(20),
  },
  tutor_stat: {
    marginTop: rem(4),
    color: chatTheme.connectUi.colors.text,
    fontSize: rem(14),
    lineHeight: rem(20),
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      '& + span': {
        marginLeft: rem(4),
      },
      '&:first-child': {
        fontWeight: 700,
      },
    },
    '& > svg': {
      fill: '#F7B500',
      width: rem(12),
      height: rem(12),
      marginTop: rem(3),
      marginLeft: rem(4),
      display: 'inline-block',
      verticalAlign: 'middle',
      '& + span': {
        marginLeft: rem(2),
      },
    },
  },
  buttons: {
    marginTop: rem(24),
  },
  button: {
    width: `calc(50% - ${rem(4)})`,
    marginLeft: rem(4),
    marginRight: rem(4),
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&:only-child': {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  details: {
    marginTop: rem(24),
    '& $subtitle': {
      marginTop: 0,
    },
  },
  subtitle: {
    marginTop: rem(16),
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    '& + $text': {
      marginTop: rem(4),
    },
  },
  text: {
    marginTop: rem(8),
    color: chatTheme.connectUi.colors.text,
    fontSize: rem(14),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
    '& > span': {
      fontWeight: 800,
    },
    '&.bold': {
      fontSize: rem(18),
      fontWeight: 700,
      lineHeight: rem(28),
      color: chatTheme.connectUi.colors.title,
    }
  },
  divider: {
    backgroundImage: `url(${require('../images/infinity_progress_bar.svg').default})`,
    backgroundSize: [rem(288), rem(4)],
    backgroundPosition: 'bottom center',
    height: rem(4),
    marginTop: rem(16),
    position: 'relative',
    zIndex: 2,
  },
  link: {
    color: chatTheme.connectUi.colors.link,
    fontWeight: 800,
    cursor: 'pointer',
    '&:hover': {
      color: chatTheme.connectUi.colors.linkHover,
    },
  },
  link1: {
    fontWeight: 700,
    color: '#3D77FF'
  },
  search_title: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(18),
    lineHeight: rem(24),
    fontWeight: 800,
    padding: 0,
  },
  search_stat: {
    marginTop: rem(4),
    paddingLeft: rem(40),
    position: 'relative',
    '& img': {
      width: rem(16),
      height: rem(16),
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      display: 'block',
      borderRadius: '50%',
      border: [rem(1), 'solid', chatTheme.connectUi.colors.backgroundColor],
      '& + img': {
        left: rem(10),
        '& + img': {
          left: rem(20),
        },
      },
    },
    '& span': {
      color: chatTheme.connectUi.colors.text,
      fontSize: rem(12),
      lineHeight: rem(20),
      letterSpacing: rem(0.15),
      opacity: 0.8,
    }
  },
  live_session: {
    marginTop: rem(24),
    paddingRight: rem(60),
    position: 'relative',
  },
  scheduled_time: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 700,
  },
  scheduled_date: {
    marginTop: rem(4),
    color: chatTheme.connectUi.colors.text,
    fontSize: rem(14),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
    '& > span': {
      fontWeight: 800,
    },
  },
  scheduled_by: {
    width: rem(48),
    height: rem(48),
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '50%',
    display: 'block',
  },
  extratime: {
    marginTop: rem(24),
  },
  extratime_description: {
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 700,
  },
  cards: {
    marginTop: rem(24),
  },
  card: {
    '& + &': {
      marginTop: rem(16),
    },
    '& input[type=radio]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            borderColor: chatTheme.connectUi.colors.radioActive,
            borderWidth: rem(6),
          },
        },
      },
      '& + label': {
        paddingLeft: rem(36),
        color: chatTheme.connectUi.colors.text,
        fontSize: rem(14),
        lineHeight: rem(24),
        fontWeight: 700,
        display: 'inline-block',
        verticalAlign: 'top',
        position: 'relative',
        cursor: 'pointer',
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: rem(24),
          height: rem(24),
          border: [rem(2), 'solid', chatTheme.connectUi.colors.radioDefault],
          borderRadius: '50%',
          transition: 'border 300ms ease',
        },
        '& > svg': {
          width: rem(36),
          height: rem(24),
          marginRight: rem(8),
          verticalAlign: 'top',
          position: 'relative',
        },
        '& > span': {
          verticalAlign: 'top',
          position: 'relative',
        },
      },
    },
  },
  more: {
    marginTop: rem(4),
    color: chatTheme.connectUi.colors.link,
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    cursor: 'pointer',
  },
  rate_experience: {
    paddingLeft: rem(72),
    marginTop: rem(20),
    position: 'relative',
  },
  rated_experience: {
    marginTop: rem(22),
    textAlign: 'center',
    position: 'relative',
  },
  avatar2: {
    width: rem(64),
    height: rem(64),
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    '& > img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      display: 'block',
    }
  },
  bold: {
    fontWeight: 800
  },
  online: {
    background: theme.colors.online,
    width: rem(10),
    height: rem(10),
    position: 'absolute',
    top: rem(3),
    left: rem(50),
    borderRadius: '50%',
  },
  favorite: {
    width: rem(16),
    height: rem(16),
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'block',
  },
  stars: {
    height: rem(42),
    marginTop: rem(24),
    padding: [0, rem(3)],
    position: 'relative',
    [adaptive]: {
      width: `calc(100% + ${rem(10)})`,
      marginLeft: rem(-5),
    },
    '& > $star': {
      marginLeft: rem(10),
      [adaptive]: {
        marginLeft: rem(6),
      },
      '&:last-child': {
        marginLeft: 0,
      },
    },
    '&:not(:checked) > input': {
      display: 'none',
    },
    '&:not(:checked) > $star': {
      float: 'right',
    },
    '& > input:checked ~ $star, &:not(:checked) > $star:hover, &:not(:checked) > $star:hover ~ $star, & > input:checked + $star:hover, & > input:checked + $star:hover ~ $star, & > input:checked ~ $star:hover, & > input:checked ~ $star:hover ~ $star, & > $star:hover ~ input:checked ~ $star': {
      background: 'rgba(247, 181, 0, 0.3)',
      '& > svg': {
        fill: chatTheme.connectUi.colors.starHover,
      },
    },
  },
  star: {
    background: 'rgba(60, 120, 255, 0.3)',
    width: rem(42),
    height: rem(42),
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background 300ms ease',
    '&:only-of-type': {
      background: 'rgba(247, 181, 0, 0.3)',
      float: 'none',
      '& > svg': {
        fill: chatTheme.connectUi.colors.starHover,
      },
    },
    '& > svg': {
      width: rem(32),
      height: rem(32),
      margin: [0, 'auto'],
      display: 'block',
      transform: `translateX(${rem(1)})`,
      fill: chatTheme.connectUi.colors.starDefault,
      transition: 'fill 300ms ease',
    },
  },
  rate_key: {
    fontSize: rem(40),
    lineHeight: 1,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > input[type=radio]': {
      display: 'none',
    },
  },
  rate_value: {
    marginTop: rem(8),
    color: chatTheme.connectUi.colors.title,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 700,
  },
  countdown: {
    fontSize: rem(12),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
    color: chatTheme.connectUi.message.inTimeColor,
    opacity: 0.6,
  },
  collapsable: {},
  collapsable_wrapper: {
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-in-out',

    //overflow: 'hidden',
  },
  collapsable_inner: {
    /*    display: 'box',
        boxOrient: 'vertical',
        lineClamp: 2,*/
  },
  collapsable_trigger: {
    paddingTop: rem(8),
    fontSize: rem(14),
  },
  collapsable_action: {},
})


const Layout = {
  TutorHeader: styled(TutorHeader),
  SearchHeader: styled(SearchHeader),
  Body: styled(Body),
  Title: styled(Title),
  Title1: styled(Title1),
  Title2: styled(Title2),
  Subject: styled(Subject),
  TutorStat: styled(TutorStat),
  Buttons: styled(Buttons),
  Button: styled(Button),
  Subtitle: styled(Subtitle),
  Text: styled(Text),
  Details: styled(Details),
  Divider: styled(Divider),
  A: styled(A),
  B: styled(B),
  Action: styled(Action),
  LiveSession: styled(LiveSession),
  Extratime: styled(Extratime),
  Cards: styled(Cards),
  Card: styled(Card),
  More: styled(More),
  RateExperience: styled(RateExperience),
  RatedExperience: styled(RatedExperience),
  Avatar: styled(Avatar),
  Stars: styled(Stars),
  Star: styled(Star),
  RateKey: styled(RateKey),
  RateValue: styled(RateValue),
  TimeCountdown: styled(TimeCountdown),
  MessageStatus: styled(MessageStatus_),
  Collapsable: styled(Collapsable),
}

export default Layout
