import React from 'react'
import injectSheet from 'react-jss'

import theme, { rem, ui, adaptive } from '../../theme/base'

const styles = {
  root: {
    ...ui.text,
    paddingTop: rem(32),
    lineHeight: rem(24),
    fontFamily: theme.fontFamilies.Manrope,
    [adaptive]: {
      ...ui.subtitle[adaptive],
      paddingTop: rem(24),
      lineHeight: rem(24),
    },
  },
}

const Paragraph = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Paragraph)