import * as moment from 'moment'

export const printUntilDate = (deadline) => {
  const date =  moment()
    .startOf('hour')
    .add(deadline + 1, 'h')
    .format('MMM D, h:mm A')
  return deadline <= 23
    ? `In ${deadline} hours`
    : deadline >= 24 && deadline <= 36
      ? `In 1 day`
      : deadline >= 36 && deadline <= 72
        ? `In 2+ days`
        : `Until ${date}`
}
