import React from 'react'

const Checkbox = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9942 7.86919C18.5211 8.34519 18.5623 9.15822 18.0863 9.68513L11.2627 17.2386L6.63393 12.6686C6.12863 12.1697 6.12342 11.3557 6.6223 10.8504C7.12118 10.3451 7.93522 10.3399 8.44052 10.8387L11.1565 13.5202L16.1782 7.96137C16.6542 7.43446 17.4672 7.39319 17.9942 7.86919Z" fill="white"/>
      <rect x="1" y="1" width="22" height="22" rx="3" strokeWidth="2"/>
  </svg>

const Close = () => <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#EBEEF5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.34435 8.34315C7.75857 8.92893 7.75857 9.87868 8.34435 10.4645L11.8799 14L8.34435 17.5355C7.75857 18.1213 7.75857 19.0711 8.34435 19.6569C8.93014 20.2426 9.87989 20.2426 10.4657 19.6569L14.0012 16.1213L17.5367 19.6569C18.1225 20.2426 19.0723 20.2426 19.6581 19.6569C20.2438 19.0711 20.2438 18.1213 19.6581 17.5355L16.1225 14L19.6581 10.4645C20.2438 9.87868 20.2438 8.92893 19.6581 8.34315C19.0723 7.75736 18.1225 7.75736 17.5367 8.34315L14.0012 11.8787L10.4657 8.34315C9.87989 7.75736 8.93014 7.75736 8.34435 8.34315Z" fill="#3D77FF"/>
</svg>

const Alert = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1066 7.70616L11.8571 7.52841L12.6076 7.70616C14.043 8.04612 15.099 9.32962 15.099 10.8571V14.8772C15.099 15.163 15.1398 15.4447 15.2182 15.7143H8.4961C8.57445 15.4447 8.61524 15.163 8.61524 14.8772V10.8571C8.61524 9.32962 9.67126 8.04612 11.1066 7.70616ZM17.6205 15.8129L17.5219 15.7143L17.3919 15.5843C17.2044 15.3968 17.099 15.1424 17.099 14.8772V10.8571C17.099 8.38095 15.3848 6.30857 13.0686 5.76V5.21143C13.0686 4.54095 12.5276 4 11.8571 4C11.1867 4 10.6457 4.54095 10.6457 5.21143V5.76C8.32952 6.30857 6.61524 8.38095 6.61524 10.8571V14.8772C6.61524 15.1424 6.50988 15.3968 6.32234 15.5843L6.19238 15.7143L6.09376 15.8129L5.57107 16.3356L5.16726 16.7394C5.06017 16.8465 5 16.9918 5 17.1432C5 17.4586 5.25568 17.7143 5.57107 17.7143H6.14215H6.88092H7.02081H16.6935H16.8334H17.5721H18.1432C18.4586 17.7143 18.7143 17.4586 18.7143 17.1432C18.7143 16.9918 18.6541 16.8465 18.547 16.7394L18.1432 16.3356L17.6205 15.8129ZM13.3008 18.5C13.3008 19.3284 12.6292 20 11.8008 20C10.9724 20 10.3008 19.3284 10.3008 18.5H13.3008Z" fill="#3D77FF"/>
</svg>

const Icon = {
  Checkbox, Close, Alert
}

export default Icon
