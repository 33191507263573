import React from 'react'
import {createUseStyles} from 'react-jss'
import {Modal, Close} from './../../modals'
import {Header} from './../../ui'
import {
  useProfileQuery,
  useOrderQuery,
  createAddTopTutorAddonAndPayMutation
} from '../../hooks'
import {getIsTopTutorAddonActive} from '../../nerdytutors'
import SelectPaymentMethodAndPay from '../SelectPaymentMethodAndPay'

const ADDON_PRICE = 995

const BuyTopTutorAddon = ({orderId, placedThrough, onClose, zIndex}) => {
  const classes = useStyle()
  const order = useOrderQuery(orderId).data
  const isAddonActive = getIsTopTutorAddonActive(order)

  const usePayment = createAddTopTutorAddonAndPayMutation({orderId, isAddonActive, placedThrough})

  const {balance} = useProfileQuery().data
  const [price, total] = [ADDON_PRICE, Math.max(0, ADDON_PRICE - balance)]


  return <Modal className={classes.root} zIndex={zIndex} mobileStickToBottom>
    <Close onClick={onClose}/>
    <Header>Get a TOP tutor</Header>
    <img className={classes.promoImg} src={require(`./img/tutors.png`).default}
         srcSet={`
            ${require(`./img/tutors@3x.png`).default} 3x,
            ${require(`./img/tutors@2x.png`).default} 2x,
            ${require(`./img/tutors.png`).default} 1x
          `}
         alt=''
    />

    <div className={classes.message}>With this addition, you will get matched with one of our TOP
      experts <strong>in {order.subject}.</strong></div>

    <SelectPaymentMethodAndPay
      isActive={!isAddonActive}
      price={price}
      total={total}
      balance={balance}
      usePayment={usePayment}
    />
  </Modal>
}

const useStyle = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  promoImg: {
    display: 'block',
    width: 112,
    height: 68,
    marginTop: [24]
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    padding: [0, 16, 24],
    borderBottom: '1px solid #EBEEF5',
  },
  paymentBlock: {
    width: '100%',
    padding: [24, 18]
  },
  paymentTitle: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '22px',
  },
  paymentSubBlock: {
    paddingLeft: 16
  },
  priceBlock: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#939FB8',
    marginTop: 16,
    '& span:last-child': {
      color: '#2F333D',
    },
  },
  balanceBlock: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
  },
  cards: {},
  button: {
    marginTop: 'auto',
    justifyContent: 'center',
    padding: '0 24px!important',
    fontWeight: 700,
    fontSize: 18,
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '&:before': {
      content: '""',
      display: 'block',
      height: 40,
      width: 0,
      borderLeft: '1px solid rgba(255, 255, 255, .2)',
      paddingRight: 16,
    },
  },
  resultBlock: {
    flex: 1,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultImg: {
    display: 'block',
    width: 36,
    height: 36,
  },
  resultTitle: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#2F333D',
    marginTop: 12,
  },
  resultMessage: {
    margin: [12, 0, 24],
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '.15px',
    color: '#2F333D',
  },
  tryButton: {
    width: 240,
  }
})

export default BuyTopTutorAddon
