import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    minHeight: rem(22),
    fontSize: rem(16),
    lineHeight: rem(22),
    fontWeight: 800,
    fontFamily: theme.fontFamilies.Manrope,
  },
}

const Title = ({
  classes,
  children,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Title)