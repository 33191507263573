import {useLocation} from 'react-router-dom'
import {useEffect, useRef} from 'react'

const useChangedLocation = onChange => {
  const {pathname} = useLocation()
  const ref = useRef(pathname)

  useEffect(() => {
    if (ref.current !== pathname) onChange()
  }, [pathname, onChange])
}

export default useChangedLocation