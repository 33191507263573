import React from 'react'
import injectSheet from 'react-jss'
import { rem, justified } from '../../../../mtuikit/theme/base'

const styles = {
  root: {
    ...justified,
    marginTop: rem(32),
    position: 'relative',
    '& > *': {
      width: `calc(50% - ${rem(4)})`,
      '&:only-child': {
        width: '100%',
      },
    },
  },
}

export const Actions = ({
  classes,
  children,
}) => 
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Actions)