import React from 'react'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import Layout from './layout'
import {useTutorQuery, useOrderQuery} from '../../../../hooks'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'
import {Link} from 'react-router-dom'
import {useActions} from '../../../../actions'
import {trackValues} from '../../../../lrtracker'
import {getIsTopTutorAddonActive} from "../../../../nerdytutors";

const OrderConnectedEvent = ({freelancer_id, order_id, ...props}) => {
  const actions = useActions()
  const tutorQuery = useTutorQuery(freelancer_id)
  const orderQuery = useOrderQuery(order_id)

  if (!(tutorQuery.isFetched && orderQuery.isFetched)) return <div style={{height: 36}}/>

  const order = orderQuery.data
  const tutor = tutorQuery.data

  const isTutorRejected = !(
    order.connected?.freelancer_id === freelancer_id ||
    order.assigned?.freelancer_id === freelancer_id
  )

  const isTopTutor = getIsTopTutorAddonActive(order)

  const isOrderAssigned = order.assigned?.freelancer_id === freelancer_id

  const color = isOrderAssigned
    ? isTopTutor ? 'yellow' : 'green'
    : isTopTutor ? 'yellow' : 'blue'

  const status = isOrderAssigned
    ? 'is your tutor'
    : 'connected!'

  const rejectTutor = () => actions.showRejectTutorPrompt({tutorId: freelancer_id, orderId: order_id})

  const trackClick = () =>
    trackValues('chatClientTutor', 'clientTutorViewProfileBtnClick', {context: {id: order_id, type: 'Card'}})

  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.TutorHeader color={color} avatar={getUserAvatarUrl(freelancer_id)}/>
      <Layout.Body>
        <Layout.Title>{tutor.name} {status}</Layout.Title>
        <Layout.Subject>{order.subject}</Layout.Subject>
        <Layout.TutorStat score={tutor.avg_score} helped={tutor.orders_count}/>
        {
          !isTutorRejected && !isOrderAssigned && <Layout.Buttons>
            {
              !isOrderAssigned &&
              <Layout.Button type='outline' onClick={rejectTutor}>Reject</Layout.Button>
            }
            <Layout.Button onClick={trackClick} container={<Link to={`/orders/${order_id}/tutor`}/>}>
              View profile
            </Layout.Button>
          </Layout.Buttons>
        }
        {
          isOrderAssigned && <Layout.Buttons>
            <Layout.Button onClick={trackClick} container={<Link to={`/orders/${order_id}/tutor`}/>}>
              View profile
            </Layout.Button>
          </Layout.Buttons>
        }
      </Layout.Body>
      <Card.Footer  {...props}/>
    </Card>
  </MessageWrapper>
}

export default OrderConnectedEvent
