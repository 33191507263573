import React from 'react'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import Layout from './layout'

const OnboardRequestSupport = ({data, end_time, ...props}) => {
  const actions = useActions()

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{
      ...data,
      message: <>
        If you have any questions just message our <Layout.Action onClick={() => actions.showSupportChat()}>support
        team</Layout.Action>. We will be very happy to help you!</>
    }}/>
  </MessageWrapper>
}

export default OnboardRequestSupport