import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../theme/base'

const styles = {
  root: {
    padding: [rem(19), rem(56), rem(15)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    textAlign: 'center',
    '& span': {
      color: theme.colors.slateGrey,
    },
    '& img': {
      background: '#BAC7E2',
      width: rem(24),
      height: rem(24),
      marginRight: rem(8),
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
      transform: `translateY(${rem(-4)})`,
    },
  },
}

const Title = ({
  classes,
  children
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Title)
