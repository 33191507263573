import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import {useSupportChatContext} from '../SupportChat'

const SupportChatRedirect = () => {
  const supportChat = useSupportChatContext()

  useEffect(() => {
    supportChat.open()
  }, [])

  return <Redirect to={{pathname: '/orders'}}/>
}

export default SupportChatRedirect