import React from 'react'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import {useOrderQuery, useProfileQuery} from '../../../../hooks'
import Layout from './layout'
import {utils} from 'mtuikit'
import {getRandomTutors} from '../../../../config'

const avatars = getRandomTutors(3).map(({image}) => image)
const randomNumber = utils.getRandom(10, 20, 1)

const OrderOfferedEvent = ({order_id, ...props}) => {
  const actions = useActions()
  const profileQuery = useProfileQuery()
  const orderQuery = useOrderQuery(order_id)

  if (!(orderQuery.isFetched && profileQuery.isFetched)) return <div style={{height: 500}}/>

  const tutorFound = orderQuery.data?.assigned !== null
  const isPreferredTutor = orderQuery.data?.recommended !== null
  
  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.SearchHeader
        title={isPreferredTutor
          ? 'Connecting you to the tutor…'
          : 'Looking for a tutor…'
        }
        description={isPreferredTutor
          ? ''
          : `${randomNumber} tutors have reviewed your task`
        }
        avatars={isPreferredTutor ? [] : avatars}
      />
      {!tutorFound && <Layout.Divider/>}
      <Layout.Body>
        <Layout.Subtitle>
          {isPreferredTutor
            ? 'How will I know when the tutor joins this chat?'
            : 'How will I know when the tutor is found?'
          }
        </Layout.Subtitle>
        <Layout.Text>
          {isPreferredTutor
            ? <>
              We’ll text you at <Layout.A
              href={`tel:${profileQuery.data.phone}`}>{profileQuery.data.phone}</Layout.A> as
              soon as your tutor accepts this task!
            </>
            : <>
              We’ll text you at <Layout.A
              href={`tel:${profileQuery.data.phone}`}>{profileQuery.data.phone}</Layout.A> as
              soon as we find a best-match tutor to help you out! Your tutor will join this chat right away.
            </>
          }
        </Layout.Text>
        <Layout.Subtitle>How to speed things up?</Layout.Subtitle>
        <Layout.Text>
          {isPreferredTutor
            ? <>
              Add extra task details. Your tutor is more likely to accept your task request if you send over your task
              details ahead of time.
            </>
            : <>
              Add extra task details. Tutors are more likely to accept your task request if they know what you need help
              with ahead of time.
            </>
          }
        </Layout.Text>
        <Layout.Text>
          Ask our <Layout.Action onClick={actions.showSupportChat}>Support Manager</Layout.Action> to review your task
          details and make sure that your tutor has everything needed to get started.
        </Layout.Text>
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

export default OrderOfferedEvent