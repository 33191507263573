const SUPPORT = require('./../images/support@3x.png').default

let AVATAR_CDN = ''

export const configUserAvatarUrl = cdn => {
  AVATAR_CDN = cdn
}

export const getUserAvatarUrl = (userId) => {
  if (AVATAR_CDN) {
    return userId && userId.indexOf('@') > -1
      ? SUPPORT
      : `${AVATAR_CDN}/${userId}`
  } else {
    throw Error('Config CDN url before user getUserAvatarUrl')
  }
}
