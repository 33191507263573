import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import { Icon } from 'mtuikit'
import theme, { rem, desktop } from './../../../theme/base'

const styles = {
  '@global': {
    '@keyframes sk-bounce': {
      '0%': {
        transform: 'scale(0)',
      },
      '50%': {
        transform: 'scale(1)',
      },
      '100%': {
        transform: 'scale(0)',
      },
    },
  },
  root: {
    width: rem(36),
    height: rem(36),
    position: 'absolute',
    top: rem(16),
    right: rem(16),
    zIndex: 2,
    [desktop]: {
      top: rem(10),
      right: rem(176),
    },
  },
  support: {
    width: 'inherit',
    height: 'inherit',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  nmessages: {
    '&:before': {
      content: "''",
      background: theme.colors.blue,
      opacity: 0.27,
      width: rem(50),
      height: rem(50),
      position: 'absolute',
      left: rem(-7),
      top: rem(-7),
      display: 'block',
      borderRadius: '50%',
      animation: 'sk-bounce 2.0s infinite ease-in-out',
      zIndex: -1,
    },
  },
  message_counter: {
    background: theme.colors.blue,
    padding: [rem(9), 0, rem(7)],
    borderRadius: '50%',
    width: 'inherit',
    height: 'inherit',
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(17),
    lineHeight: rem(20),
    fontWeight: 'bold',
    color: theme.colors.white,
    textAlign: 'center',
    zIndex: 2,
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.white,
    },
  },
}

const SupportIcon = ({
  classes,
  className,
  nmessages,
  onClick,
  innnerRef
}) =>
  <div
    className={classnames(classes.root, className, { nmessages })}
    ref={innnerRef}
  >
    {nmessages
      ? <div className={classes.message_counter} onClick={onClick}>
        <span>{nmessages}</span>
      </div>
      : <div className={classes.support} onClick={onClick}>
        <Icon iconName='support' />
      </div>}
  </div>

export default injectSheet(styles)(SupportIcon)
