import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import {modals} from './modals'
import {S3} from './config'
import {save, load} from 'redux-localstorage-simple'

const composeEnhancers =
  process.env.ENV_NAME === undefined &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const configReducer = (state = {s3config: S3}) => state

const authReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return action.payload
    default:
      return state
  }
}

const reducer = combineReducers({
  config: configReducer,
  nodes: null,
  xmpp: null,
  presence: null,
  modals: modals.reducer,
  auth: authReducer
})

const logger = () => (next) => (action) => {
  const result = next(action)
  return result
}

const storeSaveConf = {
  states: ['auth'],
  namespace: '_',
  debounce: 500,
}


const store = createStore(
  reducer,
  load(storeSaveConf),
  composeEnhancers(
    applyMiddleware(save(storeSaveConf), logger)
  )
)

export default store
