import React, {useState, useEffect} from 'react'
import injectSheet from 'react-jss'
import {Link, useHistory} from "react-router-dom"
import classnames from 'classnames'
import {Popover} from 'react-tiny-popover'
import theme, {rem, media, adaptive, desktop} from 'theme/base'
import Field from './Field'
import {regexps} from '../../utils/regexps'
import getProfileAvatarByEmail from '../../utils/getProfileAvatarByEmail'
import {Button, Icon, Message, utils} from 'mtuikit'
import ToggleSwitch from '../../ui/ToggleSwitch'
import Checkbox from '../../ui/Checkbox'
import Popup from '../OrderFormV5/layout/Popup'
import {
  useProfileQuery,
  useUpdateProfileMutation,
  useUpdateProfileNotificationMutation,
  useExplainedPushNotifications
} from '../../hooks'

const styles = {
  root: {
    background: theme.colors.white,
  },
  wrapper: {
    [adaptive]: {
      paddingTop: 24,
      paddingBottom: 70,
    },
    [desktop]: {
      paddingTop: 40,
      paddingBottom: 140,
    }
  },
  content: {
    margin: '0 auto',
    [adaptive]: {
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
    [desktop]: {
      width: rem(416),
    }
  },

  header: {
    padding: 16,
    boxShadow: 'inset 0px -1px 0px #EBEEF5',
  },
  backLink: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
  },
  backIcon: {
    display: 'block',
    width: 24,
    height: 24,
  },
  backButtonMessage: {
    fontWeight: 800,
    fontsize: 14,
    lineheight: '20px',
    color: '#2F333D',
  },


  avatar: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '& .icon': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      bottom: rem(-6),
      right: 0,
      cursor: 'pointer',
    },
    '& svg, & img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  submit: {
    marginTop: 24,
  },

  title: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '22px',
    color: '#2F333D',
    marginTop: 32,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#565D6B',
    marginTop: 8,
  },
  section: {
    marginTop: 24,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#2F333D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 'auto',
  },
  checkboxList: {
    marginTop: 20,
  },
  checkboxItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    '&:not(:first-child)': {
      marginTop: 12,
    }
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.15,
    color: '#2F333D',
    '&.disabled': {
      color: '#BAC7E2',
    }
  },

  tooltip: {
    background: '#FFF',
    boxShadow: '0px 6px 9px rgba(0, 38, 96, 0.06), 0px 6px 30px rgba(0, 53, 136, 0.15)',
    borderRadius: 6,
    width: 278,
    padding: 9,
    fontSize: 12,
    lineHeight: '16px',
    color: '#2F333D',
    margin: 6,
  },
  iconWrapper: {
    marginLeft: 6,
  },
  tooltipIcon: {
    display: 'block',
    width: 12,
    height: 12,
    color: '#939FB8',
    '&:hover': {
      color: '#3D77FF',
    },
  },
  tooltipLink: {
    fontWeight: 700,
    color: '#3D77FF',
    cursor: 'pointer',
  },
  confirmPopup: {
    [desktop]: {
      position: 'fixed',
      left: '50%',
      top: '50%',
      margin: [-79, 0, 0, -160],
    },
  }
}

const checkboxes = {
  SMS: {
    label: 'SMS notifications',
    name: 'optout_sms',
    checkboxes: [
      {label: 'New messages', name: 'optin_sms_messaging'},
      {label: 'Task updates', name: 'optin_sms_updates'},
      {label: 'Marketing updates', name: 'optin_sms_marketing'},
    ],
  },
  Email: {
    label: 'Email notifications',
    name: 'optout_email',
    checkboxes: [
      {label: 'New messages', name: 'optin_email_messaging'},
      {label: 'Task updates', name: 'optin_email_updates'},
      {label: 'Marketing updates', name: 'optin_email_marketing'},
    ],
  },
  Browser: {
    label: 'Browser notifications',
    name: 'optout_notifs',
    checkboxes: [
      {label: 'New messages', name: 'optin_browser_messaging'},
      {label: 'Task updates', name: 'optin_browser_updates'},
      {label: 'Marketing updates', name: 'optin_browser_marketing'},
    ],
  },
}

const ProfileSettings = ({classes}) => {
  const profileQuery = useProfileQuery()
  const updateProfileMutation = useUpdateProfileMutation()
  const updateProfileNotificationMutation = useUpdateProfileNotificationMutation()

  const [name, setName] = useState(profileQuery.data.name)
  const [email, setEmail] = useState(profileQuery.data.email)
  const [emailError, setEmailError] = useState('')
  //const [phone, setPhone] = useState(profileQuery.data.phone)
  //const [phoneError, setPhoneError] = useState('')

  const [showResult, setShowResult] = useState(false)

  const [showPopup, setShowPopup] = useState(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const namePrevious = profileQuery?.data?.name
  const emailPrevious = profileQuery?.data?.email
  //const phonePrevious = profileQuery?.data?.phone

  const notifications = profileQuery?.data?.notifications

  const history = useHistory()

  const setNotification = (name, value) => {
    updateProfileNotificationMutation.mutate({notifications: {[name]: value}})
  }

  const {isReady, isError, isPending, isDenied, subscribe} = useExplainedPushNotifications({
    title: 'Your browser will now tell you that NerdyTutors want to show you notifications.',
    text: 'Allow them to get notified about new messages.'
  })

  useEffect(() => {
    if (!isReady) return
    if (isError || isDenied || isPending) setNotification("optout_notifs", true)
  }, [isReady, isError, isPending, isDenied])


  useEffect(() => {
    if (!regexps.email(email.toLowerCase())) {
      setEmailError('Oops, check your email format')
    } else {
      setEmailError('')
    }
  }, [email])


  useEffect(() => {
    let t
    if (showResult) {
      t = setTimeout(() => {
        setShowResult(false)
      }, 2000)
    }
    return () => clearTimeout(t)
  }, [showResult])


  const onSubmit = () => {
    updateProfileMutation.mutate({name, email}, {
      onError: (error) => {
        console.log("error:",error);
        if (error.details === 'User exists, please try to Log in') {
          setEmailError('This email belongs to another account')
        } else {
          setEmailError('Something went wrong')
        }
      },
      onSuccess: () => {
        setEmailError('');
        setShowResult(true);
      }
    })
  }


  const isSubmitDisabled = (
    emailError
    || updateProfileMutation.status === 'loading'
  )

  const isMobile = utils.useMobile(media.desktop)

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <button className={classes.backLink} onClick={() => {
          history.goBack()
        }}>
          <img className={classes.backIcon} src={require('./ic-back.svg').default} alt=""/>
          <div className={classes.backButtonMessage}>Profile settings</div>
        </button>
      </header>

      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div>
            <div className={classes.avatar}>
              <img src={getProfileAvatarByEmail(profileQuery.data.email)} alt=''/>
              <div className='icon'>
                <Icon iconName='add-photo'/>
              </div>
            </div>
            <Field
              name='name'
              type='text'
              value={name}
              placeholder='Your name'
              changed={name !== namePrevious ? 1 : undefined}
              onChange={value => setName(value)}
              onClear={_  => setName(namePrevious)}
            />
          </div>
          <Field
            name='phone'
            type='tel'
            value={profileQuery.data.phone}
            placeholder='Phone number'
            //changed={phone !== phonePrevious}
            //onChange={value => setPhone(value)}
            //onClear={_ => setPhone(phonePrevious)}
            //error={phoneError}
            disabled
          />
          <Field
            name='email'
            type='email'
            value={email}
            placeholder='Email address'
            changed={email !== emailPrevious ? 1 : undefined}
            onChange={value => setEmail(value)}
            onClear={_ => setEmail(emailPrevious)}
            error={emailError}
          />

          {!(name === namePrevious && email === emailPrevious) &&
          <div className={classes.submit}>
            <Button
                block
                size='large'
                onClick={onSubmit}
                disabled={isSubmitDisabled}
            >
              Save
            </Button>
          </div>
          }
          {
            showResult &&
            <Message.Success>Profile updated</Message.Success>
          }


          <div className={classes.title}>Notification center</div>
          <div className={classes.subtitle}>How do you prefer to receive notifications?</div>


          <div>
            {Object.entries(checkboxes).map(([groupKey, group]) => (


              <div className={classes.section} key={groupKey}>

                {!(groupKey === 'Browser' && isError) &&
                <>
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionTitle}>
                      {group.label}

                      {groupKey === 'Browser' && isDenied &&
                      <Popover
                        isOpen={isPopoverOpen}
                        onClickOutside={() => setIsPopoverOpen(false)}
                        positions={isMobile ? ['top'] : ['right'] }
                        content={<div className={classes.tooltip}>
                          Browser notifications were denied.<br/>
                          You can modify this in your browser settings.<br/>
                          <Link className={classes.tooltipLink} to={'/browser-instructions'}>Learn how to
                            fix</Link>
                        </div>}
                      >
                        <div className={classes.iconWrapper}
                             onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                          <svg className={classes.tooltipIcon} width="12" height="12" viewBox="0 0 12 12"
                               fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6Z"
                              fill="currentColor"/>
                            <path
                              d="M6 4.14587C6.35449 4.14587 6.62842 3.87598 6.62842 3.5376C6.62842 3.19519 6.35449 2.92932 6 2.92932C5.64551 2.92932 5.37158 3.19519 5.37158 3.5376C5.37158 3.87598 5.64551 4.14587 6 4.14587ZM6 9.08459C6.36255 9.08459 6.58813 8.85498 6.58813 8.47229V5.20935C6.58813 4.82666 6.36255 4.59302 6 4.59302C5.63342 4.59302 5.41187 4.82666 5.41187 5.20935V8.47229C5.41187 8.85498 5.63342 9.08459 6 9.08459Z"
                              fill="currentColor"/>
                          </svg>
                        </div>
                      </Popover>
                      }

                    </div>

                    <ToggleSwitch
                      checked={!notifications[group.name]}
                      disabled={groupKey === 'Browser' && isDenied}
                      onChange={() => {
                        if (groupKey === 'Browser' && isPending) {
                          subscribe().then(({permission = false}) => {
                            if (permission === 'granted') {
                              setNotification(group.name, !notifications[group.name])
                            }
                          })
                        } else if (!notifications[group.name]) {
                          setShowPopup(groupKey)
                        } else {
                          setNotification(group.name, !notifications[group.name])
                        }
                      }}
                    />
                  </div>

                  {showPopup === groupKey &&
                  <Popup
                    title='Are you sure?'
                    text='You may miss critical updates related to your task if you turn off notifications.'
                    onCancel={() => setShowPopup(false)}
                    primaryText='Stay on'
                    onPrimary={() => setShowPopup(false)}
                    secondaryText='Turn off'
                    onSecondary={() => {
                      setNotification(group.name, !notifications[group.name])
                      setShowPopup(false)
                    }}
                    layout={'desktop'}
                    className={classes.confirmPopup}
                  />
                  }

                  {group.checkboxes.map((checkbox, checkboxIndex) => (
                    <div className={classes.checkboxItem} key={checkbox.name}>
                      <Checkbox
                        disabled={notifications[group.name]}
                        checked={notifications[checkbox.name]}
                        onChange={() => setNotification(checkbox.name, !notifications[checkbox.name])}
                      />
                      <div
                        className={classnames(classes.checkboxLabel, {disabled: notifications[group.name]})}>{checkbox.label}</div>
                    </div>
                  ))}
                </>
                }
              </div>
            ))}
          </div>


        </div>
      </div>

    </div>
  )
}

export default injectSheet(styles)(ProfileSettings)
