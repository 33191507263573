import React, {useRef} from 'react'
import {createUseStyles} from 'react-jss'
import theme, {rem, desktop, adaptive} from './../../theme/base'
import Actions from './Actions'
import useOutsideClick from '../../utils/useOutsideClick'
import useChangedLocation from '../../utils/useChangedLocation'

export const ContextMenu = ({
                              actions,
                              onClose,
                              zIndex,
                            }) => {
  const classes = useStyles()
  const ref = useRef()

  useOutsideClick(ref, onClose)
  useChangedLocation(onClose)
  return (
    <div className={classes.root} style={{zIndex}} ref={ref}>
      <div className={classes.wrapper}>
        <div className={classes.menu}>
          <Actions actions={actions} onClose={onClose}/>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    transition: 'top 300ms ease-in-out',
    [desktop]: {
      width: rem(280),
      top: rem(106),
      right: rem(16),
      left: 'auto',
    },
    [adaptive]: {
      top: rem(50),
      right: 0,
    },
  },
  wrapper: {
    [desktop]: {
      width: '100%',
      maxWidth: rem(343),
    },
  },
  menu: {
    background: theme.colors.white,
    position: 'absolute',
    borderRadius: rem(16),
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'],
      [0, rem(8), rem(40), 'rgba(0, 53, 136, 0.15)'],
    ],
    zIndex: 'inherit',
    [desktop]: {
      position: 'relative',
      padding: [rem(12), 0],
    },
    [adaptive]: {
      width: rem(250),
      padding: [rem(8), 0],
      top: 0,
      left: 'auto',
      right: rem(13),
    },
  },
})