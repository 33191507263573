import React from 'react'
import {createUseStyles} from 'react-jss'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useProfileQuery} from './../../../../hooks'
import {useActions} from './../../../../actions'
import Layout from './layout'

const SupportRecapMessage = ({is_preferred_tutor, data, ...props}) => {
  const classes = useStyles()
  const profileQuery = useProfileQuery()
  const actions = useActions()

  const editPhone = () => {
    actions.hideSupportChat()
    actions.showEditPhone({editedThrough: 'ChatClientTutor'})
  }

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{
      ...data,
      message: !is_preferred_tutor
        ? <>
          <Layout.B>What's Next:</Layout.B><br/><br/>
          1. It typically takes 15 minutes to several hours for the best tutor to be found and join the chat.<br/><br/>
          2. You'll receive an SMS notification at
          {' '}<span className={classes.phoneNumber}>{profileQuery.data.phone}</span>{' '}
          <span className={classes.edit} onClick={editPhone}>Edit</span>{' '}
          when the tutor joins the chat or has questions about the instructions.<br/><br/>
          3. Please respond promptly so they can start working on your task.<br/><br/>
          If you have any questions, don't hesitate to contact me here!<br/>
        </>
        : <>
          <Layout.B>What's Next:</Layout.B><br/>
          1. You'll receive an SMS notification at
          {' '}<span className={classes.phoneNumber}>{profileQuery.data.phone}</span>{' '}
          <span className={classes.edit} onClick={editPhone}>Edit</span>{' '}
          when the tutor joins the chat or has questions about the instructions.<br/><br/>
          2. Please respond promptly so they can start working on your task.<br/><br/>
          If you have any questions, don't hesitate to contact me here!<br/>
        </>
    }}/>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  phoneNumber: {
    fontWeight: 800,
  },
  edit: {
    fontWeight: 700,
    color: '#3D77FF',
  },
  strong: {
    fontWeight: 700,
  },
})

export default SupportRecapMessage
