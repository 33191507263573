import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { rem, desktop, ANDROID_HELPER_ID } from './../../theme/base'

const styles = {
  root: {
    position: 'absolute',
    top: rem(59),
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    [desktop]: {
      maxHeight: '80vh',
      top: 'auto',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      position: 'relative',
    },
  },
}

const Container = ({
  children,
  classes,
  className,
}) =>
  <div
    id={ANDROID_HELPER_ID}
    className={classnames(classes.root, className)}
  >
    {children}
  </div>

export default injectSheet(styles)(Container)