import React from 'react'
import ContentEditable from 'react-contenteditable'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    width: '100%',
    padding: rem(24),
    border: [rem(1), 'solid', '#D6E3FF'],
    fontFamily: 'arial',
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(22),
    letterSpacing: rem(0.1),
    borderRadius: rem(8),    
    position: 'relative',
    overflowY: 'auto',
    outline: 'none',
    transition: 'border 300ms ease',
    '&:focus': {
      borderColor: theme.colors.blue50,
    },
    '& span': {
      background: '#DFE6F5',
      color: theme.colors.mediumBlue,
    },
  }
}

const Editable = ({
  classes,
  className,
  ...otherProps
}) => 
  <ContentEditable
    {...otherProps}
    className={classnames(classes.root, className)}
  />

export default injectSheet(styles)(Editable)