import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'
import getProfileAvatarByEmail from '../../../../utils/getProfileAvatarByEmail'

const styles = {
  root: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      borderRadius: 'inherit',
    },
  },
}

const Avatar = ({
  classes,
  email,
}) =>
  <div className={classes.root}>
    <img src={getProfileAvatarByEmail(email)} alt='' />
  </div>    

export default injectSheet(styles)(Avatar)