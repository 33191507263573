const nanoid = (t = 21) => {
  let e = "", r = crypto.getRandomValues(new Uint8Array(t));
  for (; t--;) {
    let n = 63 & r[t];
    e += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? "_" : "-"
  }
  return e
};

const cropUnsafeSymbols = (string) => {
  // eslint-disable-next-line
  const regexp = /[^0-9a-zA-Z!\-_.*'()\ ]/g
  return string.replace(regexp, '').replace(/ +/g, ' ')
}

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent)
    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent)
    }
    return recursiveDecodeURIComponent(decodedURIComponent)
  } catch (e) {
    return cropUnsafeSymbols(uriComponent)
  }
}

export const getAwsFileKey = (fileName) => nanoid(3) + '_' + recursiveDecodeURIComponent(fileName)