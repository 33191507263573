import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useLocation} from 'react-router-dom'
import {regexps} from './../../utils/regexps'
import {compact} from 'lodash/array'
import theme, {rem, desktop} from '../../theme/base'
import {Icon} from 'mtuikit'
import DesktopWrapperHOC2 from './../DesktopWrapperHOC2'
import {Wrapper, Error, Form, Field, Submit} from './Layout'
import {StateLink} from './Layout/StateLink'
import {useBodyBackgroundColor, useRestorePasswordMutation} from './../../hooks'

const useStyles = createUseStyles({
  root: {
    minHeight: '100vh',
    paddingTop: 0,
    paddingBottom: rem(100),
    borderRadius: 0,
    [desktop]: {
      minHeight: 0,
      borderRadius: rem(28),
    },
  },
  header: {
    padding: [rem(12), 0, rem(10)],
    fontSize: rem(17),
    lineHeight: rem(22),
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 800,
    textAlign: 'center',
    position: 'relative',
  },
  back: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    left: rem(-8),
    top: rem(8),
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      pointerEvents: 'none',
      opacity: 0.7,
    },
  },
  icon: {
    width: '100%',
    maxWidth: rem(327),
    margin: [rem(24), 'auto', 0],
    display: 'block',
  },
  text: {
    marginTop: rem(24),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    textAlign: 'center',
  },
  submit: {
    marginTop: rem(24),
    padding: 0,
    border: 'none !important',
    '& a': {
      color: 'inherit',
      lineHeight: rem(56),
      display: 'block',
    },
  },
  helper: {
    paddingTop: rem(16),
  },
  bottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: rem(70),
    textAlign: 'center',
    [desktop]: {
      bottom: rem(24),
    },
    '& a': {
      color: theme.colors.blue,
    },
  },
})


const Restore = () => {
  useBodyBackgroundColor(theme.colors.blue)
  const classes = useStyles()
  const location = useLocation()
  const restorePasswordMutation = useRestorePasswordMutation()

  const [email, setEmail] = useState((location.state && location.state.email) || '')
  const [submitted, setSubmitted] = useState(false)

  const cleanedEmail = email.trim().toLowerCase()
  const validEmail = regexps.email(cleanedEmail)
  const valid = validEmail
  const errorEmail = submitted && !validEmail && 'Oops, check your email format.'
  const disabled = (restorePasswordMutation.status === 'loading') || (submitted && !valid)

  const errors = []
  errors.push(errorEmail)
  errors.push(restorePasswordMutation.error?.message)
  const error = compact(errors).length > 0 ? compact(errors)[0] : false

  const onSubmit = () => {
    setSubmitted(true)
    if (valid) {
      restorePasswordMutation.mutate({email})
    }
  }

  return (
    <DesktopWrapperHOC2>
      <Wrapper className={classes.root}>
        <div className={classes.header}>
          <StateLink className={classes.back} to='/login' state={{email}}>
            <Icon iconName='arrow-back'/>
          </StateLink>
          Restore password
        </div>
        {restorePasswordMutation.isSuccess
          ? <>
              <img className={classes.icon} src={require('./restored.svg').default} alt=''/>
              <div className={classes.text}>
                New password was sent.
                <br/>
                Please check your email
              </div>
              <Submit className={classes.submit}>
                <StateLink to='/login' state={{email}}>Back to Login page</StateLink>
              </Submit>
            </>
          : <div className={classes.helper}>
              {error &&
                <Error>{error}</Error>
              }
              <Form>
                <Field
                  type='email'
                  placeholder='Enter your email'
                  value={cleanedEmail}
                  onChange={value => setEmail(value)}
                  error={errorEmail}
                />
                <Submit onClick={onSubmit} disabled={disabled}>Restore</Submit>
              </Form>
              <div className={classes.bottom}>
                <StateLink to='/login' state={{email}}>Back to Login page</StateLink>
              </div>
            </div>
        }
      </Wrapper>
    </DesktopWrapperHOC2>
  )
}

export default Restore