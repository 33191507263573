import {utils} from 'mtuikit'

const EVENT_TO_TEXT_MAP = {
  'crm:order_offered': ({order}) => order.assigned === null ? 'Searching for a tutor' : 'Tutor found',
  'crm:chat_connected': () => 'Tutor connected!',
  'crm:chat_idle': () => 'crm:chat_idle',
  'crm:chat_disconnected': () => 'Tutor was disconnected',
  'crm:order_assigned': () => 'Tutor accepted you task',
  'crm:order_completed': () => 'Your task is completed',
  'crm:order_closed': () => 'Task was closed on',
  'crm:order_estimated': ({estimate}) => estimate
    ? {
      active: 'Additional time requested',
      accepted: 'Payment was successful',
      declined: 'Time request declined',
      canceled: 'Time request canceled',
      expired: 'Time request expired'
    }[estimate.status]
    : null,
  'crm:estimate_paid': () => 'Payment was successful',
  'crm:estimate_declined': () => 'Time request declined',
  'crm:estimate_canceled': () => 'Time request canceled',
  'crm:estimate_expired': () => 'Time request expired',
  'crm:live_session_scheduled': () => 'Live session is scheduled',
  'crm:live_session_rescheduled': () => 'Live session rescheduled',
  'crm:live_session_upcoming': ({liveSession}) => liveSession
    ? {
      upcoming: 'Live session starts soon',
      started: 'Live session starts now',
      ended: 'Live session is over'
    }[liveSession.status]
    : null,
  'crm:live_session_canceled': () => 'Live session was canceled',
  'crm:revision_requested': () => 'Task revision requested',
  'crm:rate_requested': () => 'Rate your tutor',
  'crm:freelancer_rejected': ({order}) => order.assigned === null ? 'Searching for a tutor' : 'Tutor found',
  'support:unavailable_time': () => 'Support team can’t answer right now...',
  'onboard:greetings': () => 'Thanks for choosing NerdyTutors again!',
  'onboard:request_outputs': () => 'What type of help do you prefer?',
  'onboard:request_phone': ({first_time_client}) => first_time_client
    ? 'Please make sure you phone number is correct. We will notify you as soon as the tutor is connected.'
    : 'Great! ⏱ It takes from 15 minutes up to several hours to find the best tutor.',
  'onboard:request_support': () => 'If you have any questions just message our support team.',
  'onboard:request_instructions': () => '⚡To speed up tutor search, please check you have provided full instructions and all files your tutor might need.',
  'onboard:request_instructions2': () => '⚡Add all additional information and files that the tutor might need to help you with this task:',
  'onboard:request_top_tutor_addon': () => 'Get matched with one of our TOP experts in',
  'crm:request_top_tutor_addon': () => 'Get matched with one of our TOP experts in',
  'onboard:checking_instructions': () => 'We\'re checking your instructions. Our manager will contact you ',
  'onboard:added_task_instructions': () => 'Task instructions were added',
  'crm:added_top_tutor_addon': () => 'Great! Now we are looking for a TOP tutor for your task.',
  'crm:live_session_requested': () => 'Please, let your tutor know about your preferred session time so they could schedule it for you.',
  'crm:bundle_balance_order': ({bundle, usedBundleItem}) => bundle?.is_used
    ? `${utils.printAmount(usedBundleItem.price + usedBundleItem.topup_amount, {trunc: true})} added to your balance`
    : bundle?.is_expired
      ? 'Deal expired.'
      : 'Save on your next tasks with a limited time offer!'
}

export default EVENT_TO_TEXT_MAP
