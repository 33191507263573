import React, {useState} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import GoogleLogin from 'react-google-login'
import {Icon} from 'mtuikit'
import theme, {rem} from './../../../theme/base'

const styles = {
  root: {
    marginTop: rem(24),
    '&.error': {
      '& button': {
        background: theme.colors.paleGrey,
        padding: [rem(10), rem(16), rem(6), rem(64)],
        fontSize: rem(15),
        color: theme.colors.blueyGrey,
        fontFamily: theme.fontFamilies.Manrope,
        textAlign: 'left',
        cursor: 'default',
      },
    },
    '& button': {
      background: '#5085EB',
      width: '100%',
      height: rem(56),
      padding: [rem(20), rem(16), rem(16)],
      color: theme.colors.white,
      fontSize: rem(18),
      lineHeight: rem(20),
      fontFamily: theme.fontFamilies.Manrope,
      boxShadow: `0 ${rem(8)} ${rem(16)} rgba(186, 207, 255, 0.232903), 0 ${rem(4)} ${rem(8)} rgba(86, 93, 107, 0.1)`,
      borderRadius: rem(100),
      position: 'relative',
      cursor: 'pointer',
      '&:disabled': {
        cursor: 'default',
      },
    },
    '& svg': {
      width: rem(48),
      height: rem(48),
      display: 'block',
      position: 'absolute',
      left: rem(4),
      top: rem(4),
      pointerEvents: 'none',
    },
    '& img': {
      width: rem(32),
      height: rem(32),
      margin: [0, 'auto'],
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}

const ActiveButton = ({onClick}) =>
  <button onClick={onClick}>
    <Icon iconName='google'/>
    <span>Continue with Google</span>
  </button>

const DisabledButton = ({disabled}) =>
  <button disabled={disabled}>
    <Icon iconName='google'/>
    <img src={require('../../../assets/images/loader.svg').default} alt=''/>
  </button>

const ErrorButton = ({disabled}) =>
  <button disabled={disabled}>
    <Icon iconName='google-disabled'/>
    Unable to authorize with Google. <br/> Please, use email instead.
  </button>

const LoginWithGoogleButton = ({classes, onSuccess}) => {
  const [error, setError] = useState(false)
  const onFailure = (response) => {
    setError(response.error !== 'popup_closed_by_user')
  }
  return <GoogleLogin
    clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
    render={props =>
      <div className={classnames(classes.root, {error})}>
        {error
          ? <ErrorButton {...props} />
          : props.disabled
            ? <DisabledButton {...props} />
            : <ActiveButton {...props} />
        }
      </div>
    }
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy='single_host_origin'
  />
}

export default injectSheet(styles)(LoginWithGoogleButton)
