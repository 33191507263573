import React, {forwardRef} from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import {Button, utils} from 'mtuikit'
import theme, {rem} from '../theme/base'

const styles = {
  root: {
    '& button': {
      padding: [rem(8), rem(24)],
      lineHeight: rem(20),
      border: 'none',
      position: 'relative',
      '& > span': {
        display: 'block',
        position: 'relative',
        '&:not(:only-child)': {
          fontSize: rem(19),
          '&:nth-child(1)': {
            padding: [rem(10), rem(90), rem(10), 0],
            fontWeight: 'normal',
            textAlign: 'left',
            fontFamily: theme.fontFamilies.Manrope,
            '& svg': {
              width: rem(71),
              height: rem(20),
              marginTop: rem(8),
              marginRight: 0,
              display: 'block',
              fill: theme.colors.white,
            },
          },
          '&:nth-child(2)': {
            paddingLeft: rem(24),
            borderLeft: [rem(1), 'solid', `rgba(255, 255, 255, 0.2)`],
            textAlign: 'right',
            position: 'absolute',
            right: rem(24),
            top: '50%',
            transform: 'translateY(-50%)',
          },
          '& > span': {
            textAlign: 'left',
            display: 'block',
            whiteSpace: 'nowrap',
            '&:only-child': {
              padding: [8, 0],
            },
            '&:nth-child(2)': {
              marginTop: 4,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 'normal',
              fontFamily: theme.fontFamilies.Manrope,
              textDecoration: 'line-through',
              opacity: 0.5,
            },
          },
        },
      },
    },
    '& svg': {
      width: rem(20),
      height: rem(20),
      marginRight: rem(8),
      fill: theme.colors.white,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
}

const Submit = forwardRef(
  ({
     classes,
     className,
     text,
     type = 'primary',
     price,
     total,
     onSubmit,
     disabled,
     isFetching,
   }, ref) => {
    return (
      <div className={classnames(classes.root, className)} ref={ref}>
        <Button
          block
          size='large'
          type={type}
          disabled={disabled || isFetching}
          isFetching={isFetching}
          onClick={() => !isFetching && !disabled && onSubmit()}
        >
          {total > 0
            ? <>
              <span>{text}</span>
              <span>
                  {price === total
                    ? <>
                      <span>{utils.printAmount(total)}</span>
                    </>
                    : <>
                      <span>{utils.printAmount(total)}</span>
                      <span>{utils.printAmount(price)}</span>
                    </>
                  }
                </span>
            </>
            : <span>{text}</span>
          }
        </Button>
      </div>
    )
  }
)

export default injectSheet(styles)(Submit)
