import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'

const styles = {
  root: {
    padding: [rem(16), rem(16), 0],
    color: theme.colors.blueyGrey,
    fontSize: rem(11),
    lineHeight: rem(16),
    position: 'relative',
    '& + ul': {
      marginTop: rem(-8),
    },
  },
}

const Label = ({ 
  classes,
  children, 
}) => <div className={classes.root}>{children}</div>
              

export default injectSheet(styles)(Label)