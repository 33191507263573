import React from 'react'
import { withRouter } from 'react-router'
import { useActions } from './../../actions'
import Header from './../../components/Header'

export default withRouter(props => {
  const actions = useActions()
  return <Header
    onMenu={() => actions.onMenu()}
    onSupport={() => actions.showSupportChat()}
    {...props}
  />
})
