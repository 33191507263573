import React from 'react'
import moment from 'moment-timezone'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useActions} from '../../../../actions'
import Layout from './layout'
import RevisionDetails from '../../../RevisionDetails'
import {rem} from '../../../../mtuikit/theme/base'
import {useOrderQuery} from '../../../../hooks'
import {trackValues} from '../../../../lrtracker'

const OrderCompletedWithoutLiveSessions = ({order, ...props}) => {
  const actions = useActions()
  const requestLiveSession = () => {
    actions.showRequestLiveSessionAddon(order.id)
    trackValues('chatClientTutor', 'clientRequestLiveSessionBtnClick', {
      context: {
        id: order.id,
        type: 'Card'
      }
    })
  }
  const name = order?.assigned?.freelancer_info?.name || ''
  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <Layout.Title1 iconName='task_completed'>Task is completed!</Layout.Title1>
        <Layout.Text>
          Ask the tutor questions about this task in the chat for the <Layout.B>next 12 hours for free</Layout.B>.
        </Layout.Text>
        <Layout.Subtitle>🤓 Want to dive deeper?</Layout.Subtitle>
        <Layout.Text>
          Request an additional live session from {name} and get a detailed explanation via <Layout.B>voice
          chat</Layout.B> with <Layout.B>screenshare</Layout.B>{' '}
          and <Layout.B>whiteboard</Layout.B>.
        </Layout.Text>
        <Layout.Buttons>
          <Layout.Button onClick={requestLiveSession} disabled={!!order.closed}>Request a live session</Layout.Button>
        </Layout.Buttons>
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

const RevisionRequested = ({order_id, ...props}) => {
  const actions = useActions()
  const showRevisionDetails = () => actions.showModal(RevisionDetails, {orderId: order_id})
  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <Layout.Title1
          iconName='ic_revision'
          iconStyles={{width: rem(16), height: rem(16)}}
        >
          Revision requested
        </Layout.Title1>
        <Layout.Text>Your tutor has 24 hours for revision. Meanwhile you can communicate in chat as
          usual.</Layout.Text>
        <Layout.Buttons>
          <Layout.Button onClick={showRevisionDetails}>View revision details</Layout.Button>
        </Layout.Buttons>
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

const OrderCompletedEvent = ({order_id, ...props}) => {
  const orderQuery = useOrderQuery(order_id)
  if (!orderQuery.isSuccess) return

  const {data: order} = orderQuery

  const isOrderOrRevisionCompleted = (
    order.revision === null
    || moment(props.data.time) > moment(order.revision.last_completed_at)
  )

  return isOrderOrRevisionCompleted
    ? <OrderCompletedWithoutLiveSessions order={order} {...props} />
    : <RevisionRequested order_id={order_id} {...props} />
}

export default OrderCompletedEvent
