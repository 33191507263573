import React, {useRef} from 'react'
import useStyle, {closeSvg, iconSvg} from './styles'
import useOutsideClick from '../../utils/useOutsideClick'

const Explainer = ({title, text, onClose, zIndex}) => {
  const classes = useStyle()
  const ref = useRef()
  useOutsideClick(ref, onClose)
  return <div className={classes.root} style={{zIndex}}>
    <div className={classes.body} ref={ref}>
      <div className={classes.close} onClick={onClose}>{closeSvg}</div>
      <div className={classes.icon}>{iconSvg}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.text}>{text}</div>
    </div>
  </div>
}

export default Explainer
