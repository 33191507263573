import React from 'react'
import injectSheet from 'react-jss'
import Header from './../../sections/Header'
import Footer from './../../sections/Footer'
import {Title, Subtitle, Paragraph, List, Item} from './../../layout'
import theme, {rem, content, adaptive} from '../../theme/base'
import {useAuth} from "../../../hooks";

const styles = {
  root: {
    background: theme.colors.white,
  },
  wrapper: {
    paddingTop: rem(136),
    paddingBottom: rem(80),
    [adaptive]: {
      paddingTop: rem(120),
      paddingBottom: rem(64),
    },
  },
  content: {
    ...content,
    width: rem(624),
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      width: '100%',
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },
}

const PrivacyPolicy = ({classes}) => {
  const {isLogged} = useAuth()
  return (
    <div className={classes.root}>
      <Header showLogin={!isLogged} hasFixedState/>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Title>Privacy Policy</Title>

          <Subtitle>1. Introduction</Subtitle>
          <Paragraph>Welcome! This section aims to clarify how Nerdy Tutors collects, stores, processes, and utilizes your personal information. As privacy laws are continually evolving, our Privacy Policy may be updated periodically. The most recent revision of this Privacy Policy was made in November 25, 2024 and will govern any use of the Nerdy Tutors website and services from that date forward. Please note that this Privacy Policy does not extend to any personal data you share with third parties.</Paragraph>

          <Subtitle>2. Collection of Information</Subtitle>
          <Paragraph>At Nerdy Tutors, we don’t collect any personal information about visitors to our site. If you do visit us without becoming a member, we might place session ID cookies on your computer with your consent.</Paragraph>
          <Paragraph>If you become member, we collect personal information, such as your email & phone number, as it is essential for delivering our services to you. If you choose not to share this information, you are still welcome to browse our website; however, access to certain services will be restricted.</Paragraph>
          <Paragraph>Your personal information is stored securely and safeguarded against unauthorized use. Only authorized staff trained in accordance with our data protection policies have access to your information. Additionally, we continuously monitor our systems to identify and address potential vulnerabilities.</Paragraph>
          <Paragraph>The data we collect, store, process, and use falls into three categories: information you provide to us directly, data we collect automatically, and information we obtain from other sources.</Paragraph>

          <Subtitle>2.1. Information You Provide Directly</Subtitle>
          <Paragraph>When you fill out a form or contact us through any method of communication, you may provide personal details such as your email address, phone number, education level, photos, or any other information you choose to share with us.</Paragraph>

          <Subtitle>2.2. Information We Collect Automatically</Subtitle>
          <Paragraph>When you use the Nerdy Tutors website, we automatically collect the following information:</Paragraph>

          <List>
            <Item>Device Information: Data about the device you use to access our platform, such as the hardware model, operating system version, and screen resolution.</Item>
            <Item>Log Information: Details like your IP address, browser version, and the time, date, duration, and frequency of your visits, as well as the pages you access.</Item>
            <Item>This information is collected using cookies, pixels, and local storage (like on your browser or device, which is similar to a cookie but holds more information)</Item>
          </List>

          <Paragraph>The following list presents different kinds of cookies that our Services use and explains why we use them.</Paragraph>

          <List>
            <Item>Essentials. Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</Item>
            <Item>Marketing. Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</Item>
            <Item>Performance. Help research, understand and improve products and Services. For example, when you access the Nerdy Tutors website or other related websites from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</Item>
            <Item>Functional. These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</Item>
          </List>

          <Subtitle>2.3. Information Obtained from Other Sources</Subtitle>
          <Paragraph>We may collect information about you from third-party services, such as social media platforms, but only if you have provided those services with consent to share this data with us. This information is essential for delivering our free and paid services to you.</Paragraph>

          <Subtitle>2.4. Sensitive Information</Subtitle>
          <Paragraph>We do not collect sensitive information about you and strongly advise against providing it. Sensitive information may include details about your race, ethnicity, gender, health, religious or philosophical beliefs, political opinions, and similar topics. If you choose to share this information with other users on the website, Nerdy Tutors assumes no responsibility for its use.</Paragraph>

          <Subtitle>2.5. Children's Online Privacy Protection</Subtitle>
          <Paragraph>Nerdy Tutors is not intended for independent use by individuals under the age of 18. We do not knowingly collect, store, or use information from such individuals.</Paragraph>

          <Subtitle>3. Use of Information</Subtitle>
          <Paragraph>The information you provide is used to enhance, operate, and protect Nerdy Tutors, as well as to communicate with you and provide access to our free and paid services.</Paragraph>

          <Subtitle>3.1. Use of Your Data for Nerdy Tutors Website Operation</Subtitle>
          <Paragraph>We use your data to ensure the efficient operation and continuous improvement of Nerdy Tutors, including:</Paragraph>

          <List>
            <Item>Enhancing website performance;</Item>
            <Item>Processing transactions;</Item>
            <Item>Developing new products and services;</Item>
            <Item>Monitoring and analyzing user activity to deliver an optimal experience for all users;</Item>
            <Item>Detecting, preventing, and investigating fraudulent activities;</Item>
            <Item>Preventing abuse of the website and activities that are illegal or violate our Terms of Use, Privacy Policy, or other policies;</Item>
            <Item>Protecting the rights and safety of all users;</Item>
            <Item>Conducting statistical analyses and performing other administrative tasks.</Item>
          </List>

          <Subtitle>3.2. Use of Your Data for Communication</Subtitle>
          <Paragraph>Your personal information is used to:</Paragraph>

          <List>
            <Item>Provide customer service and technical support;</Item>
            <Item>Respond to your inquiries;</Item>
            <Item>Send updates, news, or important alerts;</Item>
            <Item>Conduct surveys.</Item>
          </List>

          <Subtitle>3.3. Advertising and Other Uses</Subtitle>
          <Paragraph>Your personal information may also be used for advertising purposes, including:</Paragraph>

          <List>
            <Item>Delivering personalized content and website features;</Item>
            <Item>Promoting content relevant to your interests;</Item>
            <Item>Providing targeted advertising through third-party platforms.</Item>
          </List>

          <Subtitle>3.4. Legal Basis for Processing Your Information</Subtitle>
          <Paragraph>The collection and use of your personal information are conducted lawfully, with the legal basis depending on the specific context of data collection.</Paragraph>

          <List>
            <Item><strong>Providing Services:</strong> Your phone number & email are essential for delivering free and paid features of the website.</Item>
            <Item><strong>Compliance and Protection:</strong> Collecting and processing your information may be necessary to meet legal obligations, such as fraud prevention, network optimization, and database security, as well as to safeguard your interests and the privacy of other users.</Item>
            <Item><strong>Improving User Experience:</strong> Gathering your personal information is vital for enhancing your overall experience with the website.</Item>
          </List>

          <Subtitle>4. Sharing Information and Affiliate Disclosure</Subtitle>
          <Paragraph>We may share your information with third-party service providers and relevant authorities to comply with legal requirements and safeguard our legal rights and property. However, data collected through the short code program will not be shared with third parties for the marketing purposes.</Paragraph>

          <Subtitle>4.1. Sharing Information with Service Providers</Subtitle>
          <Paragraph>We use third-party services to improve the functionality and performance of our website. These providers assist with tasks such as processing payments, sending communications, hosting data, displaying ads, and offering technical support. They are allowed access to your personal information solely for their designated purposes, and we ensure their use remains within authorized limits.</Paragraph>

          <Subtitle>4.2. Transferring Information to Other Countries</Subtitle>
          <Paragraph>Your personal data may be transferred to countries with data protection laws different from those in your home country. We implement measures to ensure these transfers align with the requirements outlined in this Privacy Policy.</Paragraph>

          <Subtitle>4.3. Sharing Information for Security Purposes</Subtitle>
          <Paragraph>Your personal information is stored securely and safeguarded against unauthorized use. Only authorized staff trained in accordance with our data protection policies have access to your information. Additionally, we continuously monitor our systems to identify and address potential vulnerabilities.</Paragraph>

          <Subtitle>4.4. Affiliate Disclosure</Subtitle>
          <Paragraph>Some links included in our email campaigns may be affiliate links. These links allow third-party websites to earn advertising fees. While using these links does not change the cost for you, Nerdy Tutors may earn a commission if you purchase a service through them.</Paragraph>

          <Subtitle>4.5. Third-Party Partners and Privacy</Subtitle>
          <Paragraph>To display advertisements, analyze service usage, and assess the effectiveness of our promotions, Nerdy Tutors collaborates with third-party partners like advertising platforms and analytics providers. These partners may use tracking tools such as cookies and web tags to collect data, including Device IDs and other personal information, which may be considered personal data under certain regulations.</Paragraph>

          <Paragraph>We generally share aggregated, depersonalized, or pseudonymized data with third-party partners to deliver relevant advertisements both on and off our platform.</Paragraph>

          <Paragraph>Opt-Out Options:<br />
            You can opt out of tracking and personalized advertising through the following methods:</Paragraph>

          <List>
            <Item>Global Privacy Controls: Adjust browser settings to communicate your privacy preferences to websites.</Item>
            <Item>Google Analytics: Opt out by installing the Google Analytics opt-out browser add-on.</Item>
            <Item>Do Not Track (DNT): Enable DNT in your browser settings to notify websites of your tracking preferences.</Item>
            <Item>Mobile Device Settings: Modify mobile settings to disable tracking for advertising or analytics, or register with AppChoices to opt out of targeted ads.</Item>
          </List>

          <Paragraph>Please note that opting out of tracking will not eliminate advertisements altogether. You will continue to see non-personalized ads while browsing online.</Paragraph>

          <Subtitle>5. Your data rights</Subtitle>
          <Paragraph>You have several rights regarding your personal data, including:</Paragraph>

          <List>
            <Item><strong>Access, Modify, or Delete Your Information:</strong> You can review, update, or request the deletion of your information by contacting us at support@nerdy-tutors.com</Item>
            <Item><strong>Restrict Data Processing:</strong> If you wish to opt out of segmentation or other processing activities, contact us at support@nerdy-tutors.com</Item>
            <Item><strong>Data Portability:</strong> To receive a copy of all the data we have about you, request a data file by emailing support@nerdy-tutors.com</Item>
            <Item><strong>Unsubscribe from Marketing Communications:</strong> Click the unsubscribe link in any marketing email you receive from us, or email us directly at support@nerdy-tutors.com</Item>
            <Item><strong>Withdraw Consent:</strong> You can revoke your consent for us to process your personal information at any time by contacting support@nerdy-tutors.com</Item>
            <Item><strong>File a Complaint:</strong> If you have concerns about our use of your data, you have the right to file a complaint with your local Data Protection Authority (DPA).</Item>
          </List>

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default injectSheet(styles)(PrivacyPolicy)
