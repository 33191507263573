import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem, desktop, adaptive } from '../../../theme/base'

const styles = {
  subjects: {
    margin: [rem(5), 'auto', 0],
    [desktop]: {
      maxWidth: rem(610),
    },
    [adaptive]: {
      marginTop: rem(40),
      padding: [0, 0, rem(8)],
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
  },
  subject: {
    background: theme.colors.white70,
    margin: [rem(11), rem(4), 0, rem(4)],
    padding: [rem(4), rem(12)],
    borderRadius: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    transition: 'background 300ms ease',
    [adaptive]: {
      marginTop: 0,
    },
    '&:hover': {
      background: theme.colors.white80,
    },
    '&:active': {
      background: theme.colors.white,
    },
    '& > img': {
      width: rem(24),
      height: rem(24),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > span': {
      paddingTop: rem(2),
      fontSize: rem(16),
      lineHeight: rem(22),
      color: theme.colors.slateGrey,
      letterSpacing: rem(0.2),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

export const DEFAULT_SUBJECTS = [
  { name: 'Math', icon: 'math' },
  { name: 'English', icon: 'english' },
  { name: 'Business and Management', icon: 'business_and_management' },
  { name: 'Economics', icon: 'economics' },
  { name: 'Chemistry', icon: 'chemistry' },
  { name: 'Nursing', icon: 'nursing' },
  { name: 'Statistics', icon: 'statistics' },
  { name: 'Physics', icon: 'physics' },
]

const Subjects = ({
  classes,
  className,
  setSubject,
}) =>
  <div className={classnames(classes.subjects, className)}>
    {DEFAULT_SUBJECTS.map(({ name, icon }, index) =>
      <div className={classes.subject} onClick={() => setSubject(name)} key={index}>
        <img src={require(`./Icons/${icon}.svg`).default} alt='' />
        <span>{name}</span>
      </div>
    )}
  </div>


export default injectSheet(styles)(Subjects)