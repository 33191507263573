import {useEffect, useState} from 'react'
import useCallbackInTime from './useCallbackInTime'

function useToggleInTime(defaultValue, at) {
  const [value, setValue] = useState(defaultValue)
  useCallbackInTime(at, () => setValue(!defaultValue), true)
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  return value
}

export default useToggleInTime
