import React from 'react'
import injectSheet from 'react-jss'
import theme, {rem} from '../../../theme/base'

const styles = {
  root: {
    '&:before': {
      content: '""',
      backgroundImage: `url(${require('./select.svg').default})`,
      backgroundPosition: 'top center',
      backgroundSize: '100% 100%',
      width: `calc(100vw - ${rem(400)})`,
      height: rem(64),
      position: 'fixed',
      left: rem(380),
      top: '50%',
      transform: `translateY(${rem(-24)})`,
      display: 'block',
    },
    '&:after': {
      content: '"Select a chat to start messaging"',
      width: `calc(100vw - ${rem(400)})`,
      position: 'fixed',
      left: rem(380),
      right: 0,
      top: '50%',
      paddingTop: rem(56),
      textAlign: 'center',
      fontSize: rem(17),
      lineHeight: rem(24),
      color: theme.colors.blueyGrey,
      display: 'block',
    },
  }
}

export default injectSheet(styles)(({classes}) => {
  return <div className={classes.root}></div>
})
