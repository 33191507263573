import getRandomProfileAvatar from './utils/getRandomProfileAvatar'
import {utils} from 'mtuikit'

utils.configUserAvatarUrl(process.env.REACT_APP_AVATAR_CDN)

export const MIN_CHARS_TO_AVAILABLE_SEARCH = 0
export const MIN_CHARS_TO_DEFAULT_SEARCH = 7
export const MAX_TITLE_LENGTH = 36

const TUTORS = [
  {
    id: 0,
    name: 'Donald R.',
    stars: '4.5',
    university: 'Masters at CalTech',
    students: '29',
    image: require('./assets/images/tutors/Donald.jpg').default,
  },
  {
    id: 1,
    name: 'Curtis L.',
    stars: '5',
    university: 'Ph.D. at Brown',
    students: '48',
    image: require('./assets/images/tutors/Curtis.jpg').default,
  },
  {
    id: 2,
    name: 'Dennis D.',
    stars: '4.5',
    university: 'Masters at Rice',
    students: '89',
    image: require('./assets/images/tutors/Dennis.jpg').default,
  },
  {
    id: 3,
    name: 'Jason P.',
    stars: '4.5',
    university: 'Ph.D. at Vanderbilt',
    students: '110',
    image: require('./assets/images/tutors/Jason.jpg').default,
  },
  {
    id: 4,
    name: 'Kelsey W.',
    stars: '5',
    university: 'Ph.D. at Emory',
    students: '33',
    image: require('./assets/images/tutors/Kelsey.jpg').default,
  },
  {
    id: 5,
    name: 'Margaret A.',
    stars: '5',
    university: 'Masters at UCLA',
    students: '53',
    image: require('./assets/images/tutors/Margaret.jpg').default,
  },
  {
    id: 6,
    name: 'Mei L.',
    stars: '4.5',
    university: 'Ph.D. at Tufts',
    students: '94',
    image: require('./assets/images/tutors/Mei.jpg').default,
  },
  {
    id: 7,
    name: 'Patricia K.',
    stars: '5',
    university: 'Masters at Brandeis',
    students: '112',
    image: require('./assets/images/tutors/Patricia.jpg').default,
  },
  {
    id: 8,
    name: 'Sarah P.',
    stars: '5',
    university: 'Ph.D. at NYU',
    students: '37',
    image: require('./assets/images/tutors/Sarah.jpg').default,
  },
  {
    id: 9,
    name: 'Spencer W.',
    stars: '5',
    university: 'Ph.D. at Carnegie Mellon',
    students: '58',
    image: require('./assets/images/tutors/Spencer.jpg').default,
  },
  {
    id: 10,
    name: 'Leslie S.',
    stars: '5',
    university: 'Masters at Northwestern',
    students: '99',
    image: require('./assets/images/tutors/Leslie.jpg').default,
  },
  {
    id: 11,
    name: 'Manuel R.',
    stars: '4.5',
    university: 'Ph.D. at Dartmouth',
    students: '109',
    image: require('./assets/images/tutors/Manuel.jpg').default,
  },
  {
    id: 12,
    name: 'Victoria J.',
    stars: '4.5',
    university: 'Ph.D at Berkeley',
    students: '41',
    image: require('./assets/images/tutors/Victoria.jpg').default,
  },
  {
    id: 13,
    name: 'Shan B.',
    stars: '4.5',
    university: 'Masters at Chapel Hill',
    students: '61',
    image: require('./assets/images/tutors/Shan.jpg').default,
  },
  {
    id: 14,
    name: 'Niam K.',
    stars: '5',
    university: 'Ph.D. at Duke',
    students: '87',
    image: require('./assets/images/tutors/Niam.jpg').default,
  },
  {
    id: 15,
    name: 'Miguel C.',
    stars: '5',
    university: 'Masters at Georgetown',
    students: '107',
    image: require('./assets/images/tutors/Miguel.jpg').default,
  },
  {
    id: 16,
    name: 'Renee R.',
    stars: '4.5',
    university: 'Masters at JHU',
    students: '45',
    image: require('./assets/images/tutors/Renee.jpg').default,
  },
  {
    id: 17,
    name: 'Oscar J.',
    stars: '5',
    university: 'Masters at Ole Miss',
    students: '67',
    image: require('./assets/images/tutors/Oscar.jpg').default,
  },
  {
    id: 18,
    name: 'Luis T.',
    stars: '5',
    university: 'Masters at TAMU',
    students: '59',
    image: require('./assets/images/tutors/Luis.jpg').default,
  },
  {
    id: 19,
    name: 'Jorge V.',
    stars: '5',
    university: 'Masters at UChicago',
    students: '101',
    image: require('./assets/images/tutors/Jorge.jpg').default,
  },
  {
    id: 20,
    name: 'Gary V.',
    stars: '4.5',
    university: 'Ph.D. at UCSB',
    students: '49',
    image: require('./assets/images/tutors/Gary.jpg').default,
  },
  {
    id: 21,
    name: 'April L.',
    stars: '5',
    university: 'Ph.D. at Rice',
    students: '71',
    image: require('./assets/images/tutors/April.jpg').default,
  },
  {
    id: 22,
    name: 'Allison A.',
    stars: '5',
    university: 'Ph.D. at UCLA',
    students: '55',
    image: require('./assets/images/tutors/Allison.jpg').default,
  },
  {
    id: 23,
    name: 'Johnny C.',
    stars: '4.5',
    university: 'Masters at Brown',
    students: '93',
    image: require('./assets/images/tutors/Johnny.jpg').default,
  },
  {
    id: 24,
    name: 'Jamie P.',
    stars: '4.5',
    university: 'Masters at UC Davis',
    students: '31',
    image: require('./assets/images/tutors/Jamie.jpg').default,
  },
  {
    id: 25,
    name: 'Mark K.',
    stars: '4.5',
    university: 'Ph.D at UCLA',
    students: '96',
    image: require('./assets/images/tutors/Mark.jpg').default,
  },
  {
    id: 26,
    name: 'Maggie D.',
    stars: '5',
    university: 'Masters at SFSU',
    students: '27',
    image: require('./assets/images/tutors/Maggie.jpg').default,
  },
  {
    id: 27,
    name: 'Travis S.',
    stars: '4.5',
    university: 'Masters at UPenn',
    students: '59',
    image: require('./assets/images/tutors/Travis.jpg').default,
  },
]

export const getRandomTutors = (count = 1) => {
  const result = []
  const randomId = utils.getRandom(0, TUTORS.length - 1, count)
  for (let i = 0; i < randomId.length; i += 1) {
    result.push(TUTORS[randomId[i]])
  }
  return result
}


export const SUBJECT_AND_CATEGORIES = [
  ['Business and Management', 'Business Studies'],
  ['International Business', 'Business Studies'],
  ['Managerial Analytics', 'Business Studies'],
  ['Marketing and PR', 'Business Studies'],
  ['Organization Development', 'Business Studies'],
  ['Project Management', 'Business Studies'],
  ['Chemical Engineering', 'Chemistry'],
  ['Chemistry', 'Chemistry'],
  ['Computer Science', 'Computer science & IT'],
  ['Artificial Intelligence', 'Computer science & IT'],
  ['Information Theory', 'Computer science & IT'],
  ['IT', 'Computer science & IT'],
  ['IT Security', 'Computer science & IT'],
  ['Programming', 'Computer science & IT'],
  ['Web Development', 'Computer science & IT'],
  ['Creative Writing', 'English & Literature'],
  ['Academic Writing', 'English & Literature'],
  ['English', 'English & Literature'],
  ['Journalism', 'English & Literature'],
  ['Linguistics', 'English & Literature'],
  ['Literature', 'English & Literature'],
  ['Morphology', 'English & Literature'],
  ['Phonology', 'English & Literature'],
  ['Psycholinguistics', 'English & Literature'],
  ['Semantics and Pragmatics', 'English & Literature'],
  ['Syntax', 'English & Literature'],
  ['Biology and Life Sciences', 'Environmental science'],
  ['Atmospheric Science', 'Environmental science'],
  ['Astronomy', 'Environmental science'],
  ['Biology', 'Environmental science'],
  ['Agriculture, Fisheries and Food', 'Environmental science'],
  ['Ecology', 'Environmental science'],
  ['Environmental Science', 'Environmental science'],
  ['Forestry', 'Environmental science'],
  ['Genetics and Heredity', 'Environmental science'],
  ['Geography', 'Environmental science'],
  ['Geology', 'Environmental science'],
  ['Immunology', 'Environmental science'],
  ['Life Sciences', 'Environmental science'],
  ['Microbiology', 'Environmental science'],
  ['Molecular Biology', 'Environmental science'],
  ['Neuroscience', 'Environmental science'],
  ['Physiology', 'Environmental science'],
  ['Plant Sciences', 'Environmental science'],
  ['Economics', 'Finance & Economics'],
  ['Finance, Accounting and Banking', 'Finance & Economics'],
  ['International Economics', 'Finance & Economics'],
  ['Macroeconomics', 'Finance & Economics'],
  ['Microeconomics', 'Finance & Economics'],
  ['Political Economy', 'Finance & Economics'],
  ['Sociology of Economy', 'Finance & Economics'],
  ['Culture', 'Humanities'],
  ['Archaeology', 'Humanities'],
  ['Classical Music', 'Humanities'],
  ['Anthropology', 'Humanities'],
  ['Art History', 'Humanities'],
  ['Art', 'Humanities'],
  ['Education', 'Humanities'],
  ['Ethics', 'Humanities'],
  ['Healthcare and Nursing', 'Humanities'],
  ['History', 'Humanities'],
  ['History and Anthropology', 'Humanities'],
  ['History of Literature', 'Humanities'],
  ['Introduction to Visual Arts', 'Humanities'],
  ['Music', 'Humanities'],
  ['Music History', 'Humanities'],
  ['Nursing Theory', 'Humanities'],
  ['Nutrition', 'Humanities'],
  ['Performance Arts', 'Humanities'],
  ['Philosophy', 'Humanities'],
  ['Public Health', 'Humanities'],
  ['Religion', 'Humanities'],
  ['Theology', 'Humanities'],
  ['Theories of Modern Art', 'Humanities'],
  ['Visual Arts', 'Humanities'],
  ['Data Analytics', 'Math & Statistics'],
  ['Analytics', 'Math & Statistics'],
  ['Applied Mathematics', 'Math & Statistics'],
  ['Calculus', 'Math & Statistics'],
  ['Algebra', 'Math & Statistics'],
  ['Descriptive Analytics', 'Math & Statistics'],
  ['Descriptive Statistics', 'Math & Statistics'],
  ['Game Theory', 'Math & Statistics'],
  ['Geometry and Topology', 'Math & Statistics'],
  ['Inferential Statistics', 'Math & Statistics'],
  ['Logic', 'Math & Statistics'],
  ['Math', 'Math & Statistics'],
  ['Number Theory', 'Math & Statistics'],
  ['Probability and Statistics', 'Math & Statistics'],
  ['Statistics', 'Math & Statistics'],
  ['Civil Engineering', 'Physics & Engineering'],
  ['Aerodynamics', 'Physics & Engineering'],
  ['Atomic Physics', 'Physics & Engineering'],
  ['Aerospace Engineering', 'Physics & Engineering'],
  ['Design and Architecture', 'Physics & Engineering'],
  ['Electrical Engineering', 'Physics & Engineering'],
  ['Engineering', 'Physics & Engineering'],
  ['Mechanical Engineering', 'Physics & Engineering'],
  ['Modern Physics', 'Physics & Engineering'],
  ['Network Engineering', 'Physics & Engineering'],
  ['Nuclear Physics', 'Physics & Engineering'],
  ['Physics', 'Physics & Engineering'],
  ['Software Engineering', 'Physics & Engineering'],
  ['Thermodynamics', 'Physics & Engineering'],
  ['Behavioral Psychology', 'Social sciences'],
  ['Communication Studies', 'Social sciences'],
  ['Cognitive Psychology', 'Social sciences'],
  ['Criminology', 'Social sciences'],
  ['Experimental Psychology', 'Social sciences'],
  ['Governmental Studies', 'Social sciences'],
  ['HRM', 'Social sciences'],
  ['International Politics', 'Social sciences'],
  ['International Relations', 'Social sciences'],
  ['Labor and Employee Relations', 'Social sciences'],
  ['Law and International Law', 'Social sciences'],
  ['Military Studies', 'Social sciences'],
  ['Political Behavior', 'Social sciences'],
  ['Political Methodology', 'Social sciences'],
  ['Political Science', 'Social sciences'],
  ['Political Sociology', 'Social sciences'],
  ['Psychology', 'Social sciences'],
  ['Sociology', 'Social sciences'],
]

export const TOP_SUBJECTS = ['English', 'Math', 'Business and Management', 'Chemistry', 'Nursing and Healthcare']

export const SUBJECTS = SUBJECT_AND_CATEGORIES.map(s => s[0])


export const S3 = {
  keyStartsWith: 'nt9b30yq/orderform/',
  awsaccesskeyid: 'AKIAQ2ZTVN6TA3SY3RMC',
  acl: 'public-read',
  policy: 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwgCiAiY29uZGl0aW9ucyI6IFsgCiB7ImJ1Y2tldCI6ICJnMHl1MGw0cHhqIiB9LCAKIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJudDliMzB5cS8iXSwgCiB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K',
  signature: 'Fo5Yt9zOP9go5CPhubhpzVDY5ZE=',
  host: 'https://g0yu0l4pxj.s3.amazonaws.com/',
  cdn: 'https://d15uuylzi8itni.cloudfront.net',
}

export const REPORT = [
  {
    value: 'dishonesty',
    title: 'Academic dishonesty',
    text: `Tutor does not support requesting or providing pre-written 
      or custom written academic assignment. Or faking the student's 
      identity for any purpose including test or exam taking.`,
  },
  {
    value: 'plagiarism',
    title: 'Plagiarism',
    text: `Tutor does not allow using the work of another person without 
      proper attribution.`,
  },
  {
    value: 'nudity',
    title: 'Nudity',
    text: `Tutor sharing or publishing the content depicting nudity or other 
      sexually explicit material.`,
  },
  {
    value: 'illegal_activities',
    title: 'Illegal activities',
    text: `Tutor is engaged in any kind of activities that violate the law or 
      corporate policies. We have zero tolerance policy for facilitating drug 
      use, weapons, bidding or gambling.`,
  },
  {
    value: 'harassment',
    title: 'Illegal Harassment',
    text: `Tutor is sharing or publishing the content that promotes hatred or 
      violence, threatens, harasses or bullies other people.`,
  },
  {
    value: 'private',
    title: 'Private and confidential data',
    text: `Tutor is asking for your confidential data, including passwords, 
      CC details, bank account numbers or similar types of private information.`,
  },
]

export const FAQ = [
  {
    icon: 'contextMenu-support',
    title: 'How does it work?',
    content: 'Send a brief description of what you need help with or simply take a picture of the task you are stuck on. Tell us how soon you would like to get help from a tutor (chat, live tutoring session, etc.) and how much time you would like your tutor to spend on helping you. Once you make a payment, we’ll find and connect you to your tutor. You’ll be able to chat right away using our instant web messenger.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How does the payment work?',
    content: 'Tell us how much time you would like your tutor to spend on your task and how soon you would like to get help, then make the payment. All of the payments are 100% secure. Once you make the payment, we’ll connect you with your tutor. Tutors always double-check task details and will let you know in case there are any questions. Didn’t use all of the tutoring hours you have paid for? We’ll refund the difference. No questions asked! Need more time? No worries! Your tutor will let you know if he needs more time to work on your task and you will be able to buy more tutoring hours. Don’t forget that you can always ask follow-up questions and chat with your tutor for free for up to 24 hours after you and your tutor complete your task.',
  },
  {
    icon: 'contextMenu-support',
    title: 'What type of tasks can I get help with?',
    content: 'Tutors can help with any task or topic as long as it doesn’t violate college policies. We have tutors in almost every subject and topic. Simply tell us what you need help with and we’ll find a perfect tutor for your task.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How to cancel my task request?',
    content: 'If you want to cancel your task request or request a refund, simply message our Customer Support Team. They are always here to help you!',
  },
  {
    icon: 'contextMenu-support',
    title: 'How much does it cost?',
    content: 'Our tutors charge for the time they spend on working with you on your task. Our prices start at $0.5 for one minute of tutor\'s help',
  },
]

export const PAGES = [
  {name: 'Terms of Use', link: '/terms-and-conditions'},
  {name: 'Privacy Policy', link: '/privacy-policy'},
]

export const PROFILE_AVATARS = [
  'animal-avatar-1-1.svg',
  'animal-avatar-1-2.svg',
  'animal-avatar-2-1.svg',
  'animal-avatar-2-2.svg',
  'animal-avatar-3-1.svg',
  'animal-avatar-3-2.svg',
  'animal-avatar-4-1.svg',
  'animal-avatar-4-2.svg',
  'animal-avatar-5-1.svg',
  'animal-avatar-5-2.svg',
  'animal-avatar-6-1.svg',
  'animal-avatar-6-2.svg',
  'animal-avatar-7-1.svg',
  'animal-avatar-7-2.svg',
  'animal-avatar-8-1.svg',
  'animal-avatar-8-2.svg',
  'animal-avatar-9-1.svg',
  'animal-avatar-9-2.svg',
]

export const PROFILE_AVATAR_URL = getRandomProfileAvatar()

export const OTHER_CLAIM = 'Other'
export const CLAIMS = ['Content quality', 'Delivery time', 'Formatting', 'Plagiarism', 'Attentiveness to details', OTHER_CLAIM]


export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'

export const PAYMENT_REQUEST_MAP = {
  APPLE_PAY: {
    cardType: 'applepay',
    label: 'Pay with Apple Pay'
  },
  GOOGLE_PAY: {
    cardType: 'googlepay',
    label: 'Pay with Google Pay'
  }
}