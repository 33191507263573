import {v4 as uuidv4} from 'uuid'
import {getAwsFileKey} from './getAwsFileKey'

export const STATUS = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error',
}

const getXHR = (file, config, {onprogress, onload, onerror}) => {
  const formdata = new FormData();
  formdata.append('key', file.key)
  formdata.append('AWSAccessKeyId', config.awsaccesskeyid)
  formdata.append('acl', config.acl)
  formdata.append('policy', config.policy)
  formdata.append('signature', config.signature)
  formdata.append('file', file)

  const xhr = new XMLHttpRequest()
  xhr.open('POST', config.host)
  xhr.upload.onprogress = e => {
    if (e.lengthComputable) {
      const percent = Math.round(100 * e.loaded / e.total)
      onprogress(percent)
    }
  }
  xhr.onload = onload
  xhr.onerror = onerror

  xhr.send(formdata)
  return xhr
}

const mapfile = (file) => ({
  uuid: file.uuid,
  key: file.key,
  name: file.name,
  size: file.size,
  preview: URL.createObjectURL(file),
  progress: file.progress || 0,
  status: file.status || STATUS.UPLOADING,
  type: file.type,
})

const XHRS = {}

function S3Uploader(config, {onadd, onprogress, onload, onerror, onabort}) {
  return {
    add(files) {
      files.forEach(file => {
        file.uuid = uuidv4()
        file.key = config.keyStartsWith + getAwsFileKey(file.name)
        XHRS[file.uuid] = getXHR(file, config, {
          onprogress: progress => onprogress({uuid: file.uuid, progress}),
          onload: _ => onload({
            uuid: file.uuid,
            status: STATUS.UPLOADED,
            url: config.host + encodeURIComponent(file.key)
          }),
          onerror: _ => onerror({uuid: file.uuid, status: STATUS.ERROR}),
        })
      })

      onadd(files.map(mapfile))
    },
    remove(uuids) {
      const _uuids = Array.isArray(uuids) ? uuids : [uuids]
      _uuids.forEach(uuid => {
        onabort({uuid})
        if (XHRS[uuid] !== undefined) {
          XHRS[uuid].abort()
          delete XHRS[uuid]
        }
      })
    }
  }
}

export default S3Uploader
