import React from 'react'
import Highlighter from 'react-highlight-words'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'

const styles = {
  root: {
    paddingTop: rem(8),
    paddingBottom: rem(8),
    overflowY: 'auto',
    position: 'relative',
  },
  item: {
    height: rem(46),
    padding: `${rem(12)} ${rem(16)}`,
    cursor: 'pointer',
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(17),
    lineHeight: rem(22),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    '&:hover': {
      background: 'rgba(61, 119, 255, 0.08)',
    },
    // '& span': {
    //   fontWeight: 'bold',
    //   color: theme.colors.blue,
    // },
    '& mark': {
      background: 'none',
      fontWeight: 'bold',
      color: theme.colors.blue,
    },
  },
}

const List = ({ 
  classes, 
  className,
  search,
  items,
  onClick,
}) =>
  <ul className={classnames(classes.root, className)}>
    {items.map((item, index) =>
      <li key={index} className={classes.item} onClick={() => onClick(item)}>
        <Highlighter
          searchWords={search.split(' ')}
          textToHighlight={item}
          autoEscape
        />
      </li>
    )}
  </ul>

export default injectSheet(styles)(List)