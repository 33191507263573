import React from 'react';
import {CSSTransition} from 'react-transition-group';

import './animation.scss';

export const SlideUpAnimation = ({component, className, children, ...rest}) => {
    return (
        <CSSTransition
            component={component}
            className={className}
            transitionName='slide-up'
            transitionAppear={false}
            transitionEnter={true}Ø
            transitionLeave={false}
            // transitionAppearTimeout={0}
            transitionEnterTimeout={5000}
            // transitionLeaveTimeout={0}
        >
            {children}
        </CSSTransition>
    )
};

export const SlideUpDropDownAnimation = ({component, className, children, style, ...rest}) => {
    return (
        <CSSTransition
            component={component}
            className={className}
            transitionName='slide-up-dropdown'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionAppearTimeout={300}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            timeout={300}
            style={style}
        >
            {children}
        </CSSTransition>
    )
};

export const ProgressBarAnimation = ({component, className, children, ...rest}) => {
    return (
        <CSSTransition
            component={component}
            className={className}
            transitionName='progress-bar'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionAppearTimeout={30000}
            transitionEnterTimeout={30000}
            transitionLeaveTimeout={30000}
        >
            {children}
        </CSSTransition>
    )
};

export const SlideDownAnimation = ({component, className, children, ...rest}) => {
    return (
        <CSSTransition
            component={component}
            className={className}
            transitionName='slide-down'
            //transitionAppear={false}
            transitionEnter={true}
            transitionLeave={true}
            //transitionAppearTimeout={30000}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
        >
            {children}
        </CSSTransition>
    )
};

export const FadeInAnimation = ({component, className, children, ...rest}) => {
    return (
        <CSSTransition
            component={component}
            className={className}
            transitionName='opacity'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionAppearTimeout={300 * 2} // * 2 - for IE
            transitionEnterTimeout={300 * 2}  // * 2 - for IE
            transitionLeaveTimeout={300 * 2}  // * 2 - for IE
        >
            {children}
        </CSSTransition>
    )
};

export const OrderFormAnimation = ({component, className, children, ...rest}) => {
    return (
        <CSSTransition
            component='div'
            className='order-form-wrapper'
            transitionName='orderFormAnimation'
            transitionAppear={true}
            // transitionEnter={true}
            // transitionLeave={true}
            transitionAppearTimeout={30000}
            // transitionEnterTimeout={300000}
            // transitionLeaveTimeout={300000}
        >
            {children}
        </CSSTransition>
    )
};
