import React from 'react'
import {MessageWrapper, Card} from '@connect/connect-ui-js-v2'
import Layout from './layout'
import {useActions} from '../../../../actions'
import {useExplainedPushNotifications, useOrderNotificationsMutation, useOrderQuery} from '../../../../hooks'


const OnboardEnableNotifications = ({order_id: orderId, data, ...props}) => {
  const actions = useActions()
  const order = useOrderQuery(orderId).data
  const orderNotificationsMutation = useOrderNotificationsMutation()

  const {
    isError,
    isReady,
    isProcessing,
    isPending,
    isGranted,
    isDenied,
    subscribe
  } = useExplainedPushNotifications({
    title: 'Your browser will now tell you that NerdyTutors want to show you notifications.',
    text: 'Allow them to get notified about new messages.'
  })

  if (!isReady && !isError && !order) return <div style={{height: 366}}/>

  const optout_notifs = order?.notifications?.optout_notifs

  const enable = () => {
    subscribe().then(({permission}) => {
      if (permission === 'granted') orderNotificationsMutation.mutate({orderId, isEnabled: true})
    })
  }

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <Card {...props} data={{...data}}>
      <Layout.Body>
        {(isPending || optout_notifs) && !isDenied && <>
          <Layout.Title2 img={require('../images/ic_note_default.png').default}>
            Enable your browser notifications!
          </Layout.Title2>
          <Layout.Text>
            Get notified about messages from tutors & support team — like in your favorite messenger.
          </Layout.Text>
          {
            isReady &&
            <Layout.Buttons>
              <Layout.Button onClick={enable} disabled={isProcessing}>
                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.833415 11.6667C0.644526 11.6667 0.486192 11.6028 0.358415 11.475C0.230637 11.3472 0.166748 11.1889 0.166748 11C0.166748 10.8111 0.230637 10.6528 0.358415 10.525C0.486192 10.3972 0.644526 10.3333 0.833415 10.3333H1.50008V5.66666C1.50008 4.74444 1.77786 3.92499 2.33341 3.20833C2.88897 2.49166 3.61119 2.02222 4.50008 1.79999V1.33333C4.50008 1.05555 4.5973 0.819439 4.79175 0.624995C4.98619 0.43055 5.2223 0.333328 5.50008 0.333328C5.77786 0.333328 6.01397 0.43055 6.20842 0.624995C6.40286 0.819439 6.50008 1.05555 6.50008 1.33333V1.79999C7.38897 2.02222 8.11119 2.49166 8.66675 3.20833C9.2223 3.92499 9.50008 4.74444 9.50008 5.66666V10.3333H10.1667C10.3556 10.3333 10.514 10.3972 10.6417 10.525C10.7695 10.6528 10.8334 10.8111 10.8334 11C10.8334 11.1889 10.7695 11.3472 10.6417 11.475C10.514 11.6028 10.3556 11.6667 10.1667 11.6667H0.833415ZM5.50008 13.6667C5.13341 13.6667 4.81953 13.5361 4.55841 13.275C4.2973 13.0139 4.16675 12.7 4.16675 12.3333H6.83342C6.83342 12.7 6.70286 13.0139 6.44175 13.275C6.18064 13.5361 5.86675 13.6667 5.50008 13.6667Z"
                    fill="white"/>
                </svg>
                &nbsp;Enable notifications
              </Layout.Button>
            </Layout.Buttons>
          }
        </>}
        {(isGranted && !optout_notifs) && <>
          <Layout.Title2 img={require('../images/ic_note_granted.png').default}>
            Notifications enabled!
          </Layout.Title2>
          <Layout.Text>
            You can mute this order, or always adjust your notifications preferences in the
            {' '}<Layout.Action onClick={actions.showProfile}>Notifications center</Layout.Action>.
          </Layout.Text>
        </>}
        {(isDenied || isError) && <>
          <Layout.Title2 img={require('../images/ic_note_denied.png').default}>
            Your settings block notifications...
          </Layout.Title2>
          <Layout.Text>
            Turn on browser notifications to avoid missing messages from a tutor.
            <br/><Layout.Action onClick={actions.showBrowserInstructions}>Learn how to fix</Layout.Action>
          </Layout.Text>
        </>}
      </Layout.Body>
      <Card.Footer {...props} data={{...data}}/>
    </Card>
  </MessageWrapper>
}


export default OnboardEnableNotifications
