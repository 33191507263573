import React from 'react'
import {MessageWrapper, Card} from '@connect/connect-ui-js-v2'
import {createUseStyles} from 'react-jss'
import Layout from './layout'
import Attachment from '../../../OrderFormV5/layout/File/Attachment'

const OnboardAddedTaskInstructionsEvent = ({data, details, files, ...props}) => {
  const classes = useStyles()
  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <Card {...props} data={{...data}}>
      <Layout.Title>Task instructions were added</Layout.Title>
      {
        details &&
        <div className={classes.item}>
          <Layout.Collapsable trigger={['Show more text', 'Show less text']} visibleLines={2}>
            {details}
          </Layout.Collapsable>
        </div>
      }
      {
        files.length > 0 &&
        <div className={classes.item}>
          <Layout.Collapsable trigger={['Show more files', 'Show less files']} visibleLines={1}>
            {files.map(({name, location}) => <Attachment key={location} name={name} location={location} big/>)}
          </Layout.Collapsable>
        </div>
      }
      <Card.Footer {...props} data={{...data}}/>
    </Card>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  item: {
    marginTop: 24,
  },
})


export default OnboardAddedTaskInstructionsEvent
