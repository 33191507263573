import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme from '../../../mtuikit/theme/base'
import Textarea from 'react-textarea-autosize'
import {adaptive} from 'mtuikit/theme/base'

const Textarea_ = ({
                     classes,
                     className,
                     value,
                     error,
                     minRows = 4,
                     ...otherProps
                   }) =>
  <Textarea
    className={classnames(classes.root, className, {'fix-ios-placeholder-bug': !value, error,})}
    value={value}
    minRows={minRows}
    {...otherProps}
  />

const styles = {
  root: {
    width: '100%',
    border: 'none',
    resize: 'none',
    fontFamily: 'arial',
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    color: theme.colors.charcoalGrey,
    [adaptive]: {
      overflow: 'hidden',
    },
    '&:-moz-placeholder': {
      lineHeight: 'normal',
    },
    '&::-moz-placeholder': {
      lineHeight: 'normal',
    },
    '&:-ms-input-placeholder': {
      lineHeight: 'normal',
    },
    '&::-webkit-input-placeholder': {
      lineHeight: 'normal',
    },
    '&.error': {
      color: theme.colors.brightOrange,
      borderColor: `${theme.colors.brightOrange} !important`,
      '&:-moz-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&::-moz-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.brightOrange,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.brightOrange,
      },
    },
  },
}

export default injectSheet(styles)(Textarea_)
