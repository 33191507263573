import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from 'theme/base'

const styles = {
  root: {
    marginTop: rem(16),
    textAlign: 'center',
    '& > div': {
      fontSize: rem(15),
      lineHeight: rem(20),
      color: theme.colors.blue,
      fontFamily: theme.fontFamilies.Manrope,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
    },
  },
}

const TextButton = ({
  classes,
  text,
  onClick,
}) =>
  <div className={classes.root}>
    <div onClick={onClick}>{text}</div>
  </div>

export default injectSheet(styles)(TextButton)