import {printAmount} from './printAmount'
import {printDueDate} from './printDueDate'
import {printUntilDate} from './printUntilDate'
import {printDueDateCustom} from './printDueDateCustom'
import {printDateTime} from './printDateTime'
import {printDateTimeCustom} from './printDateTimeCustom'
import {cleanPhoneNumber} from './cleanPhoneNumber'
import {convertMinutesToSize} from './convertMinutesToSize'
import {convertSizeToMinutes} from './convertSizeToMinutes'
import {deadlineToISOString} from './deadlineToISOString'
import {getUserAvatarUrl, configUserAvatarUrl} from './getUserAvatarUrl'
import {secToMin} from './secToMin'
import {useMobile} from './useMobile'
import {useTouch} from './useTouch'
import {getRandom} from './getRandom'
import {sizeToHumans} from './sizeToHumans'
import {sizeToWords} from './sizeToWords'
import {sizeExtend} from './sizeExtend'
import {replaceUrls} from './replaceUrls'
import {isUserDaylightHours} from './isUserDaylightHours'
import {printTimezoneDateTime} from './printTimezoneDateTime'
import {getDeadlineInHours} from './getDeadlineInHours'
import {getDiffInMonths} from './getDiffInMonths'
import {sizeToHuman} from './sizeToHuman'

const utils = {
  printAmount,
  printDueDate,
  printDueDateCustom,
  printUntilDate,
  printDateTime,
  printDateTimeCustom,
  cleanPhoneNumber,
  convertMinutesToSize,
  convertSizeToMinutes,
  deadlineToISOString,
  getUserAvatarUrl,
  configUserAvatarUrl,
  secToMin,
  useMobile,
  useTouch,
  getRandom,
  sizeToHumans,
  sizeToWords,
  sizeExtend,
  replaceUrls,
  isUserDaylightHours,
  printTimezoneDateTime,
  getDeadlineInHours,
  getDiffInMonths,
  sizeToHuman
}

export default utils