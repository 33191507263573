import React from 'react'
import {createUseStyles} from 'react-jss'
import {getUserAvatarUrl} from '../../../mtuikit/utils/getUserAvatarUrl'
import {useIsFavoriteUser, useIsOnlineUser} from '../../../hooks'
import classnames from 'classnames'
import {Icon} from '../../../mtuikit'
import theme, {rem} from '../../../mtuikit/theme/base'

const Avatar = ({userId, big, top, className}) => {
  const classes = useStyle()
  const online = useIsOnlineUser(userId)
  const favorite = useIsFavoriteUser(userId)
  const size = big ? 'big' : 'small'
  const type = top ? 'top' : ''
  return <div className={classnames(className)}>
    <div className={classnames(classes.root, size, type)}>
      {online && <div className={classnames(classes.online, size)}/>}
      {favorite && <Icon className={classnames(classes.favorite, size)} iconName='favorite-tutor'/>}
      <img src={getUserAvatarUrl(userId)} alt=''/>
    </div>
  </div>
}

const useStyle = createUseStyles({
  root: {
    position: 'relative',
    '&.small': {
      width: rem(44),
      height: rem(44),
    },
    '&.big': {
      width: rem(64),
      height: rem(64),
    },
    '&.top img': {
      border: '3px solid #F5AB00',
    },
    borderRadius: '50%',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      display: 'inherit',
    },
  },
  online: {
    content: "''",
    width: '10px',
    height: '10px',
    background: theme.colors.online,
    borderRadius: '50%',
    position: 'absolute',
    right: '0px',
    top: '2px',
    zIndex: 1,
    display: 'block',
    '.big &': {
      width: rem(12),
      height: rem(12),
      right: rem(2),
      top: rem(2),
    }
  },
  favorite: {
    width: '14px',
    height: '14px',
    display: 'block',
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    fill: theme.colors.blue,
    '.big &': {
      width: rem(15),
      height: rem(15),
      bottom: rem(0),
      right: rem(0),
    }
  }
})

export default Avatar
