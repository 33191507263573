import React, {useEffect} from 'react'
import classnames from 'classnames'
import {createPortal} from 'react-dom'
import injectSheet from 'react-jss'
import theme, {rem} from '../../theme/base'

const styles = {
  root: {
    background: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20000,
  },
  text: {
    borderRadius: rem(16),
    color: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontFamily: theme.fontFamilies.Manrope,
    whiteSpace: 'nowrap',
    position: 'fixed',
    bottom: rem(72),
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    textAlign: 'center',
    zIndex: 20000,
  },
}

const Message = ({
                   classes,
                   className,
                   children,
                   overlay,
                 }) => {
  const node = document.createElement('div')
  const root = document.getElementById('root')

  if (overlay) {
    node.classList.add(classes.root)
  }

  useEffect(() => {
    document.body.insertBefore(node, root)
    return () => document.body.removeChild(node)
  })

  return createPortal(
    <div className={classnames(classes.text, className)}>
      {children}
    </div>, node
  )
}

export default injectSheet(styles)(Message)