import React from 'react'
import injectSheet from 'react-jss'
import Header from './../../sections/Header'
import Footer from './../../sections/Footer'
import { Link } from "react-router-dom";
import theme, {rem, adaptive, desktop} from '../../theme/base'
import {useAuth} from "../../../hooks";

const styles = {
  root: {
    background: theme.colors.white,
    fontSize: 16,
    lineHeight: '24px',
    color: '#565D6B',
  },
  wrapper: {
    paddingTop: rem(136),
    paddingBottom: rem(80),
    [adaptive]: {
      paddingTop: rem(120),
      paddingBottom: rem(64),
    },
  },
  content: {
    margin: [0, 'auto'],
    [adaptive]: {
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
    [desktop]: {
      width: rem(624),
    },
  },
  mainTitle: {
    display: 'block',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '40px',
    letterSpacing: 0.19,
    color: '#2F333D',
    marginBottom: 40,
  },
  ol: {
    listStyle: 'decimal inside',
    marginTop: 16,
  },
  olLi: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#565D6B',
  },
  linkInline: {
    fontWeight: 700,
    color: '#3D77FF',
  },
  linkSection: {
    marginTop: 24
  },
  linkTitle: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '24px',
    color: '#3D77FF',
  },
  linkList: {
    listStyle: 'disc inside',
    color: '#3D77FF',
    marginTop: 16,
  },
  linkItem: {
    '&:not(:first-child)': {
      marginTop: 8,
    }
  },
  link: {
    fontWeight: 800,
    color: '#3D77FF',
  },

  sectionTitle: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '24px',
    color: '#2F333D',
    marginTop: 64,
  },
  sectionSubtitle: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '24px',
    color: '#2F333D',
    marginTop: 64,
  }
}

const BrowserInstructions = ({classes}) => {
  const {isLogged} = useAuth()
  return (
    <div className={classes.root}>
      <Header showLogin={!isLogged} hasFixedState/>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <hl className={classes.mainTitle}>Enable Browser Notifications for NerdyTutors</hl>

          <div className={classes.linkSection}>
            <a className={classes.linkTitle} href="#windows">Windows</a>
            <ul className={classes.linkList}>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#windows_chrome">Chrome</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#windows_firefox">Firefox</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#windows_opera">Opera</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#windows_edge">Edge</a>
              </li>
            </ul>
          </div>

          <div className={classes.linkSection}>
            <a className={classes.linkTitle} href="#macos">MacOS</a>
            <ul className={classes.linkList}>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#macos_chrome">Chrome</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#macos_firefox">Firefox</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#macos_safari">Safari</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#macos_opera">Opera</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#macos_edge">Edge</a>
              </li>
            </ul>
          </div>

          <div className={classes.linkSection}>
            <a className={classes.linkTitle} href="#android">Android</a>
            <ul className={classes.linkList}>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#android_chrome">Chrome</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#android_firefox">Firefox</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#android_opera">Opera</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#android_edge">Edge</a>
              </li>
              <li className={classes.linkItem}>
                <a className={classes.link} href="#android_samsung">Samsung Internet</a>
              </li>
            </ul>
          </div>



          <h2 id="windows" className={classes.sectionTitle}>Windows</h2>

          <h3 id="windows_chrome" className={classes.sectionSubtitle}>For Chrome on Windows:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Site settings'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>

          <h3 id="windows_firefox" className={classes.sectionSubtitle}>For Firefox on Windows:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on the arrow located on the right side of 'Connection secure'.</li>
            <li className={classes.olLi}>Click on 'More information'.</li>
            <li className={classes.olLi}>Click on 'Permissions'.</li>
            <li className={classes.olLi}>Find 'Receive Notifications' and select 'Allow'.</li>
            <li className={classes.olLi}>Close the 'Page Info' window.</li>
          </ol>

          <h3 id="windows_opera" className={classes.sectionSubtitle}>For Opera on Windows:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Site settings'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>

          <h3 id="windows_edge" className={classes.sectionSubtitle}>For Edge on Windows:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Permissions'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>



          <h2 id="macos" className={classes.sectionTitle}>MacOS</h2>

          <h3 id="macos_chrome" className={classes.sectionSubtitle}>For Chrome on MacOS:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link> in Chrome.</li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Site settings'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>

          <h3 id="macos_firefox" className={classes.sectionSubtitle}>For Firefox on MacOS:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link> in Firefox.</li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on the arrow located on the right side of 'Connection secure'.</li>
            <li className={classes.olLi}>Click on 'More information'.</li>
            <li className={classes.olLi}>Click on 'Permissions'.</li>
            <li className={classes.olLi}>Find 'Receive Notifications' and select 'Allow'.</li>
            <li className={classes.olLi}>Close the 'Page Info' window.</li>
          </ol>

          <h3 id="macos_safari" className={classes.sectionSubtitle}>For Safari on MacOS:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link> in Safari.</li>
            <li className={classes.olLi}>Click on 'Safari' in the menubar.</li>
            <li className={classes.olLi}>Click on 'Preferences'.</li>
            <li className={classes.olLi}>Click on the 'Websites' tab.</li>
            <li className={classes.olLi}>Click on 'Notifications' in the left sidebar.</li>
            <li className={classes.olLi}>Find 'nerdy-tutors.com' and select 'Allow' from the dropdown menu.</li>
          </ol>

          <h3 id="macos_opera" className={classes.sectionSubtitle}>For Opera on MacOS:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link> in Opera.</li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Site settings'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>

          <h3 id="macos_edge" className={classes.sectionSubtitle}>For Edge on MacOS:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link> in Edge.</li>
            <li className={classes.olLi}>Click on the lock icon located on the left side of the URL bar.</li>
            <li className={classes.olLi}>Click on 'Permissions'.</li>
            <li className={classes.olLi}>Scroll down to 'Notifications'.</li>
            <li className={classes.olLi}>Select 'Allow' from the drop-down menu.</li>
          </ol>




          <h2 id="android" className={classes.sectionTitle}>Android</h2>

          <h3 id="android_chrome" className={classes.sectionSubtitle}>For Chrome on Android:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Tap on the three dots on the top right corner of the screen.</li>
            <li className={classes.olLi}>Select "Site settings".</li>
            <li className={classes.olLi}>Tap on "Notifications".</li>
            <li className={classes.olLi}>Move the slider to the right to enable notifications for the website.</li>
          </ol>

          <h3 id="android_firefox" className={classes.sectionSubtitle}>For Firefox on Android:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Tap on the three dots on the top right corner of the screen.</li>
            <li className={classes.olLi}>Select "Settings".</li>
            <li className={classes.olLi}>Tap on "Site settings".</li>
            <li className={classes.olLi}>Tap on "Notifications".</li>
            <li className={classes.olLi}>Move the slider to the right to enable notifications for the website.</li>
          </ol>

          <h3 id="android_opera" className={classes.sectionSubtitle}>For Opera on Android:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Tap on the three dots on the bottom right corner of the screen.</li>
            <li className={classes.olLi}>Select "Settings".</li>
            <li className={classes.olLi}>Scroll down and tap on "Site settings".</li>
            <li className={classes.olLi}>Tap on "Notifications".</li>
            <li className={classes.olLi}>Move the slider to the right to enable notifications for the website.</li>
          </ol>

          <h3 id="android_edge" className={classes.sectionSubtitle}>For Edge on Android:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Tap on the three dots on the bottom right corner of the screen.</li>
            <li className={classes.olLi}>Select "Settings".</li>
            <li className={classes.olLi}>Scroll down and tap on "Site permissions".</li>
            <li className={classes.olLi}>Tap on "Notifications".</li>
            <li className={classes.olLi}>Move the slider to the right to enable notifications for the website.</li>
          </ol>

          <h3 id="android_samsung" className={classes.sectionSubtitle}>For Samsung Internet on Android:</h3>
          <ol className={classes.ol}>
            <li className={classes.olLi}>Open <Link className={classes.linkInline} to={'/'}>nerdy-tutors.com</Link></li>
            <li className={classes.olLi}>Tap on the three dots on the bottom right corner of the screen.</li>
            <li className={classes.olLi}>Select "Settings".</li>
            <li className={classes.olLi}>Tap on "Sites and downloads".</li>
            <li className={classes.olLi}>Tap on "Notifications".</li>
            <li className={classes.olLi}>Move the slider to the right to enable notifications for the website.</li>
          </ol>

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default injectSheet(styles)(BrowserInstructions)
