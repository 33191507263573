import React from 'react'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useOrderQuery, useTutor} from '../../../../hooks'

const OnboardRequestInstructions = ({data, order_id, ...props}) => {
  const orderQuery = useOrderQuery(order_id)
  const {data: order} = orderQuery

  const tutor = useTutor(order?.recommended?.freelancer_id)

  const message = tutor
    ? <>
      Thanks for choosing NerdyTutors again!
      <br/>{tutor.name} is already notified of your request and will connect as soon as possible.
    </>
    : <>
      Thanks for choosing NerdyTutors again! 💞
      <br/>Providing clear and detailed instructions helps us match you with the best tutor faster.
    </>

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{...data, message}}/>
  </MessageWrapper>
}

export default OnboardRequestInstructions