import {useCallback, useState} from 'react';
import {regexps} from '../../utils/regexps'
import {compact} from 'lodash/array'

function getError(errors) {
  return compact(errors).length > 0 ? compact(errors)[0] : false
}

function useResetMutationOnSet(authMutation, tokenAuthMutation) {
  return useCallback(
    setFunc => value => {
      if (authMutation.status !== 'idle') {
        authMutation.reset()
      }
      if (tokenAuthMutation.status !== 'idle') {
        tokenAuthMutation.reset()
      }
      setFunc(value)
    },
    [authMutation, tokenAuthMutation]
  )
}

export default function useEmailPasswordForm(authMutation, tokenAuthMutation, defaultEmail) {
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState()

  const [email, setEmail] = useState(defaultEmail)
  const [password, setPassword] = useState('')

  const cleanedEmail = email.trim().toLowerCase()
  const cleanedPassword = password.trim()

  const isEmailValid = regexps.email(cleanedEmail)
  const isPasswordValid = cleanedPassword.length > 0

  const emailError = submitted && !isEmailValid && 'Oops, check your email format.'
  const passwordError = submitted && !isPasswordValid && 'Incorrect password'

  const error = getError([
    submitError,
    emailError,
    passwordError,
    authMutation.error?.message,
    tokenAuthMutation.error?.message
  ])

  const isSubmitDisabled = (
    authMutation.status === 'loading' ||
    tokenAuthMutation.status === 'loading' ||
    emailError ||
    passwordError
  )

  const resetMutationOn = useResetMutationOnSet(authMutation, tokenAuthMutation)

  return {
    email,
    password,
    setEmail: resetMutationOn(setEmail),
    setPassword: resetMutationOn(setPassword),
    isEmailValid,
    isPasswordValid,
    emailError,
    passwordError,
    isSubmitDisabled,
    error,
    setSubmitted,
    setSubmitError
  }
}