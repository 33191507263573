import Message from './Message'
import Offline from './Offline'
import Success from './Success'
import Deleted from './Deleted'
import Info from './Info'

Message.Offline = Offline
Message.Success = Success
Message.Deleted = Deleted
Message.Info = Info

export default Message