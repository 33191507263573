import React from 'react'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {createUseStyles} from 'react-jss'
import {useActions} from '../../../../actions'
import {Button} from 'mtuikit'
import Layout from './layout'
import {useCompleteOnboarding, useOrderQuery, useSmstoolApi} from '../../../../hooks'
import {getIsTopTutorAddonActive} from '../../../../nerdytutors'

const OnboardRequestTopTutorAddon = ({order_id, subject, data, ...props}) => {
  const classes = useStyles()
  const actions = useActions()
  const orderQuery = useOrderQuery(order_id)
  const smstool = useSmstoolApi()

  useCompleteOnboarding(order_id, () => {
    actions.showSupportChat()
    smstool.sendMessage(
      "Welcome to NerdyTutors! My name is Ann :)\n" +
      "I will check your task instructions to ensure that it has all the needed information for our tutors. \n" +
      "\n" +
      "Please give me 15 minutes to check everything, and I will message you here with clarification questions or confirmation that everything is clear and your tutor is on the way."
    )
  })

  const {data: order} = orderQuery
  if (!orderQuery.isSuccess) return <div style={{height: 252}}/>

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <Card {...props} data={{...data}}>
      <Layout.Body>
        <img className={classes.promoImg} src={require(`../images/promo.png`).default}
             srcSet={`
            ${require(`../images/promo@3x.png`).default} 3x,
            ${require(`../images/promo@2x.png`).default} 2x,
            ${require(`../images/promo.png`).default} 1x
          `}
             alt=''
        />
        <Layout.Title>Want a superhero?</Layout.Title>
        <Layout.Text>Get matched with one of our TOP experts in <Layout.B>{subject}</Layout.B>.</Layout.Text>
        <Layout.Buttons>
          {
            !getIsTopTutorAddonActive(order) && !order.assigned && !order.connected?.connected_at &&
            <Button className={classes.button} type={'promo2'} block
                    onClick={() => actions.showBuyTopTutorAddon(order_id, 'client_tutor_chat')}>
              <img className={classes.buttonIcon} src={require(`../images/ic_awersome.svg`).default} alt=''/>
              Get a TOP tutor $9.95
            </Button>
          }
        </Layout.Buttons>
      </Layout.Body>
      <Card.Footer {...props} data={{...data}}/>
    </Card>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  promoImg: {
    display: 'block',
    width: 112,
    height: 68,
  },
  button: {
    boxShadow: '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
  },
  buttonIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  }
})
export default OnboardRequestTopTutorAddon
