import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import moment from 'moment'
import {Icon} from 'mtuikit'
import theme, {rem, adaptive, desktop} from 'theme/base'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #EBEEF5',
    borderRadius: 8,
    cursor: 'pointer',
/*
    width: '20%',
    height: rem(40),
    padding: [rem(10), 0],
    color: theme.colors.slateGrey,
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& + &': {
      borderLeft: [1, 'solid', theme.colors.paleGrey],
    },
    '&:first-child': {
      borderRadius: [8, 0, 0, 8],
    },
    '&:last-child': {
      borderRadius: [0, 8, 8, 0],
    },
*/
    [adaptive]: {
      justifyContent: 'space-between',
      padding: [8,12,8,8],
    },
    [desktop]: {
      flexDirection: 'column',
      textAlign:  'center',
      padding: [16, 0, 12],
      '&:hover': {
        background: '#F0F4FF',
        borderColor: '#F0F4FF',
      },
    },
    '&:active': {
      background: '#E0E9FF',
      borderColor: '#E0E9FF',
    },
    '&.active': {
      background: '#E0E9FF',
      borderColor: '#E0E9FF',
    },
/*
    '&.custom': {
      width: 'auto',
      paddingLeft: rem(16),
      paddingRight: rem(42),
    },
*/
    '&.disabled': {
      background: '#dde3f1',
      borderColor: '#dde3f1',
      color: theme.colors.blueyGrey,
      cursor: 'default',
    },
    '&.error': {
      background: theme.colors.brightOrangeTwo,
      borderColor: theme.colors.brightOrangeTwo,
      color: theme.colors.white,
      cursor: 'default',
    },
  },
  icon: {
    display: 'block',
    width: 24,
    height: 24,
  },
  title: {
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    color: '#3D77FF',
    [adaptive]: {
      marginLeft: 8,
    },
    [desktop]: {
      marginTop: 8,
    },
  },
  dueDate: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
    [adaptive]: {
      marginLeft: 'auto',
    },
    [desktop]: {
      marginBottom: 8,
    },
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [0, 8],
    height: 20,
    background: 'rgba(73, 176, 0, 0.2)',
    borderRadius: 48,
    [adaptive]: {
      width: 64,
      marginLeft: 8,
    },
    [desktop]: {
      marginTop: 'auto',
    },
    '&.asap': {
      background: '#FFEEB8',
      '& $labelIcon': {
        fill: '#E37900',
      },
      '& $labelMessage': {
        color: '#E37900',
      }
    },
  },
  labelIcon: {
    display: 'block',
    width: 12,
    height: 12,
    fill: '#489900',
  },
  labelMessage: {
    fontWeight: 800,
    fontSize: 10,
    lineHeight: '16px',
    color: '#489900',
  },

  reset: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(4),
    right: rem(4),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}


const Preset = ({
  classes,
  className,
  active,
  disabled,
  error,
  itemPreset,
  onClick,
  onReset,
}) =>
  <div
    className={classnames(classes.root, className, { active, disabled, error, 'custom': onReset })}
    onClick={onClick}
  >
    <Icon className={classes.icon} iconName={`${itemPreset.icon}`} />
    <div className={classes.title}>{itemPreset.title}</div>
    <div className={classes.dueDate}>
      {itemPreset.labelType && itemPreset.labelType === 'asap' ?
          <>In next 6 hrs</>
          : <>Due {moment().add(itemPreset.hours + 1, 'h').startOf('hour').format('ddd, hA')}</>
      }
    </div>
    <div className={classnames(classes.label, { 'asap': itemPreset.labelType && itemPreset.labelType === 'asap' })}>
      {itemPreset.labelIcon && <Icon className={classes.labelIcon} iconName={`${itemPreset.labelIcon}`} />}
      <div className={classes.labelMessage}>{itemPreset.labelMessage}</div>
    </div>

    {onReset &&
      <div className={classes.reset} onClick={onReset}>
        <Icon iconName='close-transparent' />
      </div>
    }
  </div>

export default injectSheet(styles)(Preset)
