import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'
import {useOrderNotificationsMutation, useOrderQuery} from '../../hooks'
import ToggleSwitch from "../../ui/ToggleSwitch";
import {Popover} from 'react-tiny-popover'
import {useExplainedPushNotifications} from './../../hooks'
import {useSupportChatContext} from '../SupportChat'

function useOrderNotifications(orderId) {
  const order = useOrderQuery(orderId).data
  const mutation = useOrderNotificationsMutation()

  const {isError, isReady, isPending, isGranted, isDenied, subscribe} = useExplainedPushNotifications({
    title: 'Your browser will now tell you that NerdyTutors want to show you notifications.',
    text: 'Allow them to get notified about new messages.'
  })

  const optout_notifs = order?.notifications?.optout_notifs
  const isEnabled = isPending ? false : !optout_notifs

  const toggle = () => {
    subscribe().then(({permission = false}) => {
      if (permission === 'granted') {
        mutation.mutate({orderId, isEnabled: !isEnabled})
      }
    })
  }

  return {
    isError, //iOS
    isPending,
    isGranted,
    isDenied,
    isEnabled,
    toggle
  }
}

const OrderNotificationsSwitch = ({orderId}) => {
  const classes = useStyle()
  const {
    isError,
    isGranted,
    isDenied,
    isEnabled,
    toggle
  } = useOrderNotifications(orderId)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const supportChat = useSupportChatContext()

  if (isError) return null

  return <div className={classes.notificationSection}>
    <ToggleSwitch checked={isEnabled} disabled={isDenied} onChange={toggle}/>
    <div
      className={classes.notification}>{isEnabled ? 'Browser notifications is on' : 'Turn on browser notifications'}</div>
    <div>
      <Popover
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={['bottom']}
        content=
          {isDenied ?
            <div className={classes.tooltip}>Browser notifications were denied. You can modify this in your browser
              settings.<br/><Link className={classes.tooltipLink} to={'/browser-instructions'}>Learn how to fix</Link>
            </div>
            : isGranted && isEnabled ?
              <div className={classes.tooltip}>Get notified about messages from tutors & support team. Works on Windows,
                Mac and Android phones. Toggle off to mute this order.</div>
              :
              <div className={classes.tooltip}>Get notified about messages from tutors & support team. Works on Windows,
                Mac and Android phones. <span className={classes.tooltipLink} onClick={() => supportChat.open()}>Message support</span> if
                you need aid.</div>
          }
      >
        <div className={classes.iconWrapper}
             onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <svg className={classes.tooltipIcon} width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6Z"
              fill="currentColor"/>
            <path
              d="M6 4.14587C6.35449 4.14587 6.62842 3.87598 6.62842 3.5376C6.62842 3.19519 6.35449 2.92932 6 2.92932C5.64551 2.92932 5.37158 3.19519 5.37158 3.5376C5.37158 3.87598 5.64551 4.14587 6 4.14587ZM6 9.08459C6.36255 9.08459 6.58813 8.85498 6.58813 8.47229V5.20935C6.58813 4.82666 6.36255 4.59302 6 4.59302C5.63342 4.59302 5.41187 4.82666 5.41187 5.20935V8.47229C5.41187 8.85498 5.63342 9.08459 6 9.08459Z"
              fill="currentColor"/>
          </svg>
        </div>
      </Popover>
    </div>
  </div>
}

const useStyle = createUseStyles({
  notificationSection: {
    borderTop: '1px solid #EBEEF5',
    borderBottom: '1px solid #EBEEF5',
    padding: [16, 0],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notification: {
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    color: '#2F333D',
    marginLeft: 8,
  },
  tooltip: {
    background: '#FFFFFF',
    boxShadow: '0px 6px 9px rgba(0, 38, 96, 0.06), 0px 6px 30px rgba(0, 53, 136, 0.15)',
    borderRadius: 6,
    width: 278,
    padding: 9,
    fontSize: 12,
    lineHeight: '16px',
    color: '#2F333D',
    marginTop: 6,
  },
  iconWrapper: {
    marginLeft: 6,
  },
  tooltipIcon: {
    display: 'block',
    width: 12,
    height: 12,
    color: '#939FB8',
    '&:hover': {
      color: '#3D77FF',
    },
  },
  tooltipLink: {
    fontWeight: 700,
    color: '#3D77FF',
    cursor: 'pointer',
  }
})

export default OrderNotificationsSwitch
