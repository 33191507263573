import React from 'react'
import injectSheet from 'react-jss'
import {rem, content, media, desktop, adaptive, centered, MODAL_WIDTH_DESKTOP} from './../../theme/base'

const styles = {
  root: {
    [desktop]: {
      width: '100%',
      minHeight: '100vh',
    },
  },
  wrapper: {
    position: 'relative',
    [desktop]: {
      width: rem(MODAL_WIDTH_DESKTOP),
      marginTop: rem(16),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  content: {
    ...content,
    [adaptive]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [desktop]: {
      width: rem(media.desktop),
      minHeight: 'inherit',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  centered: {
    [desktop]: {
      ...centered,
      minHeight: 'inherit',
    }
  },
  logo: {
    display: 'none',
    [desktop]: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: rem(36),
      textAlign: 'center',
      display: 'block',
    },
    '& a': {
      width: rem(160),
      height: rem(28),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const DesktopWrapperHOC2 = ({
                              classes,
                              children,
                              blueLogo,
                            }) => (
  <div className={classes.root}>
    <div className={classes.content}>
      {/*<div className={classes.logo}>*/}
      {/*  <Link to='/'>*/}
      {/*    {blueLogo*/}
      {/*      ? <img src={require('../../assets/images/logo-blue.svg').default} alt=''/>*/}
      {/*      : <img src={require('../../assets/images/logo-white.svg').default} alt=''/>*/}
      {/*    }*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <div className={classes.centered}>
        <div className={classes.wrapper}>
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(styles)(DesktopWrapperHOC2)