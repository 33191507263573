import Layout from './Layout'

const Prompt = Layout.Wrapper

Prompt.Actions = Layout.Actions
Prompt.Button = Layout.Button
Prompt.Details = Layout.Details
Prompt.Icon = Layout.Icon
Prompt.Title = Layout.Title

export default Prompt