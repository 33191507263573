export const wrapPlaceholder = string =>
  string
    .replace(/\[(.*?)\]/ig, '<span>[$1]</span>')
    .replace(/\n/g, '<br>')

export const unwrapPlaceholder = string =>
  string
    .replace(/<br>/g, '\n')
    .replace(/<div>/g, '\n')
    .replace(/<\/div>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/(<([^>]+)>)/g, '')

export const validTemplate = string => !string.match(/\[|\]/)