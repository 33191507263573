import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {colors, typography} from './consts'
import {utils} from 'mtuikit'
import {Popover} from 'react-tiny-popover'
import Icon from '../icons'
import moment from 'moment'
import Countdown from '../../Countdown'
import useToggleInTime from '../../../utils/useToggleInTime'

const Boarding = ({order}) => {
  const classes = useStyle()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const connected = !!(order.connected && order.connected.connected_at)
  const assigned = !!order.assigned
  const completed = !!order.completed
  const under_revision = !!(order.revision && order.revision.status === 'accepted')
  const completed_revision = !!(order.revision && order.revision.status === 'completed')
  const checking_instructions = order.checking_instructions === 'in_progress' && !connected && !assigned

  const checkInstructionsDeadline = moment(order.paid.paid_at).add(10, 'm')
  const toggledCheckingInstructions = useToggleInTime(checking_instructions, checkInstructionsDeadline)
  const isCheckingInstructions = checkInstructionsDeadline > moment() && toggledCheckingInstructions

  if (order.refunded) return <>
    <div className={classes.d}/>
    <div className={classes.statusList}>

      <div className={classes.statusItem}>
        <Icon.Done/>
        <div className={classes.statusTitle}>Task placed</div>
      </div>

      <div className={classes.statusItem}>
        <Icon.Done/>
        <div>
          <div className={classes.statusTitle}>Refunded</div>
          <div className={classes.statusSubtitle}>{utils.printAmount(order.refunded.amount)}</div>
        </div>
      </div>

    </div>
  </>

  return <>
    <div className={classes.d}/>
    <div className={classes.statusList}>

      <div className={classes.statusItem}>
        <Icon.Done/>
        <div className={classes.statusTitle}>Task placed</div>
      </div>

      <div className={classes.statusItem}>
        {
          (connected || assigned || !checking_instructions)
            ? <Icon.Done/>
            : <div className={classes.rotating}><Icon.InProgress/></div>
        }
        <div>
          <div className={classes.statusTitle}>Checking your instructions</div>
          {
            checking_instructions && isCheckingInstructions &&
            <div className={classes.statusSubtitle}>
              We will message you in chat to clarify the details of your task within 10 minutes.
              <div className={classes.badge}>
                <img className={classes.badgeIcon} src={require('../images/ic_timer.svg').default} alt=''/>
                <div className={classes.badgeTime}><Countdown dl={checkInstructionsDeadline}/></div>
              </div>
            </div>
          }
        </div>
      </div>

      <div className={classes.statusItem}>
        {
          checking_instructions
            ? <Icon.Idle/>
            : (connected || assigned)
              ? <Icon.Done/>
              : <div className={classes.rotating}><Icon.InProgress/></div>
        }
        <div>
          {
            (connected || assigned)
              ? <div className={classes.statusTitle}>Tutor connected</div>
              : <>
                <div className={classes.statusTitle}>Searching for a tutor</div>
                {
                  !checking_instructions &&
                  <div className={classes.statusSubtitle}>
                    Usually it takes up to 60 mins
                    <Popover
                      isOpen={isPopoverOpen}
                      positions={['right']}
                      content={<div className={classes.tooltip}>Usually it takes from 15 min to 3 hours to match you with
                        the best tutor depending on a task complexity and how much details about the task provided in the
                        instructions.</div>}
                    >
                      <div className={classes.iconWrapper}
                           onMouseEnter={() => setIsPopoverOpen(true)}
                           onMouseLeave={() => setIsPopoverOpen(false)}>
                        <Icon.Info/>
                      </div>
                    </Popover>
                  </div>
                }
              </>
          }
        </div>
      </div>

      <div className={classes.statusItem}>
        {
          !(connected || assigned)
            ? <Icon.Idle/>
            : assigned
              ? <Icon.Done/>
              : <Icon.InProgress/>
        }
        <div className={classes.statusTitle}>
          {
            assigned
              ? 'Task accepted'
              : 'Task discussion with a tutor'
          }
        </div>
      </div>

      <div className={classes.statusItem}>
        {
          !assigned
            ? <Icon.Idle/>
            : completed
              ? <Icon.Done/>
              : <Icon.InProgress/>
        }
        <div className={classes.statusTitle}>
          {
            completed
              ? 'Tutor has finished the task'
              : 'Tutor is working on your task'
          }
        </div>
      </div>

      <div className={classes.statusItem}>
        {
          !completed
            ? <Icon.Idle/>
            : <Icon.Done/>
        }
        <div>
          {
            under_revision
              ? <>
                <div className={classes.statusTitle}>Task is under revision</div>
                <div className={classes.statusSubtitle}>Revision will be done by <span
                  className={classes.dateWrapper}>{moment(order.revision.deadline).format('MMM D [at] LT')}</span></div>
              </>
              : completed_revision
                ? <div className={classes.statusTitle}>Revision completed</div>
                : <div className={classes.statusTitle}>Task completed</div>
          }
        </div>
      </div>
    </div>
  </>
}

const useStyle = createUseStyles({
  d: {
    margin: '16px 0 16px 52px',
    height: '1px',
    background: '#EBEEF5'
  },

  statusList: {
    padding: '0 0 0 10px'
  },

  statusItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& + &': {
      marginTop: '13px',
    },
    '& > svg': {
      display: 'block',
      marginRight: '18px',
      flexShrink: 0,
    },
  },
  statusTitle: {
    ...typography.x,
    color: colors.black2,
    lineHeight: '20px',
    marginTop: 2,
  },
  statusSubtitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: colors.black1,
    marginTop: 2,
    '& > svg': {
      cursor: 'pointer',
      marginLeft: '4px',
      verticalAlign: '-1px',
    }
  },
  badge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    width: 64,
    height: 20,
    background: '#FFEEB8',
    borderRadius: 48,
    marginTop: 8,
  },
  badgeIcon: {
    display: 'block',
    width: 12,
    height: 12,
  },
  badgeTime: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '16px',
    color: '#E37900',
  },
  dateWrapper: {
    whiteSpace: 'nowrap',
  },

  tool: {
    '&:hover $tooltipWrapper': {
      visibility: 'visible',
    },
    '&:hover $number': {
      color: '#3D77FF',
    },
    'svg': {
      color: '#2F333D',
      transition: 'all .3s ease',
    },
    '&:hover svg': {
      transition: 'all .3s ease',
      color: '#3D77FF',
    },
  },

  iconWrapper: {
    display: 'inline-block',
    verticalAlign: '-2px',
    marginLeft: '4px',
    cursor: 'pointer',
    'svg': {
      color: '#939FB8'
    },
    '&:hover svg': {
      color: '#3D77FF'
    }
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '278px',
    marginLeft: '10px',
    marginBottom: '30px',
    background: '#FFF',
    boxShadow: '0px 6px 9px rgba(0, 38, 96, 0.06), 0px 6px 30px rgba(0, 53, 136, 0.15)',
    borderRadius: '6px',
    padding: '9px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F333D',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '100%',
      top: '48px',
      width: 0,
      height: 0,
      borderRight: '6px solid #fff',
      borderTop: '6px solid transparent',
      borderBottom: '6px solid transparent',
    }
  },
  rotating: {
    flexShrink: 0,
    marginRight: '18px',
    '& > svg': {
      display: 'block',
      animation: 'rotating 1s linear infinite'
    },
  },
})

export default Boarding
