import React, {useState} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, {rem} from '../../landings/theme/base'
import {Input, InputPhone, Icon} from 'mtuikit'

const styles = {
  root: {
    marginTop: rem(12),
    paddingRight: rem(24),
    paddingBottom: rem(7),
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
    '&.error': {
      borderColor: theme.colors.brightOrange,
    },
    '&.name': {
      width: `calc(100% - ${rem(83)})`,
      marginLeft: rem(19),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '&.phone': {
      marginTop: rem(24),
    },
    '& input, input.form-control[disabled]': {
      padding: 0,
      fontSize: rem(17),
      lineHeight: rem(28),
      '&:-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-text-fill-color': {
        color: theme.colors.blueyGrey,
      },
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: 0,
    top: rem(22),
  },
  iconLabel: {
    display: 'block',
    cursor: 'pointer',
  },
  icon: {
    width: rem(16),
    height: rem(16),
  },

  input: {
    '&:disabled': {
      '-webkit-text-fill-color': theme.colors.charcoalGrey,
      opacity: 1,
    },
  },
  label: {
    fontSize: rem(12),
    lineHeight: rem(20),
    color: theme.colors.blueyGrey,
    opacity: 0,
    marginBottom: rem(2),
    cursor: 'pointer',
    '&.show': {
      opacity: 1,
    },
  },
  avatar: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '& .icon': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      bottom: rem(-6),
      right: 0,
      cursor: 'pointer',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  errorMessage: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.colors.brightOrange,
    marginTop: 8,
  },
}

const Field = ({
                 classes,
                 name,
                 value,
                 type,
                 placeholder,
                 disabled,
                 error,
                 changed,
                 onChange,
                 onClear
               }) => {
  const [focus, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
  }

  // TO DO: сделать обработчик blur так, чтобы сначала срабатывал click
  const onBlur = () => setTimeout(() => setFocus(false), 150)

  const inputProps = {
    id: name,
    type,
    name,
    placeholder,
    value,
    error,
    changed,
    onChange,
    onFocus,
    onBlur,
    disabled,
  }

  return (
      <>
        <div className={classnames(classes.root, name, {'error': error})}>
          <label className={classnames(classes.label, {'show': value})} htmlFor={name}>
            {placeholder}
          </label>

          {type === 'tel'
              ? <InputPhone {...inputProps} />
              : <Input {...inputProps} className={classes.input}/>
          }
          {value && !focus && !disabled && !changed &&
          <label className={classnames(classes.iconWrapper, classes.iconLabel)} htmlFor={name}>
            <Icon className={classes.icon} iconName='ic_edit'/>
          </label>
          }
          {(!disabled && focus || changed) &&
          <div className={classnames(classes.iconWrapper, classes.iconLabel)} onClick={onClear}>
            <Icon className={classes.icon} iconName='close-gray'/>
          </div>
          }
          {disabled &&
          <div className={classes.iconWrapper}>
            <Icon className={classes.icon} iconName='lock2' styles={{fill: theme.colors.blueyGrey}}/>
          </div>
          }
        </div>
        {error && <div className={classes.errorMessage}>{error}</div>}
      </>
  )
}

export default injectSheet(styles)(Field)
