import React from 'react'
import { Button as ThemeButton } from '../../../../mtuikit'

export const Button = ({
  className,
  type = 'outline',
  onClick,
  children,
}) => 
  <ThemeButton
    size='large'
    className={className}
    type={type}
    onClick={onClick}
  >
    {children}
  </ThemeButton>