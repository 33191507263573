import React, {useState} from 'react'
import moment from 'moment'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import theme, {rem} from '../../../../theme/base'
import Datepicker from './Datepicker'
import Timepicker from './Timepicker'
import Presets from './Presets'
import Preset from './Preset'
import {Error} from '../Error'
import {DEADLINE_PRESETS2, checkRestriction, getPrice} from '../../../../nerdytutors'
import {utils, Icon} from 'mtuikit'

const useStyle = createUseStyles({
  action: {
    width: '100%',
    height: 'inherit',
    position: 'relative',
  },
  helper: {
    background: '#F6F7FB',
    padding: [rem(12), rem(48), rem(12), rem(16)],
    fontSize: rem(14),
    lineHeight: rem(20),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    borderRadius: rem(8),
    textAlign: 'left',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
    '&.active': {
      borderColor: theme.colors.blue,
      color: theme.colors.blue,
      fontWeight: 600,
    },
    '&.error': {
      background: 'rgba(255, 87, 0, 0.08)',
    },
    '& > img': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: '50%',
      right: rem(12),
      transform: 'translateY(-50%)',
      display: 'block',
    },
  },
  input: {
    width: `calc(50% - ${rem(4)})`,
    height: rem(44),
    marginTop: rem(16),
    left: '-200%',
    top: '-200%',
    position: 'absolute',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: rem(8),
    },
    '&.custom': {
      position: 'relative',
      left: 'auto',
      top: 'auto',
    },
    '&.withoutPresets': {
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      left: 'auto',
      top: 'auto',
    },
  },
  customBtn: {
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 23,
  },
  customBtnIcon: {
    display: 'block',
    width: 24,
    height: 24,
  },
  customBtnText: {
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '20px',
    color: '#3D77FF',
    marginLeft: 4,
  },
  priceRate: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
    marginTop: 4,
    '& strong': {
      fontWeight: 700,
    },
  }
})

const isCustomDeadline = (deadline, deadlineset) => deadlineset.indexOf(deadline) === -1

export const SelectDeadline = ({
                                 helpType,
                                 deadline,
                                 minDeadline,
                                 size,
                                 deadlineset,
                                 onChange,
                                 withoutPresets,
                                 dateError,
                                 timeError,
                               }) => {
  const classes = useStyle()

  const [custom, setCustom] = useState(isCustomDeadline(deadline, deadlineset))
  const [showTimepicker, setShowTimepicker] = useState(false)
  const [showDatepicker, setShowDatepicker] = useState(false)
  const [autoShowTimePicker, setAutoShowTimePicker] = useState(false)

  const onShowDatepicker = () => setShowDatepicker(true)
  const onHideDatepicker = () => setShowDatepicker(false)
  const onShowTimepicker = () => setShowTimepicker(true)
  const onHideTimepicker = () => setShowTimepicker(false)

  const chooseDifferentDeadline = () => {
    setCustom(true)
    setAutoShowTimePicker(true)
    onShowDatepicker()
  }

  const changeDate = (value) => {
    const newDeadline = utils.getDeadlineInHours(value) || minDeadline
    onChange(newDeadline)
  }

  const minDate = moment().startOf('hour').add(minDeadline + 1, 'h')
  const date = deadline
    ? moment().startOf('hour').add(deadline + 1, 'h')
    : minDate

  return <>
    <Presets type='deadline' withoutPresets={withoutPresets}>
      {!withoutPresets && deadlineset.map((item, index) => {
        const active = item === deadline && !custom
        const disabled = checkRestriction(size, item, helpType)
        const error = active && disabled
        return <Preset
          key={index}
          active={active}
          disabled={disabled}
          error={error}
          itemPreset={DEADLINE_PRESETS2[index]}
          onClick={() => {
            !disabled && !error && onChange(item)
            custom && setCustom(false)
          }}
        />
      })}
    </Presets>

    {!custom ?
      <button className={classes.customBtn} onClick={chooseDifferentDeadline}>
        <Icon className={classes.customBtnIcon} iconName={'ic_date_select'}/>
        <div className={classes.customBtnText}>Select due date</div>
      </button>
      :
      <button className={classes.customBtn}>
        <Icon className={classes.customBtnIcon} iconName={'ic_date_reset'}/>
        <div className={classes.customBtnText}>Reset due date</div>
      </button>
    }

    <div>
      <div className={classnames(classes.input, {custom, withoutPresets})}>
        <div className={classes.action} onClick={onShowDatepicker}>
          <div className={classnames(classes.helper, {
            withoutPresets,
            'active': showDatepicker,
            'error': dateError,
          })}>
            {moment(date).format('MMMM D')}
            <img src={require('./Icons/datepicker.svg').default} alt=''/>
          </div>
        </div>
        {showDatepicker &&
        <Datepicker
          date={date.toDate()}
          minDate={minDate.toDate()}
          onChange={changeDate}
          onClose={() => {
            onHideDatepicker()
            autoShowTimePicker && onShowTimepicker()
          }}
        />
        }
      </div>
      <div className={classnames(classes.input, {custom, withoutPresets})}>
        <div className={classes.action} onClick={onShowTimepicker}>
          <div className={classnames(classes.helper, {
            withoutPresets,
            'active': showTimepicker,
            'error': timeError,
          })}>
            {date.format('h A')}
            <img src={require('./Icons/timepicker.svg').default} alt=''/>
          </div>
        </div>
        {showTimepicker &&
        <Timepicker
          date={date.toDate()}
          minDate={minDate.toDate()}
          onChange={changeDate}
          onClose={() => {
            onHideTimepicker()
            autoShowTimePicker && setAutoShowTimePicker(false)
          }}
        />
        }
      </div>
      {(dateError || timeError) ?
        <Error>Please give our tutors more time</Error>
        : custom &&
        <div
          className={classes.priceRate}>Price <strong>{utils.printAmount(getPrice(1, deadline, 0).price)}/hr</strong> for
          this due date</div>
      }
    </div>
  </>
}
