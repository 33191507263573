import React from 'react'
import Alert from './../Alert'

export const ReportSentAlert = (props) => <Alert
  icon={require('./complete-icon.svg').default}
  title='Report sent'
  details={[
    'Thanks for letting us know about the problem. Our customer support will contact you soon.',
  ]}
  label='OK'
  {...props}
/>

export const GoodRateAlert = (props) => <Alert
  icon={require('./rate-icon.svg').default}
  title='Thank you for sharing your feedback!'
  details={[
    'Our tutors are looking forward to helping you in the future!',
  ]}
  label='Got it!'
  {...props}
/>

export const BadRateAlert = (props) => <Alert
  icon={require('./sad-icon.svg').default}
  title="We are really sorry you've had a negative experience"
  details={[
    'Our team will review your  feedback and get back to you ASAP.',
  ]}
  label='Got it!'
  {...props}
/>
