import React from 'react'
import {createUseStyles} from 'react-jss'
import theme, {rem, media} from './../../theme/base'
import Collapsable from './ui/Collapsable'
import Stat from './ui/Stat'
import TutorInfo from './TutorInfo'
import EditPhoneBanner from './EditPhoneBanner'
import LiveSessions from './LiveSessions'
import OrderNumber from './OrderNumber'
import Instructions from './Instructions'
import ChatFiles from './ChatFiles'
import Information from './Information'
import OrderNotificationsSwitch from './OrderNotificationsSwitch'
import {useOrderQuery} from '../../hooks'
import {utils} from 'mtuikit'
import getDeadlineStatus from './utils/getDeadlineStatus'
import {useActions} from '../../actions'
import {trackValues} from '../../lrtracker'

const OrderDetails = ({orderId}) => {
  const classes = useStyle()
  const actions = useActions()
  const orderQuery = useOrderQuery(orderId)

  const isDesktop = !utils.useMobile(media.desktop)

  if (!orderQuery.isSuccess) return null
  const {data: order} = orderQuery

  const requestLiveSession = () => {
    actions.showRequestLiveSessionAddon(orderId)
    trackValues('chatClientTutor', 'clientRequestLiveSessionBtnClick', {
      context: {
        id: orderId,
        type: 'Sidebar-accordion-menu'
      }
    })
  }

  return <div className={classes.root} key={orderId}>

    {isDesktop && <div className={classes.header}>
      <OrderNumber orderNumber={order.number}/>
    </div>}

    <div>
      <div className={classes.wrapper}>
        <TutorInfo orderId={order.id}/>
        <EditPhoneBanner orderId={order.id}/>
      </div>
    </div>

    {/*<div>*/}
    {/*  <div className={classes.wrapper}>*/}
    {/*    <TestButtons orderId={order.id}/>*/}
    {/*  </div>*/}
    {/*</div>*/}

    <div>
      <div className={classes.wrapper}>
        <Stat>
          <Stat.Item
            iconName='time_decorative'
            title='Paid for:'
            value={order.size}
          />
          <Stat.Item
            iconName='calendar_decorative'
            title={getDeadlineStatus(order)[0] || "Task will be done by"}
            value={utils.printDateTime(getDeadlineStatus(order)[1])}
          />
        </Stat>
        <LiveSessions orderId={order.id}/>
      </div>
    </div>

    {/*<div className={classnames({[classes.highlighted]: onboardParams.firstTimeClient && onboard.length === 6})}>*/}
    <div>
      <div className={classes.wrapper}>
        <Collapsable
          open={!order.assigned && !order.refunded}
          title='Instruction for a tutor'
          content={<>
            <Instructions orderId={order.id}/>
            {
              !order.assigned && !order.refunded &&
              <div className={classes.edit} onClick={() => actions.showAddTaskInstructions(orderId)}>
                <img src={require('./images/edit.svg').default} alt=''/>Add files & instructions
              </div>
            }
          </>}
        />
      </div>
    </div>

    <div>
      <div className={classes.wrapper}>
        <Collapsable
          open={!!order.completed && !!order.assigned && !order.refunded && !(order.revision && order.revision.status === 'accepted')}
          title='Files from conversation'
          content={<ChatFiles orderId={order.id}/>}
        />
      </div>
    </div>

    <div>
      <div className={classes.wrapper}>
        <Collapsable
          title='Task information'
          content={<Information orderId={order.id}/>}
        />
      </div>
    </div>

    <div>
      <div className={classes.wrapper}>
        <button className={classes.linkButton}
                disabled={order.live_session_request || (order.live_sessions || []).length > 0 || order.closed}
                onClick={requestLiveSession}>
          Request a live session
        </button>
      </div>
    </div>

    <div>
      <div className={classes.wrapper}>
        <OrderNotificationsSwitch orderId={order.id}/>
      </div>
    </div>

    <div className={classes.chatPadding}/>
  </div>
}

const useStyle = createUseStyles({
  root: {
    position: 'relative',
    minHeight: '100%',
  },
  highlighted: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: theme.colors.black60,
      zIndex: 999,
    },
    '& $wrapper': {
      position: 'relative',
      left: 0,
      right: 0,
      background: '#fff',
      zIndex: 1000,
    }
  },
  wrapper: {
    padding: [0, rem(16)],
  },
  chatPadding: {
    paddingBottom: rem(100),
  },
  header: {
    borderBottom: '1px solid #EBEEF5',
    padding: [rem(16), rem(18)],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  linkButton: {
    background: 'transparent',
    padding: [rem(15), 0],
    fontWeight: 800,
    fontSize: rem(14),
    lineHeight: rem(20),
    color: '#2F333D',
    '&:disabled': {
      color: '#BAC7E2',
    },
  },
  edit: {
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3D77FF',
    marginTop: 16,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 8
    }
  },
})

export default OrderDetails
