import React, {useRef} from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import Overlay from '../Overlay'
import ScrollbarWrap from '../ScrollbarWrap'
import theme, {rem} from 'theme/base'
import {utils} from 'mtuikit'
import useOutsideClick from '../../../../utils/useOutsideClick'
import {desktop, adaptive, media} from 'mtuikit/theme/base'

const styles = {
  list: {
    width: '100%',
    [desktop]: {
      height: 264,
    },
    [adaptive]: {
      height: '100%',
    },
  },
  item: {
    height: 48,
    padding: [0, 16],
    cursor: 'pointer',
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: 16,
    lineHeight: 48 / 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(61, 119, 255, 0.08)',
    },
    '&.active': {
      background: theme.colors.blue,
      color: theme.colors.white,
    }
  }
}

const scrollbarWrapPropsDesktop = {
  className: 'scrollbar',
  style: {
    background: theme.colors.white,
    width: 'auto',
    position: 'absolute',
    left: 16,
    right: 48,
    top: 132,
    borderRadius: 8,
    boxShadow: `0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06), 0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15)`,
    zIndex: 4,
    [adaptive]: {
      height: '75vh',
      borderRadius: '8px 8px 0 0',
    },
  }
}

const scrollbarWrapPropsMobile = {
  className: 'scrollbar',
  style: {
    background: theme.colors.white,
    maxHeight: '75vh',
    height: '75vh',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '8px 8px 0 0',
    boxShadow: `0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06), 0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15)`,
    zIndex: 4,
  },
  maxHeight: '75vh',
}

const DesktopDropdown = ({
                           onChange,
                           onBlur,
                           items,
                           defaultvalue,
                           classes
                         }) => {
  const outsideClickRef = useRef(null)
  useOutsideClick(outsideClickRef, onBlur)
  const isMobile = utils.useMobile(media.desktop)
  const scrollbarWrapProps = isMobile
    ? scrollbarWrapPropsMobile
    : scrollbarWrapPropsDesktop
  return (
    <>
      <Overlay adaptive/>
      <ScrollbarWrap {...scrollbarWrapProps}>
        <ul
          ref={outsideClickRef}
          className={classnames(classes.list)}>
          {items.map(({key, value, textStyled, icon}) =>
            <li
              key={key}
              className={classnames(classes.item, {'active': defaultvalue === value})}
              onClick={() => onChange(value)}
              dangerouslySetInnerHTML={{__html: `${textStyled} ${icon}`}}
            />
          )}
        </ul>
      </ScrollbarWrap>
    </>
  )
}

export default injectSheet(styles)(DesktopDropdown)