import {colors} from './colors'

export const media = {
  mobile: 320,
  desktop: 1024,
  desktop1280: 1280,
}

export const adaptive = `@media (min-width: ${media.mobile}px) and (max-width: ${media.desktop - 1}px)`
export const desktop = `@media (min-width: ${media.desktop}px)`
export const desktop1280 = `@media (min-width: ${media.desktop1280}px)`

const mainFontSize = 16

const fontFamilies = {
  SFProRoundedBlack: 'sf_pro_roundedblack',
  SFProRoundedRegular: 'sf_pro_roundedregular',
  SFProRoundedSemibold: 'sf_pro_roundedsemibold',
  Manrope: 'Manrope',
}

export const rel = (value, rel, unit = '') => `${value / rel}${unit}`
export const rem = (value) => {
  if (typeof value === 'string') {
    return value.split(' ').map(v => rel(v, mainFontSize, 'rem')).join(' ')
  }
  return rel(value, mainFontSize, 'rem')
}

export const clearfix = {
  '&:after': {
    content: "''",
    display: 'table',
    clear: 'both',
  }
}

export const content = {
  width: '100%',
  paddingLeft: rem(16),
  paddingRight: rem(16),
  position: 'relative',
}

export const centered = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const justified = {
  display: 'flex',
  justifyContent: 'space-between',
}

export const modal = {
  button: {
    width: rem(28),
    height: rem(28),
    cursor: 'pointer',
    '& svg': {
      fill: colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
}

export const ui = {
  title: {
    fontSize: rem(21),
    fontWeight: 'bold',
    lineHeight: rem(22),
    color: colors.charcoalGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  text: {
    fontSize: rem(16),
    lineHeight: rem(22),
    color: colors.slateGrey,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&.bold': {
      fontWeight: 600,
      color: colors.charcoalGrey,
    }
  },
}

export const errors = {
  title: {
    textAlign: 'center',
    fontSize: rem(40),
    lineHeight: rem(48),
    color: colors.charcoalGrey,
    fontWeight: '600',
    fontFamily: fontFamilies.Manrope,
  },
  text: {
    marginTop: rem(8),
    textAlign: 'center',
    fontSize: rem(15),
    lineHeight: rem(22),
    color: colors.slateGreyTwo,
    fontFamily: fontFamilies.Manrope,
  },
}

export const chat = {
  container: {
    position: 'relative',
    maxWidth: `calc(75% + ${rem(24)} + ${rem(58)})`,
  },
  dto: {
    marginRight: `calc(25% - ${rem(24)} - ${rem(58)})`,
    marginLeft: rem(8),
    textAlign: 'left',
  },
  dfrom: {
    marginLeft: `calc(25% - ${rem(24)} - ${rem(58)})`,
    marginRight: rem(24),
    textAlign: 'right',
  },
}

export const hideScroll = {
  '-ms-overflow-style': 'none',
  // overflow: '-moz-scrollbars-none',
  overflow: 'hidden',
  height: '100%',
  '&:-webkit-scrollbar': {
    display: 'none',
    overflow: 'hidden',
  },
}

export const RATE_VALUES = ["It’s horrible", "It’s bad", "It’s disappointing", "It’s good", "It’s great!"]
export const RATES = [1, 2, 3, 4, 5].reverse()
export const ANDROID_HELPER_ID = 'layout-continer-android-helper'
export const MODAL_WIDTH_DESKTOP = 464

const theme = {
  media,
  adaptive,
  desktop,
  desktop1280,
  fontFamilies,
  colors,
  rel,
  rem,
  RATE_VALUES,
  RATES,
  ANDROID_HELPER_ID,
  MODAL_WIDTH_DESKTOP,
  styles: {
    clearfix,
    content,
    modal,
    ui,
    errors,
    chat
  },
  centered,
  justified,
  hideScroll,
}

export default theme
