import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    background: theme.colors.blue,
    width: rem(16),
    height: rem(16),
    borderRadius: '50%',
    position: 'absolute',
    top: rem(18),
    right: rem(16),
    fontSize: rem(11),
    lineHeight: rem(16),
    fontWeight: 500,
    textAlign: 'center',
    color: theme.colors.white,
    display: 'block',
  }
}

const Counter = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Counter)