import React from 'react'

const Done = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="12" fill="#49B000"/>
  <path fillRule="evenodd" clipRule="evenodd"
        d="M17.5251 7.57354C18.0762 8.02132 18.16 8.83106 17.7122 9.38217L11.2932 17.2824L6.43155 12.9609C5.90083 12.4892 5.85302 11.6765 6.32477 11.1458C6.79653 10.6151 7.60919 10.5673 8.13991 11.039L10.9925 13.5747L15.7165 7.76063C16.1643 7.20953 16.974 7.12577 17.5251 7.57354Z"
        fill="white"/>
</svg>

const InProgress = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.5"
        d="M12 -5.24537e-07C18.6274 -8.1423e-07 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 -2.34843e-07 18.6274 -5.24537e-07 12C-8.1423e-07 5.37258 5.37258 -2.34843e-07 12 -5.24537e-07ZM12 19.8C16.3078 19.8 19.8 16.3078 19.8 12C19.8 7.69218 16.3078 4.2 12 4.2C7.69218 4.2 4.2 7.69218 4.2 12C4.2 16.3078 7.69218 19.8 12 19.8Z"
        fill="#BAC7E2"/>
  <path
    d="M12 -5.24537e-07C13.5759 -5.9342e-07 15.1363 0.310388 16.5922 0.913446C18.0481 1.5165 19.371 2.40041 20.4853 3.51472C21.5996 4.62902 22.4835 5.95189 23.0866 7.4078C23.6896 8.86371 24 10.4241 24 12L19.8 12C19.8 10.9757 19.5982 9.96141 19.2063 9.01507C18.8143 8.06873 18.2397 7.20886 17.5154 6.48457C16.7911 5.76027 15.9313 5.18573 14.9849 4.79374C14.0386 4.40175 13.0243 4.2 12 4.2L12 -5.24537e-07Z"
    fill="#49B000"/>
</svg>

const Idle = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.5"
        d="M12 -5.24537e-07C18.6274 -8.1423e-07 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 -2.34843e-07 18.6274 -5.24537e-07 12C-8.1423e-07 5.37258 5.37258 -2.34843e-07 12 -5.24537e-07ZM12 19.8C16.3078 19.8 19.8 16.3078 19.8 12C19.8 7.69218 16.3078 4.2 12 4.2C7.69218 4.2 4.2 7.69218 4.2 12C4.2 16.3078 7.69218 19.8 12 19.8Z"
        fill="#BAC7E2"/>
</svg>

const Info = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6Z"
        fill="currentColor"/>
  <path
    d="M5.99951 4.14575C6.354 4.14575 6.62793 3.87585 6.62793 3.53748C6.62793 3.19507 6.354 2.9292 5.99951 2.9292C5.64502 2.9292 5.37109 3.19507 5.37109 3.53748C5.37109 3.87585 5.64502 4.14575 5.99951 4.14575ZM5.99951 9.08447C6.36206 9.08447 6.58765 8.85486 6.58765 8.47217V5.20923C6.58765 4.82654 6.36206 4.5929 5.99951 4.5929C5.63293 4.5929 5.41138 4.82654 5.41138 5.20923V8.47217C5.41138 8.85486 5.63293 9.08447 5.99951 9.08447Z"
    fill="currentColor"/>
</svg>

const Delete = () => <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M2.92045 2.13636V1.72727C2.92045 1.04947 3.46992 0.5 4.14773 0.5H5.78409C6.46189 0.5 7.01136 1.04947 7.01136 1.72727V2.13636H8.64773C8.87366 2.13636 9.05682 2.31952 9.05682 2.54545C9.05682 2.77139 8.87366 2.95455 8.64773 2.95455H8.23864V8.27273C8.23864 8.95053 7.68917 9.5 7.01136 9.5H2.92045C2.24265 9.5 1.69318 8.95053 1.69318 8.27273V2.95455H1.28409C1.05816 2.95455 0.875 2.77139 0.875 2.54545C0.875 2.31952 1.05816 2.13636 1.28409 2.13636H2.92045ZM3.73864 2.13636H6.19318V1.72727C6.19318 1.50134 6.01003 1.31818 5.78409 1.31818H4.14773C3.92179 1.31818 3.73864 1.50134 3.73864 1.72727V2.13636ZM2.51136 2.95455H7.42045V8.27273C7.42045 8.49866 7.2373 8.68182 7.01136 8.68182H2.92045C2.69452 8.68182 2.51136 8.49866 2.51136 8.27273V2.95455Z"
        fill="white"/>
</svg>

const Task = ({fill}) => <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14 0.285645H4C2.93913 0.285645 1.92172 0.707072 1.17157 1.45722C0.421427 2.20736 0 3.22478 0 4.28564V16.2856C0 17.3465 0.421427 18.3639 1.17157 19.1141C1.92172 19.8642 2.93913 20.2856 4 20.2856H14C14.5304 20.2856 15.0391 20.0749 15.4142 19.6999C15.7893 19.3248 16 18.8161 16 18.2856V2.28564C16 1.75521 15.7893 1.2465 15.4142 0.871431C15.0391 0.496358 14.5304 0.285645 14 0.285645ZM2 4.28564C2 3.75521 2.21071 3.2465 2.58579 2.87143C2.96086 2.49636 3.46957 2.28564 4 2.28564H14V12.2856H4C3.29504 12.2886 2.60399 12.4821 2 12.8456V4.28564ZM4 18.2856C3.46957 18.2856 2.96086 18.0749 2.58579 17.6999C2.21071 17.3248 2 16.8161 2 16.2856C2 15.7552 2.21071 15.2465 2.58579 14.8714C2.96086 14.4964 3.46957 14.2856 4 14.2856H14V18.2856H4ZM6 6.28564H10C10.2652 6.28564 10.5196 6.18029 10.7071 5.99275C10.8946 5.80521 11 5.55086 11 5.28564C11 5.02043 10.8946 4.76607 10.7071 4.57854C10.5196 4.391 10.2652 4.28564 10 4.28564H6C5.73478 4.28564 5.48043 4.391 5.29289 4.57854C5.10536 4.76607 5 5.02043 5 5.28564C5 5.55086 5.10536 5.80521 5.29289 5.99275C5.48043 6.18029 5.73478 6.28564 6 6.28564Z"
    fill={fill}/>
</svg>

const Star = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M6.01047 7.02808e-05C5.69247 -0.00416026 5.4005 0.182899 5.26013 0.480787L3.85285 3.45753L0.704977 3.93572C0.394763 3.98317 0.137175 4.21018 0.0403367 4.52146C-0.0565012 4.83274 0.0241717 5.17442 0.248492 5.40308L2.52607 7.72094L1.98828 10.9943C1.94094 11.2833 2.03772 11.5775 2.2451 11.7751C2.50144 12.0191 2.87667 12.0695 3.18382 11.9011L6 10.3565L8.81538 11.9011C9.09308 12.0535 9.42958 12.0282 9.68345 11.8358C9.93733 11.6433 10.0646 11.3172 10.0117 10.9943L9.47393 7.72094L11.7515 5.40308C11.9758 5.17443 12.0565 4.83275 11.9597 4.52146C11.8628 4.21018 11.6052 3.98317 11.295 3.93572L8.14715 3.45753L6.73907 0.480787C6.60216 0.190236 6.32062 0.00448186 6.01047 7.02808e-05Z"
        fill="#F7B500"/>
</svg>

const Copy = () => <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3.25" y="1.75" width="6.5" height="8.5" rx="1.25" stroke="currentColor" strokeWidth="1.5"/>
  <path
    d="M8.5 13.5H12.5C12.7761 13.5 13 13.2761 13 13V7C13 6.72386 12.7761 6.5 12.5 6.5H9.5V5H12.5C13.6046 5 14.5 5.89543 14.5 7V13C14.5 14.1046 13.6046 15 12.5 15H8.5C7.39543 15 6.5 14.1046 6.5 13V10H8V13C8 13.2761 8.22386 13.5 8.5 13.5Z"
    fill="currentColor"/>
</svg>

const Close = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.99961 7.604L2.16661 11.438C1.94394 11.66 1.67994 11.7673 1.37461 11.76C1.06928 11.7533 0.805277 11.639 0.58261 11.417C0.36061 11.1943 0.24961 10.927 0.24961 10.615C0.24961 10.3023 0.36061 10.0347 0.58261 9.812L4.39561 6L0.56161 2.167C0.33961 1.94434 0.232276 1.677 0.23961 1.365C0.246276 1.05234 0.36061 0.78467 0.58261 0.562004C0.805277 0.340004 1.07261 0.229004 1.38461 0.229004C1.69728 0.229004 1.96494 0.340004 2.18761 0.562004L5.99961 4.396L9.83261 0.562004C10.0553 0.340004 10.3226 0.229004 10.6346 0.229004C10.9473 0.229004 11.2149 0.340004 11.4376 0.562004C11.6596 0.78467 11.7706 1.05234 11.7706 1.365C11.7706 1.677 11.6596 1.94434 11.4376 2.167L7.60361 6L11.4376 9.833C11.6596 10.0557 11.7706 10.3197 11.7706 10.625C11.7706 10.9303 11.6596 11.1943 11.4376 11.417C11.2149 11.639 10.9473 11.75 10.6346 11.75C10.3226 11.75 10.0553 11.639 9.83261 11.417L5.99961 7.604Z"
    fill="#2F333D"/>
</svg>

const LiveSession = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_1169_13778)">
    <circle opacity="0.2" cx="16" cy="16" r="16" fill="#3D77FF"/>
    <path d="M24.9991 12.4546L19.9082 16.091L24.9991 19.7273V12.4546Z" stroke="#3D77FF" strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M18.4545 11H10.4545C9.65122 11 9 11.6512 9 12.4545V19.7273C9 20.5306 9.65122 21.1818 10.4545 21.1818H18.4545C19.2579 21.1818 19.9091 20.5306 19.9091 19.7273V12.4545C19.9091 11.6512 19.2579 11 18.4545 11Z"
      stroke="#3D77FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_1169_13778">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>

const Icon = {
  Done, InProgress, Idle, Info, Delete, Task, Star, Copy, Close, LiveSession
}

export default Icon
