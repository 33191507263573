import React, {useState, useEffect} from 'react'
import injectSheet from 'react-jss'
import theme, {rem} from '../../theme/base'
import ContentWrapper from '../ContentWrapper'
import Field from './Field'
import {regexps} from '../../utils/regexps'
import getProfileAvatarByEmail from '../../utils/getProfileAvatarByEmail'
import {Button, Icon, Message} from 'mtuikit'
import {useProfileQuery, useUpdateProfileMutation} from '../../hooks'

const styles = {
  content: {
    flex: 1,
    padding: [16, 24, 0],
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
  },
  avatar: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '& .icon': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      bottom: rem(-6),
      right: 0,
      cursor: 'pointer',
    },
    '& svg, & img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  footer: {
    padding: rem('24 16'),
  },
}

const Settings = ({
                    classes,
                    onClose,
                  }) => {
  const profileQuery = useProfileQuery()
  const updateProfileMutation = useUpdateProfileMutation()

  const [name, setName] = useState(profileQuery.data.name)
  const [email, setEmail] = useState(profileQuery.data.email)
  const [showResult, setShowResult] = useState(false)

  const onSubmit = () => {
    updateProfileMutation.mutate({name, email})
    setShowResult(true)
  }

  useEffect(() => {
    let t
    if (showResult) {
      t = setTimeout(() => {
        setShowResult(false)
      }, 800)
    }
    return () => clearTimeout(t)
  }, [showResult])


  const isSubmitDisabled = (
    !regexps.email(email.toLowerCase())
    || updateProfileMutation.status === 'loading'
  )

  return (
    <ContentWrapper title='Profile Settings' onClose={onClose}>
      <div className={classes.content}>
      <div>
        <div className={classes.avatar}>
          <img src={getProfileAvatarByEmail(profileQuery.data.email)} alt=''/>
          <div className='icon'>
            <Icon iconName='add-photo'/>
          </div>
        </div>
        <Field
          name='name'
          type='text'
          value={name}
          placeholder='Your name'
          onChange={value => setName(value)}
          onClear={_ => setName('')}
        />
      </div>
      <Field
        name='phone'
        type='tel'
        value={profileQuery.data.phone}
        placeholder='Phone number'
        disabled
      />
      <Field
        name='email'
        type='email'
        value={email}
        placeholder='Email address'
        onChange={value => setEmail(value)}
        onClear={_ => setEmail('')}
      />
    </div>
    <div className={classes.footer}>
      <div className={classes.submit}>
        <Button
          block
          size='large'
          onClick={onSubmit}
          disabled={isSubmitDisabled}
        >
          Save
        </Button>
      </div>
    </div>
      {
        showResult &&
        <Message.Success>Profile updated</Message.Success>
      }
    </ContentWrapper>
  )
}

export default injectSheet(styles)(Settings)
