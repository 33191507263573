import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, adaptive, desktop } from '../../mtuikit/theme/base'
import Overlay from '../Overlay'

const LOGO_HEIGHT = 24

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    background: theme.colors.white,
    transition: 'top ease-in-out 300ms',
    [adaptive]: {
      height: '100%',
    },
    [desktop]: {
      maxHeight: `calc(100% - ${rem(36*2)})`, // paddingTop/Bottom
      borderRadius: rem(16),
      border: [rem(1), 'solid', theme.colors.paleGrey],
      margin: 'auto',
      display: 'flex',
    },
    '&.withLogo': {
      [desktop]: {
        marginTop: rem(24),
        maxHeight: `calc(100% - ${rem(36*2 + LOGO_HEIGHT + 24 )})`, // paddingTop/Bottom + LOGO + LogoBottomMargin
      },
    },
  },
  logo: {
    margin: 'auto auto 0',
    '& > img': {
      display: 'block',
      width: rem(160),
      height: rem(LOGO_HEIGHT),
    },
    [adaptive]: {
      display: 'none',
    },
  },
}

const Modal = ({
  zIndex,
  children,
  classes,
  className,
  style,
  color,
  withLogo,
  onClickLogo,
}) =>
  <Overlay zIndex={zIndex - 1} color={color}>
    <div className={classes.root} style={{ zIndex }}>
      {withLogo &&
        <div className={classes.logo} onClick={onClickLogo}>
          <img src={require(`components/Modal/logo.svg`).default} alt='' />
        </div>
      }
      <div className={classnames(classes.wrapper, className, { withLogo })} style={style}>
        {children}
      </div>
    </div>
  </Overlay>

export default injectSheet(styles)(Modal)
