import {isEqual} from 'lodash/lang'
import moment from 'moment'
import {v4 as uuidv4} from 'uuid'
import {
  checkRestriction,
  getMinDeadline,
  getPrice,
  getSizeSet,
  validateSubject,
  DEADLINE_SET
} from '../../nerdytutors'
import {useState} from 'react'
import {utils} from 'mtuikit'
import {
  useProfileQuery,
  useTutorsQuery,
  usePaymentRequest
} from '../../hooks'
import {useCardList} from '../CardList/hooks'

export const getDefaultOrderParams = (order) => {
  return {
    id: order?.id || null,
    toid: order?.toid || uuidv4(),
    subject: order?.subject || '',
    details: order?.details || '',
    size: order?.size ? utils.convertMinutesToSize(order.size) : null, // 1
    files: order?.files ? order?.files.map(file => ({...file, uuid: file._id})) : [],
    deadline: order?.deadline ? utils.getDeadlineInHours(moment(order.deadline).toDate()) : null, // 24
    requested_tutor_id: order?.recommended?.freelancer_id || null,
    output_formats: order?.output_formats || [],
    let_tutor_decide: !order?.id ? false : Boolean(order?.let_tutor_decide)
  }
}

export const cleanOrder = (id, toid, subject, details, size, deadline, outputs, estimateRequired) => {
  return {
    id,
    toid,
    subject: validateSubject(subject) ? subject : "",
    details,
    size: utils.convertSizeToMinutes(size),
    deadline: deadline ? utils.deadlineToISOString(deadline) : null,
    output_formats: outputs,
    let_tutor_decide: estimateRequired
  }
}

export const cleanFiles = files => files.map(({_id, name, size, location}) => ({
  _id,
  name,
  size,
  location,
  type: 'order_form'
}))

export const cleanRequestedTutorId = (id, oldRequestedTutorId, newRequestedTutorId) => (
  oldRequestedTutorId === newRequestedTutorId
    ? oldRequestedTutorId
    : id
      ? newRequestedTutorId
        ? newRequestedTutorId
        : oldRequestedTutorId
          ? false
          : null
      : newRequestedTutorId
        ? newRequestedTutorId
        : null
)

export function useOrderFormParams(defaultOrder) {
  const [id, setId] = useState(defaultOrder.id)
  const toid = useState(defaultOrder.toid)[0]
  const [subject, setSubject] = useState(defaultOrder.subject)
  const [details, setDetails] = useState(defaultOrder.details)
  const [outputs, setOutputs] = useState(defaultOrder.output_formats)
  const [estimateRequired, setEstimateRequired] = useState(defaultOrder.let_tutor_decide)
  const [size, setSize] = useState(defaultOrder.size)
  const [deadline, setDeadline] = useState(defaultOrder.deadline)
  const [files, setFiles] = useState(defaultOrder.files)
  const [requestedTutorId, setRequestedTutorId] = useState(defaultOrder.requested_tutor_id)
  const {listAllCards, paymentMethod, changePaymentMethod} = useCardList()
  const profile = useProfileQuery().data
  const tutors = useTutorsQuery().data.freelancers

  const {price, total} = deadline
    ? getPrice(size, deadline, profile.balance)
    : {price: null, total: null}

  const paymentRequest = usePaymentRequest('US', 'usd', total)

  const minDeadline = getMinDeadline(size)
  const requestedTutor = tutors.find(tutor => tutor.id === requestedTutorId)

  const isChanged = !id || !isEqual(
    [subject, details, size, files, deadline, requestedTutorId, outputs, estimateRequired],
    [
      defaultOrder.subject, defaultOrder.details, defaultOrder.size, defaultOrder.files,
      defaultOrder.deadline, defaultOrder.requested_tutor_id, defaultOrder.outputs, defaultOrder.other_output_format
    ]
  )

  const restriction = checkRestriction(size, deadline)

  const cleanedOrder = cleanOrder(id, toid, subject, details, size, deadline, outputs, estimateRequired)
  const cleanedFiles = cleanFiles(files)
  const cleanedRequestedTutorId = cleanRequestedTutorId(id, defaultOrder.requested_tutor_id, requestedTutorId)

  const isReadyForPayment = Boolean(price && subject && details && size && deadline && !restriction)

  const sizeset = getSizeSet(deadline)

  return {
    id,
    toid,
    subject,
    details,
    size,
    outputs,
    estimateRequired,
    deadline,
    files,
    requestedTutorId,
    requestedTutor,

    restriction,
    minDeadline,
    price,
    total,
    balance: profile.balance,

    cleanedOrder,
    cleanedFiles,
    cleanedRequestedTutorId,

    isReadyForPayment,
    isChanged,
    profile,
    tutors,

    listAllCards,
    paymentMethod,
    paymentRequest,

    sizeset,
    deadlineset: DEADLINE_SET,

    setId: setId,
    onChangeSubject: setSubject,
    onChangeDetails: setDetails,
    onChangeOutputs: setOutputs,
    onChangeEstimateRequired: setEstimateRequired,
    onChangeDeadline: setDeadline,
    onChangeSize: setSize,
    onChangeFiles: setFiles,
    onChangeRequestedTutorId: setRequestedTutorId,
    onChangePaymentMethod: changePaymentMethod
  }
}