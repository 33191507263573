import React from 'react'
import injectSheet from 'react-jss'
import theme, { desktop, MODAL_WIDTH_DESKTOP, rem } from './../../theme/base'
import Layout from './../Layout'
import Accordion from './../Accordion'

const styles = {
  root: {
    [desktop]: {
      width: rem(MODAL_WIDTH_DESKTOP),
    },
  },
  title: {
    padding: [rem(17), rem(56), rem(17), rem(24)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.11),
  },
  container: {
    padding: [0, rem(24)],
  },
}

const Faq = ({
  faq,
  onClose,
  classes,
}) =>
  <div className={classes.root}>
    <Layout.Header onClose={onClose}>
      <div className={classes.title}>Frequently Asked Questions</div>
    </Layout.Header>
    <Layout.Container className={classes.container}>
      <Accordion items={faq} />
    </Layout.Container>
  </div>

export default injectSheet(styles)(Faq)