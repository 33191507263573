import React from 'react'
import moment from 'moment-timezone'
import Layout from './layout'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'
import {useOrderLiveSession} from '../../../../hooks'
import LiveSessionCanceledEvent from './LiveSessionCanceledEvent'
import useToggleInTime from '../../../../utils/useToggleInTime'
import useCallbackInTime from '../../../../utils/useCallbackInTime'
import {useActions} from '../../../../actions'
import {useQueryClient} from 'react-query'
import Countdown from '../../../Countdown'

const LiveSessionUpcomingEvent = ({order_id, live_session_id, ...props}) => {
  const queryClient = useQueryClient()
  const actions = useActions()
  const {link, c_token, created_by, scheduled_at, duration} = props.data.event.event_params

  const LiveSessionOverAt = moment(scheduled_at).add(duration, 'minutes')

  const isLiveSessionStarted = useToggleInTime(false, scheduled_at)
  const isLiveSessionOver = useToggleInTime(false, LiveSessionOverAt)

  useCallbackInTime(
    LiveSessionOverAt,
    () => {
      queryClient.invalidateQueries('orders')
      queryClient.invalidateQueries(['orders', order_id])
    }
  )

  const liveSession = useOrderLiveSession(order_id, live_session_id)

  if (liveSession?.deleted) {
    return (
      <LiveSessionCanceledEvent
        order_id={order_id}
        live_session_id={live_session_id}
        {...props}
      />
    )
  }

  const join = () => actions.joinVideoCall(link, c_token)

  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.Body>
        <Layout.Title1
          iconName={isLiveSessionOver ? 'live_session_ended' : 'live_session'}
          disabled={isLiveSessionOver}>
          {isLiveSessionOver ? 'Live session is over' : 'Live session'}
        </Layout.Title1>
        <Layout.LiveSession
          datetime={scheduled_at}
          duration={duration}
          avatar={getUserAvatarUrl(created_by)}
        />
        {
          !isLiveSessionOver &&
          <Layout.Buttons>
            <Layout.Button onClick={join}>
              {isLiveSessionStarted
                ? 'Join Now'
                : <>Join in <Countdown dl={scheduled_at}/> min</>
              }
            </Layout.Button>
          </Layout.Buttons>
        }
      </Layout.Body>
      <Card.Footer {...props}/>
    </Card>
  </MessageWrapper>
}

export default LiveSessionUpcomingEvent
