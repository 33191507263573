import React from 'react'
import {useParams} from 'react-router-dom'
import {useActions} from '../../actions'
import {useOrder} from '../../hooks'
import {ContextMenu} from '../ContextMenu'
import {RequestRevision} from '../Actions/RequestRevision'

export const OrderContextMenu = ({orderId, ...rest}) => {
  const actions = useActions()
  const order = useOrder(orderId)
  const {target} = useParams()

  const menu = []

  if (target !== 'task') {
    menu.push({
      icon: '',
      text: 'Task info',
      disabled: false,
      onClick: () => actions.showOrderDetails(orderId),
      divider: !order.assigned,
      bold: true,
    })
  }

  if (target !== 'task' && order.assigned) {
    menu.push({
      icon: null,
      text: 'Tutor profile',
      disabled: false,
      onClick: () => actions.showOrderTutorProfile(orderId),
      divider: true,
      bold: true,
    })
  }

  menu.push({
    icon: 'contextMenu-copy',
    text: 'Copy task number',
    description: `#${order.number}`,
    copyByClick: `#${order.number}`,
  })

  menu.push({
    icon: 'contextMenu-support',
    text: 'Contact support',
    onClick: () => actions.showSupportChat(`Hi, I have a question regarding my task ${order.number}`),
  })

  if (order.assigned && order.closed === null) {
    const isRequestRevisionAvailable = (
      order.completed !== null
      && (order.revision === null || order.revision.status === 'completed')
    )
    menu.push({
      icon: 'contextMenu-revision',
      text: 'Request revision',
      description: !isRequestRevisionAvailable ? 'Available only after completion' : null,
      disabled: !isRequestRevisionAvailable,
      onClick: () => actions.showModal(RequestRevision, {orderId}),
    })
  }

  return <ContextMenu actions={menu}{...rest}/>
}