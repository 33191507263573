import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop, adaptive } from 'theme/base'
import Custom from '../Custom'
import TextButton from '../TextButton'
import { isMobile, isAndroid } from 'utils'
import DesktopDropdown from '../../SelectSize/DesktopDropdown'
import MobileDropdown from '../../SelectSize/MobileDropdown'

const styles = {
  root: {
    marginTop: rem(16),
    position: 'relative',
  },
  list: {
    display: 'grid',
    [desktop]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridColumnGap: 8,
    },
    [adaptive]: {
      gridRowGap: 8,
    },
  },
  input: {
    background: theme.colors.white,
    marginTop: rem(16),
    padding: [rem(14), rem(60), rem(10), rem(16)],
    fontSize: rem(17),
    lineHeight: rem(22),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    border: [rem(1), 'solid', '#BAC7E2'],
    borderRadius: rem(8),
    textAlign: 'left',
    position: 'relative',
    cursor: 'pointer',
    '&.active': {
      borderColor: theme.colors.blue,
      color: theme.colors.blue,
      fontWeight: 600,
    },
    '&.error': {
      borderColor: theme.colors.brightOrangeTwo,
      color: theme.colors.brightOrangeTwo,
    },
    '& > img': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      top: '50%',
      right: rem(12),
      transform: 'translateY(-50%)',
      display: 'block',
    },
  },
}

const Dropdown = isMobile && !isAndroid
  ? MobileDropdown
  : DesktopDropdown

const Presets = ({
  classes,
  type,
  custom,
  customText,
  children,
  withoutPresets,
  selectProps,
  showSelect,
  other,
  size,
  sizeText,
  onCustom,
  onShowSelect,
  onReset,
}) =>
  <div className={classes.root}>
    <div className={classnames(classes.list, { custom, withoutPresets })}>{children}</div>
    {other &&
      <>
        <div className={classnames(classes.input, { active: showSelect })} onClick={onShowSelect}>
          {size ? sizeText : ''}
          <img src={require('./Icons/size.svg').default} alt='' />
        </div>
        <TextButton onClick={onReset} />
      </>
    }
    {!custom && !withoutPresets && customText &&
      <Custom
        type={type}
        customText={customText}
        onClick={onCustom}
      />
    }
    {showSelect &&
      <Dropdown {...selectProps} />
    }
  </div>

export default injectSheet(styles)(Presets)
