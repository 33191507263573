import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../theme/base'

const styles = {
  root: {
    paddingBottom: rem(4),
    color: theme.colors.brightOrange,
    fontSize: rem(15),
    lineHeight: rem(20),
    textAlign: 'center',
  },
}

const Error = ({ 
  classes, 
  children, 
}) => 
  <div className={classes.root}>{children}</div>
        
export default injectSheet(styles)(Error)