import React from 'react'
import {createUseStyles} from 'react-jss'
import {theme} from 'mtuikit'

const useStyle = createUseStyles({
  error: {
    color: theme.colors.brightOrangeTwo,
    marginTop: 4,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 20/12,
    letterSpacing: 0.15,
  }
})

export const Error = ({ children }) => {
  const classes = useStyle()
  return (
    <div className={classes.error}>{children}</div>
  )
}
