import {createUseStyles} from 'react-jss'
import React from "react";


export default createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    background: '#1E222B',
    width: 288,
    // height: 142,
    padding: [18, 0],
    borderRadius: 16,
    position: 'relative',
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: 6,
    paddingLeft: 42,
    paddingRight: 40
  },
  text: {
    color: '#F6F7FB',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: 42,
    paddingRight: 36
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer'
  },
  icon: {
    position: 'absolute',
    top: 20,
    left: 16,
  }
})

export const closeSvg = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.99998 7.604L2.16698 11.438C1.94431 11.66 1.68031 11.7673 1.37498 11.76C1.06964 11.7533 0.805643 11.639 0.582976 11.417C0.360976 11.1943 0.249976 10.927 0.249976 10.615C0.249976 10.3023 0.360976 10.0347 0.582976 9.812L4.39598 6L0.561976 2.167C0.339976 1.94434 0.232642 1.677 0.239976 1.365C0.246642 1.05234 0.360976 0.78467 0.582976 0.562004C0.805643 0.340004 1.07298 0.229004 1.38498 0.229004C1.69764 0.229004 1.96531 0.340004 2.18798 0.562004L5.99998 4.396L9.83298 0.562004C10.0556 0.340004 10.323 0.229004 10.635 0.229004C10.9476 0.229004 11.2153 0.340004 11.438 0.562004C11.66 0.78467 11.771 1.05234 11.771 1.365C11.771 1.677 11.66 1.94434 11.438 2.167L7.60398 6L11.438 9.833C11.66 10.0557 11.771 10.3197 11.771 10.625C11.771 10.9303 11.66 11.1943 11.438 11.417C11.2153 11.639 10.9476 11.75 10.635 11.75C10.323 11.75 10.0556 11.639 9.83298 11.417L5.99998 7.604Z"
    fill="white"/>
</svg>

export const iconSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8 12C8.22667 12 8.41667 11.9233 8.57 11.77C8.72333 11.6167 8.8 11.4267 8.8 11.2V8C8.8 7.77333 8.72333 7.58333 8.57 7.43C8.41667 7.27667 8.22667 7.2 8 7.2C7.77333 7.2 7.58333 7.27667 7.43 7.43C7.27667 7.58333 7.2 7.77333 7.2 8V11.2C7.2 11.4267 7.27667 11.6167 7.43 11.77C7.58333 11.9233 7.77333 12 8 12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16Z"
    fill="white"/>
</svg>