import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import {useDropzone} from 'react-dropzone'
import theme, {rem, adaptive, media} from 'theme/base'
// import Icon from './../../Icon'
import utils from 'mtuikit/utils'

const styles = {
  '@keyframes upload': {
    '0%': {
      transform: 'translateY(0)',
      opacity: 0,
    },
    '40%, 60%': {
      opacity: 1,
    },
    '90%': {
      transform: `translateY(${rem(-20)})`,
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  root: {
    backgroundImage: `url(${require('./uploader.svg').default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: [40, 40],
    backgroundPosition: [16, 16],
    padding: [26, 26, 26, 64],
    whiteSpace: 'nowrap',
    margin: rem(4),
    border: [rem(1), 'dashed', '#BAC7E2'],
    borderRadius: rem(8),
    cursor: 'pointer',
    '& > span': {
      color: theme.colors.blue,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& input[type=file]': {
      display: 'none',
    },
  },
  camera: {
    width: rem(45),
    height: rem(37),
    margin: [rem(20), 'auto', 0],
    display: 'block',
  },
  icon: {
    width: rem(48),
    height: rem(34),
    margin: [rem(20), 'auto', 0],
    position: 'relative',
  },
  cloud: {
    width: 'inherit',
    height: 'inherit',
    display: 'block',
    fill: theme.colors.blue,
  },
  arrow: {
    width: rem(17),
    height: rem(21),
    display: 'block',
    position: 'absolute',
    top: rem(8),
    left: rem(16),
    animation: 'upload 2000ms cubic-bezier(0.77, 0, 0.175, 1) infinite',
  },
  label: {
    fontSize: 14,
    lineHeight: 20/14,
    letterSpacing: 0.15,
    [adaptive]: {
      color: theme.colors.blue,
    },
    '& > span': {
      color: theme.colors.blue,
      fontWeight: 800,
      cursor: 'pointer',
    },
  },
  block: {
    backgroundPosition: 'center center',
    width: rem(88),
    height: rem(88),
    margin: rem(4),
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'top',
    '& $label': {
      display: 'none',
    },
    '& $icon': {
      marginTop: rem(32),
    },
    '& $camera': {
      marginTop: rem(28),
    },
  },
}


const Attach = ({
  onAttach,
  block,
  classes,
}) => {
  const isMobile = utils.useMobile(media.desktop)
  const {
    getRootProps,
    getInputProps,
    // isDragActive,
  } = useDropzone({onDrop: onAttach})
  return (
    <div className={classnames(classes.root, {[classes.block]: block})} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={classes.label}>
        {isMobile
          ? <>Upload a file or photo</>
          : <>Drag&amp;Drop or <span>upload</span> a file or photo</>
        }
      </div>
    </div>
  )
}

export default injectSheet(styles)(Attach)
