import React from 'react'
import moment from 'moment'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {desktop} from '../../theme/base'
import {useActions} from '../../actions'
import {useOrder} from '../../hooks'
import theme, {rem} from '../../mtuikit/theme/base'
import {Prompts} from '../Prompts'
import DeleteOrder from '../Actions/DeleteOrder'
import Avatar from './ui/Avatar'
import Notifications from './ui/Notifications'
import {OrderRate} from './ui/Rate'
import Pic from './ui/Pic'
import DeleteButton from './ui/DeleteButton'
import CreateButton from './ui/CreateButton'
import Boarding from './ui/Boarding'
import {colors, typography} from './ui/consts'
import {isFinalizedOrder} from '../../nerdytutors'

const Order = ({orderId, active}) => {
  const classes = useStyle()
  const actions = useActions()
  const order = useOrder(orderId)

  const gotoOrder = !active && order.paid
    ? order.jabber_settings
      ? () => actions.showOrderChat(orderId)
      : () => actions.showOverlay(Prompts.ChatAreNotAvailable, {onAccept: actions.showSupportChat})
    : null

  const createOrder = !order.paid
    ? () => actions.showOrderForm({order})
    : null

  const deleteOrder = !order.paid
    ? () => actions.showOverlay(DeleteOrder, {orderId: orderId})
    : null

  const deadline = order.paid
    ? order.refunded
      ? `Refunded on ${moment(order.refunded.refunded_at).format('MMM D [at] LT')}`
      : order.completed
        ? order.revision && order.revision.status === 'accepted'
          ? <>Revision due date is <span className={classes.deadlineWrapper}>{moment(order.revision.deadline).format('MMM D [at] LT')}</span></>
          : order.revision && order.revision.status === 'completed'
            ? <>Completed on <span className={classes.deadlineWrapper}>{moment(order.revision.last_completed_at).format('MMM D [at] LT')}</span></>
            : <>Completed on <span className={classes.deadlineWrapper}>{moment(order.completed?.completed_at || order.deadline).format('MMM D [at] LT')}</span></>
        : <>Task will be done by <span className={classes.deadlineWrapper}>{moment(order.deadline).format('MMM D [at] LT')}</span></>
    : 'Task not placed yet'

  return <div onClick={gotoOrder}
              className={classnames(classes.card, {active, paid: !!order.paid, not_active: !active})}>
    {
      order.assigned && !order.refunded
        ? <Avatar userId={order.assigned.freelancer_id} className={classes.avatar}/>
        : <Pic className={classes.pic} paid={!!order.paid} unpaid={!order.paid} refunded={!!order.refunded}/>
    }
    <div className={classes.subject}>{order.subject}</div>
    <div className={classes.deadline}>{deadline}</div>
    {!isFinalizedOrder(order) && <Notifications orderId={orderId}/>}
    {order.paid && !order.refunded && order.closed && isFinalizedOrder(order) && <OrderRate rate={order.rated}/>}
    {
      !order.paid && <>
        <DeleteButton onClick={deleteOrder} className={classes.delete}/>
        <div className={classes.create}><CreateButton onClick={createOrder}/></div>
      </>
    }
    {active && <Boarding order={order}/>}
  </div>
}

const useStyle = createUseStyles({
  card: {
    padding: '24px 24px 24px 24px',
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
    marginTop: '16px',
    position: 'relative',
    '& + &': {
      marginTop: '8px',
    },
    [desktop]: {
      '&:hover': {
        background: '#F6F7FB',
      }
    },
    '&.active': {
      [desktop]: {
        outline: [rem(2), 'solid', theme.colors.blue],
      },
    },
    '&.paid.not_active': {
      cursor: 'pointer'
    },
    '&:hover $delete': {display: 'flex'},
  },

  subject: {
    ...typography.xx,
    color: colors.black2,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    width: '260px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: '52px'
  },

  deadlineWrapper: {
    whiteSpace: 'nowrap',
  },
  deadline: {
    fontSize: '12px',
    color: colors.black1,
    lineHeight: '16px',
    paddingLeft: '52px',
  },

  delete: {position: 'absolute', top: '8px', right: '8px', display: 'none'},
  avatar: {position: 'absolute', top: '24px', left: '24px'},
  pic: {position: 'absolute', top: '24px', left: '24px'},
  create: {margin: '24px 0 0'},
})

export default Order
