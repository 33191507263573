import React from 'react'
import {createUseStyles} from 'react-jss'

const useStyle = createUseStyles({
  back: {
    width: 'inherit',
    height: 'inherit',
  },
  icon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    display: 'block',
  },
})

export const Back = ({ onClick }) => {
  const classes = useStyle()
  return (
    <div className={classes.back}>
      <svg
        className={classes.icon}
        onClick={onClick}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H1C0.447715 24 0 23.5523 0 23V8C0 3.58172 3.58172 0 8 0Z'
          fill='url(#paint0_linear_9837_10563)'
        />
        <path
          d='M8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H1C0.447715 24 0 23.5523 0 23V8C0 3.58172 3.58172 0 8 0Z'
          fill='black'
          fillOpacity='0.2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.57479 10.7132L11.657 7.76515C12.182 7.26305 12.182 6.44898 11.657 5.94688C11.132 5.44477 10.2809 5.44477 9.75595 5.94688L3.42844 11.9989L9.75595 18.0509C10.2809 18.553 11.132 18.553 11.657 18.0509C12.182 17.5488 12.182 16.7347 11.657 16.2326L8.57479 13.2846H19.2271C19.9695 13.2846 20.5713 12.709 20.5713 11.9989C20.5713 11.2888 19.9695 10.7132 19.2271 10.7132H8.57479Z'
          fill='white'
        />
        <defs>
          <linearGradient
            id='paint0_linear_9837_10563'
            x1='0'
            y1='0'
            x2='0'
            y2='24'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#55AEFF'/>
            <stop offset='1' stopColor='#2B75FF'/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}