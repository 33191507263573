import Footer from './Footer'
import Header from './Header'
import Content from './Content'
import Container from './Container'

const Layout = {
  Footer,
  Header,
  Content,
  Container,
}

export default Layout
