import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from 'theme/base'

const styles = {
  root: {
    padding: [rem(17), rem(24), rem(15)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontWeight: 600,
    textAlign: 'center',
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontWeight: 600,
  },
  set: {
    position: 'absolute',
    top: rem(17),
    right: rem(24),
    cursor: 'pointer',
    color: theme.colors.blue,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontWeight: 600,
  },
}

const Header = ({ 
  classes,
  title,
  onSet,
}) =>
  <div className={classes.root}>
    <div className={classes.title}>
      <span>{title}</span>
      <div className={classes.set} onClick={onSet}>Set</div>
    </div>
  </div>

export default injectSheet(styles)(Header)