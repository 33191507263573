import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from '../../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(32), rem(24), rem(24)],
    borderRadius: [0, 0, rem(24), rem(24)],
    position: 'relative',
    [desktop]: {
      borderRadius: rem(24),
    },
  },
}

const Wrapper = ({ 
  classes,
  className,
  children,
}) => 
  <div className={classnames(classes.root, className)}>{children}</div>
        
export default injectSheet(styles)(Wrapper)