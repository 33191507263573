export const getRandom = (min, max, count, decimal = 0) => {
  let _count = (count) ? count : 1
  if (_count > max - min + 1 || _count < 0) {
    return []
  }
  let temp = []
  for (let i = 0; i < _count; i++) {
    let random = Math.floor(min + Math.random() * (max + 1 - min))
    if (temp[random]) {
      i--
    }
    temp[random] = 1
  }
  let result = []
  // for (let key in temp) {
  //   result[result.length] = key
  // }
  temp.forEach((item, index, arr) => {
    result[result.length] = index
  })

  const getRandomDecimal = () => Math.floor(Math.random() * 10)
  let decimalString = ''

  for (let n = 0; n < decimal; n++) {
    decimalString = `${decimalString}${getRandomDecimal()}`
  }

  return decimal
    ? Number(`${result}.${decimalString}`)
    : result

}