import React from 'react'
import {Chat} from './BaseChat'

export const SupportChat = ({chatConfig, defaultMessage}) => {
  return <Chat
    chatConfig={chatConfig}
    placeholder={() => ({
      title: 'Live Support',
      text: 'Feel free to ask any questions. We will answer you within 5 minutes, usually faster.'
    })}
    defaultMessage={defaultMessage}
    pic
  />
}
