import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    minHeight: rem(178),
    padding: [rem(24), rem(8), rem(8)],
    borderRadius: [0, 0, rem(20), rem(20)],
    boxShadow: `0 ${rem(16)} ${rem(40)} 0 rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)`,
    position: 'relative',
  },
}

const Wrapper = ({ classes,  children }) => 
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Wrapper)