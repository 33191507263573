import React from 'react'
import injectSheet from 'react-jss'
import { rem } from './../../theme/base'

const styles = {
  root: {
    position: 'relative',
    '& + &': {
      marginTop: rem(-8),
    },
  },
}

const Block = ({ 
  classes,
  children, 
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Block)