import React from 'react'
import {useDispatch} from 'react-redux'
import Settings from './components/Settings'
import Menu from './components/Menu'
import {FAQ} from './config'
import {utils, Faq} from 'mtuikit'
import {modals} from './modals'
import Modal from './components/Modal'
import {Container} from './modals'
import Overlay from './components/Overlay'
import {useAuth, useProfileQuery} from './hooks'
import {useHistory} from 'react-router-dom'
import {TutorContextMenu} from './components/TutorContextMenu'
import {OrderContextMenu} from './components/OrderContextMenu'
import {media} from './theme/base'
import AddPaymentMethod from './components/PaymentMethod/PaymentMethodInOrderForm'
import RejectTutor from './components/Actions/RejectTutor'
import {useSupportChatContext} from './components/SupportChat'
import BuyTopTutorAddon from './components/BuyTopTutorAddon'
import BuyBalanceTopUpBundle from './components/BuyBalanceTopUpBundle'
import PopupBalanceTopUpBundle from './components/PopupBalanceTopUpBundle'
import RequestLiveSessionForm from './components/RequestLiveSessionForm'
import PopupAddTaskInstructions from './components/PopupAddTaskInstructions'
import PopupEditPhone from './components/PopupEditPhone'
import Explainer from './components/Explainer'


const Transparent = ({children, zIndex, ...rest}) => <div {...rest} style={{zIndex: zIndex - 1}}>{children}</div>

export function useActions() {
  const history = useHistory()
  const dispatch = useDispatch()
  const profileQuery = useProfileQuery()
  const supportChat = useSupportChatContext()

  const {clearAuth} = useAuth()

  const isMobile = utils.useMobile(media.desktop)

  const actions = {
    onMenu: () => dispatch(modals.actions.add({
      container: Overlay,
      component: Menu,
      onFaq: actions.showFaq,
      onSupport: actions.showSupportChat,
      onLogout: actions.logout,
      onSettings: actions.showProfile
    })),

    showFaq: () => dispatch(modals.actions.add({
      container: Modal,
      component: Faq,
      faq: FAQ,
    })),

    logout: () => {
      clearAuth()
    },

    showBuyTopTutorAddon: (orderId, placedThrough) => dispatch(modals.actions.add({
      container: Container,
      component: BuyTopTutorAddon,
      orderId, placedThrough
    })),

    showRequestLiveSessionAddon: orderId => dispatch(modals.actions.add({
      container: Container,
      component: RequestLiveSessionForm,
      orderId
    })),

    showBuyBalanceTopUpBundle: (bundleId, bundleItemId, onPaid, clickSource) => dispatch(modals.actions.add({
      container: Container,
      component: BuyBalanceTopUpBundle,
      bundleId, bundleItemId, onPaid, clickSource
    })),

    showPopupBalanceTopUpBundle: bundleId => dispatch(modals.actions.add({
      container: Container,
      component: PopupBalanceTopUpBundle,
      bundleId
    })),

    showAddTaskInstructions: orderId => dispatch(modals.actions.add({
      container: Container,
      component: PopupAddTaskInstructions,
      orderId
    })),

    showEditPhone: ({orderId, editedThrough}) => dispatch(modals.actions.add({
      container: Container,
      component: PopupEditPhone,
      orderId, editedThrough
    })),

    showModal2: (component, props) => dispatch(modals.actions.add({
      container: Container,
      component,
      ...props
    })),

    showModal: (component, props) => dispatch(modals.actions.add({
      container: Modal,
      component,
      ...props
    })),

    showOverlay: (component, props) => dispatch(modals.actions.add({
      container: Overlay,
      component,
      ...props
    })),

    showTransparent: (component, props) => dispatch(modals.actions.add({
      container: Transparent,
      component,
      ...props
    })),

    removeAll: () => dispatch(modals.actions.removeAll()),

    showProfile: () => {
      history.push(`/settings`)
    },

    showBrowserInstructions: () => {
      history.push(`/browser-instructions`)
    },

    showSupportChat: (defaultMessage = '') => supportChat.open(defaultMessage),
    hideSupportChat: () => supportChat.close(),

    showOrderTutorProfile: orderId => {
      history.push(`/orders/${orderId}/tutor`)
    },

    showTutorProfile: tutorId => {
      history.push(`/tutors/${tutorId}`)
    },

    showOrders: () => {
      history.push(`/orders`)
    },

    showTutors: () => {
      history.push(`/tutors`)
    },

    showOrderDetails: orderId => {
      history.push(`/orders/${orderId}/task`)
    },
    showOrderChat: (orderId, state) => {
      history.push({pathname: `/orders/${orderId}/chat`, state})
    },

    showOrderForm: params => {
      history.push({
        pathname: `/order-form`,
        state: params
      })
    },

    joinVideoCall: (link, token) => {
      const l = token ? process.env.REACT_APP_VIDEO_CALLS + '/?' + token : link
      window.open(l, 'APP_VIDEO_CALLS')
    },

    showAddPaymentMethod: params => dispatch(modals.actions.add({
      container: profileQuery.data.returned
        ? Modal
        : props => <Modal color='whiteNoOpacity' withLogo {...props} />,
      component: AddPaymentMethod,
      ...params,
    })),

    showOrderContextMenu: ({orderId}) => isMobile
      ? actions.showOverlay(OrderContextMenu, {orderId})
      : actions.showTransparent(OrderContextMenu, {orderId}),

    showTutorContextMenu: ({tutorId, orderId}) => isMobile
      ? actions.showOverlay(TutorContextMenu, {tutorId, orderId})
      : actions.showTransparent(TutorContextMenu, {tutorId, orderId}),

    showRejectTutorPrompt: ({tutorId, orderId}) => actions.showOverlay(RejectTutor, {tutorId, orderId}),

    showExplainer: props => {
      const modal = modals.actions.add({container: Overlay, component: Explainer, ...props})
      dispatch(modal)
      const close = () => dispatch(modals.actions.remove(modal.payload.uuid))
      return close
    },
  }

  return actions
}
