import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
		fontWeight: 600,
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
		display: 'inline-block',
		verticalAlign: 'top',
  }
}

const Helped = ({
  value,
  classes,
}) =>
	<div className={classes.root}>
		·&nbsp;&nbsp;Helped {value} times
	</div>

export default injectSheet(styles)(Helped)