import React from 'react'
import {createUseStyles} from 'react-jss'
import theme, {rem} from '../../theme/base'
import Header from './../Header'

//const HEADER_HEIGHT_DESKTOP = 56
const LSIDEBAR_WIDTH_INNER = 328
const RSIDEBAR_WIDTH_INNER = 328 //375
const SIDEBAR_PADDING = 16
const LSIDEBAR_WIDTH_OUTER = LSIDEBAR_WIDTH_INNER + SIDEBAR_PADDING * 2
const RSIDEBAR_WIDTH_OUTER = RSIDEBAR_WIDTH_INNER + SIDEBAR_PADDING * 2

const DesktopThreeColumnsView = ({lsidebar, workarea, rsidebar}) => {
  const classes = useStyle()
  return (
    <>
      <div className={classes.header}><Header/></div>
      <div className={classes.body}>
        <div className={classes.lsidebar}>{lsidebar}</div>
        <div className={classes.workarea}>{workarea}</div>
        <div className={classes.rsidebar}>{rsidebar}</div>
      </div>
    </>
  )
}

const useStyle = createUseStyles({
  header: {
  },
  body: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
  },
  lsidebar: {
    flexBasis: rem(LSIDEBAR_WIDTH_OUTER),
    flexShrink: 0,
    padding: [rem(24), rem(SIDEBAR_PADDING)],
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
    scrollbarWidth: 'thin',  // for Mozilla
    borderRight: [1, 'solid', theme.colors.lightBlueGrey50],
    backgroundColor: '#fff',
  },
  rsidebar: {
    flexBasis: rem(RSIDEBAR_WIDTH_OUTER),
    flexShrink: 0,
    borderLeft: [1, 'solid', theme.colors.lightBlueGrey50],
    backgroundColor: '#fff',
    position: 'relative',
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
    scrollbarWidth: 'thin',  // for Mozilla
  },
  workarea: {
    flexGrow: 1,
  },
})

export default DesktopThreeColumnsView
