export const sizeToHumans = (size) => {
    const hours = Math.trunc(size)
    const mins = size % 1
    const parts = []

    if (size === 0) {
        return '30 min'
    }

    if (hours > 0) {
        parts.push(hours === 1 ? '1 hour' : `${hours} hours`)
    }

    if (mins > 0) {
        parts.push(`${Math.ceil(mins * 60)} min`)
    }

    return parts.join(' ')
}
