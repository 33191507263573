import React from 'react'
import injectSheet from 'react-jss'
import { rem, justified } from '../../mtuikit/theme/base'

const styles = {
  root: {
    ...justified,
    marginTop: rem(24),
    marginLeft: rem(-80),
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}

const Buttons = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Buttons)