import React, {useEffect} from 'react'
import {setToken} from '../api'
import {useAuth} from '../hooks'
import {useParams, Redirect} from 'react-router-dom'

const OperatorBackdoor = () => {
  const {token} = useParams()
  const {setAuth, isLogged} = useAuth()

  useEffect(() => {
      setToken(token)
      setAuth()
    },
    // eslint-disable-next-line
    []
  )

  return isLogged
    ? <Redirect to={{pathname: '/orders'}}/>
    : null
}

export default OperatorBackdoor