import React from 'react'
import injectSheet from 'react-jss'
import theme, {rem, desktop, adaptive} from '../../../../../../theme/base'

const styles = {
  root: {
    height: rem(120),
    padding: [0, rem(36), rem(24), rem(88)],
    color: theme.colors.white,
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    [desktop]: {
      borderRadius: [rem(16), rem(16), 0, 0],
    },
    [adaptive]: {
      height: rem(96),
      paddingBottom: 0,
      zIndex: 2,
    },
    '& img': {
      width: rem(64),
      height: rem(64),
      position: 'absolute',
      left: rem(16),
      top: rem(16),
      display: 'block',
    },
    '& div': {
      fontSize: rem(21),
      lineHeight: rem(28),
      fontWeight: 700,
    },
    '& p': {
      marginTop: rem(8),
      fontSize: rem(15),
      lineHeight: rem(20),
      opacity: 0.7,
    },
  },
}

const Header = ({
  classes,
  type,
  title,
  subtitle,
}) =>
  <div
    className={classes.root}
    style={{ background: type === 'failed'
      ? theme.colors.brightOrangeTwo
      : theme.colors.blue
    }}
  >
    {type === 'success' &&
      <img src={require('./icon-success.svg').default} alt='' />
    }
    {type === 'failed' &&
      <img src={require('./icon-failed.svg').default} alt='' />
    }
    <div>
      {title &&
        <div>{title}</div>
      }
      {subtitle &&
        <p>{subtitle}</p>
      }
    </div>
  </div>

export default injectSheet(styles)(Header)