import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'
import RateText from './RateText'

const styles = {
  root: {
		marginTop: rem(30),
		marginLeft: rem(-60),
    // paddingRight: rem(56),
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    color: theme.colors.charcoalGrey,
    textAlign: 'center',
    '& svg': {
      width: rem(16),
      height: rem(16),
      marginTop: rem(4),
      marginLeft: rem(6),
      marginRight: rem(4),
      display: 'inline-block',
      verticalAlign: 'top',
      fill: theme.colors.star,
    },
  },
  mark: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
}

const Rated = ({
  value,
	classes,
}) =>
  <div className={classes.root}>
    <div className={classes.mark}>
      {value}
      <Icon iconName='star' />
    </div>
		<RateText value={value} />
  </div>

export default injectSheet(styles)(Rated)
