import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, content2, ui, desktop, adaptive } from '../../../theme/base'
import { MiniOrderForm } from '../../components'

const styles = {
  root: {
    background: theme.colors.blue,
    padding: [rem(80), 0],
    textAlign: 'center',
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), 0],
    },
  },
  content: {
    ...content2,
    [adaptive]: {
      ...content2[adaptive],
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },
  title: {
    ...ui.title,
    maxWidth: rem(866),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.colors.white,
    position: 'relative',
    zIndex: 1,
  },
  circle: {
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    zIndex: 1,
    [adaptive]: {
      display: 'none',
    },
    '&.circle1': {
      background: '#98C0FF',
      width: rem(43),
      height: rem(43),
      top: rem(80),
      transform: `translateX(${rem(-530)})`,
    },
    '&.circle2': {
      background: '#98C0FF',
      width: rem(61),
      height: rem(61),
      top: rem(178),
      transform: `translateX(${rem(-655)})`,
    },
    '&.circle3': {
      background: '#F7B500',
      width: rem(76),
      height: rem(76),
      top: rem(313),
      transform: `translateX(${rem(-568)})`,
    },
    '&.circle4': {
      background: '#F7B500',
      width: rem(96),
      height: rem(96),
      top: rem(59),
      transform: `translateX(${rem(440)})`,
    },
    '&.circle5': {
      background: '#98C0FF',
      width: rem(85),
      height: rem(85),
      top: rem(151),
      transform: `translateX(${rem(598)})`,
    },
    '&.circle6': {
      background: '#98C0FF',
      width: rem(33),
      height: rem(33),
      top: rem(326),
      transform: `translateX(${rem(410)})`,
    },
  },
  miniOrderForm: {
    background: theme.colors.white,
    width: rem(808),
    margin: [rem(40), 'auto', 0, 'auto'],
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'], 
      [0, rem(8), rem(40), 'rgba(0, 53, 136, 0.15)']
    ],
    [desktop]: {
      borderRadius: rem(88),
    },
    [adaptive]: {
      width: '100%',
      height: rem(128),
    },
  },
}

const Cta = ({
  classes,
  title,
  buttonText,
}) => 
  <div className={classes.root}>
    <div className={classnames(classes.circle, 'circle1')} />  
    <div className={classnames(classes.circle, 'circle2')} />   
    <div className={classnames(classes.circle, 'circle3')} />
    <div className={classnames(classes.circle, 'circle4')} /> 
    <div className={classnames(classes.circle, 'circle5')} /> 
    <div className={classnames(classes.circle, 'circle6')} />   
    <div className={classes.content}>
      <div className={classes.title}>{title}</div>
      <MiniOrderForm
        className={classes.miniOrderForm}
        buttonText={buttonText}
        placeholder='What subject do you need help with?'
        bottom
      />
    </div>
  </div>

export default injectSheet(styles)(Cta)