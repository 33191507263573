import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    position: 'absolute',
    bottom: rem(16),
    right: rem(16),
    fontSize: rem(12),
    lineHeight: rem(20),
    fontWeight: 'normal',
    color: theme.colors.slateGrey,
    display: 'block',
  },
}

const Time = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Time)