import React from 'react'
import injectSheet from 'react-jss'
import { ui } from '../../theme/base'

const styles = {
  root: ui.item,
}

const Item = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Item)