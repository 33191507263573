import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from '../../../theme/base'

const is320 = window.innerWidth < 375

const styles = {
  root: {
    height: is320 ? rem(440) : rem(400),
    padding: [rem(16), rem(16), rem(64), rem(16)],
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'],
      [0, rem(8), rem(40), 'rgba(0, 53, 136, 0.15)']
    ],
    borderRadius: rem(16),
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    [desktop]: {
      width: rem(392),
    },
    '& + &': {
      marginLeft: rem(24),
    },
  },
  header: {
    paddingLeft: rem(64),
    paddingBottom: rem(12),
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
  },
  avatar: {
    width: rem(52),
    height: rem(52),
    display: 'block',
    position: 'absolute',
    top: rem(4),
    left: 0,
  },
  name: {
    fontSize: rem(18),
    lineHeight: rem(28),
    letterSpacing: rem(0.11),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 600,
  },
  rates: {
    marginTop: rem(4),
  },
  star: {
    background: '#51B37F',
    width: rem(24),
    height: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& + &': {
      marginLeft: rem(2),
    },
    '& > img': {
      width: rem(16),
      height: rem(16),
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  body: {
    marginTop: rem(12),
  },
  title: {
    fontSize: rem(16),
    lineHeight: rem(24),
    letterSpacing: rem(0.11),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 600,
  },
  text: {
    height: rem(192),
    marginTop: rem(4),
    fontSize: rem(14),
    lineHeight: rem(20),
    letterSpacing: rem(0.11),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
  },
  footer: {
    fontSize: rem(13),
    lineHeight: rem(20),
    letterSpacing: rem(0.11),
    color: theme.colors.slateGrey,
    fontFamily: theme.fontFamilies.Manrope,
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    bottom: rem(24),
  },
  verified: {
    paddingLeft: rem(18),
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: rem(2),
      width: rem(14),
      height: rem(14),
      backgroundImage: `url(${require('./check.svg').default})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(14), rem(14)],
    },
  },
  date: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}

const Item = ({
  classes,
  avatar,
  name,
  rate,
  title,
  text,
  date,
}) => 
  <div className={classes.root} style={{width: is320 ? '80vw' : rem(298)}}>
    <div className={classes.header}>
      <img className={classes.avatar} src={require(`./${avatar}.jpg`).default} alt='' />
      <div className={classes.name}>{name}</div>
      <div className={classes.rates}>
        {[...Array(rate).keys()].map((_, index) =>
          <div className={classes.star} key={index}>
            <img src={require(`./star.svg`).default} alt='' />
          </div>  
        )}
      </div>
    </div>
    <div className={classes.body}>
      <div className={classes.title}>{title}</div>
      <div className={classes.text}>{text}</div>
    </div>
    <div className={classes.footer}>
      <div className={classes.verified}>Verified purchase</div>
      <div className={classes.date}>{date}</div>
    </div>
  </div>

export default injectSheet(styles)(Item)