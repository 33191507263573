import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
    color: theme.colors.charcoalGrey,
  },
}

const Divider = ({
  children,
  classes
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Divider)