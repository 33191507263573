import React, {useEffect} from 'react'
import {useOrderChatConfig, useOrderQuery} from '../../hooks'
import {Chat} from './BaseChat'
import {useOrderOnboardParams} from './onboards'
import {useActions} from '../../actions'
import {isOnboardCanStart} from '../../nerdytutors'
import EditPhoneForm from "../EditPhoneForm";

export const OrderChat = ({orderId}) => {
  const actions = useActions()
  const chatConfig = useOrderChatConfig(orderId)
  const {data: order} = useOrderQuery(orderId)
  const messagebox = order.refunded === null
  const onboardParams = useOrderOnboardParams(order, chatConfig)

  useEffect(() => {
      if (isOnboardCanStart(order)) {
        actions.showModal2(EditPhoneForm, {orderId})
      }
    },
    // eslint-disable-next-line
    []
  )

  return <Chat
    chatConfig={chatConfig}
    messagebox={messagebox}
    onboardParams={onboardParams}
    placeholder={isFetched => isFetched
      ? {title: 'This is a chat with your tutor', text: 'We will notify you when your tutor is connected.'}
      : {title: 'Chat with your tutor', text: 'Please wait until we load your chat history.'}
    }
    audio
    pic
  />
}
