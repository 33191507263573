import React, {Fragment} from 'react'
import Card from '../Card'
import {useTutorsQuery} from '../../hooks'
import {Tutor} from './Tutor'
import {EmptyTutorList} from './EmptyTutorList'

export const Tutors = ({tutorId}) => {
  const tutorsQuery = useTutorsQuery()

  const groups = [
    ['Favorites', tutorsQuery.data.freelancers.filter(t => t.is_favorite)],
    ['Your Past tutors', tutorsQuery.data.freelancers.filter(t => !t.is_favorite)],
  ]
    .filter(group => group[1].length > 0)

  return tutorsQuery.data.freelancers.length > 0
    ? groups.map(([name, members], index) => (
      <Fragment key={name}>
        <Card.Divider>{name}</Card.Divider>
        {
          members.map(tutor =>
            <Tutor
              key={tutor.id}
              tutorId={tutor.id}
              active={tutorId === tutor.id}
            />
          )
        }
        {
          index < groups.length - 1 &&
          <Card.Divider>&nbsp;</Card.Divider>
        }
      </Fragment>
    ))
    : <EmptyTutorList/>
}