import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from './../../theme/base'
import {Icon} from './../Icon'

const styles = {
  root: {
    background: '#ebeffa',
    minHeight: rem(77),
    marginTop: rem(8),
    padding: [rem(8), rem(100), rem(8), rem(16)],
    borderRadius: rem(16),
    position: 'relative',
    [desktop]: {
      maxWidth: rem(343),
    },
    '& svg': {
      width: rem(64),
      height: rem(64),
      position: 'absolute',
      top: rem(5),
      right: rem(16),
    },
  },
  title: {
    fontSize: rem(17),
    lineHeight: rem(22),
    color: '#697896',
    fontFamily: theme.fontFamilies.Manrope,
  },
  description: {
    marginTop: rem(2),
    fontSize: rem(13),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
}

const SuccessRate = ({ title, description, classes }) =>
  <div className={classes.root}>
    <div className={classes.title}>{title}</div>
    <div className={classes.description}>{description}</div>
    <Icon iconName='success-rate' />
  </div>

export default injectSheet(styles)(SuccessRate)
