import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../mtuikit/theme/base'

const styles = {
  root: {
    marginTop: rem(16),
    fontSize: rem(22),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
  },
}

const Title = ({
  classes,
  className,
  children,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Title)