import React, {useState, useRef} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {Icon, utils} from 'mtuikit'
import useOutsideClick from '../../../../utils/useOutsideClick'
import theme, {rem} from '../../../../theme/base'
import {SlideUpDropDownAnimation} from './../../../Animation'
import List from './List'

const styles = {
  tutorsList: {
    whiteSpace: 'nowrap',
  },
  assignToTutor: {
    padding: [10, 32, 10, 12],
    borderRadius: 8,
    color: theme.colors.blue,
    fontSize: 14,
    lineHeight: 20 / 14,
    cursor: 'pointer',
    transition: 'background 300ms ease',
    '&:hover': {
      background: 'rgba(61, 119, 255, 0.08)',
    },
    '&:active': {
      background: 'rgba(61, 119, 255, 0.16)',
    },
    '&.showList': {
      background: 'rgba(61, 119, 255, 0.16)',
    }
  },
  content: {
    position: 'relative',
  },
  container: {
    overflowY: 'visible !important',
  },
  tutor: {
    padding: [rem(4), rem(32), rem(4), rem(8)],
    borderRadius: rem(8),
    position: 'relative',
    cursor: 'pointer',
  },
  avatar: {
    width: rem(32),
    height: rem(32),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  name: {
    padding: [rem(6), 0, rem(6), rem(4)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(14),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
    fontFamily: theme.fontFamilies.Manrope,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  arrow: {
    fill: theme.colors.blueyGrey,
    width: rem(10),
    height: rem(10),
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: rem(12),
    display: 'block',
    pointerEvents: 'none',
    transition: 'fill 300ms ease, transform 300ms ease',
    '&.showList': {
      fill: theme.colors.blue,
      transform: 'translateY(-50%) rotateZ(180deg)',
    },
  },
}

const TutorsList = ({
                      classes,
                      className,
                      tutors,
                      requestedTutor,
                      onChangeRequestedTutorId,
                    }) => {
  const [showList, setShowList] = useState(false)

  const onMakeShow = () => setShowList(!showList)
  const onHide = () => setShowList(false)
  const onSelectTutor = id => {
    onChangeRequestedTutorId(id)
    onHide()
  }

  const outsideClickRef = useRef(null)
  useOutsideClick(outsideClickRef, onHide)

  return (
    <div className={classnames(classes.tutorsList, className)} ref={outsideClickRef}>
      <div className={classes.container}>
        <div className={classes.content}>
          {requestedTutor
            ? <div className={classes.tutor} onClick={onMakeShow}>
              <img className={classes.avatar} src={utils.getUserAvatarUrl(requestedTutor.id)} alt=''/>
              <span className={classes.name}>{requestedTutor.name}</span>
              <Icon className={classnames(classes.arrow, {showList})} iconName='arrow10'/>
            </div>
            : <div className={classnames(classes.assignToTutor, {showList})} onClick={onMakeShow}>
              Assign to a tutor
              <Icon className={classnames(classes.arrow, {showList})} iconName='arrow10'/>
            </div>
          }
        </div>
      </div>
      {showList &&
      <SlideUpDropDownAnimation>
        <List
          tutors={tutors}
          onSelect={onSelectTutor}
          onHide={onHide}
        />
      </SlideUpDropDownAnimation>
      }
    </div>
  )
}

export default injectSheet(styles)(TutorsList)