import React from 'react'
import Card from '../Card'
import {useTutor} from '../../hooks'
import {useActions} from '../../actions'

export const Tutor = ({tutorId, active}) => {
  const tutor = useTutor(tutorId)
  const actions = useActions()

  const onClick = () => {
    actions.showTutorProfile(tutorId)
  }

  return (
    <Card key={tutor.id} image='big' onClick={onClick} active={active}>
      <Card.Avatar userId={tutorId}/>
      <Card.Title>{tutor.name}</Card.Title>
      <Card.Text>
        {tutor.my_last_subject}
      </Card.Text>
      <Card.Rate value={tutor.avg_score}>
        <Card.RateText value={`Helped ${tutor.orders_count === 1 ? 'once' : `${tutor.orders_count} times`}`}/>
      </Card.Rate>
    </Card>
  )
}