import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Button, utils} from 'mtuikit'
import Textarea from '../OrderFormV5/layout/Textarea'
import Popup from '../OrderFormV5/layout/Popup'
import Uploader from '../OrderFormV5/layout/File/Uploader'
import {S3} from '../../config'
import {desktop, adaptive, media} from '../../mtuikit/theme/base'
import {useAddTaskInstructionsMutation} from '../../hooks'
import {cleanFiles} from './../OrderFormV5/hooks'
import Icon from '../Orders/icons'
import Overlay from '../Overlay'
import {trackValues} from '../../lrtracker'


const AddTaskInstructions = ({orderId, onClose, zIndex}) => {
  const classes = useStyle()
  const isMobile = utils.useMobile(media.desktop)

  const [closing, setClosing] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const addTaskInstructionsMutation = useAddTaskInstructionsMutation({
    onSuccess: () => {
      onClose()
      setClosing(false)
    }
  })

  const [files, setFiles] = useState([])
  const [details, setDetails] = useState('')
  const [edited, setEdited] = useState(false)

  const isValid = files.length > 0 || details.trim().length > 0

  const addTaskInstructions = () => {
    const cleanedFiles = cleanFiles(files)
    addTaskInstructionsMutation.mutate({orderId, details, files: cleanedFiles})
    trackValues('chatClientTutor', 'clientSubmitInstructionBtnClick', {context: {id: orderId}})
  }

  return <Overlay zIndex={zIndex - 1}>
    <div className={classes.wrapper} style={{zIndex}}>
      <div className={classes.modal}>
        <div className={classes.close} onClick={() => {
          if (edited && isValid) {
            setClosing(true)
          } else {
            onClose()
          }
        }}>
          <Icon.Close/>
        </div>
        <div className={classes.title}>Add task instructions</div>
        <div>
          <div className={classes.textareaWrapper}>
            <Textarea
              className={'textareaStyled'}
              maxRows={10}
              placeholder={`Add instructions or describe your upload. E.g. "Here's a screenshot of my task description and a grading rubric from my course dashboard"`}
              value={details}
              onChange={e => {
                setDetails(e.target.value)
                setEdited(true)
              }}
            />
          </div>
          <div className={classes.uploader}>
            <Uploader
              config={S3}
              files={files}
              onChange={files => {
                setFiles(files)
                setEdited(true)
              }}
              onStatusChange={status => setFileUploading(status)}
              horizontal
            />
          </div>
        </div>
        <div className={classes.button}>
          <Button onClick={addTaskInstructions}
                  disabled={fileUploading || addTaskInstructionsMutation.isLoading || !isValid}
                  block={isMobile}>Submit</Button>
        </div>
      </div>
    </div>
    {
      closing
      && <Popup
        title='Close editing'
        text='Do you want to save instruction before closing?'
        onCancel={() => setClosing(false)}
        primaryText='Save'
        onPrimary={() => {
          setClosing(false)
          addTaskInstructions()
        }}
        secondaryText='Don’t save'
        onSecondary={() => {
          onClose()
          setClosing(false)
        }}
        className={classes.popup}
        zIndex={zIndex + 1}
      />
    }
  </Overlay>
}

const useStyle = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  modal: {
    position: 'relative',
    background: '#fff',
    [desktop]: {
      margin: 'auto',
      boxShadow: '0px 8px 12px rgba(0, 38, 96, 0.06), 0px 8px 40px rgba(0, 53, 136, 0.15)',
      borderRadius: 16,
      padding: 16,
      width: 504,
    },
    [adaptive]: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 16
    },
  },
  close: {
    position: 'absolute',
    top: 24,
    right: 24,
    cursor: 'pointer'
  },
  title: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '24px',
    color: '#565D6B',
    marginBottom: 16,
  },
  textareaWrapper: {
    '& .textareaStyled': {
      fallbacks: [
        {overflowY: 'auto'}, // for Mozilla
        {overflowY: 'overlay'},
      ],
      scrollbarWidth: 'thin',  // for Mozilla
    }
  },
  button: {
    [adaptive]: {
      background: 'transparent',
      marginTop: 'auto',
    },
    [desktop]: {
      textAlign: 'right',
      background: '#fff',
      borderRadius: 16,
    },
  },
  uploader: {
    [desktop]: {
      maxWidth: '100%',
      margin: [10, 0, 15],
    }
  },
  gradient: {
    [adaptive]: {
      position: 'fixed',
      right: 0,
      left: 0,
      bottom: 0,
      height: 144,
      backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
      pointerEvents: 'none'
    },
    [desktop]: {
      position: 'absolute',
      right: 0,
      left: 0,
      bottom: 72 + 96,
      height: '2rem',
      backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
      pointerEvents: 'none'
    },
  },
  popup: {
    [desktop]: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: -320 / 2,
      marginTop: -158 / 2
    }
  }
})

export default AddTaskInstructions
