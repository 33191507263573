import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useOrderQuery, useProfileQuery} from '../../hooks'
import {useActions} from '../../actions'
import Icon from './icons'

const saveBanner = id => window.localStorage.setItem(`_ep${id}`, "1")
const getBanner = id => !window.localStorage.getItem(`_ep${id}`)

const EditPhoneBanner = ({orderId}) => {
  const classes = useStyle()
  const actions = useActions()
  const [banner, setBanner] = useState(getBanner(orderId))
  const phone = useProfileQuery().data?.phone

  const order = useOrderQuery(orderId).data
  const assigned = order?.assigned
  const connected = order?.connected?.connected_at
  const isInstructionsChecked = order.checking_instructions !== 'in_progress'

  return banner && !connected && !assigned && isInstructionsChecked
    ? <div className={classes.root}>
      <div className={classes.close} onClick={() => {
        setBanner(false)
        saveBanner(orderId)
      }}><Icon.Close/></div>
      <div className={classes.banner}>
        <div className={classes.banner_icon_wrapper}>
          <img className={classes.banner_icon} src={require('./images/ill_bell_animation.gif').default} alt=''
               width='40'
               height='40'/>
        </div>
        <div>
          We will message you at{' '}
          <button className={classes.edit_button}
                  onClick={() => actions.showEditPhone({orderId, editedThrough: 'ChatClientTutorSidebar'})}
          >
            {phone}
            <img className={classes.edit_icon} src={require('./images/edit.svg').default} alt=''/>
          </button>
          <br/>
          when tutor connected or has questions about task details. Please respond ASAP.
        </div>
      </div>
    </div>
    : null
}

const useStyle = createUseStyles({
  root: {
    position: 'relative',
    marginBottom: 24,
  },
  banner: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.15px',
    color: '#000000',
    padding: [14, 8],
    background: 'rgba(255, 209, 83, 0.32)',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  banner_icon_wrapper: {
    backgroundColor: '#D8E4FF',
    borderRadius: '50%',
    marginRight: 4,
  },
  banner_icon: {
    display: 'block',
  },
  edit_button: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3D77FF',
    backgroundColor: 'transparent',
    padding: 0,
  },
  edit_icon: {
    display: 'block',
    width: 16,
    height: 16,
    marginLeft: 2,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: 8,
    right: 16
  }
})

export default EditPhoneBanner
