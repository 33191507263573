import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import Overlay from './../components/Overlay'
import {adaptive, rem} from '../theme/base'

const Modal = ({children, zIndex, color, mobileStickToBottom, className}) => {
  const classes = useStyles()
  return <Overlay zIndex={zIndex - 1} color={color}>
    <div className={classnames(classes.root, {[classes.mobileStickToBottom]: mobileStickToBottom})} style={{zIndex}}>
      <div className={classes.wrapper}>
        <div className={classnames(classes.modal, className)}>
          {children}
        </div>
      </div>
    </div>
  </Overlay>
}

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [adaptive]: {
      background: 'linear-gradient(180deg, #E2EBFF 0%, #FFFFFF 54.17%)',
    },
  },
  mobileStickToBottom: {
    [adaptive]: {
      top: 'auto',
      borderRadius: [24, 24, 0, 0],
      '& $wrapper': {
        maxWidth: 'none',
      },
    },
  },
  wrapper: {
    margin: 'auto',
    display: 'flex',
    position: 'relative',
    width: '100%',
    [adaptive]: {
      maxWidth: rem(375),
    },
  },
  modal: {
    position: 'relative',
    width: '100%',
    margin: 'auto',

    background: 'linear-gradient(180deg, #E2EBFF 0%, #FFFFFF 54.17%)',
    boxShadow: '0px 8px 12px rgba(0, 38, 96, 0.06), 0px 8px 40px rgba(0, 53, 136, 0.15)',

    borderRadius: rem(24),

    maxWidth: rem(464),
    minHeight: rem(476),
    padding: [rem(32), rem(24), rem(24)],

    [adaptive]: {
      boxShadow: 'none',
      background: 'none',
      maxWidth: rem(375),
      minHeight: rem(500),
      padding: [rem(60), rem(16), rem(16)],
    },
  }
})

export default Modal

