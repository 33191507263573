import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {colors, typography} from './consts'

const CreateButton = ({onClick, className}) => {
  const classes = useStyle()
  return <div className={classnames(classes.create_button, className)} onClick={onClick}>Continue creating task</div>
}

const useStyle = createUseStyles({
  create_button: {
    ...typography.x,
    color: colors.blue,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '280px',
    height: '44px',
    border: '2px solid #3D77FF',
    borderRadius: '28px',
    textAlign: 'center',
    cursor: 'pointer'
  },
})

export default CreateButton