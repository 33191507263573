import React from 'react'
import {createUseStyles} from 'react-jss'
import theme, {rem} from '../../theme/base'
import Header from './../Header'

const DesktopTwoColumnsView = ({sidebar, subheader, workarea}) => {
  const classes = useStyle()
  return <>
    <div className={classes.header}><Header/></div>
    <div className={classes.body}>
      <div className={classes.sidebar}>{sidebar}</div>
      <div className={classes.content}>
        {subheader && <div className={classes.subheader}>{subheader}</div>}
        <div className={classes.workarea}>{workarea}</div>
      </div>
    </div>
  </>
}

//const HEADER_HEIGHT_DESKTOP = 56
const SIDEBAR_WIDTH_INNER = 328
const SIDEBAR_PADDING = 16
const SIDEBAR_WIDTH_OUTER = SIDEBAR_WIDTH_INNER + SIDEBAR_PADDING * 2
const SUBHEADER_HEIGHT_DESKTOP = 56

const useStyle = createUseStyles({
  body: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
  },
  sidebar: {
    flexBasis: rem(SIDEBAR_WIDTH_OUTER),
    flexShrink: 0,
    padding: [rem(24), rem(SIDEBAR_PADDING)],
    borderRight: [1, 'solid', theme.colors.lightBlueGrey50],
    overflowY: 'auto',
  },
  subheader: {
    height: rem(SUBHEADER_HEIGHT_DESKTOP),
    borderBottom: [1, 'solid', theme.colors.paleGrey],
    zIndex: 10,
  },
  workarea: {
    flex: 1,
    overflowY: 'auto',
    background: '#fff',
  },
})

export default DesktopTwoColumnsView
