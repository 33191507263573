import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from './../../theme/base'
import Accordion from './../Accordion'
import Button from './../Button'
import { wrapPlaceholder, unwrapPlaceholder, validTemplate } from './utils'

const styles = {
  list: {
    paddingTop: rem(8),
  },
  item: {
    background: theme.colors.blue,
    width: rem(256),
    height: rem(133),
    marginTop: rem(8),
    marginRight: rem(8),
    padding: [rem(8), rem(8), rem(8), rem(12)],
    borderRadius: rem(20),
    color: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    [desktop]: {
      width: rem(480),
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.colors.black60,
      borderRadius: 'inherit',
      display: 'none',
      zIndex: 2,
    },
    '&:hover': {
      color: theme.colors.white,
      '&:after': {
        display: 'block',
      },
      '& $actions': {
        display: 'block',
      },
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
    },
  },
  actions: {
    position: 'absolute',
    left: rem(8),
    right: rem(8),
    bottom: rem(8),
    zIndex: 3,
    display: 'none',
  },
  button: {
    width: `calc(50% - ${rem(4)})`,
    position: 'absolute',
    bottom: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:first-child': {
      left: 0,
    },
    '&:last-child': {
      right: 0,
    },
    '&:only-child': {
      width: '100%',
    },
  },
}

const TemplateMessages = ({
  classes,
  items,
  onSelect,
  onSubmit,
}) => {
  const _items = items.map(({ icon, title, subtitle, items }) => ({
    icon,
    title,
    content: <>
      <div dangerouslySetInnerHTML={{ __html: subtitle }} />
      <div className={classes.list}>
        {items.map(item => {
          const value = wrapPlaceholder(item)
          const formattedText = unwrapPlaceholder(value)
          const validText = validTemplate(value)
          const onCopy = () => onSubmit(formattedText)
          return (
            <div key={item} className={classes.item}>
              <span dangerouslySetInnerHTML={{ __html: item }} />
              <div className={classes.actions}>
                <Button
                  className={classes.button}
                  type='reverse'
                  onClick={() => onSelect && onSelect(item)}
                >
                  Edit text
                </Button>
                {validText &&
                  <CopyToClipboard text={formattedText} onCopy={onCopy}>
                    <Button className={classes.button}>Copy text</Button>
                  </CopyToClipboard>
                }
              </div>
            </div>
          )
        })}
      </div>
    </>
  }))

  return <Accordion items={_items} />
}

export default injectSheet(styles)(TemplateMessages)