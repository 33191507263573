import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'
import { RATES } from '../../mtuikit/theme/base'

const styles = {
  rates: {
    marginTop: rem(16),
    marginLeft: rem(-60),
    color: theme.colors.lightBlueGrey,
    position: 'relative',
    direction: 'rtl',
    textAlign: 'center',
    '& label': {
      color: theme.colors.lightBlueGrey,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
      position: 'relative',
      '-webkitTapHighlightColor': 'transparent',
      transition: 'color 300ms ease-in-out',
      '&:hover': {
        color: theme.colors.star,
        '& ~ label': {
          color: theme.colors.star,
        },
      },
    },
    '& svg': {
      width: rem(44),
      height: rem(44),
      display: 'block',
      fill: 'currentColor',
      transition: 'fill 300ms ease-in-out',
      pointerEvents: 'none',
    },
  },
  radio: {
    display: 'none',
    '&:checked': {
      '& ~ label': {
        color: theme.colors.star,
      },
    },
  },
}

const Rating = ({
  rate,
  onChangeRate,
  disabled,
  classes
}) =>
  <Fragment>
    <div className={classes.rates}>
      {RATES.map(item => {
        const rateId = `rate-${item}`
        const checked = rate === item

        return (
          <Fragment key={rateId}>
            <input
              id={rateId}
              type='radio'
              value={item}
              disabled={disabled}
              className={classes.radio}
              checked={checked}
              onChange={e => onChangeRate(parseInt(e.target.value))}
            />
            <label htmlFor={rateId}>
              <Icon iconName='star2' />
            </label>
          </Fragment>
        )
      }
      )}
    </div>
  </Fragment>

export default injectSheet(styles)(Rating)
