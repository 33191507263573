import React from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'

const useStyle = createUseStyles({
  toggleSwitch: {
    height: 20,
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [0, 4],
    cursor: 'pointer',
    '&$disabled': {
      '& $checkbox': {
        backgroundColor: '#BAC7E2',
      },
      '& $checkmark': {
        backgroundColor: '#BAC7E2',
        borderColor: 'rgba(186, 199, 226, 0.2)', //#BAC7E2
      }
    }
  },
  disabled: {
    cursor: 'default',
  },
  checkbox: {
    width: 26,
    height: 8,
    background: '#3D77FF',
    opacity: 0.3,
    borderRadius: 24,
    '&:checked': {
      '& + $checkmark': {
        left: 'calc(100% - 20px)',
        borderWidth: 0,
        paddingTop: 2,
        '& $icon': {
          visibility: 'visible',
        }
      },
    }
  },
  checkmark: {
    width: 20,
    height: 20,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    background: '#3D77FF',
    backgroundClip: 'padding-box',
    border: '4px solid rgba(61, 119, 255, 0.2)',
    borderRadius: '50%',
    transition: 'all .2s ease-in-out',
  },
  icon: {
    visibility: 'hidden',
    display: 'block',
    width: 10,
    height: 8.33,
  },
});

const ToggleSwitch = ({ checked, disabled, onChange }) => {
  const classes = useStyle();
  return (
      <label className={classnames(classes.toggleSwitch, {[classes.disabled]:disabled})}>
        <input className={classes.checkbox} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <div className={classes.checkmark}>
          <svg className={classes.icon} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.60426 0.311311C10.0635 0.684456 10.1333 1.35925 9.76017 1.8185L4.41101 8.40203L0.359622 4.8008C-0.0826456 4.40767 -0.122482 3.73045 0.270644 3.28818C0.663771 2.84592 1.34099 2.80608 1.78326 3.19921L4.16045 5.31226L8.09707 0.46722C8.47022 0.00796925 9.14501 -0.0618338 9.60426 0.311311Z" fill="white"/></svg>
        </div>
      </label>
  )
};

export default ToggleSwitch
