import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Popover} from 'react-tiny-popover'
import {rem, adaptive, media} from './../../theme/base'
import Icon from './icons'
import classnames from 'classnames'
import {utils} from 'mtuikit'

const OrderNumber = ({orderNumber}) => {
  const classes = useStyle()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const isDesktop = !utils.useMobile(media.desktop)

  return <>
    <CopyToClipboard
      text={orderNumber}
      onCopy={() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }}
    >
      <div>
        <Popover
          isOpen={isPopoverOpen}
          positions={['top']}
          content={<div className={classes.tooltip}>Copy number</div>}
        >
          <div className={classnames(classes.orderNumber, {withHover: isDesktop})}
               onMouseEnter={() => isDesktop && setIsPopoverOpen(true)}
               onMouseLeave={() => isDesktop && setIsPopoverOpen(false)}>
            <div className={classes.number}>
              Task #{orderNumber}
            </div>
            <Icon.Copy/>
          </div>
        </Popover>
      </div>
    </CopyToClipboard>

    {isCopied &&
    <div className={classes.copyMessage}>Task number copied</div>
    }
  </>
}

const useStyle = createUseStyles({

  orderNumber: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    '&.withHover': {
      '&:hover $number': {
        color: '#3D77FF',
      },
      '&:hover svg': {
        transition: 'all .3s ease',
        color: '#3D77FF',
      }
    },
    'svg': {
      color: '#2F333D',
      transition: 'all .3s ease',
    },
  },
  number: {
    fontWeight: 800,
    fontSize: rem(14),
    lineHeight: rem(20),
    color: '#2F333D',
    marginRight: rem(4),
    transition: 'color .3s ease',
  },
  copyMessage: {
    position: 'fixed',
    top: rem(80),
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: rem(36),
    padding: '0 12px',
    background: 'rgba(0,0,0,0.8)',
    borderRadius: rem(18),
    fontSize: rem(16),
    color: '#FFF',
    zIndex: 99999,
    [adaptive]: {
      top: rem(48),
    },
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0,0,0,.8)',
    borderRadius: rem(4),
    height: rem(32),
    fontSize: rem(12),
    lineHeight: rem(16),
    color: '#FFF',
    position: 'relative',
    //marginRight: '23px',
    bottom: rem(4),
    left: rem(80),
    width: 'max-content',
    padding: [rem(0), rem(8)],
    zIndex: 999,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: rem(-2),
      width: 0,
      height: 0,
      borderTop: '4px solid rgba(0,0,0,.8)',
      borderRight: '4px solid transparent',
      borderLeft: '4px solid transparent',
    }
  },
})

export default OrderNumber
