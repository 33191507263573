import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from 'mtuikit'
import { Link } from "react-router-dom"
import { rem } from '../../../../theme/base'

const styles = {
  root: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(19),
    right: rem(16),
    cursor: 'pointer',
    animation: 'spin 2s linear infinite',
    '& div, svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const Settings = ({
  classes,
  onClick,
}) => {
  return (
    <Link className={classes.root} to={'/settings'} onClick={onClick}>
      <Icon iconName='settings' />
    </Link>
  )
}

export default injectSheet(styles)(Settings)
