import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {rem} from '../../mtuikit/theme/base'

const styles = {
  root: {
    flex: 1,
    fallbacks: [
      {overflowY: 'auto'}, // for Mozilla
      {overflowY: 'overlay'},
    ],
    padding: [0, rem(16), rem(16)],
  },
}

const Content = ({
                   classes,
                   style,
                   children,
                   className,
                 }) =>
  <div className={classnames(classes.root, className)} style={style}>
    {children}
  </div>

export default injectSheet(styles)(Content)
