import React, { useEffect, useState, useRef, useCallback } from 'react'
import injectSheet from 'react-jss'
import { createPortal } from 'react-dom'
import theme, { rem } from './../../theme/base'

const POPUP_TRIANGLE_HEIGHT = 6
const OFFSET_BETWEEN_ELEMENT_AND_POPUP = 10

const styles = {
  root: {
    background: theme.colors.white,
    height: 'auto',
    padding: rem(12),
    boxShadow: `
      0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15), 
      0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06)`,
    borderRadius: rem(8),
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translateX(-50%)',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: [rem(POPUP_TRIANGLE_HEIGHT), 'solid', 'transparent'],
      borderRight: [rem(POPUP_TRIANGLE_HEIGHT), 'solid', 'transparent'],
      borderTop: [rem(POPUP_TRIANGLE_HEIGHT), 'solid', theme.colors.white],
      position: 'absolute',
      left: '50%',
      top: '100%',
      transform: 'translateX(-50%)',
      display: 'block',
    },
  },
}

const getPopupStyle = (trigger, popup) => {
  const { width, top, left } = trigger.getBoundingClientRect()
  const { clientHeight } = popup
  return `
  position: absolute;
  top: ${window.scrollY + top - clientHeight - POPUP_TRIANGLE_HEIGHT - OFFSET_BETWEEN_ELEMENT_AND_POPUP}px;
  left: ${window.scrollX + left + width / 2}px;
  z-index: 999999;
  `
}

const Popup = ({
  classes,
  trigger,
  content,
  onOpen,
  onClose,
}) => {
  const popupRef = useRef()
  const triggerRef = useRef()
  const nodeRef = useRef(document.createElement('div'))

  const [active, setActive] = useState(null)
  const onTriggerMouseEnter = useCallback(e => { setActive(true); onOpen && onOpen() }, [setActive, onOpen])
  const onTriggerMouseLeave = useCallback(e => { setActive(null); onClose && onClose() }, [setActive, onClose])

  const popupContent = active && content

  useEffect(() => {
    if (popupContent) {
      document.body.insertBefore(nodeRef.current, document.getElementById('root'))
      nodeRef.current.setAttribute('style', getPopupStyle(triggerRef.current, popupRef.current))
      return () => {
        // eslint-disable-next-line
        document.body.removeChild(nodeRef.current)
      }
    }
  }, [popupContent])

  return <>
    {React.cloneElement(trigger, {
      onMouseEnter: onTriggerMouseEnter,
      onMouseLeave: onTriggerMouseLeave,
      innnerRef: node => { triggerRef.current = node }
    })}
    {popupContent && createPortal(
      <div className={classes.root} ref={popupRef}>
        {popupContent}
      </div>,
      nodeRef.current
    )}
  </>
}

export default injectSheet(styles)(Popup)
