import React from 'react'
import theme, { rem } from './../../theme/base'
import injectSheet from 'react-jss'
import {Icon} from './../Icon'
import {isUserDaylightHours} from './../../utils/isUserDaylightHours'
import {printTimezoneDateTime} from './../../utils/printTimezoneDateTime'

const styles = {
  name: {
    color: theme.colors.black,
    fontSize: rem(14),
    lineHeight: rem(16),
    fontFamily: theme.fontFamilies.Manrope,
  },
  title: {
    marginTop: rem(8),
    color: theme.colors.slateGrey,
    fontSize: rem(11),
    lineHeight: rem(16),
  },
  timezone: {
    position: 'relative',
    '& svg': {
      width: rem(16),
      height: rem(16),
      margin: [rem(1), rem(4), 0, 0],
      fill: '#2F333D',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      color: theme.colors.charcoalGrey,
      fontSize: rem(13),
      lineHeight: rem(20),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

const ClientInfo = ({
  classes,
  timezone,
  name
}) => name || timezone
    ? <>
      {name && <div className={classes.name}>{name}</div>}
      {timezone && <>
        <div className={classes.title}>Local time:</div>
        <div className={classes.timezone}>
          <Icon iconName={isUserDaylightHours(timezone) ? 'sun2' : 'moon2'} />
          <span>{printTimezoneDateTime(timezone)}</span>
        </div>
      </>}
    </>
    : null

export default injectSheet(styles)(ClientInfo)
