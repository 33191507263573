import React, {useState} from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import Attachment from './../OrderFormV5/layout/File/Attachment'
import {useOrderQuery} from '../../hooks'

const Instructions = ({orderId, className}) => {
  const classes = useStyle()

  const orderQuery = useOrderQuery(orderId)
  if (!orderQuery.isSuccess) return null
  const order = orderQuery.data
  const {details, files} = order

  return <div className={classnames(classes.root, className)}>
    <div className={classes.detailsWrapper}>
      <div className={classes.details}>{details}</div>
    </div>

    {
      files.length
        ? <div className={classes.files}>
          {files.map(({_id: key, ...file}) => <Attachment key={key} big {...file} />)}
        </div>
        : null
    }

  </div>
}

const useStyle = createUseStyles({
  root: {},
  detailsWrapper: {
    position: 'relative',
    '&:after': {
      content: '""',
      left: 0,
      right: 0,
      bottom: 0,
      height: '2rem',
      position: 'absolute',
      backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    },
  },
  details: {
    margin: [14, 0, 10],
    paddingBottom: 18,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#2F333D',
    whiteSpace: 'pre-wrap',
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
    scrollbarWidth: 'thin',  // for Mozilla
    maxHeight: '200px',
  },
  files: {},
})

export default Instructions
