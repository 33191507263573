import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import {Icon} from '../Icon'
import Message from './Message'

const styles = {
  root: {
    background: theme.colors.brightOrangeTwo,
    padding: [rem(7), rem(12), rem(3)],
    '& span': {
      lineHeight: 'inherit',
      display: 'inline-block',
    },
    '& svg': {
      width: rem(13),
      height: rem(11),
      marginRight: rem(4),
      display: 'inline-block',
      pointerEvents: 'none',
    },
  },
}

const Deleted = ({ classes }) =>
  <Message className={classes.root}>
    <Icon iconName='check' />
    <span>Message deleted</span>
  </Message>

export default injectSheet(styles)(Deleted)