import React, { useState } from 'react'
import injectSheet from 'react-jss'
import {Collapse} from 'react-collapse'
import theme, { rem } from './../../theme/base'
import {Icon} from './../Icon'

const styles = {
  root: {
    // padding: rem(24)
    padding: [rem(16), 0, rem(16), 0],
    '& .ReactCollapse--collapse': {
      transition: 'height 300ms linear',
    },
  },
  item: {
    textAlign: 'left',
    position: 'relative',
    '& + &': {
      marginTop: rem(32),
    },
  },
  title: {
    paddingLeft: rem(32),
    fontFamily: theme.fontFamilies.Manrope,
    color: '#2F333D',
    fontSize: rem(17),
    lineHeight: rem(22),
    cursor: 'pointer',
    display: 'inline-block',
    verticalAling: 'top',
    fontWeight: 600,
    '& svg': {
      width: rem(16),
      height: rem(16),
      position: 'absolute',
      left: 0,
      top: rem(4),
    },
  },
  content: {
    marginTop: rem(16),
    paddingLeft: rem(32),
    fontFamily: theme.fontFamilies.Manrope,
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(22),
    letterSpacing: rem(0.09),
  },
}

const Accordion = ({
  items,
  classes,
}) => {
  const [openedItems, setOpenedItems] = useState([])

  const onToggle = item => {
    const result = openedItems.indexOf(item) === -1
      ? [...openedItems, item]
      : openedItems.filter(title => title !== item)

    setOpenedItems(result)
  }

  return (
    <div className={classes.root}>
      {items.map(({ icon, title, content }) => {
        const isOpened = openedItems.indexOf(title) > -1
        return <div className={classes.item} key={title}>
          <div
            className={classes.title}
            onClick={() => onToggle(title)}
          >
            <Icon iconName={icon} />
            {title}
          </div>
          <Collapse isOpened={isOpened}>
            <div className={classes.content}>
              {content}
            </div>
          </Collapse>
        </div>
      })}
    </div>
  )
}

export default injectSheet(styles)(Accordion)