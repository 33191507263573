import React from 'react'
import injectSheet from 'react-jss'
import Header from './../../sections/Header'
import Footer from './../../sections/Footer'
import {Title, Subtitle, Paragraph, List, Item, Blockquote} from './../../layout'
import theme, {rem, content, adaptive} from '../../theme/base'
import {useAuth} from '../../../hooks';

const styles = {
  root: {
    background: theme.colors.white,
  },
  wrapper: {
    paddingTop: rem(136),
    paddingBottom: rem(80),
    [adaptive]: {
      paddingTop: rem(120),
      paddingBottom: rem(64),
    },
  },
  content: {
    ...content,
    width: rem(624),
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      width: '100%',
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },
}

const TermsAndConditions = ({classes}) => {
  const {isLogged} = useAuth()
  return (
    <div className={classes.root}>
      <Header showLogin={!isLogged} hasFixedState/>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Title>Terms and Conditions</Title>

          <Blockquote>
            <Paragraph>By visiting this web site, you agree to be bound by the website's Nerdy-tutors.com Terms of Use
              and all relatable HK laws and regulations. You also agree that you bear full responsibility for your
              compliance with any relatable local laws and regulations.</Paragraph>
            <Paragraph>If you do not accept any provisions of these Terms, we prohibit you from using, reading or
              otherwise facilitating this website.</Paragraph>
            <Paragraph>These Terms become effective upon the User from the time of their accession. The Terms are valid
              indefinitely after the first accession mentioned before. In case that one or more provisions of these
              Terms become non-valid, unenforceable or unrelated for any reason, this will not have an effect on the
              validity or applicability of the remaining provisions.</Paragraph>
          </Blockquote>

          <Subtitle>Nerdy-tutors.com Service Provision Agreement</Subtitle>
          <Paragraph>Nerdy-tutors.com is a technology service that facilitates communication and interaction between
            Clients and Vendors (Tutors) to provide their services. These include physical and digital goods, as well as
            services related to education and college life. These services include but not limited to book search, task
            management, tutoring services, academic advice, etc.</Paragraph>
          <Paragraph>Nerdy-tutors.com does not make decisions related to the provision of Vendor’s services, or
            otherwise manage or supervise these services or their contents. Nerdy-Tutors does not endorse any Tutor and
            only provides the communication channel with those Tutors who confirmed their willingness to interact with
            the Client. The Client is responsible for the assessment and confirmation of the identity and suitability of
            the Tutors reached with the help of Nerdy-tutors.com service. In addition, although we require Tutors to
            provide true and accurate information about themselves, we cannot confirm or test any Tutor's input
            regarding their identity, academic qualifications or experience.</Paragraph>
          <Paragraph>Nerdy-tutors does not provide, produce or procure any illegal services or goods. These include
            services, products and goods that breach policies and honour codes of educational institutions. The main
            service of Nerdy-tutors.com is the provision of contact with a Vendor (Tutor) and technological means for
            Tutors to provide their service. After the Tutor is found for the Client's request, the Client has 30
            minutes to reject the Tutor and initiate the search for another one free of charge. The Client can request
            the provision of another Tutor or request cancellation at any time after 30 minutes has passed; however, in
            this case, the Client may need to pay for already provided services.</Paragraph>
          <Paragraph>If the Client is not satisfied with the service provided, they can initiate service improvement
            free of charge during the period of 30 days after service provision. Service improvement is the alteration
            of the services, goods or products, which is made by the Vendor (Tutor) to match Client's requirements. The
            Client agrees to provide at least 24 hours for the Vendor to deliver the improved services, products or
            goods. Should the Vendor be unable to improve the service or refuse to do so, the Client is eligible for
            100% refund.</Paragraph>
          <Paragraph>If there are no services provided and/or no Tutor is found for the Client's request, the Client is
            eligible for an immediate 100% refund to their payment method. The Client may also request a refund during
            the period of 30 days after service or goods provision in case there are issues with services or goods
            quality or quantity. In the case of quality-related requests, Nerdy-tutors.com will need up to 2 business
            days to investigate the issue and determine refund eligibility and the amount of refund. It may require 2-5
            bank days for refunded funds to reflect in bank statements.</Paragraph>
          <Paragraph>The Client must note that the Vendor (Tutor) may also reject the Client's request within 30 minutes
            free of charge.</Paragraph>
          {/* <Paragraph>Legal address and name of the company: OZ Research Inc, with its registered office at 2043 W 6TH STREET,11223 NEW YORK, USA.</Paragraph> */}

          <Subtitle>Registration and Account Security</Subtitle>
          <Paragraph>Nerdy-tutors.com users provide their real names and information, and we need your help to keep it
            that way. Here are some commitments you make to us relating to registering and maintaining the security of
            your account:</Paragraph>
          <List>
            <Item>You will not provide any false personal information on Nerdy-tutors.com, or create an account for
              anyone other than yourself without permission.</Item>
            <Item>You will not create more than one personal account.</Item>
            <Item>If we disable your account, you will not create another one without our permission.</Item>
            <Item>You will keep your contact information accurate and up-to-date.</Item>
            <Item>You will not share your password (or in the case of developers, your secret key), let anyone else
              access your account, or do anything else that might jeopardize the security of your account.</Item>
          </List>
          <Paragraph>If you select a username or similar identifier for your account or Page, we reserve the right to
            remove or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a
            username that does not closely relate to a user's actual name).</Paragraph>

          <Subtitle>Safety</Subtitle>
          <Paragraph>We do our best to keep Nerdy-tutors.com safe, but we cannot guarantee it. We need your help to keep
            Nerdy-tutors.com safe, which includes the following commitments by you:</Paragraph>
          <List>
            <Item>You will not post unauthorized commercial communications (such as spam) on Nerdy-tutors.com.</Item>
            <Item>You will not collect users' content or information, or otherwise access Nerdy-tutors.com, using
              automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior
              permission.</Item>
            <Item>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on
              Nerdy-tutors.com.</Item>
            <Item>You will not upload viruses or other malicious code.</Item>
            <Item>You will not solicit login information or access an account belonging to someone else.</Item>
            <Item>You will not bully, intimidate, or harass any user.</Item>
            <Item>You will not post content that: is hate speech, threatening, or pornographic; incites violence or
              contains nudity or graphic or gratuitous violence.</Item>
            <Item>You will not use Nerdy-tutors.com to do anything unlawful, misleading, malicious, or
              discriminatory.</Item>
            <Item>You will not do anything that could disable, overburden, or impair the proper working or appearance of
              Nerdy-tutors.com, such as a denial of service attack or interference with page rendering or other
              Nerdy-tutors.com functionality.</Item>
            <Item>You will not facilitate or encourage any violations of this Statement or our policies.</Item>
          </List>

          <Subtitle>Content Regulations</Subtitle>
          <Paragraph>Users are NOT allowed to post content which</Paragraph>
          <List>
            <Item>contains expletives or language which could be deemed offensive or is likely to harass, upset,
              embarrass, alarm or annoy any other person;</Item>
            <Item>is obscene or otherwise may offend human dignity;</Item>
            <Item>is abusive, insulting or threatening, or which promotes or encourages racism, sexism, hatred or
              bigotry;</Item>
            <Item>incorporates the image or likeness of any individual under 18 years of age;</Item>
            <Item>is defamatory;</Item>
            <Item>relates to commercial activities (including, without limitation, sales, competitions and advertising,
              links to other websites or premium line telephone numbers);</Item>
            <Item>involves the transmission of "junk" mail or "spam";</Item>
            <Item>contains any spyware, adware, viruses, corrupt files, worm programs or other malicious code designed
              to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications,
              networks, servers or other equipment,</Item>
            <Item>Trojan horse or any other material designed to damage, interfere with, wrongly intercept or
              expropriate any data or personal information whether from Nerdy-tutors.com or otherwise;</Item>
            <Item>or itself, or the posting of which, infringes any third party's rights (including, without limitation,
              intellectual property rights, and privacy rights).</Item>
          </List>

          <Subtitle>Nerdy-tutors.com Operation and Liability Regarding Use of the Site</Subtitle>
          <Paragraph>The users bear liability for their own actions in connection with the creation and posting of
            information on their own personal page on the site as well as in connection with the posting of information
            on the personal pages of other users and other sections of the site in accordance with effective HK
            legislation. The breach of these Terms and effective HK legislation involves civil, administrative, and
            criminal liability.</Paragraph>
          <Paragraph>Nerdy-tutors.com makes it technically possible for the Users to use the site, does not participate
            in forming the content of the Users' personal pages, does not control and does not bear liability for the
            actions or omissions of any persons regarding use of the site or formation and use of the content of the
            Users' personal pages on the site.</Paragraph>
          <Paragraph>The information system of the site and its software do not contain any technical solutions for the
            automatic censorship and control of actions and information relations of the users regarding the use of the
            site.</Paragraph>
          <Paragraph>Nerdy-tutors.com reserves the right to change at any time the Site design, content, list of
            services, modify or supplement scripts, software, and other objects used or stored on the site, any server
            applications at any time with or without advance notice.</Paragraph>
          <Paragraph>Nerdy-tutors.com does not carry out preliminary moderation or censorship of the Users' information
            and takes actions to protect the rights and interests of persons and ensure compliance with the requirements
            of HK legislation only after the person concerned applies duly to Nerdy-tutors.com.</Paragraph>
          <Paragraph>Nerdy-tutors.com does not bear liability for the User's breach of these Terms and reserves the
            right, at its own discretion as well as upon receipt of information from other Users or third parties on the
            User's breach of these Terms, to modify (moderate) or remove any information published by the User in breach
            of the prohibitions set by these Terms (including personal messages), suspend, limit or terminate the User's
            access to all or any sections or services of the site at any time for any reason or without explaining the
            reason, with or without advance notice, without being liable for any damage that such action can cause to
            the User. Nerdy-tutors.com reserves the right to remove the User's personal page and/or suspend, limit or
            terminate the User's access to any of the Site services, if Nerdy-tutors.com believes that the User poses a
            threat to the site and/or its Users. Nerdy-tutors.com does not bear liability for temporarily blocking or
            removing information or removing the User's personal page (registration termination) in accordance with
            these Terms.</Paragraph>
          <Paragraph>The removal of the User's personal page means an automatic removal of all information posted
            entered by the User at the time of registration at the site. After the personal page is removed, the User
            has no longer the right of access to the site.</Paragraph>
          <Paragraph>Nerdy-tutors.com ensures the operation and serviceability of the site. It commits to restoring its
            serviceability and operations as soon as possible in case of technical failures and technical service
            interruptions. Nerdy-tutors.com does not bear liability for temporary failures and interruptions in the Site
            operation and resultant losses of information. Nerdy-tutors.com does not bear liability for any damage to
            the computer of the User or any third party, mobile devices, any other equipment or software caused by or
            connected with the download of materials from the site or via the links available on the site.</Paragraph>

          <Subtitle>Disclaimer</Subtitle>
          <Paragraph>The materials on Nerdy-tutors.com site are provided "as is." Nerdy-tutors.com makes no warranties,
            expressed or implied, and hereby disclaims and negates all other warranties, including without limitation,
            implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement
            of intellectual property or other violation of rights. Further, Nerdy-tutors.com does not warrant or make
            any representations concerning the accuracy, likely results, or reliability of the use of the materials on
            its Internet web site or otherwise relating to such materials or on any sites linked to this
            site.</Paragraph>
          <Paragraph>The materials appearing on Nerdy-tutors.com web site could include technical, typographical, or
            photographic errors. Nerdy-tutors.com does not warrant that any of the materials on its web site are
            accurate, complete, or current. Nerdy-tutors.com may make changes to the materials contained on its web site
            at any time without notice. Nerdy-tutors.com does not, however, make any commitment to update the
            materials.</Paragraph>

          <Subtitle>Links</Subtitle>
          <Paragraph>Nerdy-tutors.com has not reviewed all of the sites linked to its Internet web site and is not
            responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement
            by Nerdy-tutors.com of the site. Use of any such linked web site is at the User's own risk.</Paragraph>

          <Subtitle>Site Terms of Use Modifications</Subtitle>
          <Paragraph>Nerdy-tutors.com may revise these terms of use for its web site at any time without notice. By
            using this web site, you agree to be bound by the then current version of these Terms and Conditions of
            Use.</Paragraph>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default injectSheet(styles)(TermsAndConditions)
