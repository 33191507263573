import React from 'react'
import injectSheet from 'react-jss'
import theme from './../../../theme/base'

const styles = {
  root: {
    background: theme.colors.black60,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const Overlay = ({
  classes,
  children,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Overlay)