import React from 'react'
import {createUseStyles} from 'react-jss'
import {Button} from 'mtuikit'
import {Modal, Close} from '../../modals'
import {Header} from '../../ui'
import {useRequestLiveSessionMutations} from "../../hooks";
import {trackValues} from '../../lrtracker'

const RequestLiveSessionForm = ({orderId, onClose, zIndex}) => {
  const classes = useStyle()
  const mutation = useRequestLiveSessionMutations({onSuccess: onClose})
  const requestLiveSession = () => {
    mutation.mutate(orderId)
    trackValues('popupRequestLiveSession', 'clientRequestLiveSessionBtnClick', {
      context: {
        id: orderId
      }
    })
  }
  return <Modal className={classes.root} zIndex={zIndex} mobileStickToBottom>
    <Close onClick={onClose}/>
    <Header>Live session</Header>
    <div className={classes.message}>Get maximums results from your lesson:</div>
    <img className={classes.liveImg}
         src={require('./img/promo_image.png').default}
         srcSet={require('./img/promo_image.png').default + ' 1x,' + require('./img/promo_image@2x.png').default + ' 2x,' + require('./img/promo_image@3x.png').default + ' 3x'}
         alt=''/>
    <ul>
      <li className={classes.liveItem}>
        <img className={classes.liveIcon} src={require('./img/ic_voice_chat.svg').default} alt={''}/>
        Live call with a tutor
      </li>
      <li className={classes.liveItem}>
        <img className={classes.liveIcon} src={require('./img/ic_video_camera_front_off.svg').default} alt={''}/>
        Camera is not required
      </li>
      <li className={classes.liveItem}>
        <img className={classes.liveIcon} src={require('./img/ic_auto_graph.svg').default} alt={''}/>
        25% better result
      </li>
    </ul>
    <Button className={classes.button} block size={'large'} onClick={requestLiveSession}>Request a live session</Button>
  </Modal>
}

const useStyle = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#2F333D',
    marginTop: 12,
  },
  liveImg: {
    display: 'block',
    width: 357,
    height: 194,
    margin: [-16, 0, -26],
  },
  liveItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    color: '#2F333D',
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  liveIcon: {
    display: 'block',
    width: 20,
    height: 20,
    marginRight: 8,
  },
  button: {
    marginTop: 'auto',
  }
})

export default RequestLiveSessionForm
