import React from 'react'
import classnames from 'classnames'
import {Link, useParams} from 'react-router-dom'
import {withRouter} from 'react-router'
import injectSheet from 'react-jss'
import theme, {rem, desktop} from './../../theme/base'
import {Icon} from 'mtuikit'
import {useActions} from '../../actions'

const iconsPath = require.context('./')

const styles = {
  root: {
    paddingTop: rem(37),
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    [desktop]: {
      display: 'none',
    },
  },
  menu: {
    height: rem(64),
    borderRadius: [rem(24), rem(24), 0, 0],
    position: 'relative',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: `calc(50% - ${rem(38)})`,
      borderRadius: [rem(24), 0, 0, 0],
      background: theme.colors.white,
      zIndex: 1,
      boxShadow: `0 ${rem(-8)} ${rem(16)} 0 rgba(37, 56, 102, 0.07)`,
    },
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: `calc(50% - ${rem(38)})`,
      borderRadius: [0, rem(24), 0, 0],
      background: theme.colors.white,
      zIndex: 1,
      boxShadow: `0 ${rem(-8)} ${rem(16)} 0 rgba(37, 56, 102, 0.07)`,
    }
  },
  helper: {
    background: `url(${iconsPath(`./footer-background-icon.svg`).default}) no-repeat`,
    backgroundPosition: ['center', rem(-24)],
    width: rem(128),
    height: rem(64),
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
    zIndex: 1,
  },
  item: {
    width: `calc((100% - ${rem(74)})/2)`,
    position: 'absolute',
    top: rem(12),
    textAlign: 'center',
    zIndex: 2,
    '&:first-child': {
      left: 0,
    },
    '&:last-child': {
      right: 0,
    },
    '& a': {
      width: rem(48),
      height: rem(32),
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
      '&:hover, &:active': {
        '& svg': {
          fill: theme.colors.blueyGrey,
        }
      },
      '&.active': {
        '&:after': {
          content: "''",
          display: 'block',
          background: theme.colors.blue,
          width: rem(32),
          height: rem(4),
          borderRadius: rem(4),
          position: 'absolute',
          bottom: rem(-4),
          left: '50%',
          transform: 'translateX(-50%)',
        },
      },
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: theme.colors.lightBlueGrey,
      transition: 'fill 300ms ease-in-out',
    },
  },
  add: {
    width: rem(74),
    height: rem(74),
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    overflow: 'hidden',
    cursor: 'pointer',
    '& div': {
      backgroundImage: `url(${iconsPath(`./add.svg`).default})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(106), rem(106)],
      backgroundPosition: `center ${rem(8)}`,
      width: rem(106),
      height: rem(106),
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      '&:hover': {
        background: 'none',
        '&:active': {
          '& span': {
            '&:before': {
              opacity: 0,
            },
          },
        },
        '& span': {
          '&:before': {
            opacity: 1,
          },
        },
      },
      '&:active': {
        '& span': {
          '&:after': {
            opacity: 1,
          },
        },
      },
    },
    '& span': {
      width: rem(106),
      height: rem(106),
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      '&:before, &:after': {
        content: "''",
        backgroundRepeat: 'no-repeat',
        backgroundSize: [rem(106), rem(106)],
        backgroundPosition: `center ${rem(8)}`,
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
      },
      '&:before': {
        backgroundImage: `url(${iconsPath(`./add-hover.svg`).default})`,
      },
      '&:after': {
        backgroundImage: `url(${iconsPath(`./add-active.svg`).default})`,
      },
    },
  },
}

const Footer = ({classes}) => {
  const actions = useActions()
  const {folder = 'orders'} = useParams()
  return (
    <footer className={classes.root}>
      <div className={classes.menu}>
        <div className={classes.item}>
          <Link className={classnames({'active': folder === 'orders'})} to='/orders'>
            <Icon iconName='orders-icon'/>
          </Link>
        </div>
        <div className={classes.item}>
          <Link className={classnames({'active': folder === 'tutors'})} to='/tutors'>
            <Icon iconName='tutors-icon'/>
          </Link>
        </div>
      </div>
      <div className={classes.add}>
        <div onClick={() => actions.showOrderForm()}>
          <span/>
        </div>
      </div>
      <div className={classes.helper}/>
    </footer>
  )
}

export default withRouter(injectSheet(styles)(Footer))
