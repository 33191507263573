import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    background: '#BAC7E2',
    width: rem(24),
    height: rem(24),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
  }
}

const TypeIcon = ({
  classes,
  src,
}) =>
  <img className={classes.root} src={src} alt='' />

export default injectSheet(styles)(TypeIcon)