import { useState, useEffect } from 'react'

export const useMobile = (width) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < width)
  useEffect(() => {
    const detectMobile = () => {
      setIsMobile(window.innerWidth < width)
    }
    window.addEventListener('resize', detectMobile)
    detectMobile()
    return () => {
      window.removeEventListener('resize', detectMobile)
    }
  }, [width, isMobile])
  return isMobile
}