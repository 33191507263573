import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Button} from 'mtuikit'
import * as moment from 'moment'
import Icon from './icons'
import {useOrderQuery} from '../../hooks'
import useToggleInTime from '../../utils/useToggleInTime'
import {useActions} from '../../actions'
import {trackValues} from '../../lrtracker'
import Countdown from '../Countdown'


const LiveSession = ({scheduledAt, duration, status, link, c_token}) => {
  const classes = useStyle()
  const actions = useActions()
  const dt = moment(scheduledAt)
  const isLiveSessionStarted = useToggleInTime(false, scheduledAt)
  const isLiveSessionOver = useToggleInTime(false, moment(scheduledAt).add(duration, 'minutes'))
  const join = () => actions.joinVideoCall(link, c_token)
  return <div className={classes.root}>
    <div className={classes.title}>
      <Icon.LiveSession/>
      <div className={classes.titleMessage}>Live session</div>
    </div>
    <div className={classes.duration}>
      {dt.format('h:mm A')} - {dt.add(duration, 'minutes').format('h:mm A')}
    </div>
    <div className={classes.date}>{dt.format('dddd, D MMMM')}</div>
    {
      ["upcoming", "started"].indexOf(status) > -1 && !isLiveSessionOver &&
      <div className={classes.buttons}>
        <Button block onClick={join}>
          {isLiveSessionStarted
            ? 'Join Now'
            : <>Join in <Countdown dl={scheduledAt}/> min</>
          }
        </Button>
      </div>
    }
  </div>
}

const saveBanner = (preffix, id) => window.localStorage.setItem(`_${preffix}${id}`, "1")
const getBanner = (preffix, id) => !window.localStorage.getItem(`_${preffix}${id}`)

const RequestLiveSession = ({orderId}) => {
  const classes = useStyle()
  const actions = useActions()
  const [opened, setOpened] = useState(getBanner('rls', orderId))
  const close = () => {
    setOpened(false)
    saveBanner('rls', orderId)
  }
  const requestLiveSession = () => {
    actions.showRequestLiveSessionAddon(orderId)
    trackValues('chatClientTutor', 'clientRequestLiveSessionBtnClick', {
      context: {
        id: orderId,
        type: 'Sidebar-banner'
      }
    })
  }
  return opened ? <div className={classes.root}>
      <div className={classes.title}>
        <Icon.LiveSession/>
        <div className={classes.titleMessage}>Pump your session up 😎</div>
        <button className={classes.closeButton} onClick={close}>
          <Icon.Close/>
        </button>
      </div>
      <div className={classes.text}>
        Students who request a live session get <strong>25% better results</strong>. Let your tutor know that you would
        like to schedule a
        session!
      </div>
      <div className={classes.buttons}>
        <Button block type={'light'} onClick={requestLiveSession}>
          Request a live session
        </Button>
      </div>
    </div>
    : null
}

const LiveSessionPending = ({orderId}) => {
  const classes = useStyle()
  const [opened, setOpened] = useState(getBanner('lsp', orderId))
  const close = () => {
    setOpened(false)
    saveBanner('lsp', orderId)
  }
  return opened ? <div className={classes.root}>
      <div className={classes.title}>
        <Icon.LiveSession/>
        <div className={classes.titleMessage}>Live session</div>
        <button className={classes.closeButton} onClick={close}>
          <Icon.Close/>
        </button>
      </div>
      <div className={classes.text}>
        Your session date and time will appear here once you schedule everything with your tutor.
      </div>
    </div>
    : null
}

const getActiveLiveSession = order => {
  const liveSessions = (order.live_sessions || [])
    .filter(({status}) => ["scheduled", "upcoming", "started"].indexOf(status) > -1)
  liveSessions.sort((a, b) => a.scheduled_at > b.scheduled_at ? 1 : -1)
  return liveSessions.length > 0 ? liveSessions[0] : null
}

const LiveSessions = ({orderId}) => {
  const orderQuery = useOrderQuery(orderId)
  if (!orderQuery.isSuccess) return null

  const {data: order} = orderQuery

  const activeLiveSession = getActiveLiveSession(order)

  const isShowRequestLiveSession = (
    !order.live_session_request
    && (order.live_sessions || []).length === 0
    && (order.assigned || (order.connected && order.connected.connected_at))
    && order.output_formats.includes('Video call') === false
    && order.output_formats.includes('Live session') === false
    && !order.closed
  )

  const isLiveSessionPending = (
    !!order.live_session_request
    && (order.live_sessions || []).length === 0
  )

  return activeLiveSession
    ? <LiveSession
      scheduledAt={activeLiveSession.scheduled_at}
      duration={activeLiveSession.planed_duration_minutes}
      status={activeLiveSession.status}
      link={activeLiveSession.link}
      c_token={activeLiveSession.c_token}
    />
    : isLiveSessionPending
      ? <LiveSessionPending orderId={orderId}/>
      : isShowRequestLiveSession
        ? <RequestLiveSession orderId={orderId}/>
        : null
}

const useStyle = createUseStyles({
  root: {
    margin: [14, 0],
    border: '1px solid #EBEEF5',
    boxShadow: '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 0px 0px rgba(24, 39, 75, 0.12)',
    borderRadius: '16px',
    padding: 16,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  titleMessage: {
    fontSize: 16,
    fontWeight: 800,
    lineHeight: '22px',
    letterSpacing: 0,
    margin: [0, 8]
  },
  text: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#565D6B',
  },
  duration: {
    fontSize: '21px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 0
  },
  date: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  buttons: {
    marginTop: 16
  },
  closeButton: {
    padding: 6,
    backgroundColor: 'transparent',
    marginLeft: 'auto',
    '& svg': {
      display: 'block',
    }
  },
})

export default LiveSessions
