import React, {useEffect, useReducer} from 'react'
import {useNotification} from '@connect/connect-xmpp-v2'
import {useInProgressOrders, useProfileQuery, useSupportChatConfig} from '../../hooks'
import {extractOrderChatConfig} from "../../nerdytutors";

const Node = ({config, onUpdateCount}) => {
  const {count} = useNotification(config)
  useEffect(() => {
      onUpdateCount(count)
    },
    // eslint-disable-next-line
    [count]
  )
  return null
}

const reducer = (state, {node, count}) => ({...state, [node]: count})

export const Notifications = () => {
  const orders = useInProgressOrders()
  const profile = useProfileQuery().data
  const supportConfig = useSupportChatConfig()

  const [state, dispatch] = useReducer(reducer, {})

  const hasUnreadMessage = Object.keys(state).filter(key => state[key] > 0).length > 0

  useEffect(() => {
    const favicon = document.getElementById('favicon')

    const savedTitle = document.title
    const savedFavicon = favicon.href
    if (hasUnreadMessage) {
      document.title = 'You have a new message'
      favicon.href = '/favicon_new.ico'
    }

    return () => {
      document.title = savedTitle
      favicon.href = savedFavicon
    }
  }, [hasUnreadMessage])

  return <>
    <Node config={supportConfig} onUpdateCount={count => dispatch({node: supportConfig.node, count})}/>
    {orders
      .filter(order => !!order.jabber_settings)
      .map(order => extractOrderChatConfig(order, profile))
      .map(config => <Node config={config} key={config.node}
                           onUpdateCount={count => dispatch({node: config.node, count})}/>)}
  </>
}