const audio = new Audio(require('./audio.mp3').default)
audio.volume = 0.2

const play = () => {
  audio.play()
    .catch(() => {
    })
}

export default play
