import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {compact} from 'lodash/array'
import {maxBy, minBy} from 'lodash/math'
import {useStripe} from "@stripe/react-stripe-js"
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {useNotification, useOnline} from '@connect/connect-xmpp-v2'
import {
  authorizeByLoginApi,
  authorizationByProviderTokenApi,
  createSmstoolReminderApi,
  getProfileApi,
  removeToken,
  updateProfileApi,
  registerAndAuthorizeByEmailApi,
  restorePasswordApi,
  createOrderApi,
  getOrdersApi,
  getOrderApi,
  updateOrderApi,
  getTutorsApi,
  getTutorApi,
  markFavTutorApi,
  unmarkFavTutorApi,
  deleteOrderApi,
  blockTutorApi,
  rejectTutorApi,
  requestRevisionApi,
  rateOrderApi,
  getListAllCardsApi,
  getStripeCustomerApi,
  declineOrderEstimateApi,
  saveAndStripePayOrderApi,
  acceptAndPayOrderEstimateApi,
  saveAndBalancePayOrderApi,
  acceptAndBalancePayOrderEstimateApi,
  getChatFilesApi,
  updateOrderMetaDataApi,
  addTopTutorAddonAndPayApi,
  addTopTutorAddonAndBalancePayApi,
  requestLiveSessionApi,
  sendSmstoolMessageApi,
  payTopUpBundleApi,
  getBundlesApi,
  addTaskInstructionsApi,
  sendSmstoolEventApi,
  addPNSubscription,
  updateOrderNotificationsApi,
  updateProfileNotificationsApi,
} from './api'
import EVENT_TO_TEXT_MAP from 'components/Chat/event-to-text-map'
import {extractOrderChatConfig, isFinalizedOrder} from 'nerdytutors'
import {trackValues} from './lrtracker'
import {useOrderOnboardParams} from './components/Chat/onboards'
import {useChatOnboardNotification} from './components/Chat/ChatOnboard'
import useToggleInTime from './utils/useToggleInTime'
import moment from 'moment'
import {useActions} from './actions'
import {usePushNotifications} from './push-notifications'

const ordersUpdater = newOrder => old => {
  const orders = old.orders.find(order => order.id === newOrder.id)
    ? old.orders.map(order => order.id === newOrder.id ? newOrder : order)
    : [...old.orders, newOrder]
  return {...old, orders}
}

const orderEstimateUpdater = (estimateId, newEstimate) => old => {
  return {
    ...old,
    estimates: old.estimates.map(
      estimate => estimate.id === estimateId
        ? {...estimate, ...newEstimate}
        : estimate
    )
  }
}

export function useAuth() {
  const dispatch = useDispatch()
  const isLogged = useSelector(({auth}) => auth)
  const queryClient = useQueryClient()

  const setAuth = () => dispatch({type: 'SET_AUTH', payload: true})
  const clearAuth = () => {
    removeToken()
    queryClient.getQueryCache().clear()
    dispatch({type: 'SET_AUTH', payload: null})
  }

  return {isLogged: !!isLogged, setAuth, clearAuth}
}

export function useXmppConfig() {
  const profile = useProfileQuery()
  const xmppConfig = profile.data
    ? {
      userJid: profile.data.jabber_jid,
      password: profile.data.jabber_password
    }
    : null
  return xmppConfig
}

// register and authorization

export function useRegisterAndAuthorizeByEmailMutation() {
  const {setAuth} = useAuth()
  const mutation = useMutation(registerAndAuthorizeByEmailApi, {
    onSuccess: () => {
      trackValues('registration', 'SignupCompleted', {context: {}})
      setAuth()
    }
  })
  return mutation
}

export function useRestorePasswordMutation() {
  const mutation = useMutation(restorePasswordApi)
  return mutation
}

export function useAuthorizeByLoginMutation() {
  const {setAuth} = useAuth()
  const mutation = useMutation(authorizeByLoginApi, {
    onSuccess: () => {
      trackValues('authorization', 'LoginCompleted', {context: {}})
      setAuth()
    }
  })
  return mutation
}

export function useAuthorizationByProviderTokenMutation(provider) {
  const {setAuth} = useAuth()
  const mutation = useMutation(
    ({token}) => authorizationByProviderTokenApi({token, provider}),
    {
      onSuccess: () => {
        trackValues('authorization', 'LoginCompleted', {context: {provider}})
        setAuth()
      }
    }
  )
  return mutation
}

// profile

export function useProfileQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    'profile',
    getProfileApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function useUpdateProfileMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    updateProfileApi,
    {
      onMutate: async newProfile => {
        await queryClient.cancelQueries('profile')
        const previousProfile = queryClient.getQueryData('profile')
        queryClient.setQueryData('profile', old => ({...old, newProfile}))
        return {previousProfile}
      },
      onError: (err, newProfile, context) => {
        queryClient.setQueryData('profile', context.previousProfile)
      },
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData('profile', {...context.previousProfile, ...data})
      }
    }
  )
  return mutation
}

export function useUpdateProfileNotificationMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    updateProfileNotificationsApi,
    {
      onMutate: async newNotifications => {
        await queryClient.cancelQueries('profile')
        const previousProfile = queryClient.getQueryData('profile')
        queryClient.setQueryData('profile', old => ({
          ...old,
          notifications: {...old.notifications, ...newNotifications.notifications}
        }))
        return {previousProfile}
      },
      onError: (err, newNotifications, context) => {
        queryClient.setQueryData('profile', context.previousProfile)
      },
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData('profile', {...context.previousProfile, notifications: data.data})
      }
    }
  )
  return mutation
}

export function useTutorsQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    'tutors',
    getTutorsApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function useTutorQuery(freelancerId) {
  const {isLogged, clearAuth} = useAuth()
  const tutor = useTutor(freelancerId)
  const query = useQuery(
    ['tutors', freelancerId],
    () => getTutorApi(freelancerId),
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged && !!freelancerId,
      refetchOnWindowFocus: false,
      placeholderData: tutor
    }
  )
  return query
}

export function useTutor(tutorId) {
  const tutorsQuery = useTutorsQuery()
  return tutorsQuery.data.freelancers.find(t => t.id === tutorId)
}

export function useBlockTutorMutation(tutorId) {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    () => blockTutorApi(tutorId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('tutors')
        const previousTutors = queryClient.getQueryData('tutors')
        const previousTutor = queryClient.getQueryData(['tutors', tutorId])

        queryClient.setQueryData(
          'tutors',
          old => ({
            ...old,
            freelancers: old.freelancers.map(tutor => tutor.id === tutorId
              ? {...tutor, is_blocked: true}
              : tutor
            )
          })
        )

        queryClient.setQueryData(
          ['tutors', tutorId],
          old => ({
            ...old,
            is_blocked: true
          })
        )

        return {previousTutors, previousTutor}
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData('tutors', context.previousTutors)
        queryClient.setQueryData(['tutors', tutorId], context.previousTutor)

      },
    }
  )
  return mutation
}

export function useToggleFavTutorMutation(tutorId) {
  const queryClient = useQueryClient()
  const tutor = useTutor(tutorId)
  return useMutation(
    () => tutor.is_favorite ? unmarkFavTutorApi(tutorId) : markFavTutorApi(tutorId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('tutors')
        await queryClient.cancelQueries(['tutors', tutorId])

        const previousTutors = queryClient.getQueryData('tutors')
        const previousTutor = queryClient.getQueryData(['tutors', tutorId])

        queryClient.setQueryData(
          'tutors',
          old => ({
            ...old,
            freelancers: old.freelancers.map(tutor => tutor.id === tutorId
              ? {...tutor, is_favorite: !tutor.is_favorite}
              : tutor
            )
          })
        )

        queryClient.setQueryData(
          ['tutors', tutorId],
          old => ({
            ...old,
            is_favorite: !tutor.is_favorite
          })
        )

        return {previousTutors, previousTutor}
      },

      onError: (err, variables, context) => {
        queryClient.setQueryData('tutors', context.previousTutors)
        queryClient.setQueryData(['tutors', tutorId], context.previousTutor)
      },
    }
  )
}

export function useIsFavoriteUser(tutorId) {
  const tutor = useTutor(tutorId)
  return (tutor && tutor.is_favorite) || false
}

export function useIsBlockedUser(tutorId) {
  const tutor = useTutor(tutorId)
  return (tutor && tutor.is_blocked) || false
}

export function useBundlesQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    'bundles',
    getBundlesApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function useBundle(bundleId) {
  const {data: {bundles = []} = {}} = useBundlesQuery()
  const bundle = bundles.find(({id}) => id === bundleId)
  const is_expired = useToggleInTime(bundle?.is_expired, bundle?.bundle_expire_at)
  return bundle?.is_expired === false ? {...bundle, is_expired, is_active: !is_expired} : bundle
}

export function useActiveBundle() {
  const {data: {bundles = []} = {}} = useBundlesQuery()
  const activeBundles = bundles.filter(({is_active}) => is_active)
  const earliestActiveBundles = minBy(activeBundles, a => a.bundle_expire_at)
  const bundle = useBundle(earliestActiveBundles ? earliestActiveBundles.id : null)
  return bundle
}

// orders

export function useSaveOrderMutation({onSuccess}) {
  const queryClient = useQueryClient()
  return useMutation(
    ({order, files, requestedTutorId}) => !order.id
      ? createOrderApi({order, files, requestedTutorId})
      : updateOrderApi({order, files, requestedTutorId}),
    {
      onSuccess: (newOrder) => {
        queryClient.setQueryData('orders', ordersUpdater(newOrder))
        queryClient.setQueryData(['orders', newOrder.id], newOrder)
        queryClient.invalidateQueries('profile')
        if (onSuccess) {
          onSuccess(newOrder)
        }
      }
    }
  )
}

export function useSaveAndPayOrderMutation({onResult}) {
  const queryClient = useQueryClient()
  const stripe = useStripe()
  return useMutation(
    ({order, files, requestedTutorId, paymentMethod, paymentRequestEvent}) => {
      if (paymentMethod) return saveAndStripePayOrderApi({order, files, requestedTutorId, paymentMethod, stripe})
      if (paymentRequestEvent) return saveAndStripePayOrderApi({
        order,
        files,
        requestedTutorId,
        paymentMethod: paymentRequestEvent.paymentMethod.id,
        stripe
      })
      return saveAndBalancePayOrderApi({order, files, requestedTutorId})
    },
    {
      onSuccess: (result, variables) => {
        const {order, error} = result

        queryClient.setQueryData('orders', ordersUpdater(order))
        queryClient.setQueryData(['orders', order.id], order)
        queryClient.invalidateQueries('list_all_cards')
        queryClient.invalidateQueries('profile')

        if (variables.paymentRequestEvent) variables.paymentRequestEvent.complete(error ? 'fail' : 'success')
        onResult(result)
      }
    }
  )
}

export function createAddTopTutorAddonAndPayMutation({orderId, isAddonActive, placedThrough}) {
  return function ({onResult}) {
    const queryClient = useQueryClient()
    const stripe = useStripe()
    return useMutation(({paymentMethod, paymentRequestEvent} = {}) => {
      if (paymentMethod || paymentRequestEvent) {
        return addTopTutorAddonAndPayApi({
          orderId, isAddonActive, placedThrough,
          paymentMethod: paymentMethod || paymentRequestEvent.paymentMethod.id,
          stripe
        })
      } else {
        return addTopTutorAddonAndBalancePayApi({orderId, isAddonActive, placedThrough})
      }
    }, {
      onSuccess: (result, variables = {}) => {
        const {order, error} = result
        if (!error) {
          queryClient.setQueryData('orders', ordersUpdater(order))
          queryClient.setQueryData(['orders', order.id], order)
          queryClient.invalidateQueries('list_all_cards')
          queryClient.invalidateQueries('profile')
        }
        if (variables.paymentRequestEvent) {
          variables.paymentRequestEvent.complete(error ? 'fail' : 'success')
        }
        onResult(result)
      }
    })
  }
}

export function createBuyBalanceTopUpBundleMutation({bundleId, bundleItemId}) {
  return function ({onResult}) {
    const queryClient = useQueryClient()
    const stripe = useStripe()
    return useMutation(
      ({paymentMethod, paymentRequestEvent} = {}) => {
        if (paymentMethod || paymentRequestEvent) return payTopUpBundleApi({
          bundleId,
          bundleItemId,
          paymentMethod: paymentMethod || paymentRequestEvent.paymentMethod.id,
          stripe
        })
        throw new Error('Balance cannot be used to buy a bundle.')
      },
      {
        onSuccess: result => {
          const {bundle, error} = result
          if (!error) {
            queryClient.invalidateQueries('profile')
            queryClient.setQueryData(
              'bundles',
              old => ({
                ...old,
                bundles: old.bundles.map(b => b.id === bundleId ? bundle : b)
              })
            )
          }
          onResult(result)
        }
      }
    )
  }
}

export function useInvalidateOrder(orderId) {
  const queryClient = useQueryClient()
  return () => {
    queryClient.invalidateQueries('orders')
    queryClient.invalidateQueries(['orders', orderId])
  }
}

export function useAcceptAndPayOrderEstimateMutation() {
  const queryClient = useQueryClient()
  const stripe = useStripe()
  return useMutation(
    ({orderId, estimateId, paymentMethod, paymentRequestEvent}) => {
      if (paymentMethod) return acceptAndPayOrderEstimateApi({orderId, estimateId, paymentMethod, stripe})
      if (paymentRequestEvent) return acceptAndPayOrderEstimateApi({
        orderId,
        estimateId,
        paymentMethod: paymentRequestEvent.paymentMethod.id,
        stripe
      })
      return acceptAndBalancePayOrderEstimateApi({
        orderId,
        estimateId
      })
    },
    {
      onSuccess: (order, variables) => {
        queryClient.setQueryData('orders', ordersUpdater(order))
        queryClient.setQueryData(['orders', order.id], order)
        queryClient.invalidateQueries('list_all_cards')
        if (variables.paymentRequestEvent) {
          variables.paymentRequestEvent.complete('success')
        }
      },
      onError: (error, variables) => {
        if (variables.paymentRequestEvent) {
          variables.paymentRequestEvent.complete('fail')
        }
      }
    }
  )
}

export function useCreateOrderMutation({onSuccess}) {
  const mutation = useMutation(
    createOrderApi,
    {
      onSuccess: (data, variables, context) => {
        if (onSuccess) {
          onSuccess(data)
        }
      }
    }
  )
  return mutation
}

export function useUpdateOrderMutation({onSuccess}) {
  const mutation = useMutation(updateOrderApi,
    {
      onSuccess: (data, variables, context) => {
        if (onSuccess) {
          onSuccess(data)
        }
      }
    }
  )
  return mutation
}

export function useOrdersQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    'orders',
    getOrdersApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function useInProgressOrders() {
  const ordersQuery = useOrdersQuery()
  return ordersQuery.data.orders.filter(order => !isFinalizedOrder(order))
}

export function useOrderQuery(orderId, options) {
  const {force = true} = options || {}
  const order = useOrder(orderId)
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    ['orders', orderId],
    () => getOrderApi(orderId),
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged && (force || !isFinalizedOrder(order)),
      refetchOnWindowFocus: false,
      placeholderData: order
    }
  )
  return query
}

export function useOrder(orderId) {
  const ordersQuery = useOrdersQuery()
  return ordersQuery.data.orders.find(o => o.id === orderId)
}

export function useDeleteOrderMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    deleteOrderApi,
    {
      onMutate: async orderId => {
        await queryClient.cancelQueries('orders')
        const previousOrders = queryClient.getQueryData('orders')
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.filter(order => order.id !== orderId)
          })
        )
        return {previousOrders}
      },
      onError: (err, orderId, context) => {
        queryClient.setQueryData('orders', context.previousOrders)
      },
    }
  )
  return mutation
}

export function useRejectTutorMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    rejectTutorApi,
    {
      onMutate: async ({orderId}) => {
        await queryClient.cancelQueries('orders')
        const previousOrders = queryClient.getQueryData('orders')
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.map(order => order.id === orderId ? {...order, assigned: null} : order)
          })
        )
        return {previousOrders}
      },
      onSuccess: (newOrder) => {
        queryClient.setQueryData('orders', ordersUpdater(newOrder))
        queryClient.invalidateQueries(['orders', newOrder.id])
      },
      onError: (err, orderId, context) => {
        queryClient.setQueryData('orders', context.previousOrders)
      },
    }
  )
  return mutation
}

export function useRequestRevisionMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    requestRevisionApi,
    {
      onMutate: async ({orderId, revisionRequest}) => {
        await queryClient.cancelQueries('orders')
        const previousOrders = queryClient.getQueryData('orders')
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.map(order => order.id === orderId ? {
              ...order,
              revision: revisionRequest
            } : order)
          })
        )
        return {previousOrders}
      },
      onSuccess: (data, {orderId}, context) => {
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.map(order => order.id === orderId ? data : order)
          })
        )
      },
      onError: (err, orderId, context) => {
        queryClient.setQueryData('orders', context.previousOrders)
      },
    }
  )
  return mutation
}

export function useRateOrderMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    rateOrderApi,
    {
      onMutate: async ({orderId, rate}) => {
        await queryClient.cancelQueries('orders')
        const previousOrders = queryClient.getQueryData('orders')
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.map(order => order.id === orderId ? {...order, rated: {rate}} : order)
          })
        )
        return {previousOrders}
      },
      onSuccess: (data, {orderId}, context) => {
        queryClient.setQueryData(
          'orders',
          old => ({
            ...old,
            orders: old.orders.map(order => order.id === orderId ? data : order)
          })
        )
      },
      onError: (err, orderId, context) => {
        queryClient.setQueryData('orders', context.previousOrders)
      },
    }
  )
  return mutation
}

export function useDeclineOrderEstimateMutation() {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    declineOrderEstimateApi,
    {
      onMutate: async ({orderId, estimateId}) => {
        await queryClient.cancelQueries(['orders', orderId])
        const previousOrder = queryClient.getQueryData(['orders', orderId])
        queryClient.setQueryData(['orders', orderId], orderEstimateUpdater(estimateId, {status: 'declined'}))
        return {previousOrder}
      },
      onError: (err, {orderId}, context) => {
        queryClient.setQueryData(['orders', orderId], context.previousOrders)
      },
    }
  )
  return mutation
}

export function useUpdateOrderMetaDataMutation(options = {}) {
  const queryClient = useQueryClient()
  const {onSuccess} = options
  const mutation = useMutation(
    updateOrderMetaDataApi,
    {
      onSuccess: (_, {orderId}) => {
        queryClient.invalidateQueries(['orders', orderId])
        if (onSuccess) onSuccess()
      }
    }
  )
  return mutation
}

export function useRequestLiveSessionMutations({onSuccess}) {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    requestLiveSessionApi,
    {
      onSuccess: (_, {orderId}) => {
        queryClient.invalidateQueries(['orders', orderId])
        onSuccess()
      }
    }
  )
  return mutation
}

export function useAddTaskInstructionsMutation({onSuccess}) {
  return useMutation(
    ({orderId, details, files}) => addTaskInstructionsApi(orderId, {details, files}),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  )
}

export function useOrderNotificationsMutation(options = {}) {
  const queryClient = useQueryClient()
  const {onSuccess} = options
  const mutation = useMutation(
    updateOrderNotificationsApi,
    {
      onSuccess: (_, {orderId}) => {
        queryClient.invalidateQueries(['orders', orderId])
        if (onSuccess) onSuccess()
      }
    }
  )
  return mutation
}

// live sessions

export function useOrderLiveSession(orderId, scheduleId) {
  const orderQuery = useOrderQuery(orderId)
  const liveSessions = orderQuery.data?.live_sessions || []
  return liveSessions.find(({_id}) => _id === scheduleId)
}

// payments

export function useListAllCardsQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    ['list_all_cards'],
    getListAllCardsApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function useStripeCustomerQuery() {
  const {isLogged, clearAuth} = useAuth()
  const query = useQuery(
    ['stripe_customer'],
    getStripeCustomerApi,
    {
      onError: (err) => {
        if (err.response.status === 403) {
          clearAuth()
        }
      },
      refetchOnMount: false,
      enabled: isLogged,
      refetchOnWindowFocus: false
    }
  )
  return query
}

export function usePaymentRequest(country, currency, amount) {
  const [paymentRequest, setPaymentRequest] = useState(null)
  const stripe = useStripe()
  useEffect(() => {
    if (stripe && amount > 0) {
      const pr = stripe.paymentRequest({
        country,
        currency,
        total: {
          label: 'Tutoring services',
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      pr.canMakePayment()
        .then(result => {
          if (result) {
            console.log(pr)
            setPaymentRequest(pr)
          } else {
            setPaymentRequest(null)
          }
        })
    } else {
      setPaymentRequest(null)
    }
  }, [stripe, country, currency, amount])
  return paymentRequest
}

export function usePaymentRequestPaymentMethodEvent(paymentRequest, onPaymentMethod) {
  useEffect(() => {
    if (paymentRequest) paymentRequest.on('paymentmethod', onPaymentMethod)
    return () => {
      if (paymentRequest) paymentRequest.off('paymentmethod', onPaymentMethod)
    }
  }, [paymentRequest, onPaymentMethod])

}

// xmpp

export function useSupportChatConfig() {
  const profileQuery = useProfileQuery()
  return profileQuery.data?.jabber_jid
    ? {
      username: profileQuery.data.jabber_jid,
      node: profileQuery.data.jabber_node,
      to: `pubsub.${profileQuery.data.jabber_jid.split('@')[1]}`
    }
    : null
}

export function useOrderChatConfig(orderId) {
  const profile = useProfileQuery().data
  const order = useOrder(orderId)
  return order.jabber_settings ? extractOrderChatConfig(order, profile) : null
}

export function useNotifications(orderId) {
  const orderChatConfig = useOrderChatConfig(orderId)
  const orderQuery = useOrderQuery(orderId, {force: false})
  const notificationEnabled = orderQuery.data && !isFinalizedOrder(orderQuery.data)
  const onboardParams = useOrderOnboardParams(orderQuery.data, orderChatConfig)
  const onboard = useChatOnboardNotification(onboardParams)
  const {data: {bundles = []} = {}} = useBundlesQuery()

  const chat = useNotification(orderChatConfig, {enabled: notificationEnabled})

  const count = onboard.count + chat.count
  const first = minBy(compact([onboard.count && onboard.first, chat.count && chat.first]), m => m.time)
  const last = maxBy(compact([onboard.last, chat.last]), m => m.time)

  const ref = count ? first : last

  if (!ref || !orderQuery.isFetched) {
    return {
      lastMessageText: null,
      lastMessageTime: null,
      newMessageCount: 0
    }
  }

  const {estimate_id, live_session_id, bundle_id} = ref.event?.event_params || {}
  const estimate = orderQuery.data.estimates.find(estimate => estimate.id === estimate_id)
  const liveSession = orderQuery.data.live_sessions.find(liveSession => liveSession._id === live_session_id)
  const bundle = bundles.find(({id}) => id === bundle_id)
  const usedBundleItem = bundle?.items.find(({item_id}) => item_id === bundle.used_item_id)

  const whoSent = ref.isSelfSent ? 'You: Sent' : 'Sent'
  const lastMessageText = ref.event && EVENT_TO_TEXT_MAP[ref.event.event_name]
    ? EVENT_TO_TEXT_MAP[ref.event.event_name]({
      ...ref.event.event_params,
      order: orderQuery.data,
      estimate,
      liveSession,
      bundle,
      usedBundleItem
    })
    : (
      ref.message
      || (
        ref.files
          ? ref.files.length === 1
            ? `${whoSent} 1 attachment`
            : `${whoSent} ${ref.files.length} attachments`
          : null
      )
      || null
    )

  return {
    lastMessageText,
    lastMessageTime: ref.time,
    newMessageCount: count
  }
}

export function useIsOnlineUser(userId) {
  const online = useOnline().map(jid => jid.split('@')[0])
  return online.indexOf(userId) > -1
}

// externals

export function useSmstoolApi() {
  const profileQuery = useProfileQuery()
  const {data: {jabber_node: node, cid}} = profileQuery
  return {
    sendSupportChatOnboarding: order => {
      return sendSmstoolEventApi(cid, 'nt_onboarding', {
        client_flow: order.meta_data.first_time_client ? 'first' : 'second',
        subject: order.subject,
        is_prefered_tutor: !!(order?.recommended?.freelancer_id)
      })
    },
    sendMessage: message => {
      return sendSmstoolMessageApi(node, message)
    }
  }
}

export function useSmstoolRemider(cid, remider) {
  useEffect(() => {
    const t = setTimeout(() => {
      createSmstoolReminderApi(cid, remider)
    }, 7000)
    return () => clearTimeout(t)
  }, [cid, remider])
}

export function useBodyBackgroundColor(color) {
  useEffect(() => {
    const backgroundColor = document.body.style.backgroundColor
    document.body.style.backgroundColor = color
    return () => {
      document.body.style.backgroundColor = backgroundColor
    }
  }, [color])
}

export function useChatFilesQuery(node, options = {}) {
  const query = useQuery(
    ['chat files', node],
    () => getChatFilesApi(node),
    options
  )
  return query
}

export function useCompleteOnboarding(order_id, onComplete) {
  const orderQuery = useOrderQuery(order_id)
  const {data: order} = orderQuery

  const {
    onboard_started_at = null,
    onboard_completed_at = null
  } = order?.meta_data || {}

  const is_tutor_connected = order?.connected?.connected_at

  const updateMetaDataMutation = useUpdateOrderMetaDataMutation({
    onSuccess: () => onComplete(order)
  })

  useEffect(() => {
      let t

      if (
        !onboard_started_at
        || is_tutor_connected
        || onboard_completed_at
      ) return

      t = setTimeout(() => {
        updateMetaDataMutation.mutate({
          orderId: order_id,
          onboard_completed_at: moment().valueOf(),
        })
      }, 7000)

      return () => clearTimeout(t)
    },
    // eslint-disable-next-line
    [order_id, onboard_started_at, onboard_completed_at, is_tutor_connected]
  )
}

// notifications

export const useExplainedPushNotifications = (explainer) => {
  const actions = useActions()
  const {subscribe, ...other} = usePushNotifications()

  const _subscribe = () => subscribe({
    onRequest: ctx => {
      if (ctx.permission === 'default') {
        ctx.closeExplainer = actions.showExplainer(explainer)
      }
    },
    onComplete: ctx => {
      ctx.closeExplainer && ctx.closeExplainer()
    },
  })

  return {...other, subscribe: _subscribe}
}
