import React, {Fragment} from 'react'
import Card from '../Card'
import {useOrdersQuery} from '../../hooks'
import EmptyOrderList from './EmptyOrderList'
import Order from './Order'
import {isDoneOrder} from '../../nerdytutors'

export const Orders = ({orderId}) => {
  const ordersQuery = useOrdersQuery()

  const {orders} = ordersQuery.data
  orders.sort((a, b) => a.created_at > b.created_at ? -1 : 1)

  const groups = [
    ['Active', orders.filter(order => !isDoneOrder(order))],
    ['Completed', orders.filter(order => isDoneOrder(order))],
  ].filter((group) => group[1].length > 0)

  return orders.length > 0
    ? groups.map(([name, members], index) => (
      <Fragment key={name}>
        <Card.Divider>{name}</Card.Divider>
        {members.map((order) => <Order
          key={order.id}
          orderId={order.id}
          active={orderId === order.id}
        />)}
        {index < groups.length - 1 && <Card.Divider>&nbsp;</Card.Divider>}
      </Fragment>
    ))
    : <EmptyOrderList/>
}
