import React from 'react'
import {createUseStyles} from 'react-jss'
import {theme} from 'mtuikit'
import {rem} from 'mtuikit/theme/base'

const useStyles = createUseStyles({
  emptyTutorList: {
    paddingTop: rem(64),
    textAlign: 'center',
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(21),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
  },
  text: {
    marginTop: rem(8),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
  },
})

export const EmptyTutorList = () => {
  const classes = useStyles()
  return (
    <div className={classes.emptyTutorList}>
      <img src={require('./emptyTutors.svg').default} alt='' />
      <div className={classes.title}>Nobody's here...</div>
      <div className={classes.text}>Here you will be see a list of tutors you have already worked with</div>
    </div>
  )
}