import React from 'react'
import Layout from './layout'
import {Card, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useTutorQuery, useOrderQuery} from '../../../../hooks'
import {getUserAvatarUrl} from '../../../../mtuikit/utils/getUserAvatarUrl'

const OrderConnectedEvent = ({freelancer_id, order_id, ...props}) => {
  const tutorQuery = useTutorQuery(freelancer_id)
  const orderQuery = useOrderQuery(order_id)

  if (!(tutorQuery.isFetched && orderQuery.isFetched)) return <div style={{height: 252}}/>

  const order = orderQuery.data
  const tutor = tutorQuery.data

  const color = 'orange'
  const status = 'was disconnected'

  return <MessageWrapper {...props}>
    <Card {...props}>
      <Layout.TutorHeader color={color} avatar={getUserAvatarUrl(freelancer_id)}/>
      <Layout.Body>
        <Layout.Title>{tutor.name} {status}</Layout.Title>
        <Layout.Subject>{order.subject}</Layout.Subject>
        <Layout.TutorStat score={tutor.avg_score} helped={tutor.orders_count}/>
      </Layout.Body>
      <Card.Footer  {...props}/>
    </Card>
  </MessageWrapper>
}

export default OrderConnectedEvent
