import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, centered } from '../../mtuikit/theme/base'
import Button from '../../mtuikit/components/Button'

const styles = {
  root: {
    ...centered,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& img': {
      width: rem(48),
      height: rem(48),
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  block: {
    background: theme.colors.white,
    minWidth: rem(344),
    width: `calc(100% - ${rem(32)})`,
    height: rem(360),
    borderRadius: rem(24),
    padding: [rem(32), rem(16), rem(16)],
    position: 'relative',
    [desktop]: {
      width: rem(464),
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    paddingBottom: rem(80),
    position: 'relative',
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(22),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
  },
  details: {
    marginTop: rem(16),
    marginLeft: rem(8),
    marginRight: rem(8),
    fontSize: rem(15),
    lineHeight: rem(20),
    letterSpacing: rem(0.09),
    textAlign: 'center',
    color: theme.colors.slateGreyTwo,
  },
  line: {
    '& + &': {
      marginTop: rem(20),
    },
  },
  button: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
}

const Alert = ({
  icon,
  title,
  details,
  label,
  onClose,
  zIndex,
  classes,
}) => 
  <div className={classes.root} style={{ zIndex }}>
    <div className={classes.block}>
      <div className={classes.wrapper}>
        <img src={icon} alt='' />
        <div className={classes.title}>{title}</div>
        <div className={classes.details}>
          {details.map(line =>
            <div key={line} className={classes.line}>{line}</div>
          )}
        </div>
        <Button 
          className={classes.button} 
          type='primary'
          size='large'
          block 
          onClick={onClose}
        >
          {label}
        </Button>
      </div>
    </div>
  </div>

export default injectSheet(styles)(Alert)