import React from 'react'
import Prompt from './../Prompt'
import {useDeleteOrderMutation, useOrder} from '../../hooks'

const DeleteOrder = ({
                       orderId,
                       onClose,
                       ...otherProps
                     }) => {
  const order = useOrder(orderId)
  const deleteOrderMutation = useDeleteOrderMutation()
  const deleteOrder = () => {
    deleteOrderMutation.mutate(orderId)
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='delete'/>
      <Prompt.Title>Delete task</Prompt.Title>
      <Prompt.Details details={[
        `Are you sure you want to delete <br/> <strong>${order.title}</strong>?`
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='alert' onClick={deleteOrder}>Delete</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default DeleteOrder