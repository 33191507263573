import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, centered } from '../../../../mtuikit/theme/base'
import { Icon } from '../../../../mtuikit'

const styles = {
  root: {
    ...centered,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  block: {
    background: theme.colors.white,
    width: `calc(100% - ${rem(32)})`,
    borderRadius: rem(24),
    padding: [rem(32), rem(16), rem(16)],
    position: 'relative',
    [desktop]: {
      width: rem(464),
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(16),
    right: rem(16),
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
}

const Wrapper = ({
  classes,
  children,
  onClose,
  zIndex,
}) =>
  <div className={classes.root} style={{ zIndex }}>
    <div className={classes.block}>
      <div className={classes.wrapper}>
        {children}
      </div>
      <div className={classes.close} onClick={onClose}>
        <Icon iconName='close' />
      </div>
    </div>
  </div>

export default injectSheet(styles)(Wrapper)