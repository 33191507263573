import React from 'react'
import injectSheet from 'react-jss'
import ScrollbarWrap from './../ScrollbarWrap'
import Block from './Block'
import Label from './Label'
import List from './List'
import { MIN_CHARS_TO_AVAILABLE_SEARCH, TOP_SUBJECTS, SUBJECTS } from '../../config'
import theme, { rem } from '../../theme/base'

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  empty: {
    backgroundColor: theme.colors.white,
    backgroundImage: `url(${require('./no-results.svg').default})`,
    backgroundPosition: [rem(12), rem(12)],
    backgroundSize: [rem(40), rem(40)],
    backgroundRepeat: 'no-repeat',
    marginTop: rem(8),
    padding: [rem(24), rem(8), rem(24), rem(60)],
    fontSize: rem(13),
    lineHeight: rem(16),
    color: theme.colors.slateGrey,
    fontFamily: theme.fontFamilies.Manrope,
    boxShadow: `
      0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06),
      0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15)
    `,
    borderRadius: rem(8),
    position: 'relative',
  },
}

const SubjectsList = ({ 
  classes, 
  className, 
  search, 
  top = 0,
  onClick,
}) => {
  const allSubjects = [...TOP_SUBJECTS, ...SUBJECTS]

  const findSubjects = allSubjects.filter(subject => 
    search.trim().split(' ').find(item => 
      subject.toLowerCase().includes(item.toLowerCase())
    )
  )

  const isNoResults =
    (findSubjects.length > 0 && search.length >= MIN_CHARS_TO_AVAILABLE_SEARCH) || 
    !search.length

  if (!isNoResults) {
    return <div className={classes.empty}>No results for <b>«{search}»</b></div>
  }

  return (
    <div className={classes.root}>
      <ScrollbarWrap maxHeight={rem(268)} top={top}>
        {search.length
          ? <List 
              className={className}
              search={search}
              items={findSubjects}
              onClick={onClick}
            />
          : <>
              <Block>
                <Label>Top subjects</Label>
                <List 
                  className={className}
                  search={search}
                  items={TOP_SUBJECTS}
                  onClick={onClick}
                />
              </Block>
              <Block>
                <Label>All subjects</Label>
                <List 
                  className={className}
                  search={search}
                  items={SUBJECTS}
                  onClick={onClick}
                />
              </Block>
            </>
        }
      </ScrollbarWrap>
    </div>
  )
}

export default injectSheet(styles)(SubjectsList)