import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {SubjectNameStep} from './SubjectNameStep'
import {TaskDescriptionStep} from './TaskDescriptionStep'
import {DueDateStep} from './DueDateStep'
import {AmountHoursStep} from './AmountHoursStep'
import {PaymentsStep} from './PaymentsStep'
import {theme, utils} from 'mtuikit'
import {desktop, adaptive} from 'mtuikit/theme/base'
import {getRandomTutors} from '../../../config'
import {validateSubject} from '../../../nerdytutors'

const useStyle = createUseStyles({
  title: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: 22 / 16,
    fontWeight: 800,
    '& > img': {
      marginRight: 4,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  subtitle: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 700,
    '& > img': {
      marginRight: 4,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  text: {
    marginTop: 8,
    color: theme.colors.slateGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    '& p:not(:first-child)': {
      marginTop: 6,
    },
  },
  textBold: {
    marginTop: 16,
    color: theme.colors.slateGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    letterSpacing: 0.15,
    fontWeight: 800,
  },
  submit: {
    [desktop]: {
      display: 'none',
      cursor: 'pointer',
    },
    [adaptive]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 44,
      marginTop: 16,
      padding: [12, 24],
      border: [1, 'solid', '#BAC7E2'],
      borderRadius: 28,
      textAlign: 'center',
      color: theme.colors.blue,
      fontWeight: 600,
      cursor: 'pointer',
      '&.type2': {
        border: 'none',
        background: 'rgba(61, 119, 255, 0.08)',
        borderRadius: 56,
        fontWeight: 700,
        fontSize: 14,
        color: '#3D77FF',
      },
    },
  },
  items: {
    width: 80,
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 2,
      right: 0,
      width: 8,
      height: 8,
      borderRadius: '50%',
      background: '#49B000',
      zIndex: 5,
    }
  },
  item: {
    background: theme.colors.white,
    width: 32,
    height: 32,
    marginLeft: -8,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&:nth-child(1)': {
      marginLeft: 0,
      zIndex: 1,
    },
    '&:nth-child(2)': {
      zIndex: 2,
    },
    '&:nth-child(3)': {
      zIndex: 3,
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      display: 'block',
      border: [2, 'solid', theme.colors.white],
    },
  },
})

const Title = ({children}) => {
  const classes = useStyle()
  return <div className={classes.title}>{children}</div>
}

const SubTitle = ({children}) => {
  const classes = useStyle()
  return <div className={classes.subtitle}>{children}</div>
}

const Text = ({children}) => {
  const classes = useStyle()
  return <div className={classes.text}>{children}</div>
}

const TextBold = ({children}) => {
  const classes = useStyle()
  return <div className={classes.textBold}>{children}</div>
}

const Submit = ({children, className, onClick}) => {
  const classes = useStyle()
  return <div className={classnames(classes.submit, className)} onClick={onClick}>{children}</div>
}

const RandomAvatars = ({avatars, isRefresh}) => {
  const classes = useStyle()
  const items = isRefresh
    ? getRandomTutors(3).map(({image}) => image)
    : avatars
  return (
    <div className={classes.items}>
      {items.map((item, index) =>
        <div className={classes.item} key={index}>
          <img src={item} alt=''/>
        </div>
      )}
    </div>
  )
}

const avatars = getRandomTutors(3).map(({image}) => image)

const getRandomTutorsCount = () => utils.getRandom(31, 82, 1)

export const STEPS = [
  {
    component: SubjectNameStep,
    extendedTitle: 'What subject do you need help with?',
    collapsedTitle: 'What subject do you need help with?',
    valid: ({subject}) => validateSubject(subject),
    generateSavedTextFunc: ({requestedTutor, subject}) => <>
      {requestedTutor &&
      <img
        src={utils.getUserAvatarUrl(requestedTutor.id)}
        alt=''
        style={{
          width: 20,
          height: 20,
          marginRight: 8,
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      />
      }
      <span>{subject}</span>
    </>,
    helper: ({onSuggest, subject}) => <>
      <RandomAvatars avatars={avatars} isRefresh={!!subject}/>
      <Title>{subject ? 'Great news!' : '148 tutors are online now!'}</Title>
      <Text>
        {subject
          ? <><b>{getRandomTutorsCount()} tutors</b> in <b>{subject}</b> are online and ready to help!</>
          : <>They are waiting for your tasks and can help you with over <b>250 subjects</b>.</>
        }
      </Text>
      <Submit onClick={onSuggest}>Got it!</Submit>
    </>
  },
  {
    component: TaskDescriptionStep,
    extendedTitle: 'Add task details',
    collapsedTitle: 'Add task details',
    valid: ({details}) => details.trim().length,
    generateSavedTextFunc: ({details}) => details,
    helper: ({onSuggest, returned}) => <>
      <img
        src={require('./TaskDescriptionStep/TaskDescriptionStep.svg').default}
        alt=''
        style={{width: 56, height: 40}}
      />

      <Title>Tell the Tutor what you need</Title>
      <Text>
        {returned
          ? 'Detailed instructions will help us to connect you with the best Tutor for this task.'
          : 'We will find and connect you with the best available tutor for this task.'
        }
      </Text>

      <TextBold>Don’t have all task details on hand?</TextBold>
      <Text>No worries! You can add more info later.</Text>

      <Title>Get help in any way you like</Title>
      <Text>You can collaborate with your Tutor in any way you like: <b>chat, video call, audio call, exchanging files,
        and more</b>.</Text>

      <Submit onClick={onSuggest}>Got it!</Submit>
    </>
  },
  {
    component: DueDateStep,
    extendedTitle: 'How much time do you have to get this task done?',
    collapsedTitle: 'Set a due date',
    generateSavedTextFunc: ({deadline}) => utils.printUntilDate(deadline),
    helper: ({onSuggest}) => <>
      <img
        src={require('./DueDateStep/DueDateStep.svg').default}
        alt=''
        style={{width: 56, height: 40}}
      />
      <Title>Plan and save</Title>
      <Text>
        <p>Let us know by when you need help and our tutor will do their best to provide assistance on time.</p>
        <p>Don't wait until the last minute to request help – plan ahead and save money.</p>
      </Text>
      <Submit className={'type2'} onClick={onSuggest}>Got it!</Submit>
    </>
  },
  {
    component: AmountHoursStep,
    extendedTitle: 'How much time should a tutor spend on helping you out?',
    collapsedTitle: 'Pre-book tutor\'s time',
    generateSavedTextFunc: ({size, price, estimateRequired}) =>
      estimateRequired
        ? `Let tutor decide (${utils.sizeToHumans(size)} · ${utils.printAmount(price)})`
        : `${utils.sizeToHumans(size)} · ${utils.printAmount(price)}`,
    helper: ({onSuggest}) => <>
      <img
        src={require('./AmountHoursStep/AmountHoursStep.svg').default}
        alt=''
        style={{width: 56, height: 40}}
      />

      <Title>Pay only for the time tutor spends working on your task!</Title>
      <Text>You will not be charged for chatting to your tutor, discussing task details or asking followup
        questions.</Text>

      <TextBold>Not sure? Let your tutor decide!</TextBold>
      <Text>Pre-pay for an hour of tutor's help and tutor will review your task details and send you an accurate time
        estimate. </Text>

      <Submit onClick={onSuggest}>Got it!</Submit>
    </>
  },
  {
    component: PaymentsStep,
    extendedTitle: 'Review and pay',
    collapsedTitle: 'Payment',
    valid: ({isReadyForPayment}) => isReadyForPayment,
    helper: ({onSuggest}) => <>
      <img
        src={require('./PaymentsStep/PaymentsStep.svg').default}
        alt=''
        style={{width: 56, height: 40}}
      />

      <Title>You are almost there!</Title>
      <Text>As soon as you make the payment, we will find the best available tutor for your task to help you out.</Text>
      <Text>You will receive a notification as soon as your tutor joins a chat with you!</Text>

      <SubTitle>
        <img src={require('./PaymentsStep/ic_secure.svg').default} alt=''/>
        {' '}
        100% High Quality Guarantee
      </SubTitle>
      <Text>You tutor will get paid after providing high quality help. If not, our platform will issue a refund.</Text>

      <Submit onClick={onSuggest}>Got it!</Submit>
    </>
  }
]
