import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    paddingRight: rem(56),
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'block',
    color: theme.colors.blue,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.blue,
    }
  }
}

const Link = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Link)