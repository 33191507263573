function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'ADD':
      return state.concat(payload)
    case 'PATCH':
      const index = state.map(item => item.uuid).indexOf(payload.uuid)
      state.splice(index, 1, {
        ...state[index],
        ...payload,
      })
      return [...state]
    case 'REMOVE':
      return state.filter(item => item.uuid !== payload.uuid)
    default:
      return state
  }
}

export const add = payload => ({ type: 'ADD', payload })
export const patch = payload => ({ type: 'PATCH', payload })
export const remove = payload => ({ type: 'REMOVE', payload })
export default reducer
