import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content2, ui, desktop, adaptive } from '../../../theme/base'

const styles = {
  root: {
    padding: [rem(152), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
    '&:before': {
      content: '""',
      display: 'block',
      background: 'linear-gradient(180deg, rgba(60, 120, 255, 0.2) 0%, rgba(60, 120, 255, 0) 100%)',
      height: rem(305),
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
    },
  },
  content: content2,
  block: {
    [desktop]: {
      maxWidth: rem(380),
    },
  },
  title: {
    ...ui.title,
    textAlign: 'left',
    [adaptive]: {
      ...ui.title[adaptive],
      maxWidth: rem(380),
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
  },
  subtitle: {
    marginTop: rem(16),
    fontSize: rem(18),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    [adaptive]: {
      maxWidth: rem(380),
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
  },
  icons: {
    marginTop: rem(16),
    [adaptive]: {
      textAlign: 'center',
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: rem(8),
    },
  },
  image: {
    display: 'block',
    [desktop]: {
      width: rem(704),
      height: rem(400),
      position: 'absolute',
      top: rem(-110),
      left: '50%',
      transform: `translateX(${rem(-67)})`,
    },
    [adaptive]: {
      width: '100%',
      maxWidth: `calc(100vw - ${rem(32)})`,
      margin: [rem(16), 'auto', 0],
    },
  },
}

const ICONS = [
  'visa',
  'mastercard',
  'dinersClub',
  'amex',
  'discover',
  'jcb',
  // 'stripe',
]

const Payment = ({
  classes,
  imageName,
}) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.block}>
        <div className={classes.title}>
          No hidden fees.
          <br/>
          Pay as you go.
        </div>
        <img className={classes.image} src={require(`./${imageName}.svg`).default} alt='' />
        <div className={classes.subtitle}>
          Tell us what you need help with and pay only
          for the time tutor spends on helping you out.
        </div>
        <div className={classes.icons}>
          {ICONS.map((icon, index) =>
            <img key={index} className={classes.icon} src={require(`./${icon}.svg`).default} alt='' />
          )}
        </div>
      </div>
    </div>
  </div>

export default injectSheet(styles)(Payment)