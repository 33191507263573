import theme, { rem, desktop, adaptive, MODAL_WIDTH_DESKTOP } from './../../../theme/base'

//const MODAL_HEIGHT_DESKTOP = 640
const MODAL_HEADER_HEIGHT = 54
const PADDING_TOP = 16
const PADDING_BOTTOM = 24
const PADDING_LEFT = 24
const PADDING_RIGHT = 24
//const MODAL_CONTENT_HEIGHT = MODAL_HEIGHT_DESKTOP - MODAL_HEADER_HEIGHT

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    [desktop]: {
      width: rem(MODAL_WIDTH_DESKTOP),
    },
    [adaptive]: {
      height: '100%',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'start',
    [desktop]: {
      //minHeight: rem(MODAL_HEIGHT_DESKTOP),
      maxHeight: `calc(100vh - ${rem(84)} - ${rem(84)})`,
    },
    [adaptive]: {
      height: '100%',
    },
  },
  header: {
    height: rem(MODAL_HEADER_HEIGHT),
    borderRadius: 'inherit',
    position: 'relative',
  },
  wrapper: {
    padding: [rem(PADDING_TOP), rem(PADDING_LEFT), rem(PADDING_BOTTOM), rem(PADDING_RIGHT)],
    fallbacks: [
      { overflowY: 'auto' }, // for Mozilla
      { overflowY: 'overlay' },
    ],
    [desktop]: {
      minWidth: rem(MODAL_WIDTH_DESKTOP - PADDING_LEFT - PADDING_RIGHT),
      //height: rem(MODAL_CONTENT_HEIGHT),
      position: 'relative',
    },
    [adaptive]: {
      height: '100%',
      paddingLeft: rem(16),
      paddingRight: rem(16),
      overflowX: 'hidden',
    },
  },
  content: {
    background: theme.colors.white,
    width: '100%',
    borderRadius: [rem(16), rem(16), 0, 0],
    position: 'relative',
    [desktop]: {
      //minHeight: rem(MODAL_CONTENT_HEIGHT - PADDING_TOP - PADDING_BOTTOM),
      display: 'flex',
      flexDirection: 'column',
      borderRadius: [0, 0, rem(16), rem(16)],
    },
    [adaptive]: {
      paddingBottom: rem(104 + 48),
    },
  },
}

export default styles
