import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(2),
    color: theme.colors.slateGrey,
    fontSize: rem(12),
    lineHeight: rem(20),
    letterSpacing: rem(0.15),
  },
}

const Subtitle = ({
  classes,
  children,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Subtitle)