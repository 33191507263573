import React from 'react'
import injectSheet from 'react-jss'
import { rem } from './../../../theme/base'
import {StateLink} from './StateLink'

const styles = {
  root: {
    width: rem(160),
    height: rem(24),
    margin: [0, 'auto'],
    display: 'block',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const Logo = ({ classes }) =>
  <StateLink className={classes.root} to='/'>
    <img src={require('./../../../assets/images/logo-blue.svg').default} alt='' />
  </StateLink>

export default injectSheet(styles)(Logo)