import React from 'react'
import Prompt from './../Prompt'
import {useBlockTutorMutation} from './../../hooks'

const BlockTutor = ({
                      tutorId,
                      onClose,
                      ...otherProps
                    }) => {
  const blockTutorMutation = useBlockTutorMutation(tutorId)
  const blockTutor = () => {
    blockTutorMutation.mutate()
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='block'/>
      <Prompt.Title>Block Tutor</Prompt.Title>
      <Prompt.Details details={[
        'If you block this tutor she will not be allowed to accept any of your future requests.',
        'Are you sure you want to block the tutor.',
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='alert' onClick={blockTutor}>Block</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default BlockTutor