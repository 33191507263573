import React from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import theme from 'theme/base'
import {Button} from './Button'
import Overlay from './Overlay'
import {desktop, adaptive} from 'mtuikit/theme/base'

const useStyle = createUseStyles({
  root: {
    background: theme.colors.white,
    padding: 16,
    [desktop]: {
      width: 320,
      height: 158,
      borderRadius: 8,
      boxShadow: [
        [0, 8, 12, 'rgba(0, 38, 96, 0.06)'],
        [0, 8, 40, 'rgba(0, 53, 136, 0.15)'],
      ],
    },
    [adaptive]: {
      borderRadius: [8, 8, 0, 0],
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontSize: 16,
    lineHeight: 22 / 16,
    fontWeight: 800,
  },
  text: {
    marginTop: 8,
    color: theme.colors.charcoalGrey,
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 400,
    letterSpacing: 0.15,
  },
  footer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondary: {
    color: '#ED2C39',
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 600,
    cursor: 'pointer',
  },
  close: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
})

const Popup = ({title, text, primaryText, secondaryText, onCancel, onPrimary, onSecondary, className, layout, zIndex = 3}) => {
  const classes = useStyle()
  return (
    <>
      <Overlay zIndex={zIndex - 1} adaptive desktop={layout==='desktop'} />
      <div className={classnames(classes.root, className)} style={{zIndex}}>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{text}</div>
        <div className={classes.footer}>
          <div className={classes.secondary} onClick={onSecondary}>{secondaryText}</div>
          <Button onClick={onPrimary}>{primaryText}</Button>
        </div>
        <div className={classes.close} onClick={onCancel}>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd'
                  d='M15.7698 6.74338C16.1804 6.33282 16.846 6.33282 17.2566 6.74338C17.6672 7.15395 17.6671 7.81962 17.2565 8.23017L13.4865 11.9999L17.2566 15.7698C17.6672 16.1804 17.6672 16.846 17.2566 17.2566C16.846 17.6672 16.1804 17.6672 15.7698 17.2566L11.9999 13.4865L8.23017 17.2565C7.81962 17.6671 7.15395 17.6672 6.74338 17.2566C6.33282 16.846 6.33282 16.1804 6.74338 15.7698L10.5133 11.9999L6.74341 8.23017C6.33284 7.81962 6.33283 7.15396 6.7434 6.7434C7.15396 6.33283 7.81962 6.33284 8.23017 6.74341L11.9999 10.5133L15.7698 6.74338Z'
                  fill='#565D6B'/>
          </svg>
        </div>
      </div>
    </>
  )
}

export default Popup
