import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme from '../../mtuikit/theme/base'
import PropTypes from 'prop-types'

const styles = {
  overlay: {
    content: "''",
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  black: {
    background: theme.colors.black60,
  },
  white: {
    background: theme.colors.white50,
  },
  whiteNoOpacity: {
    background: theme.colors.white,
  },
}

const Overlay = ({
  zIndex,
  children,
  color,
  classes,
}) =>
  <>
    {children}
    <div className={classnames(classes.overlay, classes[color])} style={{ zIndex }} />
  </>

Overlay.propTypes = {
  color: PropTypes.oneOf(['black', 'white', 'whiteNoOpacity']),
}

Overlay.defaultProps = {
  color: 'black',
}

export default injectSheet(styles)(Overlay)