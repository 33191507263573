import axios from 'axios'
import analytics from './analytics'
import queryString from 'query-string'

export const getPaymentLink = orderId =>
  `${process.env.REACT_APP_PTGW_URL}/p/i${orderId}`
  + `?success_url=${process.env.REACT_APP_ME}/payment-success/${orderId}`
  + `&cancel_url=${process.env.REACT_APP_ME}/payment-cancel/${orderId}`
  + `&error_url=${process.env.REACT_APP_ME}/payment-failed/${orderId}`
  + `&expired_url=${process.env.REACT_APP_ME}/payment-cancel/${orderId}`


const ERROR_MAP = {
  'User wasn\'t find': 'User not found. Please Sign up',
  'Already exists email': 'User exists, please try to Log in',
  'Credentials already exists': 'User exists, please try to Log in',
  'Cant find code': 'Incorrect password',
  'password dosn\'t match': 'Incorrect password',
  'User not found': 'User not found. Please Sign up',
  'Already exists phone': 'This phone number is already in use'
}

export const createError = err => {
  if (err.response.status === 403) {
    return err
  }
  const message = ERROR_MAP[err.response.data.error_message || err.response.data.detail] || 'Something went wrong'
  const error = new Error(message)
  error.details = message
  return error
}

const normalizeId = ({_id, ...data}) => ({id: _id, ...data})

// new

// auth

const TOKEN_KEY = '_t1'
let _t = null

function getGoogleClientId() {
  let p = ('; ' + document.cookie).split(';  _ga=')
  if (p.length === 2) {
    const _ga = p.pop().split(';').shift()
    return _ga.split('.').slice(2, 4).join('.')
  }
}

localStorage.setItem('_l', localStorage.getItem('_l') || window.location.host + window.location.pathname)
localStorage.setItem('_u', localStorage.getItem('_u') || queryString.stringify({
  ...queryString.parse(window.location.search.replace(/\+/g, '%2B')),
  ga_client_id: getGoogleClientId()
}))
const [LANDING, UTM] = [localStorage.getItem('_l') || "", localStorage.getItem('_u') || ""]


export function setToken(token) {
  _t = token
  window.localStorage.setItem(TOKEN_KEY, token)
}

export function getToken() {
  _t = _t || window.localStorage.getItem(TOKEN_KEY)
  return _t ? 'Bearer ' + _t : null
}

export function removeToken() {
  _t = null
  window.localStorage.removeItem(TOKEN_KEY)
}

// authorization

export const registerAndAuthorizeByEmailApi = ({email, password}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/profile/register_by_email`,
    {
      utm: UTM,
      landing: LANDING,
      profile: {email, password},
      site_id: process.env.REACT_APP_SITE_ID,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .then(result => {
      analytics.leadCreated(result.data._id)
      return authorizeByLoginApi({email, password})
    })
    .catch(err => {
      throw createError(err)
    })
}

export const restorePasswordApi = ({email}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client_restore_password/by_email`,
    {
      auth_data: {email},
      site_id: process.env.REACT_APP_SITE_ID,
    })
    .catch(err => {
      throw createError(err)
    })
}

export const authorizationByProviderTokenApi = ({provider, token}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client_authorization/by_provider_token`,
    {
      utm: UTM,
      landing: LANDING,
      auth_data: {token, provider},
      site_id: process.env.REACT_APP_SITE_ID,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .then(result => setToken(result.data.auth_token))
    .catch(err => {
      throw createError(err)
    })
}

export const authorizeByLoginApi = ({email, password}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client_authorization/by_login`,
    {
      auth_data: {login: email, password},
      utm: UTM,
      landing: LANDING,
      site_id: process.env.REACT_APP_SITE_ID
    })
    .then(result => setToken(result.data.auth_token))
    .catch(err => {
      throw createError(err)
    })
}

export const getContextAccessTokenApi = (token) => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/context/auth/${token}`
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

// profile

const normalizeProfile = ({_id, ...data}) => {
  return {
    id: _id,
    cid: _id,
    ...data,
    name: data.name.startsWith('Customer_') ? '' : data.name,
    balance: data.balance.available,
    returned: data.orders_stat.paid_orders_count > 0
  }
}

export const getProfileApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/profile`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeProfile(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const updateProfileApi = profile => {
  return axios.patch(
    `${process.env.REACT_APP_NT_URL}/client/profile`, {profile},
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

export const getTutorsApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/profile/freelancers`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => ({
      ...result.data,
      freelancers: result.data.freelancers.map(normalizeId)
    }))
    .catch(err => {
      throw createError(err)
    })
}

export const getTutorApi = freelancerId => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/profile/freelancers/${freelancerId}`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const blockTutorApi = freelancerId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/profile/freelancers/${freelancerId}/block`,
    {freelancer_id: freelancerId},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const markFavTutorApi = freelancerId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/profile/freelancers/${freelancerId}/mark_favorite`,
    {freelancer_id: freelancerId},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const unmarkFavTutorApi = freelancerId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/profile/freelancers/${freelancerId}/unmark_favorite`,
    {freelancer_id: freelancerId},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const getBundlesApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/profile/bundles`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => ({
      ...result.data,
      bundles: result.data.bundles.map(normalizeId)
    }))
    .catch(err => {
      throw createError(err)
    })
}

export const getBundleApi = bundleId => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/profile/bundles/${bundleId}`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const addPNSubscription = (key, subscription) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/profile/wpcreds`,
    {subscription, key},
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

// order

export const createOrderApi = ({order, files, requestedTutorId}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders`,
    {
      order: {
        title: order.subject,
        details: order.details,
        subject: order.subject,
        type: "Project (in minutes)",
        deadline: order.deadline,
        size: order.size,
        output_formats: order.output_formats,
        other_output_format: order.other_output_format,
        let_tutor_decide: order.let_tutor_decide
      },
      files,
      recommend_freelancer: requestedTutorId
    },
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const updateOrderApi = ({order: {id, ...order}, files, requestedTutorId}) => {
  return axios.patch(
    `${process.env.REACT_APP_NT_URL}/client/orders/${id}`,
    {
      ...(order && {order: {...order, title: order.subject}}),
      ...(files && {files}),
      ...(requestedTutorId && {recommend_freelancer: requestedTutorId})
    },
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const getOrdersApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/orders`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => ({
      ...result.data,
      orders: result.data.orders.map(normalizeId)
    }))
    .catch(err => {
      throw createError(err)
    })
}

export const getOrderApi = orderId => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const deleteOrderApi = orderId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/delete`,
    {order_id: orderId},
    {headers: {Authorization: getToken()}}
  )
    .then(result => null)
    .catch(err => {
      throw createError(err)
    })
}

export const rejectTutorApi = ({orderId, freelancerId}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/reject_freelancer_chat`,
    {
      freelancer_id: freelancerId
    },
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const requestRevisionApi = ({orderId, revisionRequest}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/request_revision`,
    {
      revision_request: revisionRequest
    },
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const rateOrderApi = ({orderId, rate}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/rate`,
    {rate: {rate}},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const declineOrderEstimateApi = ({orderId, estimateId}) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/estimates/${estimateId}/decline`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

export const extendOrderChatApi = (orderId, extendedTo) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/extend_chat`,
    {chat_extended_to: extendedTo},
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

export const updateOrderMetaDataApi = ({orderId, ...data}) => {
  return axios.patch(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/meta_data`,
    {meta_data: data},
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

export const addTopTutorAddonApi = (orderId, placedThrough) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/add_addon/top_tutor`,
    {landing: placedThrough},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const removeTopTutorAddonApi = orderId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/remove_addon/top_tutor`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const requestLiveSessionApi = orderId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/live_sessions_request`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .then(result => normalizeId(result.data))
    .catch(err => {
      throw createError(err)
    })
}

export const addTaskInstructionsApi = (orderId, {details, files}) => axios.post(
  `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/add_task_instructions`,
  {details, files},
  {headers: {Authorization: getToken()}}
)
  .catch(err => {
    throw createError(err)
  })

export const updateOrderNotificationsApi = ({orderId, isEnabled}) => {
  return axios.patch(
    `${process.env.REACT_APP_NT_URL}/client/orders/${orderId}/notifications`,
    {
      "notifications": {
        "optout_notifs": !isEnabled
      }
    },
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

export const updateProfileNotificationsApi = ({notifications}) => {
  return axios.patch(
    `${process.env.REACT_APP_NT_URL}/client/profile/notifications`,
    {
        notifications,
    },
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

// payments

export const getListAllCardsApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/list_all_cards`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

export const getStripeCustomerApi = () => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/retrieve_customer`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

export const getOrderPaymentIntentApi = orderId => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/get_order_payment_intent/${orderId}`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

export const getOrderEstimatePaymentIntentApi = (orderId, estimateId) => {
  return axios.get(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/get_order_estimate_payment_intent/${orderId}/${estimateId}`,
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

export const handleOrderPaymentIntentConfirmationApi = paymentIntentId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/handle_order_payment_intent_confirmation/${paymentIntentId}`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

export const balancePayOrderApi = orderId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/payments/pay_order_from_balance/${orderId}`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .then(() => orderId)
    .catch(err => {
      throw createError(err)
    })
}

export const balancePayOrderEstimateApi = (orderId, estimateId) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/payments/pay_order_estimate_from_balance/${orderId}/${estimateId}`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .then(() => orderId)
    .catch(err => {
      throw createError(err)
    })
}

export const getTopUpBalanceBundlePaymentIntentApi = bundleItemId => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/stripe_payments/create_balance_bundle_payment_intent/${bundleItemId}`,
    {},
    {headers: {Authorization: getToken()}}
  )
    .then(result => result.data)
    .catch(err => {
      throw createError(err)
    })
}

// mixes

export const addTopTutorAddonAndPayApi = async ({orderId, isAddonActive, placedThrough, paymentMethod, stripe}) => {
  let error

  if (!isAddonActive) {
    await addTopTutorAddonApi(orderId, placedThrough)
  }

  try {
    const pi = await getOrderPaymentIntentApi(orderId)

    const confirmCardPaymentResult = await stripe.confirmCardPayment(pi.client_secret, {
      payment_method: paymentMethod,
      setup_future_usage: 'off_session'
    })

    if (!confirmCardPaymentResult.error) {
      await handleOrderPaymentIntentConfirmationApi(confirmCardPaymentResult.paymentIntent.id)
    } else {
      error = confirmCardPaymentResult.error
    }

  } catch (e) {
    error = e
  }

  if (error) {
    await removeTopTutorAddonApi(orderId)
    return {error}
  }

  const order = await getOrderApi(orderId)
  return {order}
}

export const addTopTutorAddonAndBalancePayApi = async ({orderId, isAddonActive, placedThrough}) => {
  let error

  if (!isAddonActive) {
    await addTopTutorAddonApi(orderId, placedThrough)
  }

  try {
    await balancePayOrderApi(orderId)
  } catch (e) {
    error = e
  }

  if (error) {
    await removeTopTutorAddonApi(orderId)
    return {error}
  }

  const order = await getOrderApi(orderId)
  return {order}
}

export const payTopUpBundleApi = async ({bundleId, bundleItemId, paymentMethod, stripe}) => {
  let error
  try {
    const pi = await getTopUpBalanceBundlePaymentIntentApi(bundleItemId)
    const confirmCardPaymentResult = await stripe.confirmCardPayment(pi.client_secret, {
      payment_method: paymentMethod,
      setup_future_usage: 'off_session'
    })
    if (!confirmCardPaymentResult.error) {
      await handleOrderPaymentIntentConfirmationApi(confirmCardPaymentResult.paymentIntent.id)
    } else {
      error = confirmCardPaymentResult.error
    }
  } catch (e) {
    error = e
  }

  if (error) return {error}

  const bundle = await getBundleApi(bundleId)
  return {bundle}
}

export const saveAndStripePayOrderApi = async ({order, files, requestedTutorId, paymentMethod, stripe}) => {
  let newOrder = null
  let error = null

  const apiCall = !order.id ? createOrderApi : updateOrderApi

  try {
    newOrder = await apiCall({order, files, requestedTutorId})

    const pi = await getOrderPaymentIntentApi(newOrder.id)

    const confirmCardPaymentResult = await stripe.confirmCardPayment(pi.client_secret, {
      payment_method: paymentMethod,
      setup_future_usage: 'off_session'
    })

    if (!confirmCardPaymentResult.error) {
      await handleOrderPaymentIntentConfirmationApi(confirmCardPaymentResult.paymentIntent.id)
    } else {
      error = confirmCardPaymentResult.error
    }

    newOrder = await getOrderApi(newOrder.id)
  } catch (e) {
    error = e
  }

  const result = {
    order: newOrder,
    error
  }

  return result
}

export const saveAndBalancePayOrderApi = async ({order, files, requestedTutorId}) => {
  const apiCall = !order.id ? createOrderApi : updateOrderApi
  const order_ = await apiCall({order, files, requestedTutorId})
  await balancePayOrderApi(order_.id)
  return {
    order: await getOrderApi(order_.id),
    error: null
  }
}

export const acceptAndPayOrderEstimateApi = ({orderId, estimateId, paymentMethod, stripe}) => {
  return getOrderEstimatePaymentIntentApi(orderId, estimateId)
    .then(paymentIntent => stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: paymentMethod,
      setup_future_usage: 'off_session'
    }))
    .then(({error, paymentIntent}) => {
      if (error) {
        throw error
      }
      return handleOrderPaymentIntentConfirmationApi(paymentIntent.id)
    })
    .then(result => getOrderApi(result.data.metadata.order_id))
}

export const acceptAndBalancePayOrderEstimateApi = ({orderId, estimateId}) => {
  return balancePayOrderEstimateApi(orderId, estimateId)
    .then(orderId => getOrderApi(orderId))
}

// externals

export const smsToolMessageApi = (cid, body, silently) => axios.post(
  `${process.env.REACT_APP_A5_URL}/smstool/send_message_new/`,
  {
    smstool_name: process.env.REACT_APP_SMSTOOL_NAME,
    _no_lock_: true,
    crm_id: cid,
    body,
    silently,
  })

export const smsToolXmppMessageApi = (node, body) => axios.post(
  `${process.env.REACT_APP_A5_URL}/smstool/send_message_new/`,
  {
    smstool_name: process.env.REACT_APP_SMSTOOL_NAME,
    body,
    channel_id: process.env.REACT_APP_SMSTOOL_CHANNEL_ID,
    credential_type: 'xmpp',
    credential_value: node,
    silently: false,
    _no_lock_: true,
  })

export const createSmstoolReminderApi = (cid, body) => smsToolMessageApi(cid, body, true)

export const sendSmstoolMessageApi = (node, body) => smsToolXmppMessageApi(node, body)

export const getChatFilesApi = node => {
  return axios.post(
    `${process.env.REACT_APP_XMPP_REST_URL}/nodes/get_items`,
    {
      limit: 500,
      node,
      server: process.env.REACT_APP_XMPP_REST_SERVER
    },
    {headers: {Authorization: getToken()}}
  ).then(({data: {items}}) => items
    .map(({json}) => JSON.parse(json))
    .filter(({files}) => files)
    .map(({from, files}) => files.map(({url: location, ...file}) => ({from, location, ...file})))
    .reduce((result, files) => [...result, ...files], [])
  )
}

export const sendSmstoolEventApi = (cid, event_name, params) => {
  return axios.post(
    `${process.env.REACT_APP_NT_URL}/communications/smstool_event_for_user`,
    {
      event_name,
      payload: {
        ...params,
        credential_type: 'crm_id',
        credential_value: cid
      },
    },
    {headers: {Authorization: getToken()}}
  )
    .catch(err => {
      throw createError(err)
    })
}

