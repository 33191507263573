import Modal from './Modal'
import Footer from './Footer'
import Header from './Header'
import Content from './Content'

Modal.Footer = Footer
Modal.Header = Header
Modal.Content = Content

export default Modal
