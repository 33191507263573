import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
  },
}

const Form = ({ 
  classes,
  children, 
}) => 
  <div className={classes.root}>{children}</div>
        
export default injectSheet(styles)(Form)