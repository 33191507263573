import React, {useEffect, useState, createContext, useContext} from 'react'
import moment from 'moment'

const Context = createContext({})

export const useOnboardContext = () => useContext(Context)

export const ChatOnboardProvider = ({children}) => {
  const [read, setRead] = useState(loadReadCache())

  useEffect(() => {
    saveReadCache(read)
  }, [read])

  const readAll = (id, index) => setRead({...read, [id]: index})

  return <Context.Provider
    value={{read, readAll}}>{children}</Context.Provider>
}

const now = () => moment().valueOf()

const getQueue = (list, time, context) => {
  const {fistLoadedMessageAt, isEmpty, hasNextPage} = context
  const queue = (
    list
      .map(([type, payload, getReadyTime]) => {
        const time = getReadyTime(context)
        return type === 'message'
          ? {
            from: 'onboard',
            message: payload,
            time
          } : {
            event: {
              event_name: payload,
              event_params: {
                order_id: context.orderId,
                first_time_client: context.firstTimeClient,
                subject: context.subject
              }
            },
            from: 'onboard',
            time
          }
      })
      .filter(m => !!m.time)
      .filter(m => isEmpty || (!fistLoadedMessageAt && !hasNextPage) || fistLoadedMessageAt > m.time)
  )

  const sent = queue.filter(m => time >= m.time).map((m, index) => ({...m, id: 'o' + index}))
  const wait = queue.filter(m => time < m.time)

  return [sent, wait]
}

const TYPING_DURATION = 2000

export const useChatOnboardNotification = ({list = [], node, ...context}) => {
  const {read: _read} = useOnboardContext()
  const [time, setTime] = useState(now())
  const [sent, wait] = getQueue(list, time, context)
  const read = _read[node] === undefined ? sent.length : _read[node]

  useEffect(() => {
      if (wait.length === 0) return

      const {0: {time}} = wait
      const delay = time - now()

      const t = setTimeout(() => {
        setTime(now())
      }, delay)

      return () => clearTimeout(t)

    },
    // eslint-disable-next-line
    [wait.length]
  )

  if (!context.isFetched) return {
    count: 0,
    first: null,
    last: null,
    length: 0
  }

  return {
    count: sent.length - read,
    first: sent.length ? sent[Math.max(read - 1, 0)] : null,
    last: sent.length ? sent[sent.length - 1] : null,
    length: sent.length
  }
}

export const useChatOnboard = (params) => {
  const {list = [], node, ...context} = params || {}

  const {readAll} = useOnboardContext()
  const [time, setTime] = useState(now())
  const [sent, wait] = getQueue(list, time, context)
  const [typing, setTyping] = useState(false)

  useEffect(() => {
      if (wait.length === 0) return

      const {0: {time}} = wait
      const delay = time - now()

      const t1 = setTimeout(() => setTyping(true), delay - TYPING_DURATION)
      const t2 = setTimeout(() => {
        setTyping(false)
        setTime(now())
      }, delay)

      return () => {
        clearTimeout(t1)
        clearTimeout(t2)
        setTyping(false)
      }
    },
    // eslint-disable-next-line
    [wait.length]
  )

  useEffect(() => {
      readAll(node, sent.length)
    },
    // eslint-disable-next-line
    [sent.length, node]
  )

  const refs = sent.reduce((result, item) => ({...result, [item.id]: item}), {})
  return {
    refs,
    list: Object.keys(refs),
    typing
  }
}

const loadReadCache = () => {
  try {
    const json = window.localStorage.getItem('orc')
    return json ? JSON.parse(json) : {}
  } catch {
    return {}
  }
}

const saveReadCache = cache => {
  window.localStorage.setItem('orc', JSON.stringify(cache))
}
