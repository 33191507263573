import {useReducer} from 'react'
import S3Uploader, {STATUS} from './s3uploader'
import reducer, {add, patch, remove} from './reducer'

const useUploader = (config) => {
  const [files, dispatch] = useReducer(reducer, [])

  const uploaded = files
    .filter(file => file.status === STATUS.UPLOADED)
    .map(({uuid, name, size, url, preview, type}) => ({uuid, name, size, location: url, preview, type}))

  const uploader = new S3Uploader(
    config,
    {
      onadd: payload => dispatch(add(payload)),
      onprogress: payload => dispatch(patch(payload)),
      onload: payload => dispatch(patch(payload)),
      onerror: payload => dispatch(patch(payload)),
      onabort: payload => dispatch(remove(payload)),
    })
  const uploadFile = nfiles => uploader.add(nfiles)
  const removeFile = uuid => uploader.remove(uuid)

  return [files, uploaded, uploadFile, removeFile]
}

export default useUploader
