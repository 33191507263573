import React from 'react'
import {createUseStyles} from 'react-jss'
import {Icon} from '../../../mtuikit'
import theme, {rem} from '../../../mtuikit/theme/base'

const Stat = ({children}) => {
  const classes = useStyle()
  return <div className={classes.list}>{children}</div>
}

const Item = ({iconName, title, value}) => {
  const classes = useStyle()
  return <div className={classes.item}>
    <Icon iconName={iconName}/>
    <div>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value}</div>
    </div>
  </div>
}

const useStyle = createUseStyles({
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: [rem(16), rem(0)],
    borderTop: '1px solid #EBEEF5',
    borderBottom: '1px solid #EBEEF5',
    gap: 20,
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      width: rem(32),
      height: rem(32),
      display: 'block',
      marginRight: rem(8),
    },
  },
  title: {
    fontSize: rem(12),
    lineHeight: rem(16),
    color: theme.colors.slateGrey,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 700,
    color: theme.colors.slateGrey,
    whiteSpace: 'nowrap',
  },
})

Stat.Item = Item

export default Stat
