import React, {useState} from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import {useChatFilesQuery, useOrderQuery, useXmppConfig} from '../../hooks'
import Attachment from '../OrderFormV5/layout/File/Attachment'

const getUserId = jid => jid.split('@')[0]

const ChatFiles = ({orderId, className}) => {
  const classes = useStyle()

  const orderQuery = useOrderQuery(orderId)
  const xmppConfig = useXmppConfig()
  const node = orderQuery.data?.jabber_settings?.jabber_node
  const chatFilesQuery = useChatFilesQuery(node, {enabled: !!node})
  const [f, setFilter] = useState('all')

  if (!(orderQuery.isSuccess && chatFilesQuery.isSuccess)) return null
  const files = chatFilesQuery.data

  const me = xmppConfig.userJid

  const _filter = {
    all: () => true,
    me: from => me === from,
    tutor: from => me !== from
  }

  const isMeFiles = files.filter(({from}) => _filter.me(from)).length > 0
  const isTutorFiles = files.filter(({from}) => _filter.tutor(from)).length > 0
  const filter = _filter[f]

  return <div className={classnames(classes.root, className)}>
    {
      files.length > 0 && <>
        {
          isMeFiles && isTutorFiles &&
          <div className={classes.filters}>
            {[
              ['all', 'all files'], ['tutor', 'from tutor'], ['me', 'from me'],
            ].map(([key, value]) => <div
              key={key}
              className={classnames(classes.filter, {active: key === f})}
              onClick={() => setFilter(key)}>{value}</div>
            )}
          </div>
        }
        {
          files
            .filter(({from}) => filter(from))
            .map(file => <Attachment key={file.location} big {...file}
                                     userId={file.from !== me ? getUserId(file.from) : undefined}/>)
        }
      </>
    }
    {
      files.length === 0 &&
      <div className={classes.no_files}>
        <img src={require('./images/files.svg').default} alt=''/>
        <div>
          <div className={classes.no_files_h}>No files yet...</div>
          <div className={classes.no_files_p}>All files that you exchange with <br/>the tutor will be saved here</div>
        </div>
      </div>
    }
  </div>
}

const useStyle = createUseStyles({
  root: {
    margin: [14, 0, 0]
  },
  filters: {
    marginBottom: 10,
  },
  filter: {
    display: 'inline-block',
    border: '1px solid #BAC7E2',
    borderRadius: '48px',
    padding: [3, 12],
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '16px',
    cursor: 'pointer',
    color: '#3D77FF',
    '& + &': {
      marginLeft: '10px',
    },
    '&.active': {
      background: 'rgba(61, 119, 255, 0.08)'
    },
  },
  no_files: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.15',
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginRight: 15
    }
  },
  no_files_h: {
    fontWeight: 700,
    marginBottom: 4,
  },
  no_files_p: {
    letterSpacing: '0'
  },
})

export default ChatFiles
