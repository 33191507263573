import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import {v4 as uuidv4} from 'uuid'
import {useLocation, useHistory} from 'react-router-dom'
import {Input, Button, utils} from 'mtuikit'
import {SlideUpDropDownAnimation} from '../../../../components/Animation'
import SubjectsList from '../../../../components/SubjectsList'
import {SUBJECTS, TOP_SUBJECTS} from '../../../../config'
import theme, {rem, adaptive, desktop, media} from '../../../theme/base'
import {trackValues} from '../../../../lrtracker'
import Subjects from '../../sections/Cta/Subjects'

const styles = {
  form: {
    background: theme.colors.white,
    width: '100%',
    height: rem(72),
    borderRadius: rem(88),
    boxShadow: [
      [0, rem(16), rem(40), 0, 'rgba(0, 53, 136, 0.15)'],
      [0, rem(8), rem(12), 0, 'rgba(0, 38, 96, 0.06)']
    ],
    textAlign: 'left',
    position: 'relative',
    [adaptive]: {
      height: rem(128),
      padding: rem(8),
      borderRadius: rem(28),
    },
  },
  input: {
    paddingRight: `${rem(264)} !important`,
    lineHeight: `${rem(24)} !important`,
    [adaptive]: {
      paddingTop: `${rem(12)} !important`,
      paddingBottom: `${rem(20)} !important`,
      paddingRight: `${rem(48)} !important`,
      paddingLeft: `${rem(8)} !important`,
      fontSize: `${rem(16)} !important`,
    },
    '& + svg': {
      right: `${rem(200)} !important`,
      [adaptive]: {
        top: `${rem(12)} !important`,
        right: `${rem(16)} !important`,
      },
    },
  },
  submit: {
    paddingLeft: rem(16),
    paddingRight: rem(16),
    [desktop]: {
      width: `${rem(168)} !important`,
      position: 'absolute',
      top: rem(8),
      right: rem(8),
    },
    [adaptive]: {
      position: 'relative',
    },
  },
}

const ALL_SUBJECTS = SUBJECTS.concat(TOP_SUBJECTS)

const getLocationOrder = (
  location,
  defaultOrder = {
    title: '',
    subject: '',
    toid: uuidv4(),
  },
) => (
  location.state &&
  location.state.from &&
  location.state.from.state &&
  location.state.from.state.order &&
  location.state.from.state.order
) || {...defaultOrder}

const MiniOrderForm = ({
                         classes,
                         className,
                         subjectsClassName,
                         buttonText = 'Get a tutor',
                         placeholder,
                         defaultSubject,
                         top
                       }) => {
  const location = useLocation()
  const history = useHistory()

  const isMobile = utils.useMobile(media.desktop)

  const toid = useState(getLocationOrder(location).toid)[0]
  const [subject, setSubject] = useState(getLocationOrder(location).subject)
  const [submittedForm, setSubmittedForm] = useState(false)
  const [showSubjects, setShowSubjects] = useState(false)

  useEffect(() => {
    if (defaultSubject) {
      setSubject(defaultSubject)
    }
  }, [defaultSubject])

  const validSubject = !!subject.trim() && ALL_SUBJECTS.indexOf(subject) > -1
  const validForm = validSubject

  const setShortcutSubject = subject => {
    setSubject(subject === 'Nursing' ? 'Nursing and Healthcare' : subject)
    trackValues(
      'OrderFormV4',
      top ? 'SubjectShortcutTopSelected' : 'SubjectShortcutBottomSelected',
      {context: {subject}}
    )
  }

  const onSubmit = () => {
    if (validForm) {
      trackValues('OrderFormV4', 'MiniOrderFormStageCompleted', {context: {toid, subject}})
      history.push({
        pathname: '/new-order',
        state: {
          order: {
            toid,
            subject,
          }
        }
      })
    }
  }

  return (
    <>
      <div className={classnames(classes.form, className)}>
        <Input
          className={classes.input}
          placeholder={placeholder}
          value={subject}
          onChange={value => setSubject(value)}
          onFocus={() => setShowSubjects(true)}
          onClickOutside={() => setShowSubjects(false)}
          icon='search'
          error={submittedForm && !validSubject}
        >
          {showSubjects &&
          <SlideUpDropDownAnimation>
            <SubjectsList
              top={isMobile ? 0 : rem(16)}
              search={subject}
              onClick={value => {
                setSubject(value)
                setShowSubjects(false)
              }}
            />
          </SlideUpDropDownAnimation>
          }
        </Input>
        <Button
          type='primary'
          size='large'
          block
          className={classes.submit}
          disabled={!validForm && submittedForm}
          onClick={() => (validForm && onSubmit()) || setSubmittedForm(true)}
        >
          {buttonText}
        </Button>
      </div>
      <Subjects className={subjectsClassName} setSubject={setShortcutSubject}/>
    </>
  )
}

export default injectSheet(styles)(MiniOrderForm)