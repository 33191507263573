import {useEffect} from 'react'

const useOutsideClick = (ref, callback) => {
  const onClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }
  })
}

export default useOutsideClick