import React, {useEffect} from 'react'
import {createUseStyles} from 'react-jss'
import {Modal, Close} from './../../modals'
import {createBuyBalanceTopUpBundleMutation, useBundle} from '../../hooks'
import SelectPaymentMethodAndPay from '../SelectPaymentMethodAndPay'
import {utils} from 'mtuikit'
import {trackValues} from '../../lrtracker'

const BuyBalanceTopUpBundle = ({bundleId, bundleItemId, clickSource, onPaid, onClose, zIndex}) => {
  const classes = useStyle()

  const bundle = useBundle(bundleId)
  const bundleItem = bundle.items.find(({item_id}) => item_id === bundleItemId)

  const {is_active: isActive} = bundle
  const {price} = bundleItem
  const total = price

  const usePayment = createBuyBalanceTopUpBundleMutation({bundleId, bundleItemId})

  useEffect(() => {

    const bundleOfferType = bundle.items
      .map(({price, topup_amount}) => utils.printAmount(price + topup_amount))
      .join(', ')

    trackValues('bundleOffer', 'bundleOfferClick', {
      context: {
        bundle_offer_id: bundleId,
        offer_ddl: bundle.bundle_expire_at,
        bundles_offer_type: bundleOfferType,
        bundle_click_type: utils.printAmount(bundleItem.price),
        stage: 'bundles',
        type: clickSource,
        bundle_item_id: bundleItemId
      }
    })
    // eslint-disable-next-line
  }, [])

  return <Modal className={classes.root} zIndex={zIndex} mobileStickToBottom>
    <Close onClick={onClose}/>
    <img className={classes.icon} src={require(`./img/ic_coin.svg`).default} alt=''/>
    <div className={classes.title}>
      Top up {utils.printAmount(bundleItem.price + bundleItem.topup_amount, {trunc: true})}
    </div>
    <div className={classes.subtitle}>your balance</div>
    <div className={classes.message}>
      <strong>Save {utils.printAmount(bundleItem.topup_amount, {trunc: true})}</strong>
      {' '}on your next task<br/>with this limited-time offer!
    </div>

    <SelectPaymentMethodAndPay
      isActive={isActive}
      price={price}
      total={total}
      balance={0}
      usePayment={usePayment}
      onPaid={onPaid}
    />
  </Modal>
}

const useStyle = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    display: 'block',
    width: 36,
    height: 36,
    //marginTop: [24]
  },
  title: {
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '24px',
    color: '#2F333D',
    marginTop: 12,
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    color: '#565D6B',
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    padding: [20, 16, 24],
    borderBottom: '1px solid #EBEEF5',
  },
  paymentBlock: {
    width: '100%',
    padding: [24, 18]
  },
  paymentTitle: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '22px',
  },
  paymentSubBlock: {
    paddingLeft: 16
  },
  priceBlock: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#939FB8',
    marginTop: 16,
    '& span:last-child': {
      color: '#2F333D',
    },
  },
  balanceBlock: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#565D6B',
  },
  cards: {},
  button: {
    marginTop: 'auto',
    justifyContent: 'center',
    padding: '0 24px!important',
    fontWeight: 700,
    fontSize: 18,
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '&:before': {
      content: '""',
      display: 'block',
      height: 40,
      width: 0,
      borderLeft: '1px solid rgba(255, 255, 255, .2)',
      paddingRight: 16,
    },
  },
  resultBlock: {
    flex: 1,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultImg: {
    display: 'block',
    width: 36,
    height: 36,
  },
  resultTitle: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '32px',
    color: '#2F333D',
    marginTop: 12,
  },
  resultMessage: {
    margin: [12, 0, 24],
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '.15px',
    color: '#2F333D',
  },
  tryButton: {
    width: 240,
  }
})

export default BuyBalanceTopUpBundle
