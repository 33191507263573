import React from 'react'
import {createUseStyles} from 'react-jss'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useOrderQuery, useProfileQuery} from './../../../../hooks'
import {useActions} from './../../../../actions'

const OnboardRequestPhone = ({first_time_client, order_id, data, ...props}) => {
  const classes = useStyles()
  const profileQuery = useProfileQuery()
  const actions = useActions()
  const orderQuery = useOrderQuery(order_id)
  const {data: order} = orderQuery
  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{
      ...data,
      message: first_time_client
        ? profileQuery.data.phone
          ? <>
            Please make sure you phone number is correct. We will notify you as soon as the tutor is connected.<br/>
            <span className={classes.phoneNumber}>{profileQuery.data.phone}</span>{' '}
            <span className={classes.edit}
                  onClick={() => actions.showEditPhone({orderId: order_id, editedThrough: 'ChatClientTutor'})}
            >Edit</span>
          </>
          : <>
            We will notify you via SMS as soon as the tutor is connected. {' '}
            <span className={classes.edit}
                  onClick={() => actions.showEditPhone({orderId: order_id, editedThrough: 'ChatClientTutor'})}
            >Add phone number</span>
          </>
        : <>Great!<br/>
          ⏱ It takes from <span className={classes.strong}>15 minutes</span> up to <span className={classes.strong}>several hours</span> to
          find the best tutor.<br/>

          ⚡ To speed up the process, please <span className={classes.strong}>check your instructions</span> and add
          all information and files that the tutor might need to help you with this task.
          {
            !order.assigned && !order.refunded &&
            <>
              {' '}
              <span className={classes.edit}
                    onClick={() => actions.showAddTaskInstructions(order_id)}>Add instructions</span>
            </>
          }
          <br/>

          {
            profileQuery.data.phone
              ? <>
                🔔 We will text you at <span className={classes.strong}>{profileQuery.data.phone}</span>{' '}
                <span className={classes.edit}
                      onClick={() => actions.showEditPhone({orderId: order_id, editedThrough: 'ChatClientTutor'})}
                >Edit</span>{' '}as soon as the tutor is connected.
              </>
              : <>
                🔔 We will text you as soon as the tutor is connected.{' '}
                <span className={classes.edit}
                      onClick={() => actions.showEditPhone({orderId: order_id, editedThrough: 'ChatClientTutor'})}
                >Add phone number</span>{' '}
              </>
          }

          <br/>
          If you have any questions, just message our{' '}
          <span className={classes.edit}
                onClick={() => actions.showSupportChat()}>support team</span>.
          We will be happy to help you!
        </>
    }}/>
  </MessageWrapper>
}

const useStyles = createUseStyles({
  phoneNumber: {
    fontWeight: 800,
  },
  edit: {
    fontWeight: 700,
    color: '#3D77FF',
  },
  strong: {
    fontWeight: 700,
  },
})

export default OnboardRequestPhone
