import React, { cloneElement, isValidElement } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import {Icon} from 'mtuikit'
import theme, { rem } from './../../theme/base'

const styles = {
  root: {
    fontFamily: theme.fontFamilies.Manrope,
    fontWeight: 600,
    borderRadius: rem(20),
    border: `${rem(2)} solid transparent`,
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none !important',
    paddingLeft: 0,
    paddingRight: 0,
    opacity: 1,
    transition: `background 0.2s linear, border 0.2s linear, opacity 0.2s linear, box-shadow 0.2s linear, color 0.2s linear`,
    '&[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      boxShadow: 'none',
    },
    'a': {
      color: theme.colors.white,
    },
  },
  'type-primary': {
    boxShadow: `0 ${rem(8)} ${rem(16)} 0 rgba(186, 207, 255, 0.23), 0 ${rem(4)} ${rem(8)} 0 rgba(86, 93, 107, 0.1)`,
    color: theme.colors.white,
    backgroundColor: theme.colors.blue,
    '&:hover': {
      backgroundColor: '#366ce6',
      color: theme.colors.white,
    },
    '&:active': {
      backgroundColor: theme.colors.mediumBlue,
    },
    '&$disabled': {
      backgroundColor: theme.colors.lightBlueGrey
    },
  },
  'type-reverse': {
    backgroundColor: theme.colors.white,
    color: theme.colors.blue,
    '&:hover': {
      backgroundColor: theme.colors.white,
    },
    '&:active': {
      backgroundColor: theme.colors.white,
    },
    '&$disabled': {
      backgroundColor: theme.colors.white,
    },
  },
  'type-outline': {
    color: theme.colors.blue,
    backgroundColor: theme.colors.transparent,
    border: `solid ${rem(2)} ${theme.colors.lightBlueGrey}`,
    '&:hover': {
      backgroundColor: theme.colors.paleGrey,
    },
    '&:active': {
      backgroundColor: '#BAC7E2',
      borderColor: '#BAC7E2'
    },
    '&$disabled': {
      color: theme.colors.blueyGrey,
      borderColor: theme.colors.lightBlueGrey50,
      background: theme.colors.transparent
    },
  },
  'type-alert': {
    backgroundColor: '#ed2c39',
    color: theme.colors.white,
  },
  'type-applepay': {
    background: theme.colors.black,
    '& > svg': {
      width: [rem(80), '!important'],
      height: [rem(36), '!important'],
    },
  },
  'type-light': {
    color: '#3D77FF',
    backgroundColor: 'rgba(61, 119, 255, 0.08)',
    '&:hover': {
      color: '#366CE5',
      backgroundColor: 'rgba(61, 119, 255, 0.16)',
    },
    '&:active': {
      color: '#3060CC',
      backgroundColor: 'rgba(61, 119, 255, 0.24)',
    },
    '&$disabled': {
      color: '#939FB8',
      borderColor: '#EBEEF5',
    },
  },
  'type-promo1': {
    display: 'flex',
    background: '#FFEEB8',
    color: '#E37900',
    padding: [0, 16],
    '&:hover': {
      backgroundColor: '#FFE89E',
    },
    '&:active': {
      backgroundColor: '#FFDA85',
    },
  },
  'type-promo2': {
    display: 'flex',
    background: '#F5AB00',
    color: '#1E222B',
    padding: [0, 16],
    '&:hover': {
      backgroundColor: '#EB8D00',
    },
    '&:active': {
      backgroundColor: '#E37900',
    },
  },

  'size-medium': {
    minWidth: rem(102),
    height: rem(44),
    fontSize: rem(14),
    lineHeight: rem(20),
  },
  'size-large': {
    minWidth: rem(102),
    height: rem(56),
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(20),
    lineHeight: rem(54),
    borderRadius: rem(28),
  },
  block: {
    display: 'flex',
    width: '100%',
  },
  disabled: {
    '& svg': {
      display: 'block',
    }
  },
}

const Button = ({
  type,
  size,
  disabled,
  loading,
  children,
  classes,
  buttonType,
  href,
  width,
  className,
  container,
  block,
  style = {},
  isFetching,
  ...other
}) => {
  const resultClassName = classnames(
    classes.root,
    classes[`type-${type}`],
    classes[`size-${size}`],
    className,
    {
      [classes.block]: block,
      [classes.disabled]: disabled,
      [classes.isFetching]: isFetching,
    }
  )

  const resultStyle = {
    width,
    ...style
  }

  const resultProps = {
    ...other,
    style: resultStyle,
    className: resultClassName,
    disabled: disabled || isFetching ? 'disabled' : null
  }

  const resultContainer = isValidElement(container)
    ? (container)
    : href
      ? (<a href={href}> </a>)
      : (<button type={buttonType} />)

  if (type === 'applepay') {
    return (
      <button type={buttonType} {...resultProps}>
        <Icon iconName='applepay' />
      </button>
    )
  }

  return cloneElement(
    resultContainer,
    resultProps,
    isFetching
      ? <img
            src={type === 'outline' ? require('../../images/loader-blue.svg').default
                : type === 'promo1' ? require('../../images/loader-orange.svg').default
                    : type === 'promo2' ? require('../../images/loader-black.svg').default
                        : require('../../images/loader.svg').default
            }
            alt=''
            width={24}
            height={24}
        />
      : children,
  )
}

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['primary', 'reverse', 'outline', 'alert', 'applepay', 'simple', 'promo1', 'promo2', 'light']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  buttonType: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  container: PropTypes.element,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

Button.defaultProps = {
  type: 'primary',
  size: 'medium',
  buttonType: 'button',
}

export default injectSheet(styles)(Button)
