import {theme} from 'mtuikit'

const {
  rem,
  styles,
  colors,
  fontFamilies,
  media,
  adaptive,
  desktop,
  desktop1280,
  centered,
  hideScroll,
  RATE_VALUES,
  RATES,
  ANDROID_HELPER_ID,
  MODAL_WIDTH_DESKTOP,
} = theme

const {clearfix, content, modal, ui, errors, chat} = styles

export {
  rem,
  clearfix,
  content,
  modal,
  ui,
  errors,
  chat,
  media,
  adaptive,
  desktop,
  desktop1280,
  centered,
  hideScroll,
  RATES,
  RATE_VALUES,
  ANDROID_HELPER_ID,
  MODAL_WIDTH_DESKTOP,
}

const fontsAndColors = {
  fontFamilies,
  colors,
}

export default fontsAndColors
