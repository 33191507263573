import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from 'mtuikit'
import theme, { rem } from 'theme/base'
import List from './List'

const styles = {
  root: {
    padding: [rem(8), rem(16), 0],
    position: 'relative',
  },
  title: {
    paddingLeft: rem(28),
    paddingRight: rem(28),
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(40),
    fontWeight: 600,
    position: 'relative',
  },
  icon: {
    fill: theme.colors.charcoalGrey,
    width: rem(24),
    height: rem(24),
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
}

const Block = ({ 
  classes,
  items,
  title,
  icon,
  onClick,
}) =>
  <div className={classes.root}>
    <div className={classes.title}>
      <Icon className={classes.icon} iconName={icon} />
      <span>{title}</span>
    </div>
    <List 
      items={items}
      onClick={onClick} 
    />
  </div>

export default injectSheet(styles)(Block)