import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from '../../../../theme/base'

const styles = {
  root: {
    margin: [rem(40), rem(32), 0, rem(56)],
    color: theme.colors.white,
    opacity: 0.7,
    cursor: 'pointer',
    position: 'relative',
    [desktop]: {
      margin: 0,
      position: 'absolute',
      bottom: rem(32),
      left: rem(56),
      right: rem(32),
    },
  },
}

const Logout = ({ 
  classes,
  onLogout,
}) => 
  <div className={classes.root} onClick={onLogout}>
    Logout
  </div>

export default injectSheet(styles)(Logout)