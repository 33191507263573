import {utils} from 'mtuikit'
import moment from 'moment'
import {SUBJECTS, TOP_SUBJECTS} from './config'

export const getPrice = (size, deadline, balance) => {
  let pricePerHour = 4000
  if (deadline > 6) pricePerHour = 4000
  if (deadline >= 11) pricePerHour = 3500
  if (deadline >= 23) pricePerHour = 3000

  const price = size < 1 ? 0.5 * pricePerHour : size * pricePerHour

  const total = balance > price ? 0 : price - balance
  return {total, price}
}

export const DEADLINE_SET = [6, 12, 24, 48]

export const DEADLINE_PRESETS = ['12 hrs', '24 hrs', '2 days', '3 days']

export const DEADLINE_PRESETS2 = [
  {
    icon: 'ic_ddl_asap',
    title: 'ASAP',
    hours: 6,
    labelType: 'asap',
    labelIcon: 'ic_flash',
    labelMessage: '$40/hr'
  },
  {
    icon: 'ic_ddl_12hrs',
    title: '12 hrs',
    hours: 12,
    labelIcon: 'ic_flash',
    labelMessage: '$35/hr'
  },
  {
    icon: 'ic_ddl_24hrs',
    title: '24 hrs',
    hours: 24,
    labelMessage: '$30/hr'
  },
  {
    icon: 'ic_ddl_2d',
    title: '2 days',
    hours: 48,
    labelMessage: '$30/hr'
  },
]

export const getSizeSet = deadline => {
  let result = []
  for (let i = 1; i <= 50; i++) {
    result.push({
      key: i === 0 ? 0.5 : i,
      value: i === 0 ? 0.5 : i,
      text: `${utils.sizeToHumans(i)} · ${utils.printAmount(getPrice(i, deadline, 0).price)}`,
      textStyled: `<b>${utils.sizeToHumans(i)}</b> · ${utils.printAmount(getPrice(i, deadline, 0).price)}`,
      icon: i === 3
        ? `<img src=${require('./assets/images/most-popular.svg').default} alt='' style='display:inline-block;vertical-align:middle;margin-left:16px;width:140px;height:30px;'}} />`
        : '',
    })
  }
  return result
}

export const OUTPUTS_SET = [
  {text: 'Live session', value: 'live_session'},
  {text: 'Chat', value: 'chat'},
  {text: 'Excel', value: 'excel'},
  {text: 'Code', value: 'code'},
  {text: 'Doc', value: 'doc'},
  {text: 'Presentation', value: 'presentation'},
  {text: 'Other', value: 'other'}
]

const RESTRICTIONS = [6, 6, 6, 6, 6, 6, 6, 6, 6, 18, 18, 24, 24, 48, 48, 48, 48, 48, 48, 48]
const getRestriction = size => Math.min(Math.ceil(size), RESTRICTIONS.length - 1)

export const checkRestriction = (size, deadline) => {
  return deadline < RESTRICTIONS[getRestriction(size)] ? RESTRICTIONS[getRestriction(size)] : null
}

export const getMinDeadline = size => RESTRICTIONS[getRestriction(size)]

export const ORDER = {
  STATE: {
    NEW: 'new',
    DRAFT: 'draft',
  },
  STAGES: {
    NEW: {
      TYPE: 0,
      DETAILS: 1,
      CALCULATE: 2,
      CHECKOUT: 3
    },
    DRAFT: {
      TYPE: 0,
      DETAILS: 1,
      CALCULATE: 2,
      CHECKOUT: 3
    }
  }
}

export const getOrderChatExtendDatetime = order =>
  order.closed
    ? moment().add(48, 'h').toISOString()
    : null

export const isDoneOrder = order => order.closed || order.refunded

export const isFinalizedOrder = order =>
  (order.closed && (!order.chat_extended_to || moment() > moment(order.chat_extended_to)))
  || order.refunded
  || !order.paid
  || !order.jabber_settings

export const extractOrderChatConfig = (order, profile) => ({
  username: profile.jabber_jid,
  node: order.jabber_settings.jabber_node,
  to: order.jabber_settings.jabber_server
})

export const getIsTopTutorAddonActive = order =>
  !!(order.addons && order.addons.find(({tag, is_active}) => tag === 'top_writer' && is_active))

const ONBOARD_AVAILABLE_FROM = 1669028294247 - (14 * 24 * 3600 * 1000)
export const isOnboardCanStart = order =>
  !order.meta_data.onboard_started_at && moment().valueOf() > ONBOARD_AVAILABLE_FROM && !order.assigned && !order.connected && !order.closed

export const TOP_TUTOR_ADDON_AVAILABLE_FROM = 1669028294247

const ALL_SUBJECTS = [...SUBJECTS, ...TOP_SUBJECTS]
export const validateSubject = subject => ALL_SUBJECTS.indexOf(subject) > -1