import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem } from './../../../theme/base'
import CloseIcon from './CloseIcon'

const styles = {
  root: {
    width: rem(256),
    padding: rem(12),
    whiteSpace: 'normal',
    cursor: 'pointer',

    background: '#EBEFFA',
    '&:hover': {
      background: '#C8D9FF',
    },
    height: 'auto',
    borderRadius: rem(8),

    // RIGHT
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: [rem(6), 'solid', 'transparent'],
      borderBottom: [rem(6), 'solid', 'transparent'],
      borderRight: [rem(6), 'solid', '#EBEFFA'],
      position: 'absolute',
      right: '100%',
      top: rem(20),
      display: 'block',
    },
    '&:hover:after': {
      borderRight: [rem(6), 'solid', '#C8D9FF'],
    }

  },
  title: {
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    fontFamily: theme.fontFamilies.Manrope,
  },
  text: {
    margin: [rem(2), 0],
    fontSize: rem(13),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    paddingRight: rem(8)
  },
  link: {
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.blue,
    fontFamily: theme.fontFamilies.Manrope,
  },
}

const Banner = ({
  title,
  text,
  classes,
  className,
  onClick,
  onClose,
  innnerRef
}) =>
  <div
    className={classnames(classes.root, className)}
    ref={innnerRef}
  >
    <CloseIcon onClick={onClose} />
    <div onClick={onClick}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.text}>{text}</div>
      <div className={classes.link}>Contact support</div>
    </div>
  </div>

export default injectSheet(styles)(Banner)