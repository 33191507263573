import React from 'react'
import {Helmet} from 'react-helmet'
import injectSheet from 'react-jss'
import theme from './../theme/base'
import Hero from './sections/Hero'
import HowItWorks from './sections/HowItWorks'
import Payment from './sections/Payment'
import Examples from './sections/Examples'
import Review from './sections/Review'
import Cta from './sections/Cta'
import Faq from './sections/Faq'
import Footer from './sections/Footer'
import useContent from './seo'

const styles = {
  root: {
    background: theme.colors.white,
  },
}

const Landing = ({classes}) => {
  const {
    title,
    description,
    keywords,
    hero,
    howItWorks,
    examples,
    payment,
    cta,
    faq,
    footer,
  } = useContent()
  return <>
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description}/>
      <meta name='keywords' content={keywords}/>
    </Helmet>
    <div className={classes.root}>
      <Hero {...hero} showLogin/>
      <HowItWorks {...howItWorks} />
      <Examples {...examples} />
      <Payment {...payment} />
      <Review/>
      <Cta {...cta} />
      <Faq {...faq} />
      <Footer {...footer} />
    </div>
  </>
}

export default injectSheet(styles)(Landing)