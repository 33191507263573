import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import classnames from 'classnames'
import {Collapse} from 'react-collapse'
import {rem} from '../../../mtuikit/theme/base'


const Collapsable = ({title, content, open: open_ = false}) => {
  const [open, setOpen] = useState(open_)
  const classes = useStyle()
  return <div className={classes.root}>
    <div className={classnames(classes.header, {open})} onClick={() => setOpen(!open)}>
      <div className={classes.title}>{title}</div>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99973 9.58647L13.1335 4.45273C13.7423 3.84388 14.7294 3.84388 15.3383 4.45273C15.9471 5.06157 15.9471 6.0487 15.3383 6.65754L7.99973 13.9961L0.661178 6.65754C0.0523357 6.0487 0.0523357 5.06157 0.661178 4.45273C1.27002 3.84388 2.25715 3.84388 2.86599 4.45273L7.99973 9.58647Z"
          fill="#939FB8"/>
      </svg>
    </div>
    <Collapse isOpened={open}>{content}</Collapse>
  </div>
}

const useStyle = createUseStyles({
  root: {
    padding: [rem(15), 0],
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& + div': {
      transition: 'height 500ms',
    },
    '& svg': {
      transform: 'rotate(-90deg)',
      transition: 'transform 300ms linear',
    },
    '&.open svg': {
      transform: 'rotate(0deg)',
    }
  },
  title: {
    fontWeight: 800,
    fontSize: rem(14),
    lineHeight: rem(20),
    color: '#2F333D',
  }
})


export default Collapsable
