import React from 'react'
import injectSheet from 'react-jss'

import { rem, ui, adaptive } from '../../theme/base'

const styles = {
  root: {
    ...ui.subtitle,
    marginTop: rem(64),
    lineHeight: rem(24),
    [adaptive]: {
      marginTop: rem(40),
      ...ui.subtitle[adaptive],
      lineHeight: rem(24),
    },
  },
}

const Subtitle = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Subtitle)