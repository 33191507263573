import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'
import {Icon} from '../../mtuikit/components/Icon'

const styles = {
  root: {
    marginTop: rem(8),
    // paddingRight: rem(56),
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
    color: theme.colors.slateGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'block',
    '& svg': {
      width: rem(16),
      height: rem(16),
      marginTop: rem(3),
      marginLeft: rem(4),
      marginRight: rem(3),
      display: 'inline-block',
      verticalAlign: 'top',
      fill: theme.colors.star,
    }
  },
  mark: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
}

const Rate = ({
  value,
  children,
  classes,
}) =>
  <div className={classes.root}>
    <div className={classes.mark}>
      {value}
      <Icon iconName='star' />
    </div>
    {children}
  </div>

export default injectSheet(styles)(Rate)
