import React from 'react'
import injectSheet from 'react-jss'
import PhoneInput from 'react-phone-input-2'
import theme, {rem} from './../../theme/base'

const styles = {
  root: {
    '& .react-tel-input': {
      width: '100%',
      position: 'relative',
      '&:-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-text-fill-color': {
        color: theme.colors.blueyGrey,
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
      '& .flag': {
        width: rem(16),
        height: rem(11),
        background: `url(${require('./flags.png').default})`,
        backgroundRepeat: 'no-repeat',
      },
      '& .ad': {backgroundPosition: [rem(-16), 0]},
      '& .ae': {backgroundPosition: [rem(-32), 0]},
      '& .af': {backgroundPosition: [rem(-48), 0]},
      '& .ag': {backgroundPosition: [rem(-64), 0]},
      '& .ai': {backgroundPosition: [rem(-80), 0]},
      '& .al': {backgroundPosition: [rem(-96), 0]},
      '& .am': {backgroundPosition: [rem(-112), 0]},
      '& .ao': {backgroundPosition: [rem(-128), 0]},
      '& .ar': {backgroundPosition: [rem(-144), 0]},
      '& .as': {backgroundPosition: [rem(-160), 0]},
      '& .at': {backgroundPosition: [rem(-176), 0]},
      '& .au': {backgroundPosition: [rem(-192), 0]},
      '& .aw': {backgroundPosition: [rem(-208), 0]},
      '& .az': {backgroundPosition: [rem(-224), 0]},
      '& .ba': {backgroundPosition: [rem(-240), 0]},
      '& .bb': {backgroundPosition: [0, rem(-11)]},
      '& .bd': {backgroundPosition: [rem(-16), rem(-11)]},
      '& .be': {backgroundPosition: [rem(-32), rem(-11)]},
      '& .bf': {backgroundPosition: [rem(-48), rem(-11)]},
      '& .bg': {backgroundPosition: [rem(-64), rem(-11)]},
      '& .bh': {backgroundPosition: [rem(-80), rem(-11)]},
      '& .bi': {backgroundPosition: [rem(-96), rem(-11)]},
      '& .bj': {backgroundPosition: [rem(-112), rem(-11)]},
      '& .bm': {backgroundPosition: [rem(-128), rem(-11)]},
      '& .bn': {backgroundPosition: [rem(-144), rem(-11)]},
      '& .bo': {backgroundPosition: [rem(-160), rem(-11)]},
      '& .br': {backgroundPosition: [rem(-176), rem(-11)]},
      '& .bs': {backgroundPosition: [rem(-192), rem(-11)]},
      '& .bt': {backgroundPosition: [rem(-208), rem(-11)]},
      '& .bw': {backgroundPosition: [rem(-224), rem(-11)]},
      '& .by': {backgroundPosition: [rem(-240), rem(-11)]},
      '& .bz': {backgroundPosition: [0, rem(-22)]},
      '& .ca': {backgroundPosition: [rem(-16), rem(-22)]},
      '& .cd': {backgroundPosition: [rem(-32), rem(-22)]},
      '& .cf': {backgroundPosition: [rem(-48), rem(-22)]},
      '& .cg': {backgroundPosition: [rem(-64), rem(-22)]},
      '& .ch': {backgroundPosition: [rem(-80), rem(-22)]},
      '& .ci': {backgroundPosition: [rem(-96), rem(-22)]},
      '& .ck': {backgroundPosition: [rem(-112), rem(-22)]},
      '& .cl': {backgroundPosition: [rem(-128), rem(-22)]},
      '& .cm': {backgroundPosition: [rem(-144), rem(-22)]},
      '& .cn': {backgroundPosition: [rem(-160), rem(-22)]},
      '& .co': {backgroundPosition: [rem(-176), rem(-22)]},
      '& .cr': {backgroundPosition: [rem(-192), rem(-22)]},
      '& .cu': {backgroundPosition: [rem(-208), rem(-22)]},
      '& .cv': {backgroundPosition: [rem(-224), rem(-22)]},
      '& .cw': {backgroundPosition: [rem(-240), rem(-22)]},
      '& .cy': {backgroundPosition: [0, rem(-33)]},
      '& .cz': {backgroundPosition: [rem(-16), rem(-33)]},
      '& .de': {backgroundPosition: [rem(-32), rem(-33)]},
      '& .dj': {backgroundPosition: [rem(-48), rem(-33)]},
      '& .dk': {backgroundPosition: [rem(-64), rem(-33)]},
      '& .dm': {backgroundPosition: [rem(-80), rem(-33)]},
      '& .do': {backgroundPosition: [rem(-96), rem(-33)]},
      '& .dz': {backgroundPosition: [rem(-112), rem(-33)]},
      '& .ec': {backgroundPosition: [rem(-128), rem(-33)]},
      '& .ee': {backgroundPosition: [rem(-144), rem(-33)]},
      '& .eg': {backgroundPosition: [rem(-160), rem(-33)]},
      '& .er': {backgroundPosition: [rem(-176), rem(-33)]},
      '& .es': {backgroundPosition: [rem(-192), rem(-33)]},
      '& .et': {backgroundPosition: [rem(-208), rem(-33)]},
      '& .fi': {backgroundPosition: [rem(-224), rem(-33)]},
      '& .fj': {backgroundPosition: [rem(-240), rem(-33)]},
      '& .fk': {backgroundPosition: [0, rem(-44)]},
      '& .fm': {backgroundPosition: [rem(-16), rem(-44)]},
      '& .fo': {backgroundPosition: [rem(-32), rem(-44)]},
      '& .fr, .bl, .mf': {backgroundPosition: [rem(-48), rem(-44)]},
      '& .ga': {backgroundPosition: [rem(-64), rem(-44)]},
      '& .gb': {backgroundPosition: [rem(-80), rem(-44)]},
      '& .gd': {backgroundPosition: [rem(-96), rem(-44)]},
      '& .ge': {backgroundPosition: [rem(-112), rem(-44)]},
      '& .gf': {backgroundPosition: [rem(-128), rem(-44)]},
      '& .gh': {backgroundPosition: [rem(-144), rem(-44)]},
      '& .gi': {backgroundPosition: [rem(-160), rem(-44)]},
      '& .gl': {backgroundPosition: [rem(-176), rem(-44)]},
      '& .gm': {backgroundPosition: [rem(-192), rem(-44)]},
      '& .gn': {backgroundPosition: [rem(-208), rem(-44)]},
      '& .gp': {backgroundPosition: [rem(-224), rem(-44)]},
      '& .gq': {backgroundPosition: [rem(-240), rem(-44)]},
      '& .gr': {backgroundPosition: [0, rem(-55)]},
      '& .gt': {backgroundPosition: [rem(-16), rem(-55)]},
      '& .gu': {backgroundPosition: [rem(-32), rem(-55)]},
      '& .gw': {backgroundPosition: [rem(-48), rem(-55)]},
      '& .gy': {backgroundPosition: [rem(-64), rem(-55)]},
      '& .hk': {backgroundPosition: [rem(-80), rem(-55)]},
      '& .hn': {backgroundPosition: [rem(-96), rem(-55)]},
      '& .hr': {backgroundPosition: [rem(-112), rem(-55)]},
      '& .ht': {backgroundPosition: [rem(-128), rem(-55)]},
      '& .hu': {backgroundPosition: [rem(-144), rem(-55)]},
      '& .id': {backgroundPosition: [rem(-160), rem(-55)]},
      '& .ie': {backgroundPosition: [rem(-176), rem(-55)]},
      '& .il': {backgroundPosition: [rem(-192), rem(-55)]},
      '& .in': {backgroundPosition: [rem(-208), rem(-55)]},
      '& .io': {backgroundPosition: [rem(-224), rem(-55)]},
      '& .iq': {backgroundPosition: [rem(-240), rem(-55)]},
      '& .ir': {backgroundPosition: [0, rem(-66)]},
      '& .is': {backgroundPosition: [rem(-16), rem(-66)]},
      '& .it': {backgroundPosition: [rem(-32), rem(-66)]},
      '& .jm': {backgroundPosition: [rem(-48), rem(-66)]},
      '& .jo': {backgroundPosition: [rem(-64), rem(-66)]},
      '& .jp': {backgroundPosition: [rem(-80), rem(-66)]},
      '& .ke': {backgroundPosition: [rem(-96), rem(-66)]},
      '& .kg': {backgroundPosition: [rem(-112), rem(-66)]},
      '& .kh': {backgroundPosition: [rem(-128), rem(-66)]},
      '& .ki': {backgroundPosition: [rem(-144), rem(-66)]},
      '& .xk': {backgroundPosition: [rem(-128), rem(-154)]},
      '& .km': {backgroundPosition: [rem(-160), rem(-66)]},
      '& .kn': {backgroundPosition: [rem(-176), rem(-66)]},
      '& .kp': {backgroundPosition: [rem(-192), rem(-66)]},
      '& .kr': {backgroundPosition: [rem(-208), rem(-66)]},
      '& .kw': {backgroundPosition: [rem(-224), rem(-66)]},
      '& .ky': {backgroundPosition: [rem(-240), rem(-66)]},
      '& .kz': {backgroundPosition: [0, rem(-77)]},
      '& .la': {backgroundPosition: [rem(-16), rem(-77)]},
      '& .lb': {backgroundPosition: [rem(-32), rem(-77)]},
      '& .lc': {backgroundPosition: [rem(-48), rem(-77)]},
      '& .li': {backgroundPosition: [rem(-64), rem(-77)]},
      '& .lk': {backgroundPosition: [rem(-80), rem(-77)]},
      '& .lr': {backgroundPosition: [rem(-96), rem(-77)]},
      '& .ls': {backgroundPosition: [rem(-112), rem(-77)]},
      '& .lt': {backgroundPosition: [rem(-128), rem(-77)]},
      '& .lu': {backgroundPosition: [rem(-144), rem(-77)]},
      '& .lv': {backgroundPosition: [rem(-160), rem(-77)]},
      '& .ly': {backgroundPosition: [rem(-176), rem(-77)]},
      '& .ma': {backgroundPosition: [rem(-192), rem(-77)]},
      '& .mc': {backgroundPosition: [rem(-208), rem(-77)]},
      '& .md': {backgroundPosition: [rem(-224), rem(-77)]},
      '& .me': {backgroundPosition: [rem(-112), rem(-154)], height: rem(12)},
      '& .mg': {backgroundPosition: [0, rem(-88)]},
      '& .mh': {backgroundPosition: [rem(-16), rem(-88)]},
      '& .mk': {backgroundPosition: [rem(-32), rem(-88)]},
      '& .ml': {backgroundPosition: [rem(-48), rem(-88)]},
      '& .mm': {backgroundPosition: [rem(-64), rem(-88)]},
      '& .mn': {backgroundPosition: [rem(-80), rem(-88)]},
      '& .mo': {backgroundPosition: [rem(-96), rem(-88)]},
      '& .mp': {backgroundPosition: [rem(-112), rem(-88)]},
      '& .mq': {backgroundPosition: [rem(-128), rem(-88)]},
      '& .mr': {backgroundPosition: [rem(-144), rem(-88)]},
      '& .ms': {backgroundPosition: [rem(-160), rem(-88)]},
      '& .mt': {backgroundPosition: [rem(-176), rem(-88)]},
      '& .mu': {backgroundPosition: [rem(-192), rem(-88)]},
      '& .mv': {backgroundPosition: [rem(-208), rem(-88)]},
      '& .mw': {backgroundPosition: [rem(-224), rem(-88)]},
      '& .mx': {backgroundPosition: [rem(-240), rem(-88)]},
      '& .my': {backgroundPosition: [0, rem(-99)]},
      '& .mz': {backgroundPosition: [rem(-16), rem(-99)]},
      '& .na': {backgroundPosition: [rem(-32), rem(-99)]},
      '& .nc': {backgroundPosition: [rem(-48), rem(-99)]},
      '& .ne': {backgroundPosition: [rem(-64), rem(-99)]},
      '& .nf': {backgroundPosition: [rem(-80), rem(-99)]},
      '& .ng': {backgroundPosition: [rem(-96), rem(-99)]},
      '& .ni': {backgroundPosition: [rem(-112), rem(-99)]},
      '& .nl, .bq': {backgroundPosition: [rem(-128), rem(-99)]},
      '& .no': {backgroundPosition: [rem(-144), rem(-99)]},
      '& .np': {backgroundPosition: [rem(-160), rem(-99)]},
      '& .nr': {backgroundPosition: [rem(-176), rem(-99)]},
      '& .nu': {backgroundPosition: [rem(-192), rem(-99)]},
      '& .nz': {backgroundPosition: [rem(-208), rem(-99)]},
      '& .om': {backgroundPosition: [rem(-224), rem(-99)]},
      '& .pa': {backgroundPosition: [rem(-240), rem(-99)]},
      '& .pe': {backgroundPosition: [0, rem(-110)]},
      '& .pf': {backgroundPosition: [rem(-16), rem(-110)]},
      '& .pg': {backgroundPosition: [rem(-32), rem(-110)]},
      '& .ph': {backgroundPosition: [rem(-48), rem(-110)]},
      '& .pk': {backgroundPosition: [rem(-64), rem(-110)]},
      '& .pl': {backgroundPosition: [rem(-80), rem(-110)]},
      '& .pm': {backgroundPosition: [rem(-96), rem(-110)]},
      '& .pr': {backgroundPosition: [rem(-112), rem(-110)]},
      '& .ps': {backgroundPosition: [rem(-128), rem(-110)]},
      '& .pt': {backgroundPosition: [rem(-144), rem(-110)]},
      '& .pw': {backgroundPosition: [rem(-160), rem(-110)]},
      '& .py': {backgroundPosition: [rem(-176), rem(-110)]},
      '& .qa': {backgroundPosition: [rem(-192), rem(-110)]},
      '& .re': {backgroundPosition: [rem(-208), rem(-110)]},
      '& .ro': {backgroundPosition: [rem(-224), rem(-110)]},
      '& .rs': {backgroundPosition: [rem(-240), rem(-110)]},
      '& .ru': {backgroundPosition: [0, rem(-121)]},
      '& .rw': {backgroundPosition: [rem(-16), rem(-121)]},
      '& .sa': {backgroundPosition: [rem(-32), rem(-121)]},
      '& .sb': {backgroundPosition: [rem(-48), rem(-121)]},
      '& .sc': {backgroundPosition: [rem(-64), rem(-121)]},
      '& .sd': {backgroundPosition: [rem(-80), rem(-121)]},
      '& .se': {backgroundPosition: [rem(-96), rem(-121)]},
      '& .sg': {backgroundPosition: [rem(-112), rem(-121)]},
      '& .sh': {backgroundPosition: [rem(-128), rem(-121)]},
      '& .si': {backgroundPosition: [rem(-144), rem(-121)]},
      '& .sk': {backgroundPosition: [rem(-160), rem(-121)]},
      '& .sl': {backgroundPosition: [rem(-176), rem(-121)]},
      '& .sm': {backgroundPosition: [rem(-192), rem(-121)]},
      '& .sn': {backgroundPosition: [rem(-208), rem(-121)]},
      '& .so': {backgroundPosition: [rem(-224), rem(-121)]},
      '& .sr': {backgroundPosition: [rem(-240), rem(-121)]},
      '& .ss': {backgroundPosition: [0, rem(-132)]},
      '& .st': {backgroundPosition: [rem(-16), rem(-132)]},
      '& .sv': {backgroundPosition: [rem(-32), rem(-132)]},
      '& .sx': {backgroundPosition: [rem(-48), rem(-132)]},
      '& .sy': {backgroundPosition: [rem(-64), rem(-132)]},
      '& .sz': {backgroundPosition: [rem(-80), rem(-132)]},
      '& .tc': {backgroundPosition: [rem(-96), rem(-132)]},
      '& .td': {backgroundPosition: [rem(-112), rem(-132)]},
      '& .tg': {backgroundPosition: [rem(-128), rem(-132)]},
      '& .th': {backgroundPosition: [rem(-144), rem(-132)]},
      '& .tj': {backgroundPosition: [rem(-160), rem(-132)]},
      '& .tk': {backgroundPosition: [rem(-176), rem(-132)]},
      '& .tl': {backgroundPosition: [rem(-192), rem(-132)]},
      '& .tm': {backgroundPosition: [rem(-208), rem(-132)]},
      '& .tn': {backgroundPosition: [rem(-224), rem(-132)]},
      '& .to': {backgroundPosition: [rem(-240), rem(-132)]},
      '& .tr': {backgroundPosition: [0, rem(-143)]},
      '& .tt': {backgroundPosition: [rem(-16), rem(-143)]},
      '& .tv': {backgroundPosition: [rem(-32), rem(-143)]},
      '& .tw': {backgroundPosition: [rem(-48), rem(-143)]},
      '& .tz': {backgroundPosition: [rem(-64), rem(-143)]},
      '& .ua': {backgroundPosition: [rem(-80), rem(-143)]},
      '& .ug': {backgroundPosition: [rem(-96), rem(-143)]},
      '& .us': {backgroundPosition: [rem(-112), rem(-143)]},
      '& .uy': {backgroundPosition: [rem(-128), rem(-143)]},
      '& .uz': {backgroundPosition: [rem(-144), rem(-143)]},
      '& .va': {backgroundPosition: [rem(-160), rem(-143)]},
      '& .vc': {backgroundPosition: [rem(-176), rem(-143)]},
      '& .ve': {backgroundPosition: [rem(-192), rem(-143)]},
      '& .vg': {backgroundPosition: [rem(-208), rem(-143)]},
      '& .vi': {backgroundPosition: [rem(-224), rem(-143)]},
      '& .vn': {backgroundPosition: [rem(-240), rem(-143)]},
      '& .vu': {backgroundPosition: [0, rem(-154)]},
      '& .wf': {backgroundPosition: [rem(-16), rem(-154)]},
      '& .ws': {backgroundPosition: [rem(-32), rem(-154)]},
      '& .ye': {backgroundPosition: [rem(-48), rem(-154)]},
      '& .za': {backgroundPosition: [rem(-64), rem(-154)]},
      '& .zm': {backgroundPosition: [rem(-80), rem(-154)]},
      '& .zw': {backgroundPosition: [rem(-96), rem(-154)]},
      '& *': {
        boxSizing: 'border-box',
        '-moz-box-sizing': 'border-box',
      },
      '& .hide': {
        display: 'none',
      },
      '& .v-hide': {
        visibility: 'hidden',
      },
      '& .form-control': {
        position: 'relative',
        zIndex: 0,
        margin: 0,
        paddingLeft: rem(72),
        paddingRight: rem(16),
        background: theme.colors.white,
        border: [rem(1), 'solid', '#BAC7E2'],
        borderRadius: rem(28),
        height: rem(56),
        outline: 'none',
        fontSize: rem(24),
        lineHeight: rem(32),
        color: theme.colors.charcoalGrey,
        textAlign: 'left',
        transition: 'border 300ms ease',
        wordBreak: 'normal',
        fontFamily: 'arial',
        '&:focus': {
          boxShadow: 'none',
          borderColor: theme.colors.blueyGrey,
          '& + .flag-dropdown': {
            '&:before': {
              borderColor: theme.colors.blueyGrey,
            },
          },
        },
        '&[disabled]': {
          width: '100%',
          height: 'auto',
          padding: 0,
          border: 'none',
          borderRadius: 0,
          '& + .flag-dropdown': {
            display: 'none',
          },
        },
        '&.invalid-number': {
          // border: [rem(1), 'solid', '#d79f9f'],
          // backgroundColor: '#FAF0F0',
          // borderLeftColor: theme.colors.blueyGrey,
          // '&:focus': {
          //   border: [rem(1),'solid', '#d79f9f'],
          //   borderLeftColor: theme.colors.blueyGrey,
          //   backgroundColor: '#FAF0F0',
          // }
        },
        '&.open': {
          borderColor: theme.colors.blueyGrey,
          zIndex: 3,
          '& + .flag-dropdown': {
            '&:before': {
              borderColor: theme.colors.blueyGrey,
            },
          },
        },
      },
      '& .flag-dropdown': {
        width: rem(56),
        padding: 0,
        borderRadius: [rem(28), 0, 0, rem(28)],
        position: 'absolute',
        top: 0,
        bottom: 0,
        outline: 'none',
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: rem(56),
          left: 0,
          top: 0,
          bottom: 0,
          borderRight: [rem(1), 'solid', '#BAC7E2'],
          borderRadius: [rem(28), 0, 0, rem(28)],
          transition: 'border 300ms ease',
        },
        '&.open': {
          background: 'transparent',
          width: '100%',
          borderRadius: [rem(28), 0, 0, 0],
          '&:before': {
            backgroundColor: 'rgba(186, 199, 226, 0.3)',
            borderColor: theme.colors.blueyGrey,
            zIndex: 4,
          },
        },
        '&:hover, &:focus': {
          cursor: 'pointer',
        },
      },
      '& input[disabled]': {
        '& +.flag-dropdown': {
          '&:hover': {
            cursor: 'default',
            '& .selected-flag': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      '& .selected-flag': {
        position: 'relative',
        width: rem(49),
        height: '100%',
        padding: [0, 0, 0, rem(8)],
        borderRadius: [rem(3), 0, 0, rem(3)],
        '&.open': {
          borderRadius: [rem(28), 0, 0, rem(28)],
          zIndex: 4,
        },
        '& .flag': {
          position: 'absolute',
          top: rem(23),
          left: rem(20),
        },
        '& .arrow': {
          position: 'absolute',
          top: rem(4),
          left: rem(20),
          width: 0,
          height: 0,
          borderLeft: [rem(3), 'solid', 'transparent'],
          borderRight: [rem(3), 'solid', 'transparent'],
          borderTop: [rem(4), 'solid', '#BAC7E2'],
          '&.up': {
            borderTop: 'none',
            borderBottom: [rem(4), 'solid', '#BAC7E2'],
          },
        },
      },
      '& .country-list': {
        backgroundColor: theme.colors.white,
        width: '100%',
        maxHeight: rem(216),
        margin: [rem(10), 0, rem(10), rem(-1)],
        padding: [rem(8), 0],
        listStyle: 'none',
        position: 'absolute',
        boxShadow: `
          0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15),
          0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06)
        `,
        borderRadius: rem(8),
        textAlign: 'left',
        zIndex: 1,
        overflowY: 'auto',
        '& .flag': {
          display: 'inline-block',
          position: 'absolute',
          left: rem(20),
          top: rem(17),
        },
        '& .divider': {
          paddingBottom: rem(5),
          marginBottom: rem(5),
          borderBottom: [rem(1), 'solid', '#ccc'],
        },
        '& .country': {
          color: theme.colors.charcoalGrey,
          fontSize: rem(17),
          lineHeight: rem(24),
          padding: [rem(11), rem(80), rem(11), rem(52)],
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          position: 'relative',
          '& .dial-code': {
            padding: [rem(11), rem(28), rem(11), 0],
            position: 'absolute',
            top: 0,
            right: 0,
            color: theme.colors.blueyGrey,
          },
          '&:hover': {
            backgroundColor: '#f1f1f1',
          },
          '&.highlight': {
            backgroundColor: '#f1f1f1',
          },
        },
        '& .country-name': {
          position: 'relative',
        },
        '& .search': {
          zIndex: 2,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.colors.white,
          padding: [rem(10), 0, rem(6), rem(10)],
        },
        '& .search-emoji': {
          fontSize: rem(15),
        },
        '& .search-box': {
          border: [rem(1), 'solid', theme.colors.blueyGrey],
          borderRadius: rem(3),
          fontSize: rem(15),
          lineHeight: rem(15),
          marginLeft: rem(6),
          padding: [rem(3), rem(8), rem(5)],
          outline: 'none',
        },
        '& .no-entries-message': {
          padding: [rem(7), rem(10), rem(11)],
          opacity: .7,
        },
      },
    },
  }
}

const InputPhone = ({
                      classes,
                      id,
                      name,
                      country,
                      value,
                      placeholder,
                      inputProps,
                      onChange,
                      ...rest
                    }) => {
  return <div className={classes.root}>
    <PhoneInput
      id={id}
      name={name}
      type="tel"
      country={country}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      inputProps={{...inputProps, ...rest}}
      specialLabel={null}
    />
  </div>
}

export default injectSheet(styles)(InputPhone)