import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../mtuikit/theme/base'

const styles = {
  root: {
    marginTop: rem(16),
    marginLeft: rem(8),
    marginRight: rem(8),
    fontSize: rem(15),
    lineHeight: rem(20),
    letterSpacing: rem(0.09),
    textAlign: 'center',
    color: theme.colors.slateGreyTwo,
  },
  line: {
    '& + &': {
      marginTop: rem(20),
    },
  },
}

const Details = ({
  classes,
  details,
}) =>
  <div className={classes.root}>
    {details.map(line => {
      switch (typeof line) {
        case 'string':
          return <div key={line} className={classes.line} dangerouslySetInnerHTML={{ __html: line }} />
        case 'function':
          return <div key={line} className={classes.line}>{line()}</div>
        default:
          return null
      }
    })}
  </div>

export default injectSheet(styles)(Details)