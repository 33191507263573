import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import { rem, adaptive } from '../../../theme/base'

const styles = {
  root: {
    textAlign: 'center',
    position: 'relative',
  },
  item: {
    margin: [0, rem(20)],
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      margin: [rem(24), rem(8), 0],
    },
    '& > img': {
      display: 'block',
    },
  },
}

const LOGOS = ['logo1', 'logo2', 'logo3', 'logo4', 'logo5', 'logo6', 'logo7', 'logo8']

const Logos = ({
  classes,
  className,
}) =>
  <div className={classnames(classes.root, className)}>
    {LOGOS.map((logo, index) =>
      <div className={classes.item} key={index}>
        <img src={require(`./${logo}.svg`).default} alt='' />
      </div>
    )}
  </div>

export default injectSheet(styles)(Logos)