import React from 'react'
import injectSheet from 'react-jss'
import {rem} from '../../mtuikit/theme/base'

const styles = {
  root: {
    padding: rem('24 16'),
  },
}

const Footer = ({
                  children,
                  classes
                }) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Footer)

