import getTracker from '@verdaccio/lrtrack-js'
import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

const tracker = getTracker({
  trackerId: process.env.REACT_APP_LR_TRACK_ID,
  baseUrl: process.env.REACT_APP_LR_TRACK_URL
})

const useTrackValues = tracker.metrics.getUseTrackValues({useEffect});
const trackClicks = tracker.metrics.trackClicks;
const trackValues = tracker.metrics.trackValues;


const TrackLanding = () => {
  const prevLocation = useRef(null)
  const location = useLocation()

  useEffect(() => {
    const curLocation = window.location.href
    if (prevLocation.current !== curLocation) {
      trackValues('stage', 'landing', {
        context: {
          moved_from: prevLocation.current,
        },
      })
      prevLocation.current = curLocation
    }
  }, [location])

  return null
}

export {
  tracker as default,
  useTrackValues,
  trackClicks,
  trackValues,
  TrackLanding
}
