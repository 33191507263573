import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  root: {
    flexGrow: 1,
  },
}

const Helper = ({
                  classes,
                  className,
                }) => <div className={classnames(classes.root, className)}/>

export default injectSheet(styles)(Helper)
