import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from 'mtuikit'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(16),
    left: rem(16),
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        fill: '#BDD0FC',
      },
    },
  },
}

const Close = ({ classes, onClose }) => 
  <div className={classes.root} onClick={onClose}>
    <Icon iconName='close' />
  </div>

export default injectSheet(styles)(Close)