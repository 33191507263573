import React from 'react'
import Image from './Image'
import {getUserAvatarUrl} from '../../mtuikit/utils/getUserAvatarUrl'
import {useIsBlockedUser, useIsFavoriteUser, useIsOnlineUser} from "../../hooks";

export const Avatar = ({userId}) => {
  const isOnlineTutor = useIsOnlineUser(userId)
  const isFavoriteTutor = useIsFavoriteUser(userId)
  const isBlockedTutor = useIsBlockedUser(userId)
  return <Image
    src={getUserAvatarUrl(userId)}
    online={isOnlineTutor}
    favorite={isFavoriteTutor}
    block={isBlockedTutor}
  />
}