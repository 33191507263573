import Card from './Card'
import Image from './Image'
import {Avatar} from './Avatar'
import Title from './Title'
import Label from './Label'
import Counter from './Counter'
import Time from './Time'
import Text from './Text'
import Rate from './Rate'
import RateText from './RateText'
import Rating from './Rating'
import Rated from './Rated'
import Status from './Status'
import Subject from './Subject'
import Link from './Link'
import Divider from './Divider'
import Helped from './Helped'
import Buttons from './Buttons'
import Button from './Button'
import Dots from './Dots'
import TypeDivider from './TypeDivider'
import TypeIcon from './TypeIcon'

Card.Image = Image
Card.Avatar = Avatar
Card.Title = Title
Card.Label = Label
Card.Counter = Counter
Card.Time = Time
Card.Text = Text
Card.Rate = Rate
Card.RateText = RateText
Card.Rating = Rating
Card.Rated = Rated
Card.Status = Status
Card.Subject = Subject
Card.Link = Link
Card.Divider = Divider
Card.Helped = Helped
Card.Buttons = Buttons
Card.Button = Button
Card.Dots = Dots
Card.TypeDivider = TypeDivider
Card.TypeIcon = TypeIcon

export default Card