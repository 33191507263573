import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from '../../mtuikit/theme/base'

const styles = {
  root: {
    background: theme.colors.paleGrey,
    width: rem(42),
    height: rem(20),
    padding: rem('5 0 3'),
    borderRadius: rem(10),
    position: 'absolute',
    top: rem(16),
    right: rem(16),
    fontSize: rem(12),
    lineHeight: rem(12),
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.blue,
    display: 'block',
    [desktop]: {
      'div:hover > div > &': {
        display: 'none',
      },
    },
  },
}

const Label = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Label)