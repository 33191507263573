import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Button, Uploader} from 'mtuikit'
import Modal from './../Modal'
import {adaptive, desktop, MODAL_WIDTH_DESKTOP, rem} from '../../theme/base'
import {S3} from '../../config'
import {useRequestRevisionMutation} from '../../hooks'
import Textarea from '../OrderFormV5/layout/Textarea'

const setRevisionFileType = files => files.map(({name, size, location}) => ({
  name,
  size,
  location,
  type: 'client_revision_request_file'
}))

export const RequestRevision = ({orderId, onClose}) => {
  const classes = useStyles()
  const [comment, setComment] = useState('')
  const [files, setFiles] = useState([])
  const [fileUploading, setFileUploading] = useState(false)
  const requestRevisionMutation = useRequestRevisionMutation()

  const requestRevision = () => {
    requestRevisionMutation.mutate({
      orderId,
      revisionRequest: {comment, files: setRevisionFileType(files)}
    })
    onClose()
  }

  const isValid = comment.trim() && !fileUploading
  return (
    <div className={classes.root}>
      <Modal.Header onClose={onClose}>Request revision</Modal.Header>
      <Modal.Content>
        <Textarea
          placeholder='Please enter details for corrections you need in your task'
          minRows={2}
          value={comment}
          onChange={e => setComment(e.target.value)}
          className={classes.textarea}
        />
        <Uploader
          config={S3}
          files={files}
          onChange={setFiles}
          onStatusChange={status => setFileUploading(status)}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          disabled={!isValid}
          size='large'
          block
          onClick={requestRevision}
        >
          Continue
        </Button>
      </Modal.Footer>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [adaptive]: {
      height: '100%',
    },
    [desktop]: {
      width: rem(MODAL_WIDTH_DESKTOP),
    },
  },
  textarea: {
    marginTop: rem(16),
  },
})
