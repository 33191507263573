export const sizeToHuman = size => {
  const m = parseInt(size?.split(" ")[0])

  const hours = Math.trunc(m / 60)
  const minutes = m % 60

  const parts = []
  if (hours > 0) {
    parts.push(hours === 1 ? '1 hour' : `${hours} hours`)
  }

  if (minutes > 0) {
    parts.push(`${minutes} min`)
  }

  return parts.join(' ')
}
