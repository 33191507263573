import PaymentMethod from './base'
import ContentWrapper from './../ContentWrapper'

const AddPaymentMethod = (props) =>
  <ContentWrapper
    title='Add a new payment method'
    onBack={props.onClose}
  >
    <PaymentMethod {...props}/>
  </ContentWrapper>

export default AddPaymentMethod