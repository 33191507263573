import React, {createContext, useContext, useEffect, useState} from 'react'
import {addPNSubscription, getTutorApi} from '../api'
import {getPic} from '../components/Chat/components/Message'
import {trackValues} from '../lrtracker'

const applicationServerKey = process.env.REACT_APP_SW_KEY_VALUE
const applicationServerKeyName = process.env.REACT_APP_SW_KEY_NAME

const Context = createContext({})

export const usePushNotifications = () => useContext(Context)


export const PushNotificationsProvider = ({children, cid}) => {
  const isEnabled = ('serviceWorker' in navigator) && ('PushManager' in window) && ('Notification' in window)

  const [isError, setIsError] = useState(!isEnabled)
  const [isProcessing, setIsProcessing] = useState(false)
  const [_registration, setRegistration] = useState()
  const [_subscription, setSubscription] = useState()
  const [_permission, setPermission] = useState('Notification' in window && Notification.permission)
  const isReady = !!_registration

  const isDenied = _permission === 'denied'
  const isPending = (_permission === 'default' || !_subscription) && !isError && !isDenied
  const isGranted = _permission === 'granted' && !!_subscription && !isError

  useEffect(() => {
    if (!isEnabled) return
    if (!cid) return
    (async function () {
      try {
        await navigator.serviceWorker.register('/sw.js')

        const available = await navigator.serviceWorker.getRegistration()
        if (!available) {
          setIsError(true)
          return
        }

        const registration = await navigator.serviceWorker.ready
        const subscription = await registration.pushManager.getSubscription()

        const pn = window.localStorage.getItem('pn' + cid)
        if (!pn && subscription) {
          const subscriptionData = JSON.parse(JSON.stringify(subscription))
          await addPNSubscription(applicationServerKeyName, subscriptionData)
        }

        setSubscription(subscription)
        setRegistration(registration)

      } catch (e) {
        setIsError(true)
        return
      }
    })()
  }, [cid])

  const subscribe = async (options) => {
    const {onRequest, onComplete} = options || {}

    if (!isReady) return
    if (isProcessing) return

    setIsProcessing(true)

    let permission = _permission
    let context = {permission}

    onRequest && onRequest(context)

    if (_permission !== 'granted') {
      permission = await window.Notification.requestPermission()
      trackValues('clientBrowserNotificationStatusReceived', permission === 'granted', {context: {}})
    }

    if (permission === 'granted') {
      const subscription = await _registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      })
      const subscriptionData = JSON.parse(JSON.stringify(subscription))
      await addPNSubscription(applicationServerKeyName, subscriptionData)
      window.localStorage.setItem('pn' + cid, '1')
      setSubscription(subscription)
    }

    setPermission(permission)
    onComplete && onComplete(context)
    setIsProcessing(false)

    return {permission}
  }

  const unsubscribe = async () => {
    if (!isReady) return
    if (!_subscription) return

    await _registration.pushManager.unsubscribe()
    window.localStorage.removeItem('pn' + cid)
  }

  return <Context.Provider value={{
    isEnabled,
    isError,
    isReady,
    isProcessing,
    isPending,
    isGranted,
    isDenied,
    subscribe,
    unsubscribe,
  }}>
    {children}
  </Context.Provider>
}

const isNotificationEnabled = () => (
  'Notification' in window
  && 'navigator' in window
  && Notification.permission === 'granted'
)

let FREELANCER_NAMES = {}

export async function showInstantTutorNotification(orderId, message) {
  if (!isNotificationEnabled) return

  const fromId = message.from.split('@')[0]
  if (!FREELANCER_NAMES[fromId]) {
    try {
      const data = await getTutorApi(fromId)
      FREELANCER_NAMES[fromId] = data.name
    } catch (e) {
      return
    }
  }

  const registration = await navigator.serviceWorker.ready
  await registration.showNotification(`Message from ${FREELANCER_NAMES[fromId]}`, {
    body: message.message,
    icon: getPic(message.from),
    data: {url: `/orders/${orderId}/chat`}
  })
}

export async function showInstantSupportNotification(message) {
  if (!isNotificationEnabled) return
  const registration = await navigator.serviceWorker.ready
  await registration.showNotification(`Message from NerdyTutors`, {
    body: message.message,
    icon: getPic(message.from),
    data: {url: `/support-chat`}
  })
}