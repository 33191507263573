import React from 'react'
import Prompt from './../Prompt'

const TaskDeleted = ({
                       onCancel,
                       onClose,
                       onAccept,
                       ...otherProps
                     }) => {
  const _onAccept = () => {
    onAccept();
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='delete'/>
      <Prompt.Title>Task deleted</Prompt.Title>
      <Prompt.Details details={[
        `If you can't estimate the size of the task or it's not clear how it works, please contact our support team. They will be pleased to help you answer your questions.`,
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='primary' onClick={_onAccept}>Contact support</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default TaskDeleted