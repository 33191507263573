import React from 'react'
import {createUseStyles} from 'react-jss'
import {RATE_VALUES} from '../../../mtuikit/theme/base'
import Icon from '../icons'
import {colors, typography} from './consts'

export const OrderRate = ({rate}) => <Rate>
  {
    rate
      ? <>{rate.rate}.0&nbsp;<Icon.Star/>&nbsp;&nbsp;·&nbsp;&nbsp;{RATE_VALUES[rate.rate - 1] || rate.rate}</>
      : 'Rate your tutor'
  }
</Rate>

export const TutorRate = ({rate, name}) => <Rate>{name}&nbsp;{rate}&nbsp;<Icon.Star/></Rate>


const Rate = ({children}) => {
  const classes = useStyle()
  return <div className={classes.root}>
    <div className={classes.rate}>{children}</div>
  </div>
}

const useStyle = createUseStyles({
  root: {
    position: 'relative',
    height: 36
  },
  rate: {
    ...typography.x,
    color: colors.black2,
    position: 'absolute',
    left: 0,
    bottom: 0,
    paddingLeft: '52px',
    whiteSpace: 'nowrap'
  }
})
