import React from 'react'
import {createUseStyles} from 'react-jss'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import theme, {desktop, adaptive} from 'theme/base'
import {Button, Back} from '../../ui'

const useStyle = createUseStyles({
  orderForm: {
    background: theme.colors.white,
    minHeight: '100vh',
    position: 'relative',
    [adaptive]: {
      width: '100%',
    },
    [desktop]: {
      width: '50%',
      minWidth: 504 + 24 + 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '&:before': {
      background: '#F5F8FA',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
  header: {
    marginBottom: 24,
    padding: [16, 0, 15, 0],
    borderBottom: [1, 'solid', theme.colors.paleGrey],
    position: 'relative',
    [adaptive]: {
      padding: [20, 16, 19],
      textAlign: 'center',
    },
  },
  backIcon: {
    width: 24,
    height: 24,
    zIndex: 2,
    cursor: 'pointer',
    [desktop]: {
      marginRight: 12,
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
    },
    [adaptive]: {
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: `translateY(-50%)`,
    },
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: 14,
    lineHeight: 24 / 14,
    fontWeight: 800,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  wrapper: {
    [desktop]: {
      width: 504,
      marginRight: 24,
    },
  },
  card: {
    height: 174,
    border: [1, 'solid', theme.colors.paleGrey],
    borderRadius: 16,
    boxShadow: [
      [0, 8, 12, 'rgba(0, 38, 96, 0.06)'],
      [0, 8, 40, 'rgba(0, 53, 136, 0.15)'],
    ],
    position: 'relative',
    [desktop]: {
      width: 504,
    },
    [adaptive]: {
      width: 'auto',
      marginLeft: 16,
      marginRight: 16,
    },
  },
  cardCircle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'absolute',
    left: 16,
    top: 16,
  },
  cardLine1: {
    height: 20,
    borderRadius: 2,
    position: 'absolute',
    left: 48,
    top: 17,
    [desktop]: {
      width: '68%',
    },
    [adaptive]: {
      width: '80%',
    },
  },
  cardLine2: {
    height: 20,
    borderRadius: 2,
    position: 'absolute',
    left: 48,
    top: 52,
    [desktop]: {
      width: '55%',
    },
    [adaptive]: {
      width: '70%',
    },
  },
  cardLine3: {
    height: 20,
    borderRadius: 2,
    position: 'absolute',
    left: 48,
    top: 80,
    [desktop]: {
      width: '45%',
    },
    [adaptive]: {
      width: '60%',
    },
  },
  item: {
    width: '100%',
    height: 48,
    marginTop: 8,
    position: 'relative',
  },
  itemCircle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'absolute',
    left: 16,
    top: 16,
  },
  itemLine: {
    height: 20,
    borderRadius: 2,
    position: 'absolute',
    left: 48,
    top: 18,
    [desktop]: {
      width: '68%',
    },
    [adaptive]: {
      width: '80%',
    },
  },
  button: {
    position: 'absolute',
    left: 16,
    right: 16,
    bottom: 16,
  },
})

export const ConnectingPage3 = () => {
  const classes = useStyle()
  return (
    <div className={classes.orderForm}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.backIcon}>
            {/* <Logo className={classes.logo} /> */}
            <Back/>
          </div>
          <div className={classes.title}>Create new task for a tutor</div>
        </div>
        <div className={classes.card}>
          <SkeletonTheme baseColor="#F3F5F9" highlightColor="#DDE2F0">
            <Skeleton className={classes.cardCircle}/>
            <Skeleton className={classes.cardLine1}/>
            <Skeleton className={classes.cardLine2}/>
            <Skeleton className={classes.cardLine3}/>
          </SkeletonTheme>
          <Button className={classes.button} disabled>Continue</Button>
        </div>
        {[...Array(4).keys()].map((_, index) =>
          <div className={classes.item} key={index}>
            <SkeletonTheme baseColor="#F3F5F9" highlightColor="#DDE2F0">
              <Skeleton className={classes.itemCircle}/>
              <Skeleton className={classes.itemLine}/>
            </SkeletonTheme>
          </div>
        )}
      </div>
    </div>
  )
}