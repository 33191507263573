import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export const StateLink = ({ to, state, ...otherProps }) =>
  <Link
    to={{
      pathname: to,
      state: { ...useLocation().state, ...state }
    }}
    {...otherProps}
  />