import React from 'react'
import {MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import {useTutorQuery} from '../../../../hooks'

const OrderAssignedEvent = ({data, freelancer_id, ...props}) => {
  const tutorQuery = useTutorQuery(freelancer_id)

  if (!tutorQuery.isFetched) return <div style={{height: 316}}/>

  const tutor = tutorQuery.data
  const message = `Tutor ${tutor.name} accepted your task.`

  return <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{...data, message}}/>
  </MessageWrapper>
}

export default OrderAssignedEvent
