import React from 'react'
import injectSheet from 'react-jss'
import { Icon, utils } from 'mtuikit'
import theme, { rem } from '../../../../theme/base'
import Title from './Title'
import Subtitle from './Subtitle'


const styles = {
  root: {
    background: theme.colors.white,
    height: rem(60),
    padding: [rem(8), rem(16), rem(8), rem(56)],
    position: 'relative',
    cursor: 'pointer',
    transition: 'background 300ms ease',
    '&:hover': {
      background: 'rgba(61, 119, 255, 0.08)',
    }
  },
  avatar: {
    width: rem(32),
    height: rem(32),
    borderRadius: '50%',
    display: 'block',
    position: 'absolute',
    left: rem(16),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  favorite: {
    width: rem(16),
    height: rem(17),
    display: 'block',
    position: 'absolute',
    right: rem(16),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}

const Item = ({
  classes,
  id,
  name,
  subtitle,
  favorite,
  onClick,
}) => <div className={classes.root} onClick={onClick}>
    <img
      className={classes.avatar}
      src={id !== 'default'
        ? utils.getUserAvatarUrl(id)
        : require('./defaultAvatar.svg').default
      }
      alt=''
    />
    <Title>{name}</Title>
    <Subtitle>{subtitle}</Subtitle>
    {favorite &&
      <Icon className={classes.favorite} iconName='favorite' />
    }
  </div>

export default injectSheet(styles)(Item)