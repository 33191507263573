import React from 'react'
import injectSheet from 'react-jss'
import Slider from 'react-slick'
import theme, { rem, adaptive } from '../../../../theme/base'

const OPTIONS = {
  centerPadding: 0,
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  fade: true,
  easing: 'ease-in-out',
}

const styles = {
  root: {
    position: 'relative',
    [adaptive]: {
      width: `calc(100% + ${rem(32)})`,
      marginLeft: rem(-16),
    },
    '& .types-adaptive-slider': {
      marginTop: rem(80),
    },
    // '& .types-adaptive-slider .slick-dots': {
    //   bottom: rem(-20),
    // },
    '& .slick-list': {
      margin: [0, rem(16)],
    },
    '& .slick-track': {
      '&:before': {
        content: '""',
        display: 'table',
      },
      '&:after': {
        content: '""',
        display: 'table',
        clear: 'both',
      },
    },
    '& .slick-slide': {
      float: 'left',
      position: 'relative',
    },
    '& .slick-arrow': {
      width: rem(64),
      height: rem(64),
      padding: 0,
      textIndent: rem(-9999),
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      '&.slick-prev': {
        background: `url(${require('./btn-prev.svg').default}) no-repeat`,
        left: 0,
      },
      '&.slick-next': {
        background: `url(${require('./btn-next.svg').default}) no-repeat`,
        right: 0,
      },
    },
    '& .slick-dots': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: rem(30),
      textAlign: 'center',
      [adaptive]: {
        bottom: 0,
      },
      '& > li': {
        marginLeft: rem(6),
        marginRight: rem(6),
        display: 'inline-block',
        verticalAlign: 'top',
        '& > button': {
          background: '#BAC7E2',
          width: rem(8),
          height: rem(8),
          padding: 0,
          borderRadius: '50%',
          textIndent: rem(-9999),
          display: 'inline-block',
          verticalAlign: 'middle',
          transition: `
            width ${OPTIONS.speed}ms ${OPTIONS.easing},
            height ${OPTIONS.speed}ms ${OPTIONS.easing},
            background ${OPTIONS.speed}ms ${OPTIONS.easing}
          `,
        },
        '&.slick-active': {
          '& > button': {
            background: theme.colors.blue,
            width: rem(12),
            height: rem(12),
          },
        },
      },
    },
  },
}

const _Slider = ({
  classes,
  items,
  options = {},
}) =>
  <div className={classes.root}>
    <Slider {...({...OPTIONS, ...options})}>
      {items.map((item, index) =>
        <img src={item} alt='' key={index} />
      )}
    </Slider>
  </div>

export default injectSheet(styles)(_Slider)