import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    width: rem(25),
    height: rem(25),
    padding: [10, 3],
    position: 'absolute',
    top: rem(13),
    right: rem(9),
    cursor: 'pointer',
  },
  dot: {
    background: theme.colors.slateGrey,
    width: rem(5),
    height: rem(5),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    pointerEvents: 'none',
    '& + &': {
      marginLeft: rem(2),
    },
  },
}

const Dots = ({ 
  classes,
  id, 
  onClick,
}) =>
  <div id={id} className={classes.root} onClick={onClick}>
    <div className={classes.dot} />
    <div className={classes.dot} />
    <div className={classes.dot} />
  </div>

export default injectSheet(styles)(Dots)