import React, {useEffect} from 'react'
import {useLocation, Redirect} from 'react-router-dom'
import {useActions} from './../../actions'

const NewOrderRedirect = () => {
  const location = useLocation()
  const actions = useActions()

  useEffect(() => {
    actions.showOrderForm(location.state)
  }, [location, actions])

  return <Redirect to={{pathname: '/orders'}}/>
}

export default NewOrderRedirect