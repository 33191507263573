import React from 'react'
import injectSheet from 'react-jss'
import { RATE_VALUES } from '../../mtuikit/theme/base'

const styles = {
  root: {
    display: 'inline-block',
    verticalAlign: 'top',
  }
}

const RateText = ({
  value,
  classes,
}) =>
  <div className={classes.root}>
    ·&nbsp;&nbsp;
		{RATE_VALUES[value - 1] || value}
  </div>

export default injectSheet(styles)(RateText)
