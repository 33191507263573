import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../theme/base'
import {StateLink} from './StateLink'

const styles = {
  root: {
    marginTop: rem(40),
    padding: [0, rem(24)],
    textAlign: 'center',
    color: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(24),
    '& a': {
      color: 'inherit',
      fontWeight: 600,
    },
  },
}

const Action = ({
  classes,
  text,
  link,
  to,
  state,
}) =>
  <div className={classes.root}>
    {text}
    {' '}
    <StateLink to={to} state={state}>
      {link}
    </StateLink>
  </div>

export default injectSheet(styles)(Action)