import React from 'react'
import Prompt from './../Prompt'

const TaskDeleted = ({
                       onCancel,
                       onClose,
                       onAccept,
                       ...otherProps
                     }) => {
  const _onAccept = () => {
    onAccept()
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='attention'/>
      <Prompt.Title>Chat history is not available at the moment</Prompt.Title>
      <Prompt.Details details={[
        `Please check back soon or message our Customer Support Team.`,
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='primary' onClick={_onAccept}>Contact support</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default TaskDeleted