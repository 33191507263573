import React from 'react'
import {createUseStyles} from 'react-jss'
import {desktop, adaptive} from 'theme/base'
import classnames from 'classnames'

const useStyle = createUseStyles({
  overlay: {
    display: 'none',
    '&.desktop': {
      [desktop]: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        display: 'block',
      },
      '&:not(.adaptive)': {
        [adaptive]: {
          display: 'none',
        },
      },
    },
    '&.adaptive': {
      [adaptive]: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        display: 'block',
      },
      '&:not(.desktop)': {
        [desktop]: {
          display: 'none',
        },
      },
    },
  },
})

const Overlay = ({desktop, adaptive, zIndex = 3, onClick}) => {
  const classes = useStyle()
  return (
    <div className={classnames(classes.overlay, {desktop, adaptive})} style={{zIndex}} onClick={onClick}/>
  )
}

export default Overlay
