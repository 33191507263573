import React from 'react'
import injectSheet from 'react-jss'
import { rem } from '../../mtuikit/theme/base'

const styles = {
  root: {
    background: '#BAC7E2',
    width: rem(1),
    height: rem(20),
    margin: `0 ${rem(8)}`,
    display: 'inline-block',
    verticalAlign: 'middle',
  }
}

const TypeDivider = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(TypeDivider)