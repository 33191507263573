import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import {Icon} from '../Icon'
import Message from './Message'

const styles = {
  root: {
    background: theme.colors.brightOrangeTwo,
    padding: [rem(7), rem(40), rem(3)],
    bottom: rem(40),
  },
  icon: {
    width: rem(21),
    height: rem(18),
    position: 'absolute',
    right: rem(8),
    top: rem(7),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      pointerEvents: 'none',
    },
  },
}

const Offline = ({ classes, onClick }) =>
  <Message className={classes.root}>
    Offline. Trying to connect...
    <div className={classes.icon} onClick={onClick}>
      <Icon iconName='refresh' />
    </div>
  </Message>

export default injectSheet(styles)(Offline)