import React from 'react'
import injectSheet from 'react-jss'
import Slider from 'react-slick'
import theme, {rem, content2, ui, adaptive} from '../../../theme/base'

const styles = {
  root: {
    padding: [rem(80), 0, rem(160)],
    textAlign: 'center',
    position: 'relative',
    overflowX: 'hidden',
    [adaptive]: {
      padding: [rem(64), 0],
    },
  },
  content: content2,
  title: {
    ...ui.title,
  },
  list: {
    marginTop: rem(40),
    whiteSpace: 'nowrap',
    '& .slick-track': {
      '&:before': {
        content: '""',
        display: 'table',
      },
      '&:after': {
        content: '""',
        display: 'table',
        clear: 'both',
      },
    },
    '& .slick-slide': {
      float: 'left',
      position: 'relative',
    },
  },
  item: {
    maxWidth: rem(264),
    width: '75.74vw',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'normal',
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'],
      [0, rem(16), rem(40), 'rgba(0, 53, 136, 0.15)']
    ],
    borderRadius: rem(24),
    position: 'relative',
    '&:before': {
      content: '""',
      width: rem(12),
      height: rem(12),
      background: theme.colors.white,
      borderRadius: rem(2),
      position: 'absolute',
      left: rem(24),
      bottom: rem(-6),
      transform: 'rotateZ(45deg)',
    },
  },
  header: {
    padding: [rem(16), rem(16), 0, rem(16)],
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: rem(24),
    height: rem(24),
    marginRight: rem(8),
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& > img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  type: {
    fontSize: rem(15),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.2),
    color: theme.colors.slateGrey,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  text: {
    width: rem(264),
    height: rem(104),
    marginTop: rem(14),
    padding: [0, rem(16), rem(16), rem(16)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(28),
    letterSpacing: rem(0.11),
    fontFamily: theme.fontFamilies.Manrope,
    textAlign: 'left',
    [adaptive]: {
      width: rem(264),
    },
  },
  disclaimer: {
    width: rem(1016),
    margin: [rem(80), 'auto', 0],
    padding: [rem(40), rem(104), 0],
    borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    fontSize: rem(16),
    lineHeight: rem(24),
    letterSpacing: rem(0.08),
    color: theme.colors.slateGrey,
    textAlign: 'center',
    [adaptive]: {
      width: 'auto',
      marginLeft: rem(16),
      marginRight: rem(16),
      padding: [rem(48), rem(16), 0],
    },
  },
}

const ITEMS = [
  {
    type: `Math`,
    text: `I'm stuck on this Math problem :(`,
    icon: require(`./Icons/math.svg`).default,
  },
  {
    type: 'Statistics',
    text: `I need someone to help me prep for my Statistics midterm`,
    icon: require(`./Icons/statistics.svg`).default,
  },
  {
    type: `Business and Management`,
    text: `Here is a business email I want to send out. Does it sound ok?`,
    icon: require(`./Icons/business.svg`).default,
  },
  {
    type: 'Physics',
    text: `I have a few questions about gravitational acceleration and how to calculate it.`,
    icon: require(`./Icons/physics.svg`).default,
  },
  {
    type: `Academic Writing`,
    text: `What are the differences between MLA and APA formats?`,
    icon: require(`./Icons/writing.svg`).default,
  },
  {
    type: `Chemistry`,
    text: `Is it the right formula to be used in this case?`,
    icon: require(`./Icons/chemistry.svg`).default,
  },
  {
    type: `Business and Management`,
    text: `Need help with my Business Management research`,
    icon: require(`./Icons/business.svg`).default,
  },
  {
    type: `Economics`,
    text: `I don’t get this Economics concept. Can you please explain it to me?`,
    icon: require(`./Icons/economics.svg`).default,
  },
  {
    type: `Sociology`,
    text: `I created a list of questions for a focus group. Please let me know what you think.`,
    icon: require(`./Icons/sociology.svg`).default,
  },
]

const OPTIONS = {
  centerPadding: 0,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  speed: 3000,
}

const Examples = ({
  classes,
  title,
}) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>{title}</div>
    </div>
    <div className={classes.list}>
      <Slider {...OPTIONS}>
        {ITEMS.map(({icon, type, text}, index) =>
          <div key={index} className={classes.item} style={{width: rem(288)}}>
            <div className={classes.header}>
              <img className={classes.icon} src={icon} alt='' />
              <div className={classes.type}>{type}</div>
            </div>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        )}
      </Slider>
    </div>
    <div className={classes.disclaimer}>
      Please note that tutors provide help
      for the purpose of facilitating learning
      and will not help students engage in any form
      of academic dishonesty or violate school policies.
    </div>
  </div>

export default injectSheet(styles)(Examples)