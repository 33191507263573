import React from 'react'
import {createUseStyles} from 'react-jss'
import {Button} from 'mtuikit'
import theme, {rem, adaptive} from './../../../../../theme/base'
import {Wrapper, Header, Content, Item, Helper} from '../Layout'
import {useActions} from './../../../../../actions'

const PaymentFailed = ({onClose}) => {
  const classes = useStyles()
  const actions = useActions()
  return (
    <Wrapper>
      <Header
        className={classes.header}
        type='failed'
        title='Payment Failed'
        subtitle={`Unfortunately your payment didn't go through`}
      />
      <Content>
        <div className={classes.title}>What can I do?</div>
        <Item icon='failed'>Double-check your card details</Item>
        <Item icon='failed'>Make sure you have a sufficient card balance</Item>
        <Item icon='failed'>Try using a different card or payment method</Item>
        <Item icon='failed'>
          Nothing helps?{' '}
          <span className={classes.link} onClick={() => actions.showSupportChat()}>
            Contact our support team.
          </span>
        </Item>
        <Helper/>
        <Button
          block
          type='primary'
          size='large'
          className={classes.button}
          onClick={() => onClose()}
        >
          Back to checkout
        </Button>
      </Content>
    </Wrapper>
  )
}

const useStyles = createUseStyles({
  title: {
    paddingBottom: rem(24),
    color: theme.colors.charcoalGrey,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontFamily: theme.fontFamilies.Manrope,
  },
  link: {
    color: theme.colors.blue,
    fontWeight: 600,
    cursor: 'pointer',
  },
  button: {
    [adaptive]: {
      width: `calc(100vw - ${rem(48)}) !important`,
      position: 'fixed',
      left: rem(24),
      right: rem(24),
      bottom: rem(24),
    },
  },
})

export default PaymentFailed