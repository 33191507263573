import React, { useState } from 'react'
import {Collapse} from 'react-collapse'
import injectSheet from 'react-jss'
import theme, { rem, content2, desktop, adaptive } from '../../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(80), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
  },
  content: content2,
  title: {
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(24),
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: rem(0.14),
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
  },
  list: {
    marginTop: rem(16),
    textAlign: 'center',
  },
  column: {
    width: `calc(50% - ${rem(40)})`,
    display: 'inline-block',
    verticalAlign: 'top',
    [desktop]: {
      '& + &': {
        marginLeft: rem(80),
      },
    },
    [adaptive]: {
      width: '100%',
    },
  },
  item: {
    marginTop: rem(24),
  },
  question: {
    background: theme.colors.blue,
    padding: [rem(12), rem(40), rem(12), rem(20)],
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    lineHeight: rem(28),
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.colors.white,
    boxShadow: [0, rem(4), rem(8), 'rgba(86, 93, 107, 0.1)'],
    borderRadius: [rem(24), rem(24), rem(2), rem(24)],
    cursor: 'pointer',
    position: 'relative',
    transition: 'background 300ms ease',
    [adaptive]: {
      fontSize: rem(16),
      textAlign: 'left',
    },
    '&:hover': {
      background: '#366CE5',
    },
    '&:active': {
      background: '#3060CC',
    },
    '& img': {
      width: rem(24),
      height: rem(24),
      display: 'block',
      position: 'absolute',
      right: rem(14),
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  answer: {
    marginTop: rem(16),
    padding: [rem(8), rem(8), rem(8), rem(12)],
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(15),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.47,
    letterSpacing: rem(0.09),
    color: theme.colors.slateGrey,
    boxShadow: [
      [0, rem(8), rem(12), 'rgba(0, 38, 96, 0.06)'], 
      [0, rem(8), rem(40), 'rgba(0, 53, 136, 0.15)']
    ],
    borderRadius: [rem(20), rem(20), rem(20), rem(2)],
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      maxWidth: 'none',
    },
  },
}

const Faq = ({
  classes,
  title,
  items,
}) => {
  const initialState = items.map(_ => false)
  const [collapse, setCollapse] = useState(initialState)
  const onOpen = (idx) => setCollapse(collapse.map((item, index) => {
    if (idx === index) {
      item = !item
    }
    return item
  }))

  const count = 3

  const _items = [
    items.slice(0, count),
    items.slice(count, items.length + 1),
  ]

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <ul className={classes.list}>
          {_items.map((array, index) =>
            <div className={classes.column} key={index}>
              {array.map(({ question, answer }, idx) => {
                const key = index * array.length + idx
                const isOpened = collapse[key]
                return (
                  <li className={classes.item} onClick={() => onOpen(key)} key={question}>
                    <div className={classes.question}>
                      {isOpened
                        ? <img src={require('./minus.svg').default} alt='' />
                        : <img src={require('./plus.svg').default} alt='' />
                      }
                      <span dangerouslySetInnerHTML={{ __html: question }} />
                    </div>
                    <Collapse isOpened={isOpened}>
                      <div
                        className={classes.answer}
                        dangerouslySetInnerHTML={{ __html: answer }}
                      />
                    </Collapse>
                  </li>
                )
              })}
            </div>
          )}
          </ul>
      </div>
    </div>
  )
}

export default injectSheet(styles)(Faq)