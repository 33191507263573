export const getStageNameAndContext = (step, stages, {price, cleanedOrder, cleanedFiles, cleanedRequestedTutorId}) => {
  const requestedTutorId = cleanedRequestedTutorId ? cleanedRequestedTutorId : null
  const countFiles = cleanedFiles.length
  const {subject, details, size, deadline, output_formats, let_tutor_decide} = cleanedOrder
  switch (step) {
    case 1:
      return ['SelectSubjectTutorStageCompleted', {subject, requestedTutorId}];
    case 2:
      return ['SelectDescriptionFilesOutputStageCompleted', {
        details,
        countFiles,
        outputFormats: output_formats
      }]
    case 3:
      return ['SelectDeadlineStageCompleted', {deadline}]
    case 4:
      return ['SelectSizeStageCompleted', {size, letTutorDecide: let_tutor_decide, price}]
    default:
      return [undefined, undefined]
  }
}