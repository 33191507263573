import React, {useState, useEffect} from 'react'
import classnames from 'classnames'
import {Link} from 'react-router-dom'
import injectSheet from 'react-jss'
import MobileMenu from './../../../sections/MobileMenu'
import theme, {rem, content, adaptive, desktop} from '../../../theme/base'

const styles = {
  content,
  headerRelative: {
    height: rem(56),
    display: 'none',
    zIndex: 3,
    [desktop]: {
      display: 'block',
    },
  },
  headerFixed: {
    background: theme.colors.white,
    height: rem(56),
    boxShadow: [0, rem(4), rem(8), 0, 'rgba(86, 92, 107, 0.1)'],
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    display: 'none',
    [adaptive]: {
      display: 'block',
    },
    '& $logo': {
      [adaptive]: {
        left: rem(16),
      },
    },
    '& $hamburger': {
      [adaptive]: {
        right: rem(16),
      },
    },
  },
  hamburger: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(14),
    right: rem(8),
    display: 'none',
    cursor: 'pointer',
    zIndex: 2,
    [adaptive]: {
      display: 'block',
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  logo: {
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'absolute',
    top: rem(16),
    left: rem(40),
    zIndex: 2,
    [adaptive]: {
      left: rem(8),
    },
    '& img': {
      width: rem(160),
      height: rem(24),
      display: 'block',
    },
  },
  links: {
    position: 'absolute',
    top: rem(8),
    right: rem(40),
    zIndex: 2,
    [adaptive]: {
      right: rem(48),
    },
  },
  actions: {
    display: 'inline-block',
    verticalAlign: 'middle',
    [adaptive]: {
      display: 'none',
    },
  },
  link: {
    padding: [rem(5), 0],
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    lineHeight: rem(24),
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: rem(0.12),
    textAlign: 'center',
    color: theme.colors.blue,
    border: [rem(3), 'solid', 'transparent'],
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    transition: 'color 300ms ease',
    '&:hover': {
      color: '#366CE5',
    },
    '&:active': {
      color: '#366CE5',
    },
  },
  signup: {
    width: rem(160),
    marginLeft: rem(24),
    padding: [rem(5), 0],
    fontFamily: theme.fontFamilies.Manrope,
    fontSize: rem(18),
    lineHeight: rem(24),
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: rem(0.12),
    textAlign: 'center',
    color: theme.colors.blue,
    border: [rem(3), 'solid', theme.colors.blue],
    borderRadius: rem(20),
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    transition: 'color 300ms ease, border 300ms ease',
    [adaptive]: {
      display: 'none',
    },
    '&:hover': {
      color: '#366CE5',
      borderColor: '#366CE5',
    },
    '&:active': {
      color: '#366CE5',
      borderColor: '#366CE5',
    },
  },
  divider: {
    [desktop]: {
      background: '#BAC7E2',
      width: rem(1),
      height: rem(24),
      margin: [0, rem(40)],
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    [adaptive]: {
      display: 'none',
    },
  },
}

const Header = ({
                  classes,
                  hasFixedState,
                  customRelativeClassName,
                  showLogin,
                }) => {
  const [scrolled, setScrolled] = useState(false)
  const [openedMenu, setOpenedMenu] = useState(false)

  const onOpenMenu = () => setOpenedMenu(true)
  const onCloseMenu = () => setOpenedMenu(false)

  const onScroll = () => setScrolled(window.pageYOffset > 0)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const isFixedHeader = scrolled || hasFixedState

  const Logo = () =>
    <Link className={classes.logo} to='/'>
      <img src={require('./logo.svg').default} alt=''/>
    </Link>

  const Hamburger = () =>
    <div className={classes.hamburger} onClick={onOpenMenu}>
      <img src={require('./hamburger-blue.svg').default} alt=''/>
    </div>

  return (
    <>
      <div className={classnames(classes.headerRelative, customRelativeClassName)}>
        <Hamburger/>
        <Logo/>
        {showLogin &&
        <div className={classes.links}>
          {/*<Link className={classes.link} to={{pathname: '/signup', state: {ref: 'parent'}}}>For parents</Link>*/}
          {/*<div className={classes.divider}/>*/}
          <div className={classes.actions}>
            <Link className={classes.link} to='/login'>Login</Link>
            <Link className={classes.signup} to='/signup'>Sign up</Link>
          </div>
        </div>
        }
      </div>
      {isFixedHeader &&
      <div className={classes.headerFixed}>
        <Logo/>
        <Hamburger/>
      </div>
      }
      <MobileMenu
        opened={openedMenu}
        showLogin={showLogin}
        onClose={onCloseMenu}
      />
    </>
  )
}

export default injectSheet(styles)(Header)