import React from 'react'
import Prompt from './../Prompt'
import {useRejectTutorMutation} from "../../hooks";

const RejectTutor = ({
                       orderId,
                       tutorId,
                       onClose,
                       ...otherProps
                     }) => {
  const rejectTutorMutation = useRejectTutorMutation()
  const rejectTutor = () => {
    rejectTutorMutation.mutate({orderId, freelancerId: tutorId})
    onClose()
  }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='reject'/>
      <Prompt.Title>Reject Tutor</Prompt.Title>
      <Prompt.Details details={[
        'If you reject this tutor, we will start searching for a new one. It may take up to a few hours to find a new one.',
        'Are you sure you want to reject this tutor?',
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='primary' onClick={rejectTutor}>Reject</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default RejectTutor