import React from 'react'
import {useDraftMessage, useNodeApi, useSaveMessage} from '@connect/connect-xmpp-v2'
import {useSendTextDraft, useSendFilesDraft} from '../useSendMessage'
import {ChatMessage, MessageText, MessageWrapper} from '@connect/connect-ui-js-v2'
import OrderOfferedEvent from './events/OrderOfferedEvent'
import OrderConnectedEvent from './events/OrderConnectedEvent'
import OrderDisconnectedOrRejectedEvent from './events/OrderDisconnectedOrRejectedEvent'
import OrderCompletedEvent from './events/OrderCompletedEvent'
import OrderClosedEvent from './events/OrderClosedEvent'
import OrderEstimatedEvent from './events/OrderEstimatedEvent'
import LiveSessionScheduledEvent from './events/LiveSessionScheduledEvent'
import LiveSessionRescheduledEvent from './events/LiveSessionRescheduledEvent'
import LiveSessionUpcomingEvent from './events/LiveSessionUpcomingEvent'
import LiveSessionCanceledEvent from './events/LiveSessionCanceledEvent'
import RateRequestedEvent from './events/RateRequestedEvent'
import SupportUnavailable from './events/SupportUnavailable'
import OrderAssignedEvent from './events/OrderAssignedEvent'
import EstimatePaidEvent from './events/EstimatePaidEvent'
import OnboardRequestOutputs from './events/OnboardRequestOutputs'
import OnboardRequestPhone from './events/OnboardRequestPhone'
import OnboardRequestSupport from './events/OnboardRequestSupport'
import OnboardRequestInstructions from './events/OnboardRequestInstructions'
import OnboardRequestInstructions2 from './events/OnboardRequestInstructions2'
import OnboardAddedTaskInstructionsEvent from './events/OnboardAddedTaskInstructionsEvent'
import OnboardEnableNotifications from './events/OnboardEnableNotifications'
import OnboardRequestTopTutorAddon from './events/OnboardRequestTopTutorAddon'
import OfferBalanceTopUpBundleEvent from './events/OfferBalanceTopUpBundleEvent'
import OnboardGreetings from './events/OnboardGreetings'
import SupportRecapMessage from './events/SupportRecapMessage'
import Layout from './events/layout'

const {B} = Layout

const bot = require('../../../assets/images/bot.svg').default

export const getPic = from => {
  if (!from) return ''

  const username = from.split('@')[0]
  if (username === 'smstool') return bot
  if (username === 'onboard') return bot
  return `${process.env.REACT_APP_AVATAR_CDN}/${username}`
}

const DraftMessage = ({messageId, data, draft, chatConfig, getPic}) => {
  const {isConnected, removeRef} = useNodeApi(chatConfig)
  const {save, retry, error, isLoading} = useSaveMessage(chatConfig)
  const mergedData = {...data, ...draft}
  const available = !error && !isLoading && draft.isDraft && isConnected

  const hook = draft.files
    ? useSendFilesDraft
    : useSendTextDraft

  const remove = () => removeRef(messageId)
  const fileUpload = hook(save, remove, messageId, draft, available)

  return <ChatMessage data={mergedData} retry={retry} remove={remove} isLoading={isLoading} fileUpload={fileUpload}
                      error={error} getPic={getPic}/>
}

const processDeletedFilesFlag = data => {
  if (data?.files) {
    const availableFiles = data.files.filter(({deleted}) => !deleted)
    return {
      data: {...data, files: availableFiles},
      isAllFilesDeleted: availableFiles.length === 0
    }
  }

  if (data?.event?.event_params?.files) {
    const availableFiles = data.event.event_params.files.filter(({deleted}) => !deleted)
    const event = {
      ...data.event,
      event_params: {
        ...data.event.event_params,
        files: availableFiles
      }
    }
    return {
      data: {...data, event},
      isAllFilesDeleted: availableFiles.length === 0
    }
  }

  return {data, isAllFilesDeleted: false}
}

const SentMessage = ({data, getPic}) => {
  return <ChatMessage data={data} getPic={getPic}/>
}

export const Message = ({messageId, messageData: data_, chatConfig, pic = null}) => {
  const {data: draft_} = useDraftMessage(messageId, chatConfig)

  const {data, isAllFilesDeleted: isDataAllFilesDeleted} = processDeletedFilesFlag(data_)
  const {data: draft, isAllFilesDeleted: isDraftAllFilesDeleted} = processDeletedFilesFlag(draft_)

  const additionalData = (draft_?.event?.event_params?.details || data_.event?.event_params?.details)
  if (!additionalData && (isDataAllFilesDeleted || isDraftAllFilesDeleted)) return <ChatMessage
    data={{...data, type: 'message', message: 'The message was deleted'}} getPic={getPic}
  />

  return data.event
    ? React.createElement(EVENT_MAP[data.event.event_name] || EVENT_MAP['undefined'], {data, ...data.event.event_params, ...(pic && {getPic})})
    : draft && draft.isDraft
      ? <DraftMessage messageId={messageId} data={data} draft={draft} chatConfig={chatConfig} getPic={pic && getPic}/>
      : <SentMessage messageId={messageId} data={data} chatConfig={chatConfig} getPic={pic && getPic}/>
}

const createEventMessage = message => ({data, ...props}) =>
  <MessageWrapper {...props} data={{...data, type: 'message'}}>
    <MessageText {...props} data={{...data, message}}/>
  </MessageWrapper>

const EVENT_MAP = {
  'onboard:greetings': OnboardGreetings,
  'onboard:request_outputs': OnboardRequestOutputs,
  'onboard:request_phone': OnboardRequestPhone,
  'onboard:request_support': OnboardRequestSupport,
  'onboard:request_instructions': OnboardRequestInstructions,
  'onboard:request_instructions2': OnboardRequestInstructions2,
  'onboard:enable_notifications': OnboardEnableNotifications,
  'onboard:request_top_tutor_addon': OnboardRequestTopTutorAddon,
  'onboard:added_task_instructions': OnboardAddedTaskInstructionsEvent,
  'crm:request_top_tutor_addon': OnboardRequestTopTutorAddon,
  'crm:bundle_balance_order': OfferBalanceTopUpBundleEvent,
  'crm:order_offered': OrderOfferedEvent,
  'crm:chat_connected': OrderConnectedEvent,
  'crm:chat_idle': createEventMessage('crm:chat_idle'),
  'crm:chat_disconnected': OrderDisconnectedOrRejectedEvent,
  'crm:order_assigned': OrderAssignedEvent,
  'crm:order_completed': OrderCompletedEvent,
  'crm:order_closed': OrderClosedEvent,
  'crm:order_estimated': OrderEstimatedEvent,
  'crm:estimate_paid': EstimatePaidEvent,
  'crm:estimate_declined': createEventMessage('You have declined tutor\'s additional time request.'),
  'crm:estimate_canceled': createEventMessage('Additional time request was canceled.'),
  'crm:estimate_expired': createEventMessage('Additional time request has just expired.'),
  'crm:live_session_scheduled': LiveSessionScheduledEvent,
  'crm:live_session_rescheduled': LiveSessionRescheduledEvent,
  'crm:live_session_upcoming': LiveSessionUpcomingEvent,
  'crm:live_session_canceled': LiveSessionCanceledEvent,
  'crm:revision_requested': createEventMessage('You have just submitted a task revision request. Your tutor will take a look at your feedback and get back to you ASAP.'),
  'crm:rate_requested': RateRequestedEvent,
  'crm:freelancer_rejected': OrderDisconnectedOrRejectedEvent,
  'support:unavailable_time': SupportUnavailable,
  'smstool:send_recap_message': SupportRecapMessage,
  'crm:added_top_tutor_addon': createEventMessage(<>Great! Now we are looking for a <B>TOP tutor</B> for your task.</>),
  'crm:live_session_requested': createEventMessage('Please, let your tutor know about your preferred session time so they could schedule it for you'),
  'crm:order_refunded': () => null,
  'undefined': () => null
}



